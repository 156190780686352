import React from 'react';
import { FiLayers, FiMoon, FiPower, FiSun } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FaPowerOff, FaTable } from 'react-icons/fa';
import { useTheme } from '../../../contexts/theme';
import { useAuth } from '../../../contexts/auth';

function DropDownFooter() {
  const { signOut, setModulo } = useAuth();
  const { isDarkMode, toggleTheme } = useTheme();
  const history = useHistory();

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle color="link" className="d-flex p-0">
        <FaPowerOff size={18} className="button-pdv" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={toggleTheme}>
          {isDarkMode ? (
            <>
              <FiSun className="mr-1" color="var(--body-color)" size={18} />
              Tema
            </>
          ) : (
            <>
              <FiMoon className="mr-1" color="var(--body-color)" size={18} />
              Tema
            </>
          )}
        </DropdownItem>
        <div tabIndex="-1" className="dropdown-divider" />
        <DropdownItem
          onClick={() => {
            history.push('/');
          }}
        >
          <FaTable className="mr-1" size={18} />
          Módulos
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setModulo(null);
            history.push('/erp');
          }}
        >
          <FiLayers className="mr-1" size={18} />
          Menu erp
        </DropdownItem>
        <div tabIndex="-1" className="dropdown-divider" />
        <DropdownItem
          onClick={() => {
            signOut();
            history.push('/');
          }}
        >
          <FiPower className="mr-1" size={18} />
          Sair
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default DropDownFooter;
