import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { IoSearch } from 'react-icons/io5';
import { FiSettings } from 'react-icons/fi';
import { InputGroup, Select } from 'components/unform/index';
import apiTelecom from 'services/apiTelecom';
import useValidator from 'hooks/useValidator';
import getQuery from 'utils/getQuery';
import { maskDocumento } from 'utils';

function ListarUsuariosTelecom() {
  const [dados, setDados] = useState({ page: 1 });
  const [status, setStatus] = useState('Ativo');
  const filtrosRef = useRef();
  const debounce = useRef();
  const history = useHistory();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = `${getQuery(filtrosRef.current.getData())}&status=${status}`;
    try {
      showLoader();
      const { data } = await apiTelecom.get(
        `/listusuarioramal?page=${page || 1}&${query}`
      );
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await apiTelecom.get(
          `/listusuarioramal?status=${status}`
        );
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, [status, history]);

  return (
    <>
      <div className="page-header">
        <Row>
          <Col lg={9} xs={5}>
            <p className="mb-0 small mt-n1 text-muted">Configurações</p>
            <h4>Usuários</h4>
          </Col>
          <Col lg={3} xs={7}>
            <Link
              to="/telecom/configuracoes/usuarios/novo"
              className="btn-block block btn btn-primary"
            >
              Adicionar usuário
            </Link>
          </Col>
        </Row>
      </div>

      <Card>
        <div className="px-3">
          <Form onSubmit={() => loadMore()} ref={filtrosRef}>
            <Row>
              <Col lg={8}>
                <InputGroup
                  name="nome"
                  className="form-control-group no-border mb-0"
                  placeholder="Pesquisar usuário"
                  formGroupClass="mb-3"
                  prepend={<IoSearch />}
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="id_perfil"
                  formGroupClass="mb-3"
                  onChange={filtrarPesquisa}
                  className="form-control no-border mb-0"
                  data={[
                    {
                      value: '',
                      label: 'Cargo',
                    },
                    {
                      value: '1',
                      label: 'Operador',
                    },
                    {
                      value: '2',
                      label: 'Supervisor',
                    },
                    {
                      value: '3',
                      label: 'Gestor',
                    },
                  ]}
                />
              </Col>
              <Nav className="nav-filter" tabs>
                <NavItem>
                  <button
                    className={'btn-nav small '.concat(
                      status === 'Ativo' ? 'active' : ''
                    )}
                    type="button"
                    onClick={() => setStatus('Ativo')}
                  >
                    <div className="text-center">
                      ATIVO
                      <br /> 0
                    </div>
                  </button>
                </NavItem>
                <NavItem>
                  <button
                    className={'btn-nav small '.concat(
                      status === 'Inativo' ? 'active' : ''
                    )}
                    type="button"
                    onClick={() => setStatus('Inativo')}
                  >
                    <div className="text-center">
                      INATIVO
                      <br /> 0
                    </div>
                  </button>
                </NavItem>
              </Nav>
            </Row>
          </Form>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cargo</th>
              <th>Ramal</th>
              <th>Email</th>
              <th>CPF</th>
              <th>Status</th>
              <th className="text-right">Ações &nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="Cargo">
                  {m?.Perfil?.descricao || m?.id_perfil}
                </td>
                <td data-label="Ramal">{m.ramal}</td>
                <td data-label="Email">{m.email}</td>
                <td data-label="cpf">{maskDocumento(m.cpf)}</td>
                <td data-label="Status">{m.status}</td>
                <td data-label="Ações">
                  <div className="d-flex justify-content-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          tag={Link}
                          to={`/telecom/configuracoes/usuarios/novo?id=${m.id}`}
                        >
                          Editar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ListarUsuariosTelecom;
