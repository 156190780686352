import React, { useRef, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';
import customStyles, { theme } from './stylesSelect';

export default function ReactSelect({ name, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultVal = useMemo(() => {
    if (defaultValue?.value) {
      return defaultValue;
    }

    const obj = [].concat(rest.options).find((f) => f.value === defaultValue);
    if (obj) {
      return obj;
    }

    return {
      label: defaultValue,
      value: defaultValue,
    };
  }, [defaultValue, rest.options]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        // ref.select.state.value = value;
        // ref.select.updater.enqueueForceUpdate(ref);
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Select
        defaultValue={defaultVal}
        ref={selectRef}
        classNamePrefix="react-select"
        className={error && 'error'}
        styles={customStyles}
        theme={theme}
        defaultOptions
        placeholder="Digite para pesquisar"
        noOptionsMessage={() => 'Sem registros'}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
