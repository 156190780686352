import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Card,
  Col,
  Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Input,
  MaskInput,
  Select,
  ReactSelect,
  Image,
  Radio,
  NumberFormat,
} from '../../../components/unform';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';

function Dashboard() {
  const formRef = useRef();
  const [activeTab, setActiveTab] = useState('1');
  const [planos, setPlanos] = useState([]);
  const [mccs, setMccs] = useState([]);
  const [bancos, setBancos] = useState([]);
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const history = useHistory();
  const [informarBanco, setInformarBanco] = useState(
    history?.location?.state?.abrir_conta === 'nao'
  );

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      document
        .querySelector('.dashboard')
        .scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  async function abrirConta() {
    const dados = formRef.current.getData();

    dados.representante_codigo_pais = 'BR';

    const formData = new FormData();

    Object.entries(dados).forEach(([key, value]) => {
      if (
        [
          'representante_legal_documento_frente',
          'representante_legal_documento_verso',
          'selfie',
        ].includes(key)
      ) {
        if (value.file) {
          formData.append('file', value.file, `${key}@${value.file.name}`);
        } else {
          formData.append(key, history?.location?.state?.[key]);
        }
      } else {
        formData.append(key, value);
      }
    });

    try {
      await api.post('/criar-vendedor/pj', formData);
      closeLoader();
      toast('Vendedor criado com sucesso', { type: 'success' });
      history.push('/pay/vendedores');
    } catch (err) {
      closeLoader();
      if (err?.response?.data?.type === 'swal') {
        Swal.fire({
          title: err.mensagem,
          html: err?.response?.data?.tratado,
          icon: 'error',
        });
      } else {
        toast(err.mensagem);
      }
    }
  }

  const processChange = debounce(() => abrirConta());

  async function handleSubmit(dados) {
    const { errors, hasError } = validator(dados, [
      'complemento',
      'representante_complemento',
      'representante_legal_tipo_documento',
      'representante_legal_documento_frente',
      'representante_legal_documento_verso',
      'selfie',
      'responsavel_conta',
    ]);

    formRef.current.setErrors(errors);

    if (hasError) {
      if (Object.keys(errors).some((s) => !s.includes('representante'))) {
        toggle('1');
      }

      toast();
      return;
    }

    showLoader();

    processChange();
  }

  async function getCep(cepStr = '', rep) {
    const cep = String(cepStr).replace(/[^A-Z0-9]/gi, '');
    const str = rep ? 'representante_' : '';
    if (cep.length !== 8) {
      return;
    }
    showLoader();
    try {
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      formRef.current.setFieldValue(`${str}cidade`, data.city);
      formRef.current.setFieldValue(`${str}bairro`, data.neighborhood);
      formRef.current.setFieldValue(`${str}estado`, data.state);
      formRef.current.setFieldValue(`${str}logradouro`, data.street);
    } catch (err) {
      formRef.current.setFieldValue(`${str}cidade`, '');
      formRef.current.setFieldValue(`${str}bairro`, '');
      formRef.current.setFieldValue(`${str}estado`, '');
      formRef.current.setFieldValue(`${str}logradouro`, '');
      toast('CEP não encontrado', { type: 'warning' });
    }
    closeLoader();
  }

  useEffect(() => {
    const getBancos = async () => {
      try {
        const { data } = await api.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(
          data
            .filter((f) => Boolean(f.code))
            .map((m) => ({
              value: String(m.code).padStart(3, '0'),
              label: `${m.code} - ${m.name}`,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (informarBanco && !bancos.length) {
      getBancos();
    }
  }, [informarBanco, bancos]);

  useEffect(() => {
    const getPlanos = async () => {
      try {
        const { data } = await api.get('/planos');
        setPlanos(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: m.id,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    const getMccs = async () => {
      try {
        const { data } = await api.get('/mcc');
        setMccs(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: `${m.code} - ${m.category} : ${m.description}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };

    getPlanos();
    getMccs();
  }, []);

  return (
    <>
      <Card className="px-3 py-3">
        <Form
          initialData={history?.location?.state}
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : null}
                onClick={() => {
                  toggle('1');
                }}
              >
                Dados da empresa
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : null}
                onClick={() => {
                  toggle('2');
                }}
              >
                Dados do representante legal
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cnpj"
                    label="CNPJ"
                    placeholder="CNPJ"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="razao_social"
                    label="Razão social"
                    placeholder="Razão social"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="nome_fantasia"
                    label="Nome fantasia"
                    placeholder="Nome fantasia"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={3}>
                  <Input
                    name="data_abertura_empresa"
                    label="Data de abertura"
                    placeholder="Data de abertura"
                    className="form-control"
                    type="date"
                  />
                </Col>
                <Col lg={3} sm={3}>
                  <NumberFormat
                    name="faturamento"
                    label="Faturamento"
                    className="form-control"
                    thousandSeparator="."
                    prefix="R$"
                    decimalSeparator=","
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    className="form-control"
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Select
                    name="tipo_empresa"
                    label="Tipo empresa"
                    placeholder="Tipo empresa"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'MEI',
                        label: 'MEI',
                      },
                      {
                        value: 'EI',
                        label: 'EI',
                      },
                      {
                        value: 'EIREILI',
                        label: 'EIREILI',
                      },
                      {
                        value: 'LTDA',
                        label: 'LTDA',
                      },
                      {
                        value: 'ME',
                        label: 'ME',
                      },
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect
                    name="plano_id_zoop"
                    label="Plano"
                    options={planos}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect name="cnae_mcc" label="MCC" options={mccs} />
                </Col>
                <Col lg={6} sm={12}>
                  <Input
                    name="responsavel_conta"
                    label="Reponsável UneoPay"
                    className="form-control"
                    placeholder="Reponsável UneoPay"
                  />
                </Col>
              </Row>
              <div className="separator">Endereço</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cep"
                    label="CEP"
                    placeholder="00.000-000"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => getCep(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6}>
                  <Input
                    name="logradouro"
                    label="Logrdouro"
                    placeholder="Logradouro"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="numero"
                    label="Número"
                    placeholder="Número"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="estado"
                    label="Estado"
                    placeholder="Estado"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="separator">Conta bancaria</div>
              <Row>
                <Col lg={12} className="mb-3">
                  <Radio
                    name="abrir_conta"
                    label="Abrir conta no UneoPay Bank"
                    onChange={(e) => setInformarBanco(e.target.value === 'nao')}
                    defaultValue={
                      history?.location?.state?.abrir_conta || 'sim'
                    }
                    data={[
                      {
                        id: 'sim',
                        value: 'sim',
                        label: 'Sim',
                      },
                      {
                        value: 'nao',
                        id: 'nao',
                        label: 'Não',
                      },
                    ]}
                  />
                </Col>
                {informarBanco && (
                  <>
                    <Col lg={12} className="mb-3">
                      <code>
                        * A conta de recebimento deve pertecencer ao CPF/CNPJ do
                        vendedor
                      </code>
                    </Col>
                    <Col lg={3}>
                      <Input
                        name="agencia"
                        label="Agência"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3}>
                      <Input
                        name="conta_bancaria"
                        label="Número da conta"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3}>
                      <Select
                        name="tipo_conta"
                        label="Tipo da conta"
                        className="form-control"
                        data={[
                          {
                            value: '',
                            label: 'Selecione',
                          },
                          {
                            value: 'checking',
                            label: 'Corrente',
                          },
                          {
                            value: 'savings',
                            label: 'Poupança',
                          },
                        ]}
                      />
                    </Col>
                    <Col lg={3}>
                      <ReactSelect
                        name="codigo_banco"
                        label="Banco"
                        options={bancos}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col
                  className="d-flex justify-content-end"
                  onClick={() => toggle('2')}
                >
                  <Button color="light-primary" type="button">
                    Avançar
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_legal_cpf"
                    label="CPF"
                    placeholder="CPF"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="rg_responsavel"
                    label="RG"
                    placeholder="RG"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_nome"
                    label="Nome"
                    placeholder="Nome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_sobrenome"
                    label="Sobrenome"
                    placeholder="Sobrenome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_email"
                    label="E-mail"
                    placeholder="E-mail"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_legal_telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    className="form-control"
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_data_nascimento"
                    label="Data nascimento"
                    type="date"
                    placeholder="Data nascimento"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="separator">Documento</div>
              <Row>
                <Col lg={3}>
                  <Select
                    name="representante_legal_tipo_documento"
                    label="Tipo do documento"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'RG',
                        label: 'RG',
                      },
                      {
                        value: 'CNH',
                        label: 'CNH',
                      },
                      // {
                      //   value: 'RNE',
                      //   label: 'RNE',
                      // },
                      // {
                      //   value: 'CRNM',
                      //   label: 'CRNM',
                      // },
                      // {
                      //   value: 'DNI',
                      //   label: 'DNI',
                      // },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={6}>
                  <Image
                    returnPreview
                    name="representante_legal_documento_frente"
                    label="Documento frente"
                    objectFit="fit"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    returnPreview
                    objectFit="fit"
                    name="representante_legal_documento_verso"
                    label="Documento verso"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    returnPreview
                    objectFit="fit"
                    name="selfie"
                    label="Selfie"
                    btnSelect="Selecionar"
                  />
                </Col>
              </Row>
              <div className="separator">Endereço</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_cep"
                    label="CEP"
                    placeholder="00.000-000"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => getCep(e.target.value, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_logradouro"
                    label="Logrdouro"
                    placeholder="Logradouro"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="representante_numero"
                    label="Número"
                    placeholder="Número"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="representante_estado"
                    label="Estado"
                    placeholder="Estado"
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <Button
                    color="light-primary"
                    type="button"
                    onClick={() => toggle('1')}
                  >
                    Voltar
                  </Button>
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Form>
      </Card>
    </>
  );
}

export default Dashboard;
