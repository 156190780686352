import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { IoRadioButtonOn, IoRadioButtonOff } from 'react-icons/io5';

export default function RadioCard({
  name,
  label,
  items = [],
  labelClass = '',
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => checked,
      setValue: (ref, value) => {
        setChecked(value);
      },
      clearValue: () => {
        setChecked('');
      },
    });
  }, [fieldName, registerField, checked]);
  return (
    <div className="form-group">
      <label
        htmlFor={fieldName}
        className={'form-label '.concat(labelClass).trim()}
      >
        {label}
      </label>

      <div className="row">
        {items.map((m) => (
          <button
            kye={m.value}
            type="button"
            onClick={() => setChecked(m.value)}
            className="btn-none col-lg-12 col-xl-4 text-start mb-2"
          >
            <div className="card mb-0">
              <div className="card-body ">
                <div className="row">
                  <div
                    style={{
                      width: 50,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {m.icon}
                  </div>
                  <div className="col">
                    <p className="mb-0">{m.label}</p>
                    {m.sub && <p className="small mb-0">{m.sub}</p>}
                  </div>
                  <div
                    style={{
                      width: 50,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {String(checked) === String(m.value) ? (
                      <IoRadioButtonOn size={24} color="var(--color-primary)" />
                    ) : (
                      <IoRadioButtonOff
                        size={24}
                        color="var(--color-primary)"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>

      {error && <small className="error">{error}</small>}
    </div>
  );
}
