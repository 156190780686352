/* eslint-disable react/no-unescaped-entities */
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { useTheme } from '../../contexts/theme';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarChart = ({ data, ...rest }) => {
  const { isDarkMode } = useTheme();
  // const padding = isMobile ? 120 : 60;

  const txtColor = useMemo(
    () =>
      getComputedStyle(document.querySelector('body'))
        .getPropertyValue('--theme-color-rgb')
        .trim(),
    [isDarkMode]
  );

  return (
    <ResponsiveBar
      theme={{
        textColor: `rgb(${txtColor})`,
        tooltip: {
          container: {
            background: 'var(--body-bg)',
            border: '1px solid rgba(var(--theme-color-rgb), 0.2)',
          },
          basic: {
            color: `rgb(${txtColor})`,
            background: 'var(--body-bg)',
          },
        },
      }}
      data={data}
      margin={{ top: 20, right: 20, bottom: 50, left: 20 }}
      // colors={() => '#0100be'}
      // labelTextColor="#fff"
      colors={() => '#97e738'}
      // labelTextColor="#0100be"
      labelTextColor="#000"
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisRight={null}
      axisTop={null}
      valueScale={{
        type: 'symlog',
      }}
      animate={false}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'Ano',
      //   legendPosition: 'middle',
      //   legendOffset: 32,
      // }}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
      }}
      {...rest}
    />
  );
};

export default BarChart;
