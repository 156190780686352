import React, { useEffect, useRef, useState } from 'react';
import { FiEdit, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../../components/unform/index';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';

function UnidadeMedida() {
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);

  const debounce = useRef();
  const filtrosRef = useRef();
  const formRef = useRef();

  const { showLoader, closeLoader, toast } = useValidator();
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(
        `/empresa/unidade-medida?page=${p + 1}${filtros}`
      );
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  const handleSubmit = async (e) => {
    const errors = {};

    if (!e.sigla) {
      errors.sigla = 'Obrigatório';
    }
    if (!e.descricao) {
      errors.descricao = 'Obrigatório';
    }

    formRef.current.setErrors(errors);

    if (errors.sigla || errors.descricao) {
      toast();
      return;
    }

    try {
      showLoader();
      if (offcanvas?.id) {
        await api.put(`/empresa/unidade-medida/${offcanvas?.id}`, e);
      } else {
        await api.post('/empresa/unidade-medida', e);
      }
      filtrosRef.current.reset();
      toggleOffcanvas();
      await handleChangePage(0);
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  useEffect(() => {
    async function getData() {
      try {
        showLoader();

        const { data } = await api.get('/empresa/unidade-medida');
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Unidade medida</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button onClick={toggleOffcanvas} color="primary">
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => handleChangePage(0)} ref={filtrosRef}>
              <Row>
                <Col lg={4}>
                  <Input
                    name="sigla"
                    label="Sigla"
                    className="form-control"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    name="descricao"
                    label="Descrição"
                    onChange={filtrarPesquisa}
                    className="form-control"
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Sigla</th>
              <th>Descrição</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id}>
                <td data-label="Condição pgto.">{m?.sigla}</td>
                <td data-label="Perc. max.">{m.descricao}</td>
                <td data-label="Ação">
                  {m.id_empresa ? (
                    <div className="d-flex justify-content-lg-end justify-content-sm-between">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiSettings className="mr-2" /> <FaChevronDown />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <DropdownItem onClick={() => setOffcanvas(m)}>
                            <FiEdit className="mr-1" />
                            Editar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  ) : (
                    <div style={{ height: 33.5 }} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Offcanvas direction="end" isOpen={!!offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          {offcanvas?.id
            ? `Alterando "${offcanvas?.sigla}"`
            : 'Nova unidade medida'}
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={12}>
                <Input
                  placeholder="Sigla"
                  className="form-control"
                  label="Sigla *"
                  name="sigla"
                  defaultValue={offcanvas?.sigla || ''}
                />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                  defaultValue={offcanvas?.descricao || ''}
                />
              </Col>
            </Row>
            <Button block color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default UnidadeMedida;
