/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useRef, useState } from 'react';
import { FiClipboard, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  Input,
  ReactSelectAsync,
  Select,
} from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';
import {
  statusHistorico,
  tipoVenda,
  transacao,
  transacaoColors,
  transacaoOptions,
  bandeiras,
} from '../../../utils/status';
import { useAuth } from '../../../contexts/auth';

function ListarTransacoes() {
  const { isAdmin } = useAuth();
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const toggleModal = () => setModal((old) => !old);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/options/vendedores?value=${inputValue}`);

    return data;
  }

  const getOptions = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/transacoes?page=${page || 1}&${query}`);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function detalhesTransacao(tr) {
    showLoader();
    try {
      const { data } = await api.get(`/transacoes/${tr.id}/detalhes`);
      setModal({
        ...tr,
        ...data,
      });
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/transacoes');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div className="pl-3 pr-5" />
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    name="data_inicio"
                    onChange={filtrarPesquisa}
                    type="date"
                    label="Período início"
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    name="data_fim"
                    onChange={filtrarPesquisa}
                    type="date"
                    label="Período fim"
                  />
                </Col>
                {isAdmin && (
                  <Col>
                    <ReactSelectAsync
                      label="Vendedor Zoop"
                      placeholder="Vendedor"
                      name="id_vendedor_zoop"
                      defaultOptions
                      isClearable
                      loadOptions={getOptions}
                      onChange={filtrarPesquisa}
                    />
                  </Col>
                )}
                <Col>
                  <Input
                    label="Id zoop"
                    className="form-control"
                    placeholder="Id zoop"
                    name="transacao_id_zoop"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    label="Tipo pagamento"
                    name="tipo_pagamento"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Tipo',
                      },
                      {
                        value: 'credit',
                        label: 'Crédito',
                      },
                      {
                        value: 'debit',
                        label: 'Débito',
                      },
                      {
                        value: 'pix',
                        label: 'Pix',
                      },
                      {
                        value: 'boleto',
                        label: 'Boleto',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    label="Status pagamento"
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[{ value: '', label: 'Status' }].concat(
                      transacaoOptions
                    )}
                  />
                </Col>
                <Col>
                  <Select
                    label="Status repasse"
                    name="repasse"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Repasse',
                      },
                      {
                        value: 'pago',
                        label: 'Efetuado',
                      },
                      {
                        value: 'nao-pago',
                        label: 'Não efetuado',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              {isAdmin && <th>Vendedor</th>}
              <th>Tipo</th>
              <th>Valor</th>
              <th>Data venda</th>
              <th>Data repasse</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                {isAdmin && (
                  <td data-label="Vendedor">{m?.VendedorZoop?.Pessoa?.nome}</td>
                )}
                <td data-label="Tipo">
                  <div className="d-flex align-items-center">
                    {Object.keys(bandeiras).includes(
                      m?.TransacaoMetodoCartao?.metodo_pagamento_marca_cartao ||
                        m?.tipo_pagamento
                    ) && (
                      <img
                        width={30}
                        height={20}
                        alt={
                          m?.TransacaoMetodoCartao
                            ?.metodo_pagamento_marca_cartao || m?.tipo_pagamento
                        }
                        className="object-fit mr-1"
                        src={require(`../../../assets/svg/bandeiras/${
                          bandeiras[
                            m?.TransacaoMetodoCartao
                              ?.metodo_pagamento_marca_cartao ||
                              m?.tipo_pagamento
                          ]
                        }`)}
                      />
                    )}
                    <div>
                      {tipoVenda[m.tipo_pagamento] || m.tipo_pagamento}
                      {m.tipo_pagamento === 'credit' &&
                        (m.parcelas > 1 ? ` em ${m.parcelas}` : ' à vista')}
                    </div>
                  </div>
                </td>
                <td data-label="Valor">
                  <div>
                    {Number(m.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: m.moeda || 'BRL',
                    })}
                  </div>
                  <div className="small">
                    Taxas:{' '}
                    {Number(m.valor_total_taxas).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: m.moeda || 'BRL',
                    })}
                  </div>
                </td>
                <td data-label="Data venda">
                  {new Date(m.data_transacao)
                    .toLocaleString('pt-BR')
                    .substring(0, 17)
                    .replace(',', '')}
                </td>
                <td data-label="Data repasse">
                  {m?.RecebivelZoop?.pago_em &&
                    new Date(m?.RecebivelZoop?.pago_em).toLocaleDateString(
                      'pt-BR'
                    )}
                </td>
                <td data-label="Status">
                  <div className={`badge ${transacaoColors[m.status]}`}>
                    {transacao[m.status]}
                  </div>
                </td>
                <td data-label="Ações">
                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem onClick={() => detalhesTransacao(m)}>
                          <FiClipboard className="mr-1" />
                          Detalhes
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Detalhes</ModalHeader>
        <ModalBody>
          {modal?.tipo_pagamento === 'boleto' && (
            <>
              <h5 className="txt-muted text-center">Boleto</h5>
              <div className="d-flex-wrap justify-content-between">
                <p>Recipiente: {modal?.metodo?.recipiente}</p>
                <p>
                  Valor:{' '}
                  {Number(modal?.valor).toLocaleString('pt-BR', {
                    currency: modal?.moeda || 'BRL',
                    style: 'currency',
                  })}
                </p>
              </div>
              <div className="d-flex-wrap justify-content-between">
                <p>Status: {modal?.metodo?.status}</p>
                {modal?.metodo?.data_expiracao && (
                  <p>
                    Expira em:{' '}
                    {new Date(modal?.metodo?.data_expiracao).toLocaleDateString(
                      'pt-BR'
                    )}
                  </p>
                )}
                {modal?.metodo?.pago_em && (
                  <p>
                    Pago em:{' '}
                    {new Date(modal?.metodo?.pago_em).toLocaleDateString(
                      'pt-BR'
                    )}
                  </p>
                )}
                <span>
                  {modal?.metodo?.url_boleto && (
                    <a
                      className="btn btn-sm btn-light-primary"
                      href={modal?.metodo?.url_boleto}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link boleto
                    </a>
                  )}
                </span>
              </div>
            </>
          )}
          {modal?.tipo_pagamento === 'pix' && (
            <>
              <h5 className="txt-muted text-center">PIX</h5>
              <div className="d-flex-wrap justify-content-between">
                {modal?.VendedorZoop?.Pessoa?.nome && (
                  <p>Vendedor: {modal?.VendedorZoop?.Pessoa?.nome}</p>
                )}
                <p>
                  Valor:{' '}
                  {Number(modal?.valor).toLocaleString('pt-BR', {
                    currency: modal?.moeda || 'BRL',
                    style: 'currency',
                  })}
                </p>
              </div>
              <div className="d-flex-wrap justify-content-between">
                <p>Chave {modal?.metodo?.metodo_pagamento_pix_chave}</p>
                {modal?.metodo?.metodo_pagamento_pix_data_expiracao && (
                  <p>
                    Expira em:{' '}
                    {new Date(
                      modal?.metodo?.metodo_pagamento_pix_data_expiracao
                    ).toLocaleString('pt-BR')}
                  </p>
                )}
              </div>
              <div className="d-flex-wrap justify-content-between">
                {modal?.metodo?.metodo_pagamento_pix_qrcode_objeto && (
                  <p>
                    Copia e cola:{' '}
                    <small>
                      {modal?.metodo?.metodo_pagamento_pix_qrcode_objeto}
                    </small>
                  </p>
                )}
              </div>
            </>
          )}
          {['credit', 'cartao', 'debit'].includes(modal?.tipo_pagamento) &&
            modal?.metodo && (
              <>
                <h5 className="txt-muted text-center">
                  Cartão{' '}
                  {modal?.tipo_pagamento === 'credit' ? 'crédito' : 'débito'}
                </h5>
                <div className="d-flex-wrap justify-content-between">
                  {modal?.VendedorZoop?.Pessoa?.nome && (
                    <p>Vendedor: {modal?.VendedorZoop?.Pessoa?.nome}</p>
                  )}
                  <p>
                    Valor:{' '}
                    {Number(modal?.valor).toLocaleString('pt-BR', {
                      currency: modal?.moeda || 'BRL',
                      style: 'currency',
                    })}
                    {Number(modal?.parcelas) > 1
                      ? ` em ${modal?.parcelas} parcelas`
                      : ' à vista'}
                  </p>
                </div>
                <div className="d-flex-wrap justify-content-between">
                  <p>
                    Nome cartão:{' '}
                    {modal?.metodo?.metodo_pagamento_nome_cartao ||
                      'Não informado'}
                  </p>
                  <p>
                    Bandeira:{' '}
                    {Object.keys(bandeiras).includes(
                      modal?.metodo?.metodo_pagamento_marca_cartao
                    ) ? (
                      <img
                        width={30}
                        height={20}
                        alt={modal?.metodo?.metodo_pagamento_marca_cartao}
                        title={modal?.metodo?.metodo_pagamento_marca_cartao}
                        src={require(`../../../assets/svg/bandeiras/${
                          bandeiras[
                            modal?.metodo?.metodo_pagamento_marca_cartao
                          ]
                        }`)}
                      />
                    ) : (
                      modal?.metodo?.metodo_pagamento_marca_cartao
                    )}
                  </p>
                  <p>
                    4 últimos dígitos:{' '}
                    {modal?.metodo?.metodo_pagamento_finais_4_digitos}
                  </p>
                </div>
              </>
            )}
          <hr />
          {(modal?.taxas || []).length > 0 && (
            <h5 className="txt-muted text-center">Taxas</h5>
          )}
          {(modal?.taxas || []).map((m) => (
            <p>
              {Number(m.valor).toLocaleString('pt-BR', {
                currency: m.moeda || 'BRL',
                style: 'currency',
              })}{' '}
              - {m.tipo}
            </p>
          ))}
          {(modal?.taxas || []).length > 0 && <hr />}
          {(modal?.recebiveis || []).length > 0 && (
            <>
              <h5 className="txt-muted text-center">Recebíveis</h5>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Esperado para</th>
                    <th>Pago em</th>
                    <th>Taxa antecipação</th>
                    <th>Valor líquido</th>
                    <th>Valor bruto</th>
                  </tr>
                </thead>
                <tbody>
                  {(modal?.recebiveis || []).map((m) => (
                    <tr key={m.id}>
                      <td data-label="Status">
                        {m?.status === 'pending' ? 'Não recebido' : 'Recebido'}
                      </td>
                      <td data-label="Esperado em">
                        {new Date(m?.esperado_para).toLocaleDateString('pt-BR')}
                      </td>
                      <td data-label="Pago em">
                        {m?.pago_em &&
                          new Date(m?.pago_em)
                            .toLocaleString('pt-BR')
                            .substring(0, 17)
                            .replace(',', '')}
                      </td>
                      <td data-label="Taxa antecipação">
                        {Number(m?.taxa_antecipacao).toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                        })}
                      </td>
                      <td data-label="Valor líquido">
                        {Number(m?.valor_liquido).toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                        })}
                      </td>
                      <td data-label="Valor bruto">
                        {Number(m?.valor_bruto).toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
            </>
          )}
          {(modal?.historico || []).length > 0 && (
            <>
              <h5 className="txt-muted text-center">Histórico</h5>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Hora</th>
                    <th>Status</th>
                    <th>Tipo operação</th>
                    <th>Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {(modal?.historico || []).map((m) => (
                    <tr key={m.id}>
                      <td data-label="Hora">
                        {new Date(m?.data_criacao)
                          .toLocaleString('pt-BR')
                          .substring(0, 17)
                          .replace(',', '')}
                      </td>
                      <td data-label="Status">{m?.status}</td>
                      <td data-label="Tipo operação">
                        {statusHistorico[m?.tipo_operacao] || m?.tipo_operacao}
                      </td>
                      <td data-label="Mensagem">{m?.mensagem_resposta}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListarTransacoes;
