import React, { useEffect, useRef, useState } from 'react';
import {
  FiEdit,
  FiFilter,
  FiSettings,
  FiTrash,
  FiArrowDown,
} from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  ModalFooter,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';

import Devolucao from './devolucao';
import NovaTributacao from './novaTributacao';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Tributacao() {
  const dadosGeraisRef = useRef();
  const filtrosRef = useRef();
  const ufRef = useRef();
  const formRef = useRef();

  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });

  const [page, setPage] = useState(1);
  const { showLoader, closeLoader, toast } = useValidator();

  const [ufsFiliais, setUfsFiliais] = useState([]);
  const [tipoRegime, setTipoRegime] = useState(1);
  const [modal, setModal] = useState({ open: false, type: '' });

  const [modalConfirma, setModalConfirma] = useState({ open: false, obj: {} });

  const [modalDevolucao, setModalDevolucao] = useState({
    open: false,
    type: '',
  });

  const [contemRegimetributario, setContemRegimeTributario] = useState({
    contem: false,
    tipo: '',
  });

  const toggleModalConfirma = (obj) =>
    setModalConfirma({ open: !modalConfirma?.open, obj });

  const toggleModal = (type, objEdit = null) =>
    setModal({ open: !modal?.open, type, objEdit });

  const toggleModalDevolucao = (type, objEdit = null) =>
    setModalDevolucao({ open: !modalDevolucao?.open, type, objEdit });

  const handleChangePage = async (p, loader = true) => {
    try {
      if (loader) {
        showLoader();
      }

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(`/tributacao?page=${p + 1}${filtros}`);
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  useEffect(() => {
    const getDados = async () => {
      try {
        showLoader();
        const { data: ufs } = await api.get('/ufs/filiais');
        setUfsFiliais(ufs);

        const { data: empresatributcao } = await api.get('/empresa/tributacao');
        if (empresatributcao) {
          Object.keys(empresatributcao).forEach((f) => {
            if (f === 'regime_tributario') {
              setContemRegimeTributario({
                contem: true,
                tipo: empresatributcao[f],
              });
              setTipoRegime(empresatributcao[f]);
            }
            setTimeout(() => {
              dadosGeraisRef.current.setFieldValue(f, empresatributcao[f]);
            }, 200);
          });
        }

        const { data } = await api.get('/tributacao');
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    };

    getDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filtrarPesquisa(tipo) {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      if (tipo === 'uf') {
        ufRef.current.submitForm();
      } else {
        filtrosRef.current.submitForm();
      }
    }, 500);
  }

  async function salvaDadosGerais(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    dadosGeraisRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      if (
        contemRegimetributario?.contem &&
        ((Number(data?.regime_tributario) === 1 &&
          contemRegimetributario.tipo !== 1) ||
          (Number(data?.regime_tributario) !== 1 &&
            contemRegimetributario.tipo === 1))
      ) {
        toggleModalConfirma(data);
      } else {
        try {
          showLoader();
          await api.post('/empresa/tributacao', data);
          toast('Dados salvos com sucesso!', { type: 'success' });
          setContemRegimeTributario({
            contem: true,
            tipo: data?.regime_tributario,
          });
        } catch (err) {
          toast(err.mensagem);
        }
        closeLoader();
      }
    }
  }

  async function handleDeleteTributacao(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir essa tributação?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          showLoader();
          api
            .delete(`/tributacao/${id}`)
            .then(() => {
              handleChangePage(0);
              toast('Dados excluidos com sucesso!', { type: 'success' });
              closeLoader();
            })
            .catch((error) => {
              toast(error.mensagem);
              closeLoader();
            });
        }
      });
  }

  async function handleSubmitConfirmacao(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      if (e.confirma !== 'confirma') {
        toast("Digite 'confirma' para confirmar!", { type: 'error' });
      } else {
        try {
          const data = modalConfirma?.obj;
          showLoader();
          await api.post('/empresa/tributacao', data);
          toast('Dados salvos com sucesso!', { type: 'success' });
          setContemRegimeTributario({
            contem: true,
            tipo: data?.regime_tributario,
          });
          handleChangePage(0);
        } catch (err) {
          toast(err.mensagem);
        }
        closeLoader();
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Tributação</h4>
        </Col>
      </Row>
      <Card>
        <Button
          color="muted"
          id="toggle1"
          className="d-flex mt-2 align-items-center"
        >
          <FiArrowDown color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
          &nbsp;&nbsp;Dados gerais
        </Button>
        <div className="filter-collapse px-3 pb-2">
          <UncontrolledCollapse toggler="#toggle1">
            <Form
              onSubmit={(data) => {
                salvaDadosGerais(data);
              }}
              ref={dadosGeraisRef}
            >
              <Row className="p-3">
                <Col lg={3}>
                  <Select
                    name="regime_tributario"
                    label="Regime tributário"
                    className="form-control"
                    onChange={(e) => setTipoRegime(e.target.value)}
                    data={[
                      { value: 1, label: 'Simples Nacional' },
                      { value: 2, label: 'Lucro Presumido' },
                      { value: 3, label: 'Lucro Real' },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Alíquota PIS (%)"
                    className="form-control"
                    placeholder="Digite a alíquota"
                    name="aliquota_pis"
                    type="number"
                    step="0.01"
                    max="99.99"
                    min="0"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Alíquota COFINS (%)"
                    className="form-control"
                    placeholder="Digite a alíquota"
                    name="aliquota_cofins"
                    type="number"
                    step="0.01"
                    max="99.99"
                    min="0"
                  />
                </Col>
                {Number(tipoRegime) !== 1 && (
                  <Col lg={3}>
                    <Input
                      label="Alíquota crédito ICMS (%)"
                      className="form-control"
                      placeholder="Digite a alíquota"
                      name="aliquota_credito_sn"
                      type="number"
                      step="0.01"
                      max="99.99"
                      min="0"
                    />
                  </Col>
                )}
                <Col lg={12} className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="btn btn-primary mr-2"
                    onClick={() => toggleModalDevolucao('add')}
                  >
                    Parametrização devolução
                  </Button>
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>
      </Card>
      <Card hidden={!contemRegimetributario?.contem}>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>

          <Button
            color="primary"
            className="btn btn-primary"
            onClick={() => toggleModal('add')}
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Select
                    name="id_uf"
                    label="UF"
                    className="form-control"
                    data={ufsFiliais}
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    label="Cenário"
                    className="form-control"
                    placeholder="Cenário"
                    name="cenario"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    label="Tributação"
                    className="form-control"
                    placeholder="Tributação"
                    name="descricao"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>
        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>UF</th>
              <th>Cenário</th>
              <th>Tributação</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m?.id}>
                <td data-label="UF">{m?.Uf?.sigla}</td>
                <td data-label="Cenário">{m?.CenarioTributacao?.nome}</td>
                <td data-label="Tributação">{m?.descricao}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => toggleModal('edit', m)}>
                        <FiEdit className="mr-1" />
                        Editar
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleDeleteTributacao(m?.id);
                        }}
                      >
                        <FiTrash className="mr-1" />
                        Excluir
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal
        isOpen={modalConfirma?.open}
        toggle={() => {
          toggleModalConfirma();
        }}
        size="md"
      >
        <ModalHeader
          toggle={() => {
            toggleModalConfirma();
          }}
        >
          Alteração de regime tributário
        </ModalHeader>
        <Form
          onSubmit={(d) => {
            handleSubmitConfirmacao(d);
          }}
          ref={formRef}
        >
          <ModalBody>
            <Row>
              <Col>
                <h6>
                  <strong style={{ color: 'red' }}>Atenção:</strong> A alteração
                  do regime tributário invalida as tributações cadastradas!
                </h6>
                <p>
                  Será necessário cadastrar as tributações para o novo regime
                  tributário e vincular com os produtos.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Confirma a exclusão das tributações?"
                  className="form-control"
                  placeholder="Digite 'confirma' para confirmar a alteração"
                  name="confirma"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={toggleModalConfirma}
              color="secondary"
              type="buton"
            >
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <NovaTributacao
        toggleModal={toggleModal}
        modal={modal}
        regimeTributario={contemRegimetributario}
        ufs={ufsFiliais}
        refresh={handleChangePage}
      />

      <Devolucao
        toggleModal={toggleModalDevolucao}
        modal={modalDevolucao}
        // regimeTributario={contemRegimetributario}
        // ufs={ufsFiliais}
        // refresh={handleChangePage}
      />
    </>
  );
}

export default Tributacao;
