import debounce from 'debounce-promise';
import api from '../services/apiPDV';

async function filtrarConsulta({
  value = '',
  idFilial,
  route,
  param = 'nome',
}) {
  try {
    const { data } = await api.get(
      `${route}?options=true&${param}=${value}&filial=${idFilial}`
    );
    return data;
  } catch (err) {
    return [];
  }
}

const getOptions = debounce(filtrarConsulta, 500);

export default getOptions;
