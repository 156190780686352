/* eslint-disable react/destructuring-assignment */
import { Form } from '@unform/web';
import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Input } from '../../../../components/unform/index';

function Tag(props) {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form onSubmit={(data) => props.onAdd(data)}>
        <ModalHeader toggle={props.toggle}>Nova tag</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={9}>
              <Input
                label="Nome"
                className="form-control"
                type="text"
                name="nome"
                defaultValue={props.value}
              />
            </Col>
            <Col lg={3}>
              <Input
                label="Cor"
                className="form-control"
                type="color"
                name="cor"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default Tag;
