function getVarValue(variable) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
}
const colors = {
  primary: () => getVarValue('--color-primary'),
  secondary: () => getVarValue('--color-secondary'),
  tertiary: () => getVarValue('--color-tertiary'),
  error: () => getVarValue('--color-error'),
  errorDarken: () => getVarValue('--color-error-darken'),
  success: () => getVarValue('--color-success'),
  successDarken: () => getVarValue('--color-success-darken'),
};

function setVarValue(variable, value) {
  const r = document.querySelector(':root');
  r.style.setProperty(variable, value);
}
function setCorModulo(modulo) {
  if (['pay', 'bank'].includes(modulo)) {
    setVarValue('--color-primary', 'rgb(12, 51, 26)');
    setVarValue('--color-primary-rgb', '12, 51, 26');
  } else {
    setVarValue('--color-primary', 'rgb(115, 103, 240)');
    setVarValue('--color-primary-rgb', '115, 103, 240');
  }
}

export { colors, setVarValue, setCorModulo };
