// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie';
import { useMemo } from 'react';
import { useTheme } from '../../contexts/theme';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const PieChart = ({ data, ...rest }) => {
  const isMobile = window.innerWidth < 992;
  const padding = isMobile ? 120 : 60;
  const { isDarkMode } = useTheme();
  // const padding = isMobile ? 120 : 60;

  const txtColor = useMemo(
    () =>
      getComputedStyle(document.querySelector('body'))
        .getPropertyValue('--theme-color-rgb')
        .trim(),
    [isDarkMode]
  );

  return (
    <ResponsivePie
      data={data}
      theme={{
        tooltip: {
          container: {
            background: 'var(--body-bg)',
            border: '1px solid rgba(var(--theme-color-rgb), 0.2)',
          },
          basic: {
            color: `rgb(${txtColor})`,
            background: 'var(--body-bg)',
          },
        },
      }}
      margin={{ top: 60, right: padding, bottom: 60, left: padding }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      // animate={false}
      startAngle={-170}
      arcLinkLabelsTextColor={`rgb(${txtColor})`}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={0}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      arcLinkLabelsDiagonalLength={8}
      arcLinkLabelsStraightLength={12}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'ruby',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'c',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'go',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'python',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'scala',
          },
          id: 'lines',
        },
        {
          match: {
            id: 'lisp',
          },
          id: 'lines',
        },
        {
          match: {
            id: 'elixir',
          },
          id: 'lines',
        },
        {
          match: {
            id: 'javascript',
          },
          id: 'lines',
        },
      ]}
      // legends={[
      //   {
      //     anchor: 'bottom-left',
      //     direction: 'column',
      //     justify: false,
      //     translateX: -(padding - 5),
      //     translateY: 50,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 15,
      //     itemTextColor: '#000',
      //     itemBackground: 'rgba(255,255,255,0.8)',
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 1,
      //     symbolSize: 15,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000',
      //         },
      //       },
      //     ],
      //   },
      // ]}
      {...rest}
    />
  );
};

export default PieChart;
