import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import { Input } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';

function NovoPaciente() {
  const { selectedFilial } = useAuth();
  const search = useSearch();
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = ['nome', 'numero_registro', 'dias_carencia'];

    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      if (search?.id) {
        await api.put(`/convenios/${search?.id}`, e);
      } else {
        e.id_filial = selectedFilial?.filial;
        await api.post('/convenios', e);
      }
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  useEffect(() => {
    async function getConvenios() {
      try {
        const { data } = await api.get(`/convenios/${search?.id}`);
        const { ...rest } = data;
        const body = {};

        Object.entries(rest).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key}`] = value;
          } else {
            body[key] = value;
          }
        });

        formRef.current.setData(body);
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getConvenios();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">
            {
              // eslint-disable-next-line no-nested-ternary
              search?.infos ? 'Informações' : search?.id ? 'Editar' : 'Novo'
            }{' '}
            convênio
          </h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col lg={6}>
              <Input
                name="nome"
                label="Nome"
                className="form-control"
                type="text"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={3}>
              <Input
                name="numero_registro"
                label="Nº registro *"
                className="form-control"
                type="text"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={3}>
              <Input
                name="dias_carencia"
                label="Dias de carência *"
                className="form-control"
                type="number"
                disabled={search?.infos}
              />
            </Col>
            {!search?.infos && (
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoPaciente;
