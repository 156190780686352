import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import DashboardEc from 'pages/erp/Ecommerce/Dashboard';
import Cupoms from 'pages/erp/Ecommerce/Cupom';
import FreteGratis from 'pages/erp/Ecommerce/FreteGratis';
import Loja from 'pages/erp/Ecommerce/Loja';
import Pedidos from 'pages/erp/Ecommerce/Pedidos';
import PreCadastro from 'pages/pay/Vendedores/preCadastro';
import ListarPreCadastro from 'pages/pay/Vendedores/listarPre';
import AgentesTelecom from 'pages/telecom/agentes/listar';
import ListarUsuariosTelecom from 'pages/telecom/usuarios/listar';
import LayoutSemNav from 'layouts/semNav';
import NovoUsuarioTelecom from 'pages/telecom/usuarios/criar';
import ListarEmpresasTelecom from 'pages/telecom/empresas/listar';
import NovaEmpresaTelecom from 'pages/telecom/empresas/criar';
import { useAuth } from '../contexts/auth';

import AuthRoute from './authRoute';

import Layout from '../layouts';
import Login from '../pages/pay/Login/index';
import Dashboard from '../pages/pay/Dashboard/index';
import ListarVendedores from '../pages/pay/Vendedores/listar';
import CriarVendedorPj from '../pages/pay/Vendedores/criarPj';
import CriarVendedorPf from '../pages/pay/Vendedores/criarPf';
import ListarTransacoes from '../pages/pay/Transacoes/listar';
import Modulos from '../pages/Modulos';
import Chat from '../pages/pay/Chat';
import ListarPos from '../pages/pay/Pos/listar';
import Perfil from '../pages/pay/Perfil';
import ListarTransferencias from '../pages/pay/Transferencias/listar';
import ListarLinkPagamento from '../pages/pay/LinkPagamento/listar';
import Pagamento from '../pages/pay/Pagamento';
import ListarTaxas from '../pages/pay/Taxas/listar';
import { setCorModulo } from '../utils/global';

// Bank
import Extrato from '../pages/bank/Extrato';
import Transferencia from '../pages/bank/Transferencia';
import PagamentoErp from '../pages/bank/Pagamento';
import Boleto from '../pages/bank/Boleto';
import Pix from '../pages/bank/Pix';
import Uneocarbon from '../pages/bank/Uneocarbon';

import ModulosErp from '../pages/erp/Modulos';
import LayoutPdv from '../layouts/pdv';
import Pdv from '../pages/erp/Pdv';
import Orcamento from '../pages/erp/Pdv/orcamento';
import TipoPdv from '../pages/erp/Pdv/selecionaTipo';

import UnidadeMedida from '../pages/erp/UnidadeMedida/listar';
import Tributacao from '../pages/erp/Estoque/Tributacao';
import ProdutosEstoque from '../pages/erp/Estoque/Produtos';
import Marca from '../pages/erp/Marca/listar';
import Promocoes from '../pages/erp/Estoque/Promocoes';
import Filial from '../pages/erp/Filial';
import NovaFilial from '../pages/erp/Filial/nova';
import Saudacoes from '../pages/saudacoes';
import ListarUsuarios from '../pages/pay/Usuarios/listar';

// Cadastros
import Fornecedores from '../pages/erp/Cadastros/fornecedores';
import NovoFornecedor from '../pages/erp/Cadastros/novoFornecedor';
import Clientes from '../pages/erp/Cadastros/clientes';
import NovoCliente from '../pages/erp/Cadastros/novoCliente';
import ListarDescontoManual from '../pages/erp/DescontoManual/listar';

// Financeiro
import DashboardFinanceiro from '../pages/erp/Financeiro/Dashboard/index';
import CadastroLancamento from '../pages/erp/Financeiro/Lancamento/Cadastro';
import ConsultaLancamento from '../pages/erp/Financeiro/Lancamento/Consulta';
import RelatorioLancamentos from '../pages/erp/Financeiro/Lancamento/Relatorio';
import Planejamento from '../pages/erp/Financeiro/Planejamento';
import GestaoContratoNovo from '../pages/erp/Financeiro/GestaoContrato/Cadastro';
import GestaoContrato from '../pages/erp/Financeiro/GestaoContrato/Consulta';
import GestaoContratoEditar from '../pages/erp/Financeiro/GestaoContrato/Editar';
import GestaoContratoRenovar from '../pages/erp/Financeiro/GestaoContrato/Renovar';

// Saude
import Pacientes from '../pages/erp/Saude/Pacientes/pacientes';
import CadastroPaciente from '../pages/erp/Saude/Pacientes/novoPaciente';
import Profissionais from '../pages/erp/Saude/Profissionais/profissionais';
import CadastroProfissional from '../pages/erp/Saude/Profissionais/novoProfissional';
import Convenios from '../pages/erp/Saude/Convenios/convenios';
import CadastroConvenio from '../pages/erp/Saude/Convenios/novoConvenio';
import Procedimentos from '../pages/erp/Saude/Procedimentos/procedimentos';
import CadastroProcedimento from '../pages/erp/Saude/Procedimentos/novoProcedimento';
import ListaEspera from '../pages/erp/Saude/ListaEspera/listaEspera';
import CadastroListaEspera from '../pages/erp/Saude/ListaEspera/novaListaEspera';

import ListarProntuarios from '../pages/erp/Saude/Prontuario/listarProntuarios';
import CriarProntuario from '../pages/erp/Saude/Prontuario/criarProntuario';
import Agenda from '../pages/erp/Saude/Agenda/Agenda';

import ListarAtendimentos from '../pages/erp/Saude/Atendimento/listAtendimentos';
import NovoAtendimento from '../pages/erp/Saude/Atendimento/novoAtendimento';

import Funcionarios from '../pages/pay/Funcionarios/index';
import NovoFuncionario from '../pages/pay/Funcionarios/cadastro';

import Parceiros from '../pages/pay/Parceiros/index';
import NovoParceiro from '../pages/pay/Parceiros/cadastro';

const Routes = () => {
  const { loading, signed, modulo, user, acessos, isAdmin } = useAuth();
  const [location] = useState(useRouteMatch(window.location.pathname));
  const history = useHistory();

  useEffect(() => {
    if (!loading && location && window.location.pathname !== location.path) {
      setCorModulo(String(location.path).split('/')[1]);
      history.replace(location.path);
    }
  }, [loading]);

  useEffect(() => {
    if (isAdmin && history.location.pathname.includes('/pay')) {
      if (
        acessos?.load &&
        !acessos?.acessos?.some(
          (s) =>
            history.location.pathname === '/pay/perfil' ||
            `/pay${s.tela}` === history.location.pathname ||
            history.location.pathname.includes(`/pay${s.tela}/novo`)
        )
      ) {
        history.push(`/pay${acessos?.acessos?.[0]?.tela}`);
      }
    }
  }, [history.location.pathname, acessos, isAdmin]);

  return (
    <Switch>
      <Route path="/pagamento/:id" exact component={Pagamento} />
      {loading || signed ? (
        <>
          <Route path={['/', 'pagamento']} exact>
            <Switch>
              <AuthRoute path="/" exact component={Modulos} />
            </Switch>
          </Route>
          {user?.bank && (
            <Route path={['/bank/']}>
              <Layout>
                <Switch>
                  <AuthRoute path="/bank/" exact component={Saudacoes} />
                  <AuthRoute path="/bank/extrato" exact component={Extrato} />
                  <AuthRoute
                    path="/bank/pagamento"
                    exact
                    component={PagamentoErp}
                  />
                  <AuthRoute path="/bank/boleto" exact component={Boleto} />
                  <AuthRoute
                    path="/bank/transferencia"
                    exact
                    component={Transferencia}
                  />
                  <AuthRoute path="/bank/pix" exact component={Pix} />
                  <AuthRoute
                    path="/bank/uneocarbon"
                    exact
                    component={Uneocarbon}
                  />
                  <Redirect to="/bank" />
                </Switch>
              </Layout>
            </Route>
          )}
          {user?.erp && (
            <Route path={['/erp/']}>
              {!modulo && (
                <AuthRoute path="/erp" exact component={ModulosErp} />
              )}
              {modulo === 'Pdv' && (
                <LayoutPdv>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={TipoPdv} />
                    <AuthRoute path="/erp/venda" exact component={Pdv} />
                    <AuthRoute
                      path="/erp/orcamento"
                      exact
                      component={Orcamento}
                    />
                    <Redirect to="/erp" />
                  </Switch>
                </LayoutPdv>
              )}
              {modulo === 'Estoque' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={Saudacoes} />
                    <AuthRoute
                      path="/erp/Estoque"
                      exact
                      component={ProdutosEstoque}
                    />
                    <AuthRoute
                      path="/erp/Tributacao"
                      exact
                      component={Tributacao}
                    />
                    <AuthRoute
                      path="/erp/Promocoes"
                      exact
                      component={Promocoes}
                    />
                    <Redirect to="/erp/Estoque" />
                  </Switch>
                </Layout>
              )}
              {modulo === 'Ecommerce' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={DashboardEc} />
                    <AuthRoute path="/erp/cupom" exact component={Cupoms} />
                    <AuthRoute path="/erp/loja" exact component={Loja} />
                    <AuthRoute path="/erp/pedidos" exact component={Pedidos} />
                    <AuthRoute
                      path="/erp/frete-gratis"
                      exact
                      component={FreteGratis}
                    />

                    <Redirect to="/erp" />
                  </Switch>
                </Layout>
              )}
              {modulo === 'Financeiro' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={Saudacoes} />
                    <AuthRoute
                      path="/erp/lancamento/cadastro"
                      exact
                      component={CadastroLancamento}
                    />
                    <AuthRoute
                      path="/erp/lancamento"
                      exact
                      component={ConsultaLancamento}
                    />
                    <AuthRoute
                      path="/erp/relatorio-lancamento"
                      exact
                      component={RelatorioLancamentos}
                    />
                    <AuthRoute
                      path="/erp/dashboard-financeiro"
                      exact
                      component={DashboardFinanceiro}
                    />
                    <AuthRoute
                      path="/erp/planejamento"
                      exact
                      component={Planejamento}
                    />
                    <AuthRoute
                      path="/erp/renovar-contrato/:id"
                      exact
                      component={GestaoContratoRenovar}
                    />
                    <AuthRoute
                      path="/erp/editar-contrato/:id"
                      exact
                      component={GestaoContratoEditar}
                    />
                    <AuthRoute
                      path="/erp/novo-contrato"
                      exact
                      component={GestaoContratoNovo}
                    />
                    <AuthRoute
                      path="/erp/contrato"
                      exact
                      component={GestaoContrato}
                    />
                    <Redirect to="/erp" />
                  </Switch>
                </Layout>
              )}
              {modulo === 'Cadastros' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={Saudacoes} />
                    <AuthRoute
                      path="/erp/fornecedores"
                      exact
                      component={Fornecedores}
                    />
                    <AuthRoute
                      path="/erp/fornecedores/novo"
                      exact
                      component={NovoFornecedor}
                    />
                    <AuthRoute
                      path="/erp/clientes"
                      exact
                      component={Clientes}
                    />
                    <AuthRoute
                      path="/erp/clientes/novo"
                      exact
                      component={NovoCliente}
                    />
                    <Redirect to="/erp/" />
                  </Switch>
                </Layout>
              )}
              {modulo === 'Saude' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={Saudacoes} />
                    <AuthRoute
                      path="/erp/pacientes"
                      exact
                      component={Pacientes}
                    />
                    <AuthRoute
                      path="/erp/pacientes/novo"
                      exact
                      component={CadastroPaciente}
                    />

                    <AuthRoute
                      path="/erp/profissionais"
                      exact
                      component={Profissionais}
                    />
                    <AuthRoute
                      path="/erp/profissionais/novo"
                      exact
                      component={CadastroProfissional}
                    />

                    <AuthRoute
                      path="/erp/convenios"
                      exact
                      component={Convenios}
                    />
                    <AuthRoute
                      path="/erp/convenios/novo"
                      exact
                      component={CadastroConvenio}
                    />

                    <AuthRoute
                      path="/erp/procedimentos"
                      exact
                      component={Procedimentos}
                    />
                    <AuthRoute
                      path="/erp/procedimentos/novo"
                      exact
                      component={CadastroProcedimento}
                    />

                    <AuthRoute
                      path="/erp/listaespera"
                      exact
                      component={ListaEspera}
                    />
                    <AuthRoute
                      path="/erp/listaespera/novo"
                      exact
                      component={CadastroListaEspera}
                    />

                    <AuthRoute
                      path="/erp/prontuario/novo"
                      exact
                      component={CriarProntuario}
                    />

                    <AuthRoute
                      path="/erp/prontuario"
                      exact
                      component={ListarProntuarios}
                    />

                    <AuthRoute
                      path="/erp/atendimento"
                      exact
                      component={ListarAtendimentos}
                    />

                    <AuthRoute
                      path="/erp/atendimento/novo"
                      exact
                      component={NovoAtendimento}
                    />

                    <AuthRoute path="/erp/agenda" exact component={Agenda} />
                    <Redirect to="/erp/agenda" />
                  </Switch>
                </Layout>
              )}
              {modulo === 'Configuracoes' && (
                <Layout>
                  <Switch>
                    <AuthRoute path="/erp/" exact component={Saudacoes} />
                    <AuthRoute
                      path="/erp/desconto-manual"
                      exact
                      component={ListarDescontoManual}
                    />
                    <AuthRoute
                      path="/erp/unidade-medida"
                      exact
                      component={UnidadeMedida}
                    />
                    <AuthRoute path="/erp/marca" exact component={Marca} />
                    <AuthRoute path="/erp/filial" exact component={Filial} />
                    <AuthRoute
                      path="/erp/filial/nova"
                      exact
                      component={NovaFilial}
                    />
                    <Redirect to="/erp/" />
                  </Switch>
                </Layout>
              )}
            </Route>
          )}
          {user?.pay && (
            <Route path={['/pay/']}>
              <Layout>
                <Switch>
                  <AuthRoute path="/pay/" exact component={Dashboard} />
                  <AuthRoute path="/pay/chat" exact component={Chat} />
                  <AuthRoute
                    path="/pay/pre-cadastro"
                    exact
                    component={ListarPreCadastro}
                  />
                  <AuthRoute
                    path="/pay/pre-cadastro/novo"
                    exact
                    component={PreCadastro}
                  />
                  <AuthRoute
                    path="/pay/vendedores"
                    exact
                    component={ListarVendedores}
                  />
                  <AuthRoute
                    path="/pay/vendedores/novo-pj"
                    exact
                    component={CriarVendedorPj}
                  />
                  <AuthRoute
                    path="/pay/vendedores/novo-pf"
                    exact
                    component={CriarVendedorPf}
                  />
                  <AuthRoute
                    path="/pay/transacoes"
                    exact
                    component={ListarTransacoes}
                  />
                  <AuthRoute
                    path="/pay/link-pagamento"
                    exact
                    component={ListarLinkPagamento}
                  />
                  <AuthRoute path="/pay/perfil" exact component={Perfil} />
                  <AuthRoute path="/pay/pos" exact component={ListarPos} />
                  <AuthRoute
                    path="/pay/transferencias"
                    exact
                    component={ListarTransferencias}
                  />
                  <AuthRoute path="/pay/taxas" exact component={ListarTaxas} />
                  <AuthRoute
                    path="/pay/usuarios"
                    exact
                    component={ListarUsuarios}
                  />

                  <AuthRoute
                    path="/pay/funcionarios"
                    exact
                    component={Funcionarios}
                  />
                  <AuthRoute
                    path="/pay/funcionarios/novo"
                    exact
                    component={NovoFuncionario}
                  />

                  <AuthRoute
                    path="/pay/parceiros"
                    exact
                    component={Parceiros}
                  />
                  <AuthRoute
                    path="/pay/parceiros/novo"
                    exact
                    component={NovoParceiro}
                  />
                </Switch>
              </Layout>
            </Route>
          )}
          {user?.telecom && (
            <Route path={['/telecom/']}>
              <LayoutSemNav>
                <Switch>
                  <AuthRoute
                    path="/telecom/agentes"
                    exact
                    component={AgentesTelecom}
                  />
                  <AuthRoute
                    path="/telecom/configuracoes/usuarios"
                    exact
                    component={ListarUsuariosTelecom}
                  />
                  <AuthRoute
                    path="/telecom/configuracoes/usuarios/novo"
                    exact
                    component={NovoUsuarioTelecom}
                  />
                  <AuthRoute
                    path="/telecom/configuracoes/empresas"
                    exact
                    component={ListarEmpresasTelecom}
                  />
                  <AuthRoute
                    path="/telecom/configuracoes/empresas/nova"
                    exact
                    component={NovaEmpresaTelecom}
                  />
                </Switch>
              </LayoutSemNav>
            </Route>
          )}
          <Redirect to="/" />
        </>
      ) : (
        <>
          <Route path="/" exact component={Modulos} />
          <Redirect to="/" />
        </>
      )}
    </Switch>
  );
};

export default Routes;
