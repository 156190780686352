import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import { Input, Select, NumberFormat } from '../../../components/unform';
import useValidator from '../../../hooks/useValidator';
import api from '../../../services/apiPDV';
import { useAuth } from '../../../contexts/auth';

function Sangria({ modalProps, toggleModal }) {
  const [tipoSangria, setTipoSangria] = useState('R');
  const { toast, showLoader, closeLoader, showError } = useValidator();
  const formRef = useRef();
  const { idFilial } = useAuth();

  async function handleSubmit(e) {
    const errors = {};

    if (e.valor === 0) {
      errors.valor = 'Obrigatório';
    }
    if (e.tipo === 'D') {
      if (!e.numero_nf) {
        errors.numero_nf = 'Obrigatório';
      }
      if (!e.observacao) {
        errors.observacao = 'Obrigatório';
      }
    }

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast();
    }

    e.id_filial = idFilial;

    try {
      showLoader();
      await api.post('/sangria', e);
      toggleModal();
      closeLoader();
    } catch (err) {
      showError(err);
    }
  }

  return (
    <>
      <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <NumberFormat
                className="form-control"
                prefix="R$"
                placeholder="R$ 0,00"
                label="Valor sangria"
                name="valor"
              />
            </Col>
            <Col lg={6}>
              <Select
                label="Tipo sangria"
                name="tipo"
                className="form-control"
                onChange={(e) => {
                  setTipoSangria(e.target.value);
                }}
                data={[
                  {
                    value: 'R',
                    label: 'Retirada',
                  },
                  {
                    value: 'D',
                    label: 'Despesa',
                  },
                ]}
              />
            </Col>
            {tipoSangria === 'D' && (
              <>
                <Col lg={6}>
                  <Input
                    label="Número NF"
                    name="numero_nf"
                    className="form-control"
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label="Observação"
                    name="observacao"
                    className="form-control"
                  />
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}

export default Sangria;
