/* eslint-disable no-return-await */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import { FiFilter, FiRefreshCcw } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisH,
  FaMoneyBill,
  FaMoneyBillWave,
  FaPlusCircle,
  FaTimes,
  FaTrash,
  FaCog,
  FaAngleDown,
  FaPencilAlt,
} from 'react-icons/fa';
// import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  ModalFooter,
  Label,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import CurrencyInput from 'react-currency-input-field';
import api from '../../services/apiBank';
import { NumberFormat, Input, Radio } from '../../components/unform/index';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Pix() {
  const formRef = useRef();
  const formRefChave = useRef();
  const formRefModal = useRef();
  const formRefModalFav = useRef();
  const [activeTab, setActiveTab] = useState('1');
  const [favoritos, setFavoritos] = useState([]);

  const [selectedFav, setselectedFav] = useState([]);
  const [valueFromSelected, setValueFromSelected] = useState([]);

  const [favoritosTransfer, setFavoritosTransfer] = useState([]);

  const [showBtn, setShowBtn] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalFav, setModalFav] = useState(false);

  const [dadosPix, setDadosPix] = useState(null);
  const [dadosPixTransferencia, setDadosPixTransferencia] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  const toggleModalFav = () => setModalFav(!modalFav);

  async function getFavoritos() {
    try {
      const { data } = await api.get('/favoritos-pj/Pix');
      setFavoritos(data);
    } catch (err) {
      toast.error('Erro ao buscar favoritos!');
    }
  }

  useEffect(() => {
    getFavoritos();
  }, []);

  useEffect(() => {
    if (selectedFav.length) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [selectedFav]);

  async function handlePix(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      setDadosPixTransferencia(data);
      toggleModal();
    }
  }

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefModal.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const body = {
        valor: dadosPixTransferencia?.valor,
        descricaoCliente: dadosPixTransferencia?.descricao,
        codigo: data.codigo,
        favorito: dadosPixTransferencia?.favorito,
        ...dadosPix,
      };

      body.senha_conta = data.senha;

      try {
        await api.post('/pix-pj', body);
        toast.success('Pix realizado com sucesso!');
        toggleModal();
        Swal.close();
      } catch (err) {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
        toggleModal();
      }
    }
  }

  async function handleTransfer() {
    if (valueFromSelected.length) {
      const arrayToTransfer = [];
      const arrayFormat = valueFromSelected?.filter(
        (e) => e?.valor !== undefined
      );

      for (let i = 0; i < favoritos.length; i += 1) {
        const favorito = favoritos[i];
        for (let o = 0; o < arrayFormat.length; o += 1) {
          const selecionado = arrayFormat[o];

          if (favorito.id === selecionado.id) {
            arrayToTransfer.push({
              nome_recebedor: favorito?.nome,
              valor: Number(selecionado?.valor),
              chave_pix: favorito?.chave_pix,
              initializationType: 'key',
              descricao: '',
            });
          }
        }
      }

      setFavoritosTransfer(arrayToTransfer);
      toggleModalFav();
    }
  }

  async function handleSubmitMany(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefModalFav.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // eslint-disable-next-line no-restricted-syntax
      for await (const [, favorito] of favoritosTransfer.entries()) {
        try {
          const { data: retornoConsultaChave } = await api.get(
            `/pix/chaves/${favorito?.chave_pix}`
          );

          const body = {
            valor: favorito?.valor,
            descricaoCliente: favorito?.descricao,
            codigo: data?.codigo,
            senha_conta: data.senha,
            ...retornoConsultaChave,
          };

          await api.post('/pix-pj', body);
          toast.success(`Pix para ${favorito?.nome_recebedor} realizado!`);
        } catch (err) {
          const error =
            err?.response?.data?.error || 'Ocorreu um erro tente novamente';
          toast.error(error);
        }
      }
      Swal.close();
      toggleModalFav();
    }
  }

  async function handleDeleteFavorito(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir esse favorito?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/deletar-favorito/${id}`)
            .then(() => {
              setFavoritos(favoritos?.filter((f) => f.id !== id));
              toast.success('Favorito excluido com sucesso!');
              Swal.close();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  async function consultaChave(er) {
    const errors = {};

    Object.keys(er).forEach((f) => {
      if (!er[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefChave.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data } = await api.get(`/pix/chaves/${er?.chave}`);

        data.chave_pix = er?.chave;
        setDadosPix(data);
        Swal.close();
      } catch (err) {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        setDadosPix(null);
        Swal.close();
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Pix</h4>
        </Col>
      </Row>
      <Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Realizar pix
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' && 'active'}
              onClick={() => {
                toggle('2');
              }}
            >
              Favoritos
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              ref={formRefChave}
              onSubmit={(e) => {
                consultaChave(e);
              }}
            >
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Input
                      label="Chave PIX"
                      className="form-control"
                      type="text"
                      name="chave"
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Consultar chave
                </Button>
              </CardFooter>
            </Form>
            {dadosPix && (
              <>
                <hr />
                <h6 style={{ textAlign: 'center' }}>
                  Chave encontrada, realize o PIX abaixo, ou consulte uma nova
                  chave
                </h6>
                <hr />
                <Form
                  ref={formRef}
                  onSubmit={(e) => {
                    handlePix(e);
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col lg={12} className="mb-3">
                        <h3>Transferir para</h3>
                        <div>
                          Banco
                          <h5>{dadosPix?.banco?.descricao}</h5>
                        </div>
                        <div className="d-flex-wrap">
                          <div className="mr-3">
                            Nome
                            <h5>{dadosPix?.nomeCorrentista}</h5>
                          </div>
                          <div className="ml-3 mr-3">
                            Documento
                            <h5>{dadosPix?.documentoFederal}</h5>
                          </div>
                          <div className="ml-3">
                            Chave
                            <h5>{dadosPix?.chave_pix}</h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <NumberFormat
                          name="valor"
                          label="Valor R$"
                          className="form-control"
                          thousandSeparator="."
                          prefix="R$"
                          decimalSeparator=","
                        />
                      </Col>
                      <Col lg={6}>
                        <Input
                          label="Descrição"
                          className="form-control"
                          type="text"
                          name="descricao"
                        />
                      </Col>
                      <Col lg={3}>
                        <Radio
                          name="favorito"
                          label="Adicionar aos favoritos?"
                          className="radio"
                          defaultValue="S"
                          data={[
                            { id: 'S', label: 'Sim', value: true },
                            { id: 'N', label: 'Não', value: false },
                          ]}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Transferir
                    </Button>
                  </CardFooter>
                </Form>
              </>
            )}
          </TabPane>
          <TabPane tabId="2">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th style={{ textAlign: 'center' }}>Transferência</th>
                    <th style={{ textAlign: 'center' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {favoritos?.length ? (
                    favoritos?.map((tar) => (
                      <tr key={tar?.id}>
                        <td data-label="Nome">{tar?.nome}</td>
                        <td data-label="Ações" className="text-center w-10">
                          <Button
                            outline
                            color="success"
                            hidden={selectedFav?.includes(tar?.id)}
                            onClick={() => {
                              setselectedFav([...selectedFav, tar?.id]);
                            }}
                          >
                            <FaPlusCircle />
                          </Button>
                          {selectedFav?.includes(tar?.id) && (
                            <Row>
                              <Col>
                                <CurrencyInput
                                  className="form-control"
                                  onValueChange={(value) => {
                                    const newArray = valueFromSelected?.filter(
                                      (e) => e?.id !== tar?.id
                                    );
                                    const selected = {
                                      valor: value?.replace(',', '.'),
                                      id: tar?.id,
                                    };
                                    newArray.push(selected);
                                    setValueFromSelected(newArray);
                                  }}
                                  name="valor"
                                  intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                  }}
                                  placeholder="Valor R$"
                                  decimalsLimit={2}
                                  id={tar?.id}
                                />
                              </Col>
                              <Col lg={1} className="mr-3">
                                <Button
                                  outline
                                  className="btn-md"
                                  color="danger"
                                  onClick={() => {
                                    setValueFromSelected(
                                      valueFromSelected?.filter(
                                        (e) => e?.id !== tar?.id
                                      )
                                    );
                                    setselectedFav(
                                      selectedFav?.filter((e) => e !== tar?.id)
                                    );
                                  }}
                                >
                                  <FaTimes />
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </td>
                        <td data-label="Ações" className="text-center w-10">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color="link"
                              className="text-decoration-none padding-0-5"
                            >
                              <FaCog className="mr-2" /> <FaAngleDown />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() => {
                                  handleDeleteFavorito(tar?.id);
                                }}
                              >
                                <FaTrash className="mr-1" />
                                Excluir
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              {showBtn && (
                <Button color="primary" onClick={handleTransfer}>
                  Transferir
                </Button>
              )}
            </CardFooter>
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Validar transferência</ModalHeader>
        <Form
          ref={formRefModal}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col>
                <h6>Chave: {dadosPix?.chave}</h6>
              </Col>
              <Col>
                <h6>
                  Valor:{' '}
                  {Number(dadosPixTransferencia?.valor || 0).toLocaleString(
                    'pt-BR',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Descrição: {dadosPixTransferencia?.descricao}</h6>
              </Col>
            </Row>
            <hr />
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modalFav} toggle={toggleModalFav}>
        <ModalHeader toggle={toggleModalFav}>Validar transferência</ModalHeader>
        <Form
          ref={formRefModalFav}
          onSubmit={(e) => {
            handleSubmitMany(e);
          }}
        >
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {favoritosTransfer?.length ? (
                  favoritosTransfer?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>{tar?.nome_recebedor}</td>
                      <td>
                        {Number(tar?.valor)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <hr />
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Pix;
