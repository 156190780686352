/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { Children, cloneElement, useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  Button,
  ModalHeader,
} from 'reactstrap';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { FaPlus, FaRegClock, FaCog } from 'react-icons/fa';
import calendar from '../../../../assets/images/calendar.svg';
import api from '../../../../services/apiPDV';
import defaultEvents from './defaultEvents';
import formatEvents from './formatEvents';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { useAuth } from '../../../../contexts/auth';
import NovoEvento from './novoEvento';
import VizualizarEvento from './viewEvent';
import ConfigsAgenda from './configsAgenda';
import ListaDeEspera from '../ListaEspera/listaEspera';

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

const TouchCellWrapper = ({ children, value, onSelectSlot, ...rest }) =>
  cloneElement(Children.only(children), {
    onTouchEnd: () =>
      onSelectSlot({ action: 'click', slots: [value], ...rest }),
  });

const obj = {};

window.screen.width <= 768
  ? (obj.month = {
      dateHeader: ({ label }) => <div>{label}</div>,
    })
  : null;

function formatTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return {
    hora: String(hours).padStart(2, '0'),
    minuto: String(mins).padStart(2, '0'),
  };
}

function formatarData(data) {
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();
  return `${ano}-${mes}-${dia}`;
}

function formatHour(data) {
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  const horaFormatada = `${horas}:${minutos}`;

  return horaFormatada;
}

export default function Calendar() {
  const { selectedFilial } = useAuth();

  const [modal, setModal] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalListaEspera, setModalListaEspera] = useState(false);
  const [modalConfigs, setModalConfigs] = useState(false);

  const [agendar, setAgendar] = useState(null);

  const [minAgenda, setMinAgenda] = useState(6);

  const isEventDraggable = (event) => event.blocked === false;

  const [events, setEvents] = useState([]);
  const [defaultConfigs, setDefaultConfigs] = useState([]);

  const [diaEvento, setDiaEvento] = useState(false);
  const [edicao, setEdicao] = useState(null);

  const [profissional, setProfissional] = useState(null);
  const [profissionais, setProfissionais] = useState([]);

  const [maxMinHours, setMaxMinHours] = useState({
    inicio: {
      hora: 0,
      minuto: 0,
    },
    fim: {
      hora: 23,
      minuto: 59,
    },
  });

  function newEvent(event) {
    setDiaEvento(event);
    setModal(!modal);
  }

  const eventColors = (event) => {
    const textColor = event.textColor || 'white';

    switch (event.atividade) {
      case 'almoco':
        event.color = '#FFFFFB';
        break;
      case 'indisponivel':
        event.color = 'rgb(231,231,231)';
        break;
      case 'atendimento':
        event.color = '#489ef7';
        break;
      case 'bloqueio':
        event.color = '#c3d5e0';
        break;
      default:
        event.color = 'rgb(99, 43, 238)';
        break;
    }

    return {
      style: {
        color: textColor,
        background: event.color,
        textDecoration: event.concluido ? 'line-through' : 'none',
        border: '1px solid #BFBFBF',
        fontWeight: 'bold',
        cursor: event.cursor || 'default',
      },
    };
  };

  function eventDetails(event) {
    if (!event?.blocked) {
      setEdicao(event);
      setModalView(!modalView);
    }
  }

  async function alterarDia({ end, start, event }) {
    if (
      end.getTime() !== event.endDate.getTime() ||
      start.getTime() !== event.startDate.getTime()
    ) {
      try {
        setEvents([
          ...events.filter((f) => f.id !== event.id),
          { ...event, startDate: start, endDate: end },
        ]);
        await api.put(`/reagendar/${event.id}`, {
          data: formatarData(start),
          id_profissional: profissional,
          hora_inicio: formatHour(start),
          hora_fim: formatHour(end),
        });

        toast.success('Reagendado com sucesso!');
      } catch (err) {
        setEvents([...events.filter((f) => f.id !== event.id), event]);
        toast.error(
          err?.response?.data?.message || 'Não foi possível reagendar!'
        );
      }
    }
  }

  async function getEventos(data = new Date()) {
    const { data: eventos } = await api.post('/agenda-eventos', {
      day: data,
      id_filial: selectedFilial?.filial || 1,
      id_profissional: profissional,
    });

    if (eventos?.length) {
      setEvents(formatEvents(eventos));
    }
  }

  async function getConfigsAgenda(data = new Date()) {
    const { data: configs } = await api.get(
      `/agenda-config?id_filial=${selectedFilial?.filial}&id_profissional=${profissional}`
    );
    if (configs?.dias_horas_atendimento) {
      const diasComAtendimento =
        configs?.dias_horas_atendimento?.horario_atendimento.filter(
          (dia) => dia.horario_atendimento
        );
      const menorInicio = diasComAtendimento.reduce((min, dia) => {
        const [horas, minutos] = dia.horario_atendimento.inicio
          .split(':')
          .map(Number);
        const totalMinutos = horas * 60 + minutos;
        return totalMinutos < min ? totalMinutos : min;
      }, Infinity);

      const maiorFim = diasComAtendimento.reduce((max, dia) => {
        const [horas, minutos] = dia.horario_atendimento.fim
          .split(':')
          .map(Number);
        const totalMinutos = horas * 60 + minutos;
        return totalMinutos > max ? totalMinutos : max;
      }, 0);

      // Aqui para deixar menor a exibição de acordo com o horário inicio e fim
      setMaxMinHours({
        inicio: formatTime(menorInicio),
        fim: formatTime(maiorFim),
      });

      setDefaultConfigs(
        defaultEvents(
          configs?.dias_horas_atendimento?.horario_atendimento,
          data
        )
      );
    }
  }

  async function getProfissionais() {
    try {
      if (selectedFilial?.filial) {
        const { data } = await api.get(
          `/profissionais?filial=${selectedFilial?.filial}&options=true`
        );
        setProfissionais(data?.data);
        setProfissional(data?.data[0]?.value);
      }
    } catch (err) {
      toast.error('Erro ao buscar profissionais!');
    }
  }

  useEffect(() => {
    getConfigsAgenda();
    getEventos();
  }, [selectedFilial?.filial, profissional]);

  useEffect(() => {
    getProfissionais();
  }, [selectedFilial?.filial]);

  return (
    <>
      <Card>
        <CardBody>
          <Row className="justify-between">
            <Col lg={5} className="mb-4">
              <Row className="justify-start">
                <Col lg={3}>
                  <label>Visualização agenda</label>
                  <select
                    className="form-control"
                    name="select"
                    defaultValue={6}
                    onChange={(e) => setMinAgenda(e.target.value)}
                  >
                    <option value="1">5 min</option>
                    <option value="2">10 min</option>
                    <option value="3">15 min</option>
                    <option value="4">20 min</option>
                    <option value="5">25 min</option>
                    <option value="6">30 min</option>
                    <option value="7">35 min</option>
                    <option value="8">40 min</option>
                    <option value="9">45 min</option>
                    <option value="10">50 min</option>
                    <option value="11">55 min</option>
                    <option value="12">60 min</option>
                  </select>
                </Col>
                <Col lg={5}>
                  <label>Profissional</label>
                  <select
                    className="form-control"
                    name="select"
                    defaultValue={6}
                    onChange={(e) => setProfissional(e.target.value)}
                  >
                    {profissionais?.map((p) => (
                      <option value={p?.value}>{p?.label}</option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <div className="justify-between">
                <Button
                  block
                  color="outline-primary"
                  type="button"
                  onClick={newEvent}
                >
                  <FaPlus className="mb-1 mr-2" /> Novo agendamento
                </Button>
                <Button
                  block
                  className="ml-2"
                  color="outline-warning"
                  type="button"
                  onClick={() => setModalListaEspera(true)}
                >
                  <FaRegClock className="mb-1 mr-2" /> Lista de espera
                </Button>
                <Button
                  block
                  className="ml-2"
                  color="outline-secondary"
                  type="button"
                  onClick={() => setModalConfigs(true)}
                >
                  <FaCog className="mb-1 mr-2" /> Configurações
                </Button>
              </div>
            </Col>
          </Row>
          <DragAndDropCalendar
            views={['week', 'day']}
            min={
              new Date(
                0,
                0,
                0,
                maxMinHours?.inicio?.hora,
                maxMinHours?.inicio?.minuto,
                0
              )
            }
            max={
              new Date(
                0,
                0,
                0,
                maxMinHours?.fim?.hora,
                maxMinHours?.fim?.minuto,
                0
              )
            }
            showMultiDayTimes
            step={minAgenda}
            timeslots={5} // 5 linhas no periodo
            style={{
              minHeight: '68vh',
            }}
            draggableAccessor={isEventDraggable}
            eventPropGetter={(e) => eventColors(e)}
            localizer={localizer}
            events={[...events, ...defaultConfigs]}
            onEventDrop={alterarDia}
            onEventResize={(e) => alterarDia({ ...e, resize: true })}
            onRangeChange={async ({ start, end, ...rest }) => {
              let inicial;

              if (start && end) {
                inicial = start;
              } else if (rest) {
                inicial = rest['0'];
              }
              getEventos(inicial);
              getConfigsAgenda(inicial);
            }}
            components={{
              dateCellWrapper: (props) => (
                <TouchCellWrapper {...props} onSelectSlot={newEvent} />
              ),
              ...obj,
            }}
            longPressThreshold={1}
            onSelectEvent={(e) => eventDetails(e)}
            onSelectSlot={(e) => newEvent(e)}
            selectable="ignoreEvents"
            defaultView="week"
            defaultDate={new Date()}
            startAccessor="startDate"
            endAccessor="endDate"
            messages={{
              today: 'Hoje',
              previous: '<',
              next: '>',
              month: 'Mês',
              week: 'Semana',
              day: 'Dia',
              date: 'Data',
              time: 'Horário',
              event: 'Evento',
              allDay: 'Dia inteiro',
              tomorrow: 'Amanhã',
              showMore: (count) => `E mais ${count} eventos`,
              yesterday: 'Ontem',
              work_week: 'Trabalho',
              noEventsInRange: (
                <div className="d-block">
                  <div className="w-100 mb-1">Sem eventos até o momento!</div>
                  <img
                    src={calendar}
                    alt="sem registros!"
                    style={{ height: '40vh' }}
                  />
                </div>
              ),
            }}
            resizableAccessor={() => false}
            popup
          />
        </CardBody>
      </Card>
      <Modal isOpen={modal} size="md">
        <NovoEvento
          setEvents={setEvents}
          setModal={setModal}
          isOpen={!modal}
          diaEvento={diaEvento}
          agendar={agendar}
          setModalListaEspera={setModalListaEspera}
          idProfissional={profissional}
        />
      </Modal>
      <Modal isOpen={modalView} size="lg">
        <VizualizarEvento
          setEvents={setEvents}
          setModal={setModalView}
          isOpen={!modalView}
          diaEvento={diaEvento}
          edicao={edicao}
          setEdicao={setEdicao}
          idProfissional={profissional}
        />
      </Modal>
      <Modal isOpen={modalListaEspera} size="xl">
        <ModalHeader
          toggle={() => {
            setModalListaEspera(!modalListaEspera);
          }}
        >
          Lista de espera
        </ModalHeader>
        <Row>
          <Col className="m-2">
            <ListaDeEspera
              vizualizacaoAgenda
              setAgendar={setAgendar}
              setModal={setModal}
              setModalListaEspera={setModalListaEspera}
            />
          </Col>
        </Row>
      </Modal>
      <Modal isOpen={modalConfigs} size="lg">
        <ConfigsAgenda
          setModal={setModalConfigs}
          isOpen={!modalConfigs}
          idProfissional={profissional}
        />
      </Modal>
    </>
  );
}
