import React from 'react';

// import { Container } from './styles';

function LayoutPdv({ children }) {
  return (
    <div className="full-resolution-pdv">
      <main>{children}</main>
    </div>
  );
}

export default LayoutPdv;
