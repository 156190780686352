/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import ReactJson from 'react-json-view';
import {
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisH,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Funcionarios() {
  const history = useHistory();
  let timeoutConsulta;
  const [funcionarios, setFuncionarios] = useState([]);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [filtros, setFiltros] = useState(null);

  const [funcionario, setFuncionario] = useState(null);

  const [modal, setModal] = useState(false);

  const [total, setTotal] = useState(0);
  const [showInnerTable, setShowInnerTable] = useState({});

  const toggleModal = () => setModal(!modal);

  const refNome = useRef(null);
  const refDocumento = useRef(null);

  const handleRowClick = (index) => {
    setShowInnerTable((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  async function getfuncionarios() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let filtroConsulta = '';
      if (filtros) {
        Object.entries(filtros).forEach(([key, value]) => {
          if (['string', 'number'].includes(typeof value)) {
            filtroConsulta += `${key}=${value}&`;
          } else if (value) {
            if (value.length === undefined) {
              Object.entries(value).forEach(([key2, value2]) => {
                filtroConsulta += `${key}${key2}=${
                  new Date(value2).toISOString().split('T')[0]
                }&`;
              });
            } else {
              filtroConsulta += `${key}=[${value.toString()}]&`;
            }
          }
        });
      }
      const { data } = await api.get(
        `/funcionario?page=${page + 1}&${filtroConsulta}`
      );
      if (data?.items) {
        setFuncionarios(data?.items);
        setPagination(data?.pages);
        setTotal(data?.total);
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar funcionarios!');
      Swal.close();
    }
  }

  useEffect(() => {
    getfuncionarios();
  }, [page, filtros]);

  const debounceConsulta = async (body) =>
    await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });

  async function verInfos(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/funcionario/${id}`);
      if (data) {
        setFuncionario(data);
        toggleModal();
        Swal.close();
      }
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  function formataCPF(cpf) {
    if (cpf) {
      cpf = cpf.replace(/[^\d]/g, '');
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return '';
  }

  function getFormattedDate(data) {
    const date = new Date(data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let formatterDay;
    if (day < 10) {
      formatterDay = `0${day}`;
    } else {
      formatterDay = day;
    }

    let formatterMonth;
    if (month < 10) {
      formatterMonth = `0${month}`;
    } else {
      formatterMonth = month;
    }

    return `${formatterDay}/${formatterMonth}/${year}`;
  }

  const handleEdit = (id) => {
    history.push(`/pay/funcionarios/novo?id=${id}`);
  };

  async function atualizarKye(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja atualizar o KYE?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .post(`/funcionario-kye/${id}`)
            .then(async ({ data }) => {
              Swal.close();
              if (data === 'Approved') {
                toast.success('Funcionário aprovado no KYE');
              } else {
                toast.warning('Funcionário reprovado no KYE');
              }

              await getfuncionarios();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Funcionários</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {total || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              history.push('/pay/funcionarios/novo');
            }}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Nome"
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="Documento"
                  ref={refDocumento}
                  onChange={() =>
                    debounceConsulta({
                      documento: refDocumento?.current?.value,
                    })
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>CPF</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>E-mail</th>
              <th>Status</th>
              <th className="justify-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {funcionarios?.length ? (
              funcionarios?.map((func) => (
                <tr key={func?.id}>
                  <td>{formataCPF(func?.cpf)}</td>
                  <td>{func?.nome}</td>
                  <td>{func?.telefone}</td>
                  <td>{func?.email}</td>
                  <td>{func?.status}</td>
                  <td className="justify-center">
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right container="body">
                        <DropdownItem
                          onClick={() => atualizarKye(func?.id)}
                          size="xs"
                        >
                          Atualizar KYE
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleEdit(func?.id)}
                          size="xs"
                        >
                          Editar
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => verInfos(func?.id)}
                          size="xs"
                        >
                          Ver histórico KYE
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pagination || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Histórico KYE</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Score</th>
                    <th className="text-center">Score limite</th>
                    <th>Data</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Objeto</th>
                  </tr>
                </thead>
                <tbody>
                  {funcionario?.FuncionarioAvaliacaos?.map((m, index) => (
                    <>
                      <tr key={m?.id}>
                        <td data-label="Score" className="text-center">
                          {m?.score}
                        </td>
                        <td data-label="Score limite" className="text-center">
                          {m?.score_limite}
                        </td>
                        <td data-label="Data">
                          {getFormattedDate(m?.createdAt)}
                        </td>
                        <td data-label="Status" className="text-center">
                          {m?.resultado}
                        </td>
                        <td data-label="Objeto" className="text-center">
                          <Button
                            color="link"
                            onClick={() => handleRowClick(index)}
                          >
                            {showInnerTable[index] ? (
                              <FaArrowUp size={10} />
                            ) : (
                              <FaArrowDown size={10} />
                            )}
                          </Button>
                        </td>
                      </tr>
                      {showInnerTable[index] && (
                        <>
                          <tr>
                            <td colSpan="9" style={{ width: '100%' }}>
                              <ReactJson
                                displayDataTypes={false}
                                iconStyle="circle"
                                displayObjectSize={false}
                                indentWidth={3}
                                collapsed={false}
                                src={m?.objeto || {}}
                              />
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Funcionarios;
