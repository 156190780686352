import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Input,
  MaskInput,
  Select,
  ReactSelect,
  Image,
  Radio,
  NumberFormat,
} from '../../../components/unform';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import ufs from './ufs.json';

function Dashboard() {
  const formRef = useRef();
  const [planos, setPlanos] = useState([]);
  const [mccs, setMccs] = useState([]);
  const [bancos, setBancos] = useState([]);
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const history = useHistory();
  const [informarBanco, setInformarBanco] = useState(
    history?.location?.state?.abrir_conta === 'nao'
  );

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  async function abrirConta() {
    const dados = formRef.current.getData();

    const formData = new FormData();

    Object.entries(dados).forEach(([key, value]) => {
      if (['documento_frente', 'documento_verso', 'selfie'].includes(key)) {
        if (value.file) {
          formData.append('file', value.file, `${key}@${value.file.name}`);
        } else {
          formData.append(key, history?.location?.state?.[key]);
        }
      } else {
        formData.append(key, value);
      }
    });

    try {
      await api.post('/criar-vendedor/pf', formData);
      closeLoader();
      toast('Vendedor criado com sucesso', { type: 'success' });
      history.push('/pay/vendedores');
    } catch (err) {
      closeLoader();
      if (err?.response?.data?.type === 'swal') {
        Swal.fire({
          title: err.mensagem,
          html: err?.response?.data?.tratado,
          icon: 'error',
        });
      } else {
        toast(err.mensagem);
      }
    }
  }

  const processChange = debounce(() => abrirConta());

  async function handleSubmit(dados) {
    const { errors, hasError } = validator(dados, [
      'complemento',
      'tipo_documento',
      'selfie',
      'documento_frente',
      'documento_verso',
      'responsavel_conta',
    ]);

    formRef.current.setErrors(errors);

    if (hasError) {
      toast();
      return;
    }

    showLoader();

    processChange();
  }

  async function getCep(cepStr = '') {
    const cep = String(cepStr).replace(/[^A-Z0-9]/gi, '');
    if (cep.length !== 8) {
      return;
    }
    showLoader();
    try {
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      formRef.current.setFieldValue('cidade', data.city);
      formRef.current.setFieldValue('bairro', data.neighborhood);
      formRef.current.setFieldValue('estado', data.state);
      formRef.current.setFieldValue('logradouro', data.street);
    } catch (err) {
      formRef.current.setFieldValue('cidade', '');
      formRef.current.setFieldValue('bairro', '');
      formRef.current.setFieldValue('estado', '');
      formRef.current.setFieldValue('logradouro', '');
      toast('CEP não encontrado', { type: 'warning' });
    }
    closeLoader();
  }

  useEffect(() => {
    const getBancos = async () => {
      try {
        const { data } = await api.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(
          data
            .filter((f) => Boolean(f.code))
            .map((m) => ({
              value: String(m.code).padStart(3, '0'),
              label: `${m.code} - ${m.name}`,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (informarBanco && !bancos.length) {
      getBancos();
    }
  }, [informarBanco, bancos]);

  useEffect(() => {
    const getPlanos = async () => {
      try {
        const { data } = await api.get('/planos');
        setPlanos(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: m.id,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    const getMccs = async () => {
      try {
        const { data } = await api.get('/mcc');
        setMccs(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: `${m.code} - ${m.category} : ${m.description}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };

    getPlanos();
    getMccs();
  }, []);

  return (
    <>
      <Card className="px-3 py-3">
        <Form
          initialData={history?.location?.state}
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row>
            <Col lg={3} sm={6}>
              <MaskInput
                name="cpf"
                label="CPF"
                placeholder="CPF"
                className="form-control"
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="rg"
                label="RG"
                placeholder="RG"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="rgorgao"
                label="RG orgão emissor"
                placeholder="RG orgão emissor"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Select
                name="rguf"
                label="RG UF"
                className="form-control"
                data={ufs}
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="rgdata"
                label="Data de expedição RG"
                className="form-control"
                type="date"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Select
                name="sexo"
                label="Sexo"
                className="form-control"
                data={[
                  { label: 'Selecione', value: '' },
                  {
                    label: 'Masculino',
                    value: 'M',
                  },
                  {
                    label: 'Feminino',
                    value: 'F',
                  },
                ]}
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="primeiro_nome"
                label="Nome"
                placeholder="Nome"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="ultimo_nome"
                label="Sobrenome"
                placeholder="Sobrenome"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="nome_exibicao"
                label="Nome de exibição"
                placeholder="Nome de exibição"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="email"
                label="E-mail"
                placeholder="E-mail"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={6}>
              <MaskInput
                name="telefone"
                label="Telefone"
                placeholder="Telefone"
                className="form-control"
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </Col>
            <Col lg={3} sm={3}>
              <Input
                name="data_nascimento"
                label="Data nascimento"
                type="date"
                placeholder="Data nascimento"
                className="form-control"
              />
            </Col>
            <Col lg={3} sm={3}>
              <NumberFormat
                name="faturamento"
                label="Faturamento"
                className="form-control"
                thousandSeparator="."
                prefix="R$"
                decimalSeparator=","
              />
            </Col>
            <Col lg={3} sm={6}>
              <ReactSelect
                name="plano_id_zoop"
                label="Plano"
                options={planos}
              />
            </Col>
            <Col lg={3} sm={6}>
              <ReactSelect name="cnae_mcc" label="MCC" options={mccs} />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="nomeMae"
                label="Nome da mãe"
                className="form-control"
                placeholder="Digite o nome completo da mãe"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Input
                name="nomePai"
                label="Nome do pai"
                className="form-control"
                placeholder="Digite o nome completo do pai"
              />
            </Col>
            <Col lg={6} sm={12}>
              <Input
                name="responsavel_conta"
                label="Reponsável UneoPay"
                className="form-control"
                placeholder="Reponsável UneoPay"
              />
            </Col>
          </Row>
          <div className="separator">Documento</div>
          <Row>
            <Col lg={3}>
              <Select
                name="tipo_documento"
                label="Tipo do documento"
                className="form-control"
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'RG',
                    label: 'RG',
                  },
                  {
                    value: 'CNH',
                    label: 'CNH',
                  },
                  // {
                  //   value: 'RNE',
                  //   label: 'RNE',
                  // },
                  // {
                  //   value: 'CRNM',
                  //   label: 'CRNM',
                  // },
                  // {
                  //   value: 'DNI',
                  //   label: 'DNI',
                  // },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} sm={6}>
              <Image
                returnPreview
                name="documento_frente"
                label="Documento frente"
                objectFit="fit"
                btnSelect="Selecionar"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Image
                returnPreview
                objectFit="fit"
                name="documento_verso"
                label="Documento verso"
                btnSelect="Selecionar"
              />
            </Col>
            <Col lg={3} sm={6}>
              <Image
                returnPreview
                objectFit="fit"
                name="selfie"
                label="Selfie"
                btnSelect="Selecionar"
              />
            </Col>
          </Row>
          <div className="separator">Endereço</div>
          <Row>
            <Col lg={3} sm={6}>
              <MaskInput
                name="cep"
                label="CEP"
                placeholder="00.000-000"
                className="form-control"
                mask={[
                  /[0-9]/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                onChange={(e) => getCep(e.target.value, true)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5} sm={6}>
              <Input
                name="logradouro"
                label="Logrdouro"
                placeholder="Logradouro"
                className="form-control"
              />
            </Col>
            <Col lg={2} sm={6}>
              <Input
                name="numero"
                label="Número"
                placeholder="Número"
                className="form-control"
              />
            </Col>
            <Col lg={5} sm={6}>
              <Input
                name="complemento"
                label="Complemento"
                placeholder="Complemento"
                className="form-control"
              />
            </Col>
            <Col lg={5} sm={6}>
              <Input
                name="bairro"
                label="Bairro"
                placeholder="Bairro"
                className="form-control"
              />
            </Col>
            <Col lg={5} sm={6}>
              <Input
                name="cidade"
                label="Cidade"
                placeholder="Cidade"
                className="form-control"
              />
            </Col>
            <Col lg={2} sm={6}>
              <Input
                name="estado"
                label="Estado"
                placeholder="Estado"
                className="form-control"
              />
            </Col>
          </Row>
          <div className="separator">Conta bancaria</div>
          <Row>
            <Col lg={12} className="mb-3">
              <Radio
                name="abrir_conta"
                label="Abrir conta no UneoPay Bank"
                onChange={(e) => setInformarBanco(e.target.value === 'nao')}
                defaultValue="sim"
                data={[
                  {
                    id: 'sim',
                    value: 'sim',
                    label: 'Sim',
                  },
                  {
                    value: 'nao',
                    id: 'nao',
                    label: 'Não',
                  },
                ]}
              />
            </Col>
            {informarBanco && (
              <>
                <Col lg={12} className="mb-3">
                  <code>
                    * A conta de recebimento deve pertecencer ao CPF/CNPJ do
                    vendedor
                  </code>
                </Col>
                <Col lg={3}>
                  <Input
                    name="agencia"
                    label="Agência"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="conta_bancaria"
                    label="Número da conta"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="tipo_conta"
                    label="Tipo da conta"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'checking',
                        label: 'Corrente',
                      },
                      {
                        value: 'savings',
                        label: 'Poupança',
                      },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <ReactSelect
                    name="codigo_banco"
                    label="Banco"
                    options={bancos}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default Dashboard;
