import React, { useEffect, useRef, useState } from 'react';
import { FiClipboard, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  Input,
  NumberFormat,
  ReactSelectAsync,
  Select,
} from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';
import { useAuth } from '../../../contexts/auth';

function ListarPos() {
  const { isAdmin } = useAuth();
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const filtrosRef = useRef();
  const formRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef();
  const { showLoader, closeLoader, toast, validator } = useValidator();

  const toggleModal = () => setModal((old) => !old);

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/options/vendedores?value=${inputValue}`);

    return data;
  }

  const getOptions = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/pos?page=${page || 1}&${query}`);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function handleAtualizar(id) {
    try {
      showLoader();
      await api.post(`/pos/${id}`);
      filtrosRef.current.reset();
      setTimeout(() => {
        toggleModal();
        toast('POS atualizada com sucesso!', { type: 'success' });
        filtrosRef.current.submitForm();
      }, 100);
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  }

  async function handleSubmit(body) {
    if (modal?.id) {
      return;
    }
    const fields = [];
    const locacao = [
      'tempo_locacao',
      'fidelidade_locacao',
      'valor_locacao',
      'contrato_locacao',
      'fornecedor_locacao',
    ];

    body.fidelidade_locacao = body.fidelidade_locacao === 's';

    if (body.origem !== 'Locado') {
      locacao.forEach((e) => {
        fields.push(e);
        body[e] = null;
      });
    }

    const { errors, hasError } = validator(body, fields);
    formRef.current.setErrors(errors);

    if (hasError) {
      toast();
      return;
    }
    try {
      showLoader();
      await api.post('/pos', body);
      filtrosRef.current.reset();
      setTimeout(() => {
        toggleModal();
        toast('POS cadastrado com sucesso!', { type: 'success' });
        filtrosRef.current.submitForm();
      }, 100);
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/pos');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          {isAdmin ? (
            <Button color="primary" onClick={toggleModal}>
              Novo
            </Button>
          ) : (
            <div className="pl-3 pr-5" />
          )}
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                {isAdmin && (
                  <Col>
                    <ReactSelectAsync
                      placeholder="Vendedor"
                      name="id_vendedor_zoop"
                      defaultOptions
                      isClearable
                      loadOptions={getOptions}
                      onChange={filtrarPesquisa}
                    />
                  </Col>
                )}
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Id zoop"
                    name="terminal_id_zoop"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    name="origem"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Origem',
                      },
                      {
                        value: 'Próprio',
                        label: 'Próprio',
                      },
                      {
                        value: 'Locado',
                        label: 'Locado',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Pendente',
                        label: 'Pendente',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              {isAdmin && <th>Vendedor</th>}
              <th>N° série hardware</th>
              <th>Origem</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                {isAdmin && (
                  <td data-label="Vendedor">{m?.VendedorZoop?.Pessoa?.nome}</td>
                )}
                <td data-label="N° série hardware">
                  {m.numero_serie_hardware}
                </td>
                <td data-label="Origem">{m.origem}</td>
                <td data-label="Status">{m.status}</td>
                <td data-label="Ações">
                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          onClick={() => {
                            setModal(m);
                            setTimeout(() => {
                              if (m.origem === 'Locado') {
                                document
                                  .getElementById('locacao')
                                  .classList.remove('d-none');
                              } else {
                                document
                                  .getElementById('locacao')
                                  .classList.add('d-none');
                              }
                            }, 300);
                          }}
                        >
                          <FiClipboard className="mr-1" />
                          Detalhes
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {modal?.id
            ? `Detalhes POS: ${modal?.numero_serie_hardware}`
            : 'Cadastro POS'}
        </ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              {isAdmin && (
                <Col lg={12}>
                  <ReactSelectAsync
                    label="Vendedor"
                    name="id_vendedor_zoop"
                    defaultOptions
                    isDisabled={modal?.id}
                    defaultValue={
                      modal?.id
                        ? {
                            label: modal?.VendedorZoop?.Pessoa?.nome,
                            value: modal?.VendedorZoop?.id,
                          }
                        : undefined
                    }
                    loadOptions={getOptions}
                  />
                </Col>
              )}
              <Col lg={6}>
                <Input
                  name="numero_serie_hardware"
                  label="N° série hardware"
                  disabled={modal?.id}
                  defaultValue={modal?.numero_serie_hardware}
                  className="form-control"
                />
              </Col>
              <Col lg={6}>
                <Select
                  name="origem"
                  label="Origem"
                  disabled={modal?.id}
                  className="form-control"
                  defaultValue={modal?.origem}
                  onChange={(e) => {
                    if (e.target.value === 'Locado') {
                      document
                        .getElementById('locacao')
                        .classList.remove('d-none');
                    } else {
                      document
                        .getElementById('locacao')
                        .classList.add('d-none');
                    }
                  }}
                  data={[
                    {
                      value: '',
                      label: 'Selecione',
                    },
                    {
                      value: 'Locado',
                      label: 'Locado',
                    },
                    {
                      value: 'Próprio',
                      label: 'Próprio',
                    },
                  ]}
                />
              </Col>
            </Row>
            <div className="row d-none" id="locacao">
              <Col lg={6}>
                <Input
                  name="fornecedor_locacao"
                  label="Fornecedor locação"
                  disabled={modal?.id}
                  defaultValue={modal?.fornecedor_locacao}
                  className="form-control"
                />
              </Col>
              <Col lg={6}>
                <Input
                  name="contrato_locacao"
                  label="Contrato locação"
                  disabled={modal?.id}
                  defaultValue={modal?.contrato_locacao}
                  className="form-control"
                />
              </Col>
              <Col lg={4}>
                <NumberFormat
                  disabled={modal?.id}
                  defaultValue={modal?.valor_locacao}
                  name="valor_locacao"
                  label="Valor locação"
                  className="form-control"
                  prefix="R$"
                  placeholder="R$"
                />
              </Col>
              <Col lg={4}>
                <Select
                  name="fidelidade_locacao"
                  label="Fidelidade locação"
                  disabled={modal?.id}
                  defaultValue={modal?.fidelidade_locacao ? 's' : 'n'}
                  className="form-control"
                  data={[
                    {
                      label: 'Sim',
                      value: 's',
                    },
                    {
                      label: 'Não',
                      value: 'n',
                    },
                  ]}
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="tempo_locacao"
                  label="Tempo locação"
                  disabled={modal?.id}
                  defaultValue={modal?.tempo_locacao}
                  className="form-control"
                />
              </Col>
            </div>
            <Row>
              {modal?.status &&
                modal?.status !== 'Pendente' &&
                [
                  { label: 'Terminal id zoop', value: 'terminal_id_zoop' },
                  { label: 'Código zoop', value: 'codigo_zoop' },
                  { label: 'Modelo terminal', value: 'modelo_terminal' },
                  { label: 'Pin Capability', value: 'pin_capability' },
                  {
                    label: 'Pagamento aproximação',
                    value: 'pagamento_aproximacao',
                  },
                  { label: 'Versão aplicação', value: 'versao_aplicacao' },
                  { label: 'Emv lib version', value: 'emv_lib_version' },
                  { label: 'Versão firmware', value: 'versao_firmware' },
                  { label: 'Versão hardware', value: 'versao_hardware' },
                  {
                    label: 'Versão especificação',
                    value: 'versao_especificacao',
                  },
                  { label: 'Data cadastro', value: 'data_cadastro' },
                  { label: 'Status', value: 'status' },
                ].map((m) => (
                  <Col lg={6} key={m.value}>
                    <Input
                      name={m.value}
                      label={m.label}
                      disabled
                      value={modal[m.value] || ''}
                      className="form-control"
                    />
                  </Col>
                ))}
            </Row>
          </ModalBody>
          <ModalFooter>
            {modal?.id ? (
              <Button
                type="button"
                color="primary"
                onClick={() => handleAtualizar(modal?.id)}
              >
                Atualizar dados
              </Button>
            ) : (
              <Button type="submit" color="primary">
                Cadastrar
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default ListarPos;
