import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  IoChevronBack,
  IoClipboardOutline,
  IoHeadset,
  IoCogOutline,
} from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import {
  Input,
  InputGroup,
  MaskInput,
  RadioCard,
  Select,
} from 'components/unform/index';
import apiTelecom from 'services/apiTelecom';
import useValidator from 'hooks/useValidator';
import useSearch from 'utils/searchParams';

function NovoUsuarioTelecom() {
  const formRef = useRef();
  const formModalRef = useRef();
  const { showLoader, closeLoader, validator, toast } = useValidator();
  const history = useHistory();
  const search = useSearch();
  const [edit, setEdit] = useState({
    modal: false,
  });

  const toggle = () =>
    setEdit((old) => ({
      ...old,
      modal: !old.modal,
    }));
  async function handleSubmitRamal(dados) {
    try {
      showLoader();
      if (edit?.ramal) {
        await apiTelecom.put(`/desvincsuarioramal/${search?.id}`, dados);
        formRef.current.setFieldValue('ramal', '');
        formRef.current.setFieldValue('senha_ramal', '');
        formRef.current.setFieldValue('contexto', '');
        setEdit((old) => ({
          ...old,
          ramal: '',
          senha_ramal: '',
        }));
        toast('Ramal desvinculado com sucesso', { type: 'success' });
      } else {
        await apiTelecom.put(`/vincsuarioramal/${search?.id}`, dados);
        formRef.current.setFieldValue('ramal', dados.ramal);
        formRef.current.setFieldValue('senha_ramal', dados.senha_ramal);
        formRef.current.setFieldValue('contexto', dados.contexto);
        setEdit((old) => ({
          ...old,
          ...dados,
        }));
        toast('Ramal vinculado com sucesso', { type: 'success' });
      }
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function handleSubmit(dados) {
    const { errors, hasError } = validator(dados);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }

    showLoader();
    try {
      if (search?.id) {
        await apiTelecom.put(`/editusuarioramal/${search?.id}`, dados);
        toast('Usuário alterado com sucesso', { type: 'success' });
      } else {
        await apiTelecom.post('/usuarioramal', dados);
        toast('Usuário criado com sucesso', { type: 'success' });
      }
      history.goBack();
    } catch (error) {
      toast(error.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        showLoader();
        const { data } = await apiTelecom.get(
          `/buscarusuarioramal/${search?.id}`
        );
        setEdit({
          ...data[0],
          modal: false,
        });
        formRef.current.setData(data[0]);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    }
    if (Number(search?.id)) {
      getData();
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="page-header">
          <Row>
            <Col lg={9} className="d-flex align-items-center">
              <Button
                onClick={() => history.goBack()}
                className="mr-3"
                color="outline-primary"
              >
                <IoChevronBack />
              </Button>
              <div>
                <p className="mb-0 small mt-n1 text-muted">Configurações</p>
                <h4 className="mb-0">Adicionar usuário</h4>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col lg={12}>
                  <RadioCard
                    name="id_perfil"
                    label="Cargo *"
                    items={[
                      {
                        value: '1',
                        label: 'Operador',
                        sub: 'Para realizar e receber ligações',
                        icon: <IoHeadset size={30} />,
                      },
                      {
                        value: '2',
                        label: 'Supervisor',
                        sub: 'Para gerir equipes',
                        icon: <IoClipboardOutline size={30} />,
                      },
                      {
                        value: '3',
                        label: 'Gestor',
                        sub: 'Para gerir a operação',
                        icon: <IoClipboardOutline size={30} />,
                      },
                    ]}
                  />
                </Col>
                <Col lg={6}>
                  <Input label="Nome *" name="nome" className="form-control" />
                </Col>
                <Col lg={6}>
                  <Input
                    label="Email *"
                    name="email"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  <MaskInput
                    name="cpf"
                    label="CPF *"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Contexto *"
                    name="contexto"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  {search?.id ? (
                    <InputGroup
                      name="ramal"
                      label="Ramal *"
                      disabled
                      type="number"
                      className="form-control-group"
                      append={
                        <Button
                          size="sm"
                          type="button"
                          color="link"
                          onClick={toggle}
                        >
                          <IoCogOutline size={20} />
                        </Button>
                      }
                    />
                  ) : (
                    <Input
                      label="Ramal *"
                      type="number"
                      name="ramal"
                      className="form-control"
                    />
                  )}
                </Col>
                <Col lg={3}>
                  <Select
                    label="Status *"
                    name="status"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                    ]}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label="Senha ramal *"
                    name="senha_ramal"
                    className="form-control"
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label="Senha plataforma *"
                    name="senha_plataforma"
                    className="form-control"
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter className="mb-3">
            <Button
              block
              color="primary"
              onClick={() => formRef.current.submitForm()}
            >
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </div>
      <Modal isOpen={edit.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Gerenciar ramal</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitRamal} ref={formModalRef}>
            {edit?.ramal ? (
              <Row>
                <Col lg={4}>
                  <Input
                    label="Ramal atual"
                    type="number"
                    name="ramal"
                    defaultValue={edit.ramal}
                    disabled={edit.ramal}
                    className="form-control"
                  />
                </Col>
                <Col lg={12} className="mt-3">
                  <Button block color="danger">
                    Desvincular ramal
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={4}>
                  <Input
                    label="Novo ramal"
                    type="number"
                    name="ramal"
                    defaultValue={edit.ramal}
                    disabled={edit.ramal}
                    className="form-control"
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    label="Senha ramal"
                    name="senha_ramal"
                    className="form-control"
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    label="Contexto"
                    name="contexto"
                    className="form-control"
                  />
                </Col>
                <Col lg={12} className="mt-3">
                  <Button block color="primary">
                    Vincular ramal
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default NovoUsuarioTelecom;
