/* eslint-disable no-nested-ternary */
import axios from 'axios';

export const baseURL = 'http://localhost:3333';

const apiTelecom = axios.create({
  baseURL,
});

export default apiTelecom;
