import moment from 'moment';

function dates(current) {
  const week = [];
  current.setDate(current.getDate() - current.getDay());
  for (let i = 0; i < 7; i += 1) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return week;
}

export default function deafultEventsWeek(horarioAtendimento, data) {
  const arrayEvents = [];
  const datas = dates(data);

  datas.forEach((d) => {
    horarioAtendimento?.forEach((ha) => {
      if (Number(ha.dia_semana) === Number(d.getDay())) {
        const check = moment(d);

        const month = check.format('M');
        const day = check.format('D');
        const year = check.format('YYYY');

        if (ha?.horario_atendimento) {
          if (ha?.horario_atendimento?.almoco) {
            const inicio = ha?.horario_atendimento?.almoco?.inicio;
            const fim = ha?.horario_atendimento?.almoco?.fim;

            const inicioSplit = inicio.split(':');
            const fimSplit = fim.split(':');

            arrayEvents.push({
              id: 'horario_almoco',
              allDay: false,
              endDate: new Date(year, month - 1, day, fimSplit[0], fimSplit[1]),
              startDate: new Date(
                year,
                month - 1,
                day,
                inicioSplit[0],
                inicioSplit[1]
              ),
              title: 'Horário de almoço',
              atividade: 'almoco',
              blocked: true,
              textColor: '#505050',
            });
          }

          const inicio = ha?.horario_atendimento?.inicio;
          const fim = ha?.horario_atendimento?.fim;

          const inicioSplit = inicio.split(':');
          const fimSplit = fim.split(':');

          arrayEvents.push({
            id: 'indisponivel',
            allDay: false,
            startDate: new Date(year, month - 1, day, fimSplit[0], fimSplit[1]),
            endDate: new Date(year, month - 1, day, 23, 59),
            title: 'Horário indisponível',
            atividade: 'indisponivel',
            blocked: true,
            textColor: '#c1c1c1',
            cursor: 'not-allowed',
          });

          arrayEvents.push({
            id: 'indisponivel',
            allDay: false,
            startDate: new Date(year, month - 1, day, 0, 0),
            endDate: new Date(
              year,
              month - 1,
              day,
              inicioSplit[0],
              inicioSplit[1]
            ),
            title: 'Horário indisponível',
            atividade: 'indisponivel',
            blocked: true,
            textColor: '#c1c1c1',
            cursor: 'not-allowed',
          });
        } else {
          arrayEvents.push({
            id: 'indisponivel',
            allDay: false,
            startDate: new Date(year, month - 1, day, 0, 0),
            endDate: new Date(year, month - 1, day, 23, 59),
            title: 'Horário indisponível',
            atividade: 'indisponivel',
            blocked: true,
            textColor: '#c1c1c1',
            cursor: 'not-allowed',
          });
        }
      }
    });
  });

  return arrayEvents;
}
