import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import {
  Input,
  NumberFormat,
  ReactSelectAsync,
} from '../../../components/unform';
import api from '../../../services/apiPDV';
import useValidator from '../../../hooks/useValidator';
import { useAuth } from '../../../contexts/auth';

function AberturaCaixa({ modalProps, toggleModal, setObjCaixa }) {
  const selectOptions = useRef();
  const formRef = useRef();
  const { idFilial } = useAuth();

  const [bordero, setBordero] = useState('');

  const { validator, showLoader, closeLoader, showError, toast } =
    useValidator();

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/options/operador?nome=${inputValue}`);

    return data;
  }
  const optionsCulturas = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.culturas = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function handleSubmit(e) {
    if (!e.operador) {
      formRef.current.setErrors({
        operador: 'Obrigatório',
      });

      return toast();
    }

    formRef.current.setErrors({});

    showLoader();
    try {
      if (e.bordero) {
        const { data } = await api.post('/caixa/reabrir', {
          ...e,
          id_operador: e.operador,
          id_filial: idFilial,
        });
        setObjCaixa({
          id_bordero: data?.id,
          bordero: data?.numero,
          id_operador: e.operador,
          operador: data?.operador,
        });
        localStorage.setItem(
          'caixa',
          JSON.stringify({
            id_bordero: data?.id,
            bordero: data?.numero,
            id_operador: e.operador,
            operador: data?.operador,
          })
        );
      } else {
        const { data } = await api.post('/caixa', {
          ...e,
          id_filial: idFilial,
          id_operador: e.operador,
        });
        setObjCaixa({
          id_bordero: data?.id,
          bordero: data?.numero,
          id_operador: e.operador,
          operador: data?.operador,
        });
        localStorage.setItem(
          'caixa',
          JSON.stringify({
            id_bordero: data?.id,
            bordero: data?.numero,
            id_operador: e.operador,
            operador: data?.operador,
          })
        );
      }
      toggleModal();
      closeLoader();
    } catch (err) {
      showError({
        mensagem: err.mensagem,
        titulo: err.titulo,
      });
    }
  }

  async function handleChangeOperador(e) {
    if (!e.value) {
      return;
    }
    const senhaFocus = formRef.current.getFieldRef('senha_operador');
    senhaFocus?.focus?.();
    showLoader();
    try {
      const { data } = await api.get(`/operador/${e.value}/bordero`);
      setBordero(data?.numero || '');
      if (data?.numero) {
        toast('Operador com borderô não encerrado', { type: 'warning' });
      }
      closeLoader();
    } catch (err) {
      showError(err.mensagem);
    }
  }

  return (
    <>
      <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <ReactSelectAsync
                label="Operador *"
                name="operador"
                obj
                onChange={handleChangeOperador}
                loadOptions={optionsCulturas}
              />
            </Col>
            <Col lg={6}>
              <Input
                type="password"
                className="form-control"
                label="Senha operador"
                name="senha_operador"
              />
            </Col>
            <Col lg={6}>
              <Input
                className="form-control"
                label="Borderô"
                name="bordero"
                disabled
                value={bordero}
              />
            </Col>
            <Col lg={6}>
              <NumberFormat
                className="form-control"
                prefix="R$"
                placeholder="R$ 0,00"
                label="Aporte inicial"
                name="aporte_inicial"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Abrir caixa
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}

export default AberturaCaixa;
