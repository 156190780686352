import React, { useRef } from 'react';
import axios from 'axios';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, MaskInput, Select } from '../../../components/unform/index';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';

function CriarCliente({ modalProps, toggleModal, voltar, open }) {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = [
      'nome',
      'cpf_cnpj',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'cidade',
      'estado',
    ];
    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      const { data } = await api.post('/cliente', e);
      closeLoader();
      // open(data.clien);
      voltar();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  const handleCep = async (cep) => {
    if (cep.length === 8) {
      showLoader();
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        formRef.current.setFieldValue('logradouro', resultCep?.street || '');
        formRef.current.setFieldValue('estado', resultCep?.state || '');
        formRef.current.setFieldValue('cidade', resultCep?.city || '');
        formRef.current.setFieldValue('bairro', resultCep?.neighborhood || '');
        closeLoader();
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');
        closeLoader();
        toast('CEP não encontrado', { type: 'warn' });
      }
    }
  };

  return (
    <>
      <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
      <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <Row>
            <p className="text-muted">Informações gerais</p>

            <Col lg={4}>
              <Input name="nome" label="Nome *" className="form-control" />
            </Col>
            <Col lg={4}>
              <Input
                name="cpf_cnpj"
                label="CPF/CNPJ *"
                getValueRegex={/[^A-Z0-9]/gi}
                className="form-control"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="data_nascimento"
                type="date"
                label="Data nascimento"
                className="form-control"
              />
            </Col>
            <Col lg={4}>
              <Input name="email" label="Email" className="form-control" />
            </Col>
            <Col lg={2}>
              <MaskInput
                name="telefone_ddd"
                label="DDD"
                placeholder="00"
                className="form-control"
                mask={['(', /[0-9]/, /\d/, ')']}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <MaskInput
                name="telefone"
                label="Telefone"
                placeholder="00000-0000"
                className="form-control"
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <Select
                name="sexo"
                label="Sexo"
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'Masculino',
                    label: 'Masculino',
                  },
                  {
                    value: 'Feminino',
                    label: 'Feminino',
                  },
                ]}
                className="form-control"
              />
            </Col>

            <p className="text-muted">Endereço</p>

            <Col lg={2}>
              <MaskInput
                name="cep"
                label="CEP *"
                placeholder="00.000-000"
                className="form-control"
                removeEspecial
                onChange={(e) =>
                  handleCep(String(e.target.value).replace(/\D/g, ''))
                }
                mask={[
                  /[0-9]/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </Col>
            <Col lg={4}>
              <Input
                name="logradouro"
                label="Logradouro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={2}>
              <Input
                name="numero"
                label="Nº *"
                className="form-control"
                type="number"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="complemento"
                label="Complemento *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="bairro"
                label="Bairro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={2}>
              <Input
                name="estado"
                label="Estado *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={3}>
              <Input
                name="cidade"
                label="Cidade *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={3}>
              <Select
                name="tipo_endereco"
                label="Tipo endereço *"
                data={[
                  {
                    value: 'R',
                    label: 'Residencial',
                  },
                  {
                    value: 'C',
                    label: 'Comercial',
                  },
                  {
                    value: 'O',
                    label: 'Outros',
                  },
                ]}
                className="form-control"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-between">
          <Button color="primary" outline onClick={voltar}>
            Voltar
          </Button>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}

export default CriarCliente;
