import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  Table,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { useValidator } from 'hooks/index';
import formFiltros from 'utils/formFiltros';
import api from 'services/apiPDV';

function Dashboard() {
  const debouncePedido = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const [dados, setDados] = useState(null);

  async function getDados(body) {
    showLoader();
    try {
      const { data } = await api.post('/ecommerce/loja/indicadores', body);
      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  useEffect(() => {
    clearTimeout(debouncePedido.current);
    debouncePedido.current = setTimeout(() => {
      getDados({});
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const data = formFiltros();
          getDados(data);
        }}
      >
        <Row className="mb-3">
          <Col lg={3}>
            <InputGroup className="custom">
              <InputGroupText>
                <div>Período</div>
              </InputGroupText>

              <Flatpickr
                label="date ranger"
                name="periodo"
                className="date-picker border-none"
                options={{
                  mode: 'range',
                  locale: Portuguese,
                  dateFormat: 'd/m/Y',
                }}
              />
            </InputGroup>
          </Col>
          <Col lg={3}>
            <Row>
              <Col lg={6} sm={6} className="mb-1">
                <Button
                  color="light-primary"
                  style={{ lineHeight: 2 }}
                  type="submit"
                  size="sm"
                >
                  Procurar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
      <div className="hr" />
      <h5 className="justify-center">Pedidos</h5>
      <div className="hr mb-3" />
      <Row>
        <Col>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Ticket médio</h5>
              <h2 className="text-center">
                {Number(dados?.tm[0]?.tm || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Concluídos</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Finalizado')[0]
                  ?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Finalizado')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Não pagos</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Aberto')[0]?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Aberto')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Aguardando envio</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter(
                  (f) => f.status === 'Aguardando envio'
                )[0]?.count
                  ? dados?.vendas?.filter(
                      (f) => f.status === 'Aguardando envio'
                    )[0]?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Enviados</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Enviado')[0]?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Enviado')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Entregues</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Entregue')[0]?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Entregue')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>

        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Cancelados</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Cancelado')[0]
                  ?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Cancelado')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Devolvido</h5>
              <h2 className="text-center">
                {dados?.vendas?.filter((f) => f.status === 'Devolvido')[0]
                  ?.count
                  ? dados?.vendas?.filter((f) => f.status === 'Devolvido')[0]
                      ?.count
                  : 0}
              </h2>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Produtos mais vendidos</h5>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="text-center">Estoque disponível</th>
                    <th className="text-center">Quantidade de vendas</th>
                  </tr>
                </thead>
                <tbody>
                  {dados?.vendasItem?.map((i) => (
                    <tr key={i?.id}>
                      <td data-label="Nome">
                        <div className="d-flex align-items-center mb-2">
                          {i?.path && (
                            <img
                              width={60}
                              height={70}
                              className="object-fit bg-gray1 mr-3"
                              src={i?.path || '/default.jpg'}
                              alt={i?.nome_produto}
                            />
                          )}
                          <div>
                            <p className="bold mb-0">{i?.nome_produto}</p>
                            {/* <p className="mb-0">{pde?.Produto?.Autor?.nome}</p> */}
                          </div>
                        </div>
                      </td>
                      <td data-label="Estoque" className="text-center">
                        {i?.estoque_disponivel}
                      </td>
                      <td
                        data-label="Quantidade de vendas"
                        className="text-center"
                      >
                        {i?.quantidade}
                      </td>
                    </tr>
                  ))}
                  {dados?.vendasItem?.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        Nenhuma venda no período
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Vendas por categoria</h5>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Categoria</th>
                    <th className="text-center">Vendas</th>
                  </tr>
                </thead>
                <tbody>
                  {dados?.categoria?.map((i) => (
                    <tr key={i?.id}>
                      <td data-label="Categoria" className="text-center">
                        {i?.categoria}
                      </td>
                      <td
                        data-label="Quantidade de vendas"
                        className="text-center"
                      >
                        {i?.quantidade}
                      </td>
                    </tr>
                  ))}
                  {dados?.categoria?.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        Nenhuma venda no período
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col lg={2}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Estoque mínimo</h5>
              <h2 className="text-center">{dados?.estoqueMinimo} Produto(s)</h2>
            </div>
          </Card>
        </Col>
        {/* <Col lg={4}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">Estoque mínimo</h5>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Produto</th>
                    <th className="text-center">Estoque</th>
                  </tr>
                </thead>
                <tbody>
                  {dados?.estoqueMinimo?.map((i) => (
                    <tr key={i?.id}>
                      <td data-label="Produto" className="text-center">
                        <p className="bold mb-0">{i?.titulo}</p>
                        <p className="mb-0">{i?.Autor?.nome}</p>
                      </td>
                      <td data-label="Estoque" className="text-center">
                        {i?.estoque_disponivel}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col> */}
      </Row>
    </>
  );
}

export default Dashboard;
