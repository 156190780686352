import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row, FormGroup } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import {
  Input,
  Select,
  MaskInput,
  ReactSelectAsync,
} from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import getOptions from '../../../../utils/getOptionsPDV';
import { useAuth } from '../../../../contexts/auth';

function NovoPaciente() {
  const { selectedFilial } = useAuth();
  const search = useSearch();
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const handleSubmit = async (e) => {
    const errors = {};

    const campos = [
      'nome',
      'cpf',
      'email',
      'celular',
      'tipo',
      'sexo',
      'registro',
      'id_operador',
      'id_conselho',
      'id_uf',
      'id_profissao',
      'id_cbo',
    ];
    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);
    if (Object.keys(errors).length) {
      toast();
      return;
    }
    try {
      showLoader();
      if (search?.id) {
        const body = { endereco: {} };
        const camposEndereco = [
          'bairro',
          'cep',
          'cidade',
          'complemento',
          'estado',
          'logradouro',
          'numero',
          'tipo_endereco',
        ];
        Object.entries(e).forEach(([key, value]) => {
          if (camposEndereco.includes(key)) {
            body.endereco[key] = value;
          } else {
            body[key] = value;
          }
        });

        await api.put(`/profissional/${search?.id}`, body);
      } else {
        e.id_filial = selectedFilial?.filial;
        await api.post('/profissionais', e);
      }
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  useEffect(() => {
    async function getProfissional() {
      showLoader();
      try {
        const { data } = await api.get(
          `/profissional/${search?.id}?id_filial=${selectedFilial?.filial}`
        );
        const { ...rest } = data;
        const body = {};

        Object.entries(rest?.profissional).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key.toLowerCase()}`] = value;
          } else {
            body[key] = value;
          }
        });

        if (rest?.convenios) {
          const listConvenios = [];
          rest?.convenios?.forEach((c) => {
            listConvenios.push(c?.Convenio);
          });

          setTimeout(() => {
            formRef.current.setFieldValue('id_convenio', listConvenios);
          }, 200);
        }

        formRef.current.setData(body);
      } catch (err) {
        return err;
      }
      closeLoader();
    }
    if (search?.id) {
      getProfissional();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">
            {
              // eslint-disable-next-line no-nested-ternary
              search?.infos ? 'Informações' : search?.id ? 'Editar' : 'Novo'
            }{' '}
            profissional
          </h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <p className="text-muted">Informações gerais</p>
            <hr />
            <Col lg={4}>
              <Input
                name="nome"
                label="Nome *"
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Input
                name="cpf"
                label="CPF *"
                getValueRegex={/[^A-Z0-9]/gi}
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={4}>
              <Input
                name="email"
                label="Email *"
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <MaskInput
                name="celular"
                label="Celular"
                placeholder="00000-0000"
                className="form-control"
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                removeEspecial
                disabled={search?.infos}
              />
            </Col>

            <Col lg={2}>
              <Select
                name="sexo"
                label="Sexo"
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'Masculino',
                    label: 'Masculino',
                  },
                  {
                    value: 'Feminino',
                    label: 'Feminino',
                  },
                ]}
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Input
                name="registro"
                label="Registro"
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <ReactSelectAsync
                label="Registro uf"
                name="id_uf"
                isClearable
                loadOptions={async (value) => {
                  const dados = await getOptions({
                    value,
                    route: '/options-ufs',
                    idFilial: selectedFilial?.filial,
                  });
                  return dados;
                }}
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Input
                name="rqe"
                label="RQE"
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Input
                name="cnes"
                label="CNES"
                className="form-control"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Select
                name="tipo"
                label="Tipo"
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'Profissional Saúde',
                    label: 'Profissional Saúde',
                  },
                  {
                    value: 'Recepcionista',
                    label: 'Recepcionista',
                  },
                ]}
                className="form-control"
                disabled={search?.infos}
              />
            </Col>

            <Col lg={4}>
              <ReactSelectAsync
                label="Operador"
                name="id_operador"
                isClearable
                loadOptions={async (value) => {
                  const dados = await getOptions({
                    value,
                    route: '/options-operador',
                    idFilial: selectedFilial?.filial,
                  });

                  if (dados?.data?.length) {
                    const arrayOperador = [];
                    dados?.data?.forEach((d) => {
                      arrayOperador.push(d?.Operador);
                    });

                    return arrayOperador;
                  }
                }}
                disabled={search?.infos}
              />
            </Col>

            <Col lg={2}>
              <ReactSelectAsync
                label="Conselho"
                name="id_conselho"
                isClearable
                loadOptions={async (value) => {
                  const dados = await getOptions({
                    value,
                    route: '/options-conselhos',
                    idFilial: selectedFilial?.filial,
                  });
                  return dados;
                }}
                disabled={search?.infos}
              />
            </Col>

            <Col lg={2}>
              <ReactSelectAsync
                label="Profissao"
                name="id_profissao"
                isClearable
                loadOptions={async (value) => {
                  const dados = await getOptions({
                    value,
                    route: '/options-profissao',
                    idFilial: selectedFilial?.filial,
                  });
                  return dados;
                }}
                disabled={search?.infos}
              />
            </Col>

            <Col lg={4}>
              <ReactSelectAsync
                label="Cbo"
                name="id_cbo"
                isClearable
                loadOptions={async (value) => {
                  const dados = await getOptions({
                    value,
                    route: '/options-cbo',
                    idFilial: selectedFilial?.filial,
                  });
                  return dados;
                }}
                disabled={search?.infos}
              />
            </Col>

            <Col lg={12}>
              <FormGroup>
                <ReactSelectAsync
                  label="Convênios"
                  name="id_convenio"
                  isClearable
                  isMulti
                  loadOptions={async (value) => {
                    const dados = await getOptions({
                      value,
                      route: '/convenios',
                      idFilial: selectedFilial?.filial,
                    });
                    return dados;
                  }}
                  disabled={search?.infos}
                />
              </FormGroup>
            </Col>

            {!search?.infos && (
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoPaciente;
