/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useState } from 'react';
import { FaTable } from 'react-icons/fa';
import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
  IoCog,
  IoPerson,
} from 'react-icons/io5';
import { FiMoon, FiPower, FiSun, FiMenu } from 'react-icons/fi';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from 'react-pro-sidebar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import uneotelecom from '../assets/svg/uneotelecom.svg';
import { useAuth } from '../contexts/auth';
import { useTheme } from '../contexts/theme';

const size = 20;

// const logos = {
//   telecom: () => uneotelecom,
// };

const templateName = 'UneoTelecom';

const routes = [
  {
    label: 'Agentes',
    value: '/telecom/agentes',
    icon: <IoPerson size={size} />,
  },
  {
    label: 'Configurações',
    items: [
      {
        label: 'Usuários',
        value: '/telecom/configuracoes/usuarios',
      },
      {
        label: 'Empresas',
        value: '/telecom/configuracoes/empresas',
      },
    ],
    icon: <IoCog size={size} />,
  },
];

const LayoutSemNav = ({ children }) => {
  const formRef = useRef();
  const { user, signOut, modulo, setModulo } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { isDarkMode, toggleTheme, title, setTitle } = useTheme();

  const userName = user[history.location.pathname.split('/')[1]]?.nome || '';

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const toggleCollapse = () => setCollapsed(!collapsed);

  const handleToggled = () => {
    if (toggled) {
      setToggled(false);
    }
  };

  return (
    <div className="d-flex bg-gray min-100h layout-sem-nav">
      <ProSidebar
        collapsed={collapsed}
        breakPoint="lg"
        id="sem-nav"
        toggled={toggled}
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="sidebar-header-main-sem-nav px-3 pt-1 mt-3">
            <Link to="/telecom/" className="collapsed-hidden">
              <img
                alt="logo text"
                className="object-fit logo"
                src={uneotelecom}
                style={{
                  height: 38,
                  width: '90%',
                  objectFit: 'contain',
                }}
              />
            </Link>

            <button
              className="btn-none p-2 mobile-hidden"
              type="button"
              onClick={toggleCollapse}
            >
              {collapsed ? (
                <IoChevronForwardCircleOutline
                  color="var(--body-color)"
                  size={24}
                />
              ) : (
                <IoChevronBackCircleOutline
                  color="var(--body-color)"
                  size={24}
                />
              )}
            </button>
          </div>
          <div className="mb-2" />
        </SidebarHeader>

        <SidebarContent>
          <Menu>
            {routes.map((m) =>
              m.value ? (
                <MenuItem
                  key={m.value}
                  icon={m.icon}
                  active={location.pathname === m.value}
                  onClick={handleToggled}
                >
                  <Link to={m.value}>{m.label}</Link>
                </MenuItem>
              ) : (
                <SubMenu
                  key={m.label}
                  title={m.label}
                  icon={m.icon}
                  className={
                    m.items.some((sub) => location.pathname === sub.value)
                      ? 'has-active'
                      : ''
                  }
                >
                  {m.items.map((sub) => (
                    <MenuItem
                      key={sub.value}
                      active={location.pathname === sub.value}
                      onClick={handleToggled}
                    >
                      <Link to={sub.value}>{sub.label}</Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              )
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <hr className="mt-0 mb-1" />
          <div className="p-3 pt-2 mb-3 sidebar-dropdown-container">
            <UncontrolledDropdown direction="down">
              <DropdownToggle color="link" caret className="sidebar-dropdown">
                {userName.split(' ')[0]}
              </DropdownToggle>
              <DropdownMenu right container="sem-nav">
                <DropdownItem
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <FaTable className="mr-1" size={18} />
                  Módulos
                </DropdownItem>
                <DropdownItem onClick={toggleTheme}>
                  {isDarkMode ? (
                    <FiSun className="mr-1" size={18} />
                  ) : (
                    <FiMoon className="mr-1" size={18} />
                  )}{' '}
                  Tema
                </DropdownItem>
                <div tabIndex="-1" className="dropdown-divider" />
                <DropdownItem
                  onClick={() => {
                    signOut();
                    setTitle(templateName);
                    history.push('/');
                  }}
                >
                  <FiPower className="mr-1" size={18} />
                  Sair
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div
              className="justify-center text-center"
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 5,
                fontSize: 12,
              }}
            >
              <div className="hide-collapsed">
                Made with &nbsp;
                <span
                  style={{
                    color: 'red',
                  }}
                >
                  ❤
                </span>
                &nbsp; by &nbsp;
              </div>
              <a
                href="https://xcoding.com.br"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: 'rgb(var(--theme-color-rgb))',
                  fontWeight: 600,
                }}
              >
                Xcoding
              </a>
            </div>
          </div>
        </SidebarFooter>
      </ProSidebar>
      <main>
        {children}
        <div
          className="d-lg-none-custom"
          style={{
            height: 40,
          }}
        />
      </main>
      {/* <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: 40,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          background: 'var(--body-color)',
          color: 'var(--body-bg)',
          display: 'flex',
        }}
      >
        <button
          className="float-menu d-lg-none-custom"
          type="button"
          onClick={() => setToggled(!toggled)}
          style={{
            width: '33%',
            height: '100%',
            border: 'none',
            borderTopLeftRadius: 10,
            background: 'var(--body-color)',
            color: 'var(--body-bg)',
          }}
        >
          <div>
            <FiMenu size={20} />
            <p className="small">Menus</p>
          </div>
        </button>
        <button
          className="float-menu d-lg-none-custom"
          type="button"
          onClick={() => setToggled(!toggled)}
          style={{
            width: '33%',
            height: '100%',
            border: 'none',
            borderTopLeftRadius: 10,
            background: 'var(--body-color)',
            color: 'var(--body-bg)',
          }}
        >
          <FiMenu size={24} />
        </button>
        <button
          className="float-menu d-lg-none-custom"
          type="button"
          onClick={() => setToggled(!toggled)}
          style={{
            width: '33%',
            height: '100%',
            border: 'none',
            borderTopLeftRadius: 10,
            background: 'var(--body-color)',
            color: 'var(--body-bg)',
          }}
        >
          <FiMenu size={24} />
        </button>
      </div> */}
      <button
        className="float-menu d-lg-none-custom"
        type="button"
        onClick={() => setToggled(!toggled)}
        style={{
          position: 'fixed',
          right: 10,
          width: 50,
          height: 50,
          background: 'var(--body-color)',
          color: 'var(--body-bg)',
          border: 'none',
          borderRadius: 60,
          bottom: 10,
        }}
      >
        <FiMenu size={24} />
      </button>
    </div>
  );
};

export default LayoutSemNav;
