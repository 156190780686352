import React from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import venda from '../../../assets/images/venda.jpg';
import preVenda from '../../../assets/images/pre-venda.jpg';
import nfce from '../../../assets/images/nfce.jpg';
import logo from '../../../assets/svg/logo-text.svg';
import { useAuth } from '../../../contexts/auth';

function TipoPdv() {
  const history = useHistory();
  const { setTipoPdv } = useAuth();

  return (
    <div className="container">
      <Row>
        <Col lg={12} className="mb-5 mt-3 d-flex justify-content-center">
          <img width={220} src={logo} alt="logo" />
        </Col>
        <Col lg={4}>
          <button
            type="button"
            className="btn-img-1x1 mb-3"
            onClick={() => {
              setTipoPdv('V');
              history.push('/erp/venda');
            }}
          >
            <img src={venda} alt="venda" />
            Venda
          </button>
        </Col>
        <Col lg={4}>
          <button
            type="button"
            className="btn-img-1x1 mb-3"
            onClick={() => {
              setTipoPdv('O');
              history.push('/erp/orcamento');
            }}
          >
            <img src={preVenda} alt="venda" />
            Pré-venda
          </button>
        </Col>
        <Col lg={4}>
          <button
            type="button"
            className="btn-img-1x1 mb-3"
            onClick={() => {
              setTipoPdv('N');
              history.push('/erp/venda');
            }}
          >
            <img src={nfce} alt="venda" />
            NFCe
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default TipoPdv;
