import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { Input, Select } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

function NovaTributacao({
  modal,
  toggleModal,
  regimeTributario,
  ufs,
  refresh,
}) {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [cenarios, setCenarios] = useState([]);
  const [cfop, setCfop] = useState([]);

  const [icmsCst, setIcmsCst] = useState([]);
  const [pisCst, setPisCst] = useState([]);
  const [cofinsCst, setCofinsCst] = useState([]);

  const handleSubmit = async (e) => {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        if (
          f !== 'codigo_beneficio' &&
          f !== 'aliquota_icms' &&
          f !== 'aliquota_icms_desonerado' &&
          f !== 'perc_redutor_base_icms'
        ) {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        showLoader();
        if (modal?.type !== 'edit') {
          await api.post('/tributacao/uf', e);
        } else {
          await api.put(`/tributacao/uf/${modal?.objEdit?.id}`, e);
        }
        refresh(0);
        toast('Dados salvos com sucesso!', { type: 'success' });
        toggleModal();
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        showLoader();
        const { data } = await api.get('/cenarios/tributacao');
        setCenarios(data);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCFOP(codigo) {
    try {
      showLoader();
      const { data: cfopData } = codigo
        ? await api.get(`/cenarios/cfop/${codigo}`)
        : { data: null };

      setCfop(cfopData || []);
    } catch (err) {
      toast(err.mensagem);
      setCfop([]);
    } finally {
      closeLoader();
    }
  }

  async function getCST(cenario) {
    try {
      showLoader();
      const { data: cstData } = cenario
        ? await api.get(`/cst/tributacao/${regimeTributario?.tipo}/${cenario}`)
        : { data: null };

      setIcmsCst(cstData?.icms || []);
      setPisCst(cstData?.pis || []);
      setCofinsCst(cstData?.cofins || []);
    } catch (err) {
      toast(err.mensagem);
      setIcmsCst([]);
      setPisCst([]);
      setCofinsCst([]);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDataEdit() {
      if (modal?.objEdit) {
        const { objEdit } = modal;
        await getCST(objEdit?.id_cenario_tributacao);
        Object.keys(objEdit).forEach((e) => {
          if (e === 'Cfop') {
            setCfop([
              {
                value: objEdit[e]?.id,
                label: objEdit[e]?.descricao,
              },
            ]);
            formRef.current.setFieldValue('codigo_cfop', objEdit[e]?.codigo);
          } else {
            formRef.current.setFieldValue(e, objEdit[e]);
          }
        });
      }
    }
    getDataEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return (
    <Modal
      isOpen={modal?.open}
      toggle={() => {
        toggleModal();
        setCfop([]);
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
          setCfop([]);
        }}
      >
        Cadastro de tributação
      </ModalHeader>
      <Form
        onSubmit={(d) => {
          handleSubmit(d);
        }}
        ref={formRef}
      >
        <ModalBody>
          <Row>
            <Col lg={4}>
              <Select
                disabled={modal?.type === 'edit'}
                name="id_uf"
                label="UF"
                className="form-control"
                data={ufs}
              />
            </Col>
            <Col lg={8}>
              <Select
                disabled={modal?.type === 'edit'}
                name="id_cenario_tributacao"
                label="Cenário"
                className="form-control"
                data={cenarios}
                onChange={(e) => getCST(e?.target?.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Descrição"
                className="form-control"
                placeholder="CFOP [valor]; ICMS [valor CST] – [alíquota]% -
                Red. [valor]% - Benef. [valor]; PIS [valor CST]; COFINS [valor
                CST]"
                name="descricao"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <Input
                label="Código CFOP"
                className="form-control"
                placeholder=""
                name="codigo_cfop"
                type="number"
                onBlur={(e) => {
                  getCFOP(e?.target?.value);
                }}
              />
            </Col>
            <Col>
              <Select
                name="id_cfop"
                label="CFOP"
                className="form-control"
                data={cfop}
                disabled
              />
            </Col>
          </Row>
          {Number(regimeTributario?.tipo) !== 1 && (
            <Row>
              <Col lg={4}>
                <Input
                  label="Código benefício"
                  className="form-control"
                  placeholder=""
                  name="codigo_beneficio"
                  type="number"
                />
              </Col>
            </Row>
          )}
          <h6>ICMS</h6>
          <hr />
          <Row>
            <Col>
              <Select
                name="id_cst_icms"
                label="CST ICMS"
                className="form-control"
                data={icmsCst}
              />
            </Col>
          </Row>
          {Number(regimeTributario?.tipo) !== 1 && (
            <Row>
              <Col lg={4}>
                <Input
                  label="Alíquota ICMS (%)"
                  className="form-control"
                  placeholder=""
                  name="aliquota_icms"
                  type="number"
                />
              </Col>
              <Col lg={4}>
                <Input
                  label="Alíquota desonerado (%)"
                  className="form-control"
                  placeholder=""
                  name="aliquota_icms_desonerado"
                  type="number"
                />
              </Col>
              <Col lg={4}>
                <Input
                  label="Redutor base ICMS (%)"
                  className="form-control"
                  placeholder=""
                  name="perc_redutor_base_icms"
                  type="number"
                />
              </Col>
            </Row>
          )}
          <h6>PIS / COFINS</h6>
          <hr />
          <Row>
            <Col>
              <Select
                name="id_cst_pis"
                label="CST PIS"
                className="form-control"
                data={pisCst}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                name="id_cst_cofins"
                label="CST COFINS"
                className="form-control"
                data={cofinsCst}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default NovaTributacao;
