import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input, MaskInput } from '../../../components/unform';
import { useValidator } from '../../../hooks';
import api from '../../../services/apiPDV';

function NovaFilial() {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = [
      'cnpj',
      'telefone',
      'telefone_ddd',
      'inscricao_estadual',
      'razao_social',
      'codigo',
      'nome',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'cidade',
      'estado',
    ];

    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      await api.post('/filial', e);
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  const handleCep = async (cep) => {
    if (cep.length === 8) {
      showLoader();
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        formRef.current.setFieldValue('logradouro', resultCep?.street || '');
        formRef.current.setFieldValue('estado', resultCep?.state || '');
        formRef.current.setFieldValue('cidade', resultCep?.city || '');
        formRef.current.setFieldValue('bairro', resultCep?.neighborhood || '');
        closeLoader();
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');
        closeLoader();
        toast('CEP não encontrado', { type: 'warn' });
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Nova filial</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <p className="text-muted">Informações gerais</p>
            <Col lg={2}>
              <Input
                name="codigo"
                label="Código *"
                type="number"
                className="form-control"
              />
            </Col>
            <Col lg={3}>
              <Input
                name="cnpj"
                label="CNPJ *"
                getValueRegex={/[^A-Z0-9]/gi}
                className="form-control"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="razao_social"
                label="Razão social *"
                className="form-control"
              />
            </Col>
            <Col lg={3}>
              <Input
                name="nome"
                label="Nome fantasia *"
                className="form-control"
              />
            </Col>
            <Col lg={1}>
              <MaskInput
                name="telefone_ddd"
                label="DDD telefone *"
                placeholder="(00)"
                className="form-control"
                mask={['(', /[0-9]/, /\d/, ')']}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <MaskInput
                name="telefone"
                label="Telefone *"
                placeholder="00000-0000"
                className="form-control"
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <Input
                name="inscricao_estadual"
                label="Inscrição estadual *"
                className="form-control"
              />
            </Col>
            <p className="text-muted">Endereço</p>

            <Col lg={2}>
              <MaskInput
                name="cep"
                label="CEP *"
                placeholder="00.000-000"
                className="form-control"
                removeEspecial
                onChange={(e) =>
                  handleCep(String(e.target.value).replace(/\D/g, ''))
                }
                mask={[
                  /[0-9]/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </Col>
            <Col lg={4}>
              <Input
                name="logradouro"
                label="Logradouro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={2}>
              <Input
                name="numero"
                label="Nº *"
                className="form-control"
                type="number"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="complemento"
                label="Complemento *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="bairro"
                label="Bairro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="estado"
                label="Estado *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="cidade"
                label="Cidade *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovaFilial;
