import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { Form } from '@unform/web';
import { IoChevronBack } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { Input, Select } from 'components/unform/index';
import apiTelecom from 'services/apiTelecom';
import useValidator from 'hooks/useValidator';
import useSearch from 'utils/searchParams';

function NovaEmpresaTelecom() {
  const formRef = useRef();
  const { showLoader, closeLoader, validator, toast } = useValidator();
  const history = useHistory();
  const search = useSearch();

  async function handleSubmit(dados) {
    const { errors, hasError } = validator(dados);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }

    showLoader();
    try {
      if (search?.id) {
        await apiTelecom.put(`/empresa/${search?.id}`, dados);
        toast('Empresa alterada com sucesso', { type: 'success' });
      } else {
        await apiTelecom.post('/empresa', dados);
        toast('Empresa criada com sucesso', { type: 'success' });
      }
      history.goBack();
    } catch (error) {
      toast(error.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        showLoader();
        const { data } = await apiTelecom.get(`/empresa/${search?.id}`);
        formRef.current.setData({
          ...data[0],
          // senha_plataforma: data[0]?.senha_plataforma || '',
          // email: data[0]?.email || '',
        });
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    }
    if (Number(search?.id)) {
      getData();
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="page-header">
          <Row>
            <Col lg={9} className="d-flex align-items-center">
              <Button
                onClick={() => history.goBack()}
                className="mr-3"
                color="outline-primary"
              >
                <IoChevronBack />
              </Button>
              <div>
                <p className="mb-0 small mt-n1 text-muted">Configurações</p>
                <h4 className="mb-0">Adicionar empresa</h4>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col lg={6}>
                  <Input label="Nome *" name="nome" className="form-control" />
                </Col>
                <Col lg={6}>
                  <Input
                    name="documento"
                    label="CPF/CNPJ *"
                    getValueRegex={/[^A-Z0-9]/gi}
                    className="form-control"
                  />
                </Col>
                {!search?.id && (
                  <>
                    <Col lg={6}>
                      <Input
                        label="Email *"
                        name="email"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Senha plataforma *"
                        name="senha_plataforma"
                        className="form-control"
                      />
                    </Col>
                  </>
                )}

                <Col lg={5}>
                  <Input label="IP *" name="ip" className="form-control" />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Porta *"
                    type="number"
                    name="porta"
                    className="form-control"
                  />
                </Col>
                <Col lg={4}>
                  <Select
                    label="Status *"
                    name="status"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter className="mb-3">
            <Button
              block
              color="primary"
              onClick={() => formRef.current.submitForm()}
            >
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default NovaEmpresaTelecom;
