/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useField } from '@unform/core';
import { Col, Row } from 'reactstrap';
import {} from 'react-icons/fa';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1.25,
  position: 'relative',
  margin: `0 ${grid * 1.25}px 0 0`,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  background: '#ededed',
  display: 'flex',
  padding: grid * 1.25,
  overflow: 'auto',
});

export default function ImageDnD({
  name,
  label,
  handleRemove,
  defaultValue,
  radius,
  objectFit = 'cover',
  onChange,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue: defaultVal,
    registerField,
    error,
  } = useField(name);
  const [preview, setPreview] = useState(defaultVal || defaultValue || []);
  const handlePreview = useCallback((e) => {
    setPreview((old) =>
      old.concat(
        Array.from(e.target.files).map((m) => ({
          file: m,
          path: URL.createObjectURL(m),
          id: `temp${Math.random().toString(16).slice(2)}`,
        }))
      )
    );
    setTimeout(() => {
      e.target.files = null;
      e.target.value = null;
    }, 300);
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    setPreview((old) =>
      old.concat(
        Array.from(acceptedFiles).map((m) => ({
          _id: (Math.random() + 1).toString(36).substring(2, 10),
          file: m,
          path: URL.createObjectURL(m),
        }))
      )
    );
  }, []);
  const { getRootProps } = useDropzone({ onDrop, accept: { 'image/*': [] } });
  const [askedPermission, setAskedPermission] = useState(false);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setPreview(reorder(preview, result.source.index, result.destination.index));
  };

  useEffect(() => {
    return registerField({
      name: fieldName,
      ref: inputRef.current,
      clearValue(ref) {
        ref.value = '';
        setPreview([]);
      },
      getValue() {
        return preview;
      },
      setValue(_, value) {
        if (Array.isArray(value)) {
          setPreview(value);
        }
      },
    });
  }, [fieldName, registerField, preview]);

  return (
    <Row>
      <Col lg={12}>
        <div className="mb-3">
          {label && <label>{label}</label>}
          <div>
            <input
              ref={inputRef}
              type="file"
              onChange={handlePreview}
              multiple
              id={name}
              className="d-none"
              onClick={(e) => {
                if (
                  typeof window?.ReactNativeWebView !== 'undefined' &&
                  !askedPermission
                ) {
                  e.preventDefault();
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      type: 'permissions',
                      data: 'camera',
                    })
                  );
                  setAskedPermission(true);
                }
              }}
              accept="image/png,image/jpg,image/jpeg,image/webp"
              {...rest}
            />
            <label htmlFor={name} {...getRootProps()}>
              <div
                className={`image-drop object-${objectFit} ${
                  radius ? 'radius' : ''
                }`}
              >
                Clique aqui para selecionar <br />
                ou arraste fotos
              </div>
            </label>
          </div>
          {error && <small className="error">{error}</small>}
        </div>
        {preview.length > 0 && (
          <p className="text-muted mb-2">
            Preview {label}
            {' | '}
            <small className="text-muted bold">
              segure e arraste os itens para ajustar a ordem
            </small>
          </p>
        )}
      </Col>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={preview.length === 0 ? 'd-none' : ''}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {preview.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(provided2, snapshot2) => (
                    <div
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}
                      style={getItemStyle(
                        snapshot2.isDragging,
                        provided2.draggableProps.style
                      )}
                      className="img-card-upload"
                    >
                      <button
                        className="btn-remove"
                        type="button"
                        onClick={() => {
                          setPreview((old) => {
                            return old.filter((f) =>
                              item.id ? f.id !== item.id : f._id !== item._id
                            );
                          });
                        }}
                      >
                        X
                      </button>
                      <div className="bg-img mb-0">
                        <img
                          width={180}
                          height={100}
                          src={item.path}
                          className="object-fit"
                          alt={`Preview foto ${index}`}
                        />
                      </div>
                      <div className="img-subtitle">
                        <small className="text-muted mb-3">
                          {item?.file?.name || item.nome_arquivo}
                        </small>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Row>
  );
}
