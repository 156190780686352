import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

function TributacoesUf({
  modal,
  toggleModal,
  idProdutoTributacao,
  refresh,
  uf,
  idProduto,
}) {
  const { showLoader, closeLoader, toast } = useValidator();

  const [tributacoesUf, setTributacoesUf] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (modal?.cenario && modal?.selectedUf) {
          showLoader();
          const { data } = await api.get(
            `/tributacao/uf/produto/${modal?.selectedUf}/${modal?.cenario}`
          );
          setTributacoesUf(data);
        }
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  async function handleClick(id, cenario, idUf) {
    if (idProdutoTributacao) {
      showLoader();
      try {
        const body = {
          id_produto_tributacao: idProdutoTributacao,
          id_tributacao_uf: id,
          id_cenario_tributacao: cenario,
          id_uf: idUf,
          id_produto: idProduto,
        };
        await api.post('/tributacao/produto/uf', body);
        refresh(uf);
        toast('Dados salvos com sucesso!', { type: 'success' });
        toggleModal();
      } catch (err) {
        toast(err.mensagem);
        closeLoader();
      }
    }
  }

  return (
    <Modal
      isOpen={modal?.open}
      toggle={() => {
        toggleModal();
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
        }}
      >
        Seleção da tributação
      </ModalHeader>
      <ModalBody>
        {tributacoesUf?.length ? (
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>UF</th>
                    <th>Cenário</th>
                    <th>Tributação</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {tributacoesUf?.map((v) => (
                    <tr key={v?.value}>
                      <td data-label="UF">{v?.uf}</td>
                      <td data-label="Cenário">{v?.cenario}</td>
                      <td data-label="Tributação">{v?.label}</td>
                      <td data-label="Ação" className="text-center">
                        <Button
                          onClick={() => {
                            handleClick(v?.value, v?.cenario_id, v?.uf_id);
                          }}
                        >
                          <FaCheck />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <p>Sem tributações cadastradas para esse cenário e UF!</p>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
}

export default TributacoesUf;
