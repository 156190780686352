import React, { useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaBoxOpen,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../../components/unform/index';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';
import { useAuth } from '../../../contexts/auth';

function ListarOrcamentos({ modalProps, toggleModal, open }) {
  let debounce = null;
  const { idFilial } = useAuth();
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const filtrosRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(
        `/orcamento?page=${p + 1}&id_filial=${idFilial}${filtros}`
      );
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  return (
    <>
      <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
      <ModalBody>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div />
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Número"
                    name="numero_orcamento"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    className="form-control"
                    placeholder="status"
                    name="status"
                    data={[
                      {
                        value: 'F',
                        label: 'Fechado',
                      },
                      {
                        value: 'A',
                        label: 'Aberto',
                      },
                    ]}
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Número</th>
              <th>Série</th>
              <th>Qtd. itens</th>
              <th>Valor total</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="Número">{m.numero_orcamento}</td>
                <td data-label="Série">{m.serie_orcamento}</td>
                <td data-label="Qtd. itens">{m.qtde_itens}</td>
                <td data-label="Valor total">
                  {Number(m.valor_total).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        onClick={() => {
                          toggleModal();
                          open(m.id);
                        }}
                      >
                        <FaBoxOpen className="mr-1" />
                        Reabrir
                      </DropdownItem>
                      {/* <DropdownItem>
                        <FiTrash className="mr-1" />
                        Excluir
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages}
          onPageChange={({ selected }) => handleChangePage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </ModalFooter>
    </>
  );
}

export default ListarOrcamentos;
