import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Pagination from 'react-paginate';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import html2pdf from 'html2pdf.js';
import api from '../../services/apiBank';

import { NumberFormat, Input, Select } from '../../components/unform/index';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Pagamento() {
  const formRef = useRef();
  const formRefGerar = useRef();

  const [boletos, setBoletos] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [filtro, setFiltro] = useState({});

  const [modal, setModal] = useState(false);

  const [dadosBoleto, setDadosBoleto] = useState({});

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'boleto.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async function handlePDF(codigo) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/boletos/${codigo}/html`);
      const opt = {
        margin: 0,
        filename: 'boleto.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 4,
          height: 1000,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(data).set(opt).save();
    } catch (err) {
      toast.error('Erro ao buscar boletos!');
    }
    Swal.close();
  }

  async function gerarBoleto(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefGerar.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const body = {
          ...dadosBoleto,
          codigo: e?.codigo,
          senha_conta: e?.senha,
        };
        const { data } = await api.post('/boleto-pj', body);

        swalWithBootstrapButtons.fire({
          title: 'Código de barras gerado:',
          text: data?.numCodBarras,
          icon: 'success',
          confirmButtonText: 'OK',
          reverseButtons: true,
        });
        toggleModal();
        toast.success('Operação realizada com sucesso!');
      } catch (err) {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  async function senhaCodigo(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      setDadosBoleto(e);
      toggleModal();
    }
  }

  async function getAbertos() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      if (params) {
        const { data, headers } = await api.get(
          `/boleto-pj?status=Aberto&${params}`
        );
        const retorno = {
          boletos: data,
          total: headers['x-total-count'],
          pages: headers['x-total-pages'],
        };
        setBoletos(retorno);
        window.scrollTo(0, 0);
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar boletos!');
      Swal.close();
    }
  }

  async function getPagos() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      if (params) {
        const { data, headers } = await api.get(
          `/boleto-pj?status=Pago&${params}`
        );
        const retorno = {
          boletos: data,
          total: headers['x-total-count'],
          pages: headers['x-total-pages'],
        };
        setBoletos(retorno);
        window.scrollTo(0, 0);
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar boletos!');
      Swal.close();
    }
  }

  function formatDate(date) {
    const dataArray = date?.split('T')[0]?.split('-');
    return `${dataArray[2]}/${dataArray[1]}/${dataArray[0]}`;
  }

  async function handleCancelar(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja cancelar o boleto?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/boleto-pj/${id}`)
            .then(() => {
              getAbertos();
              toast.success('Boleto cancelado com sucesso!');
              Swal.close();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Boletos</h4>
        </Col>
      </Row>
      <Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Gerar boleto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' && 'active'}
              onClick={() => {
                getAbertos();
                toggle('2');
              }}
            >
              Boletos em aberto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' && 'active'}
              onClick={() => {
                getPagos();
                toggle('3');
              }}
            >
              Boletos pagos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              ref={formRef}
              onSubmit={(e) => {
                senhaCodigo(e);
              }}
            >
              <CardBody>
                <Row>
                  <Col lg={2}>
                    <NumberFormat
                      name="valor_titulo"
                      label="Valo R$"
                      className="form-control"
                      thousandSeparator="."
                      prefix="R$"
                      decimalSeparator=","
                    />
                  </Col>
                  <Col lg={2}>
                    <Input
                      label="Data de vencimento"
                      className="form-control"
                      type="date"
                      name="data_vencimento"
                    />
                  </Col>
                  <Col lg={2}>
                    <Input
                      label="Data limite pagamento"
                      className="form-control"
                      type="date"
                      name="data_limite_pagamento"
                    />
                  </Col>
                  <Col lg={2}>
                    <Select
                      name="tipo_pessoa"
                      label="Tipo de pessoa"
                      className="form-control"
                      data={[
                        { label: 'Selecione', value: '' },
                        { label: 'Pessoa Física', value: 1 },
                        { label: 'Pessoa Jurídica', value: 2 },
                      ]}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={2}>
                    <Input
                      label="Documento pagador"
                      className="form-control"
                      type="text"
                      name="documento"
                    />
                  </Col>
                  <Col lg={10}>
                    <Input
                      label="Nome pagador"
                      className="form-control"
                      type="text"
                      name="nome_razao"
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Gerar boleto
                </Button>
              </CardFooter>
            </Form>
          </TabPane>
          <TabPane tabId="2">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Valor</th>
                    <th>Emissão</th>
                    <th>Pagador</th>
                    <th>Vencimento</th>
                    <th>Status</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {boletos?.boletos?.length ? (
                    boletos?.boletos?.map((c) => (
                      <tr key={c?.id}>
                        <td>{c?.retorno_codigo_barras}</td>
                        <td>
                          {Number(c?.valor || 0).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>{formatDate(c?.data_emissao)}</td>
                        <td>{c?.pagador_nome_razao}</td>
                        <td>{formatDate(c?.data_vencimento)}</td>
                        <td>{c?.status}</td>
                        <td className="text-center">
                          <UncontrolledDropdown size="xs">
                            <DropdownToggle color="link link-gray" size="xs">
                              <FaEllipsisH size="1.3em" />
                            </DropdownToggle>
                            <DropdownMenu right container="body">
                              <DropdownItem
                                onClick={() => {
                                  handlePDF(c?.retorno_codigo_barras);
                                }}
                                size="xs"
                              >
                                PDF
                              </DropdownItem>
                              {/* <DropdownItem
                                onClick={() => {
                                  handleCancelar(c?.id);
                                }}
                                size="xs"
                              >
                                Cancelar
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination
                nextLabel={<FaChevronRight />}
                previousLabel={<FaChevronLeft />}
                pageCount={boletos.pages || 1}
                onPageChange={({ selected }) =>
                  setFiltro({ ...filtro, page: selected + 1 })
                }
                initialPage={0}
                containerClassName="pagination justify-end mr-1"
                activeClassName="active"
              />
            </CardBody>
          </TabPane>
          <TabPane tabId="3">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Valor</th>
                    <th>Emissão</th>
                    <th>Pagador</th>
                    <th>Vencimento</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {boletos?.boletos?.length ? (
                    boletos?.boletos?.map((c) => (
                      <tr key={c?.id}>
                        <td>{c?.codigo_barras}</td>
                        <td>
                          {Number(c?.valor || 0).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>{formatDate(c?.data_emissao)}</td>
                        <td>{c?.nome_pagador}</td>
                        <td>{formatDate(c?.data_vencimento)}</td>
                        <td className="text-center">
                          <UncontrolledDropdown size="xs">
                            <DropdownToggle color="link link-gray" size="xs">
                              <FaEllipsisH size="1.3em" />
                            </DropdownToggle>
                            <DropdownMenu right container="body">
                              <DropdownItem
                                onClick={() => {
                                  handlePDF(c?.codigo_barras);
                                }}
                                size="xs"
                              >
                                PDF
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination
                nextLabel={<FaChevronRight />}
                previousLabel={<FaChevronLeft />}
                pageCount={boletos.pages || 1}
                onPageChange={({ selected }) =>
                  setFiltro({ ...filtro, page: selected + 1 })
                }
                initialPage={0}
                containerClassName="pagination justify-end mr-1"
                activeClassName="active"
              />
            </CardBody>
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Gerar boleto</ModalHeader>
        <Form
          ref={formRefGerar}
          onSubmit={(e) => {
            gerarBoleto(e);
          }}
        >
          <ModalBody>
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Pagamento;
