import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input, Select, MaskInput } from '../../../components/unform';
import { useValidator } from '../../../hooks';
import api from '../../../services/apiPDV';
import useSearch from '../../../utils/searchParams';

function NovoCliente() {
  const search = useSearch();
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const [idEndereco, setIdEndereco] = useState();

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = [
      'nome',
      'cpf_cnpj',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'cidade',
      'estado',
    ];
    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      if (search?.id) {
        const body = { endereco: {} };
        const camposEndereco = [
          'bairro',
          'cep',
          'cidade',
          'complemento',
          'estado',
          'logradouro',
          'numero',
          'tipo_endereco',
        ];

        Object.entries(e).forEach(([key, value]) => {
          if (camposEndereco.includes(key)) {
            body.endereco[key] = value;
          } else {
            body[key] = value;
          }
        });

        if (idEndereco) {
          body.endereco.id_endereco = idEndereco;
        }
        await api.put(`/cliente/${search?.id}`, body);
      } else {
        await api.post('/cliente', e);
      }
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  const handleCep = async (cep) => {
    if (cep.length === 8) {
      showLoader();
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        formRef.current.setFieldValue('logradouro', resultCep?.street || '');
        formRef.current.setFieldValue('estado', resultCep?.state || '');
        formRef.current.setFieldValue('cidade', resultCep?.city || '');
        formRef.current.setFieldValue('bairro', resultCep?.neighborhood || '');
        closeLoader();
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');
        closeLoader();
        toast('CEP não encontrado', { type: 'warn' });
      }
    }
  };

  useEffect(() => {
    async function getCliente() {
      try {
        const { data } = await api.get(`/cliente/${search?.id}`);
        const { ...rest } = data;
        const body = {};

        Object.entries(rest?.cli).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key}`] = value;
          } else {
            // eslint-disable-next-line no-lonely-if
            if (key === 'data_nascimento') {
              if (value) {
                body[key] = value?.split('T')[0];
              }
            } else {
              body[key] = value;
            }
          }
        });

        Object.entries(rest?.endereco).forEach(([key, value]) => {
          if (key === 'id') {
            setIdEndereco(value);
          }
          if (typeof value === 'object') {
            if (key === 'Cidade') {
              if (value) {
                body.cidade = value?.nome;
              }
            } else if (key === 'Uf') {
              if (value) {
                body.estado = value?.sigla;
              }
            } else {
              body[`id_${key}`] = value;
            }
          } else {
            body[key] = value;
          }
        });

        formRef.current.setData(body);
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getCliente();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Novo cliente</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <p className="text-muted">Informações gerais</p>

            <Col lg={4}>
              <Input name="nome" label="Nome *" className="form-control" />
            </Col>
            <Col lg={4}>
              <Input
                name="cpf_cnpj"
                label="CPF/CNPJ *"
                getValueRegex={/[^A-Z0-9]/gi}
                className="form-control"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="data_nascimento"
                type="date"
                label="Data nascimento"
                className="form-control"
              />
            </Col>
            <Col lg={4}>
              <Input name="email" label="Email" className="form-control" />
            </Col>
            <Col lg={2}>
              <MaskInput
                name="telefone_ddd"
                label="DDD"
                placeholder="00"
                className="form-control"
                mask={['(', /[0-9]/, /\d/, ')']}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <MaskInput
                name="telefone"
                label="Telefone"
                placeholder="00000-0000"
                className="form-control"
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                removeEspecial
              />
            </Col>
            <Col lg={3}>
              <Select
                name="sexo"
                label="Sexo"
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'Masculino',
                    label: 'Masculino',
                  },
                  {
                    value: 'Feminino',
                    label: 'Feminino',
                  },
                ]}
                className="form-control"
              />
            </Col>

            <p className="text-muted">Endereço</p>
            <Col lg={2}>
              <MaskInput
                name="cep"
                label="CEP *"
                placeholder="00.000-000"
                className="form-control"
                removeEspecial
                onChange={(e) =>
                  handleCep(String(e.target.value).replace(/\D/g, ''))
                }
                mask={[
                  /[0-9]/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </Col>
            <Col lg={4}>
              <Input
                name="logradouro"
                label="Logradouro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={2}>
              <Input
                name="numero"
                label="Nº *"
                className="form-control"
                type="number"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="complemento"
                label="Complemento *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4}>
              <Input
                name="bairro"
                label="Bairro *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={2}>
              <Input
                name="estado"
                label="Estado *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={3}>
              <Input
                name="cidade"
                label="Cidade *"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={3}>
              <Select
                name="tipo_endereco"
                label="Tipo endereço *"
                data={[
                  {
                    value: 'R',
                    label: 'Residencial',
                  },
                  {
                    value: 'C',
                    label: 'Comercial',
                  },
                  {
                    value: 'O',
                    label: 'Outros',
                  },
                ]}
                className="form-control"
              />
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoCliente;
