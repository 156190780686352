import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { HiDotsHorizontal, HiOutlinePencil } from 'react-icons/hi';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import BouncingDotsLoader from 'components/boucingDots';
import { CheckBox, Input, Select } from 'components/unform';
import { useValidator } from 'hooks';
import api from 'services/apiPDV';
import formFiltros from 'utils/formFiltros';

function Cupoms() {
  const [offcanvas, setOffcanvas] = useState(false);
  const [dados, setDados] = useState({});
  const { showLoader, closeLoader } = useValidator();
  const [tipoDesconto, setTipoDesconto] = useState();
  const offcanvasRef = useRef([]);
  const debounceProduto = useRef();

  const loadMore = async (page) => {
    const nextPage = (page === 0 ? page : dados?.page || 1) + 1;

    const filtros = `page=${nextPage}&${dados?.filtros?.join?.('&') || ''}`;

    const { data } = await api.get(`/cupom?${filtros}`).catch((err) => {
      toast.error(err.mensagem);
      return null;
    });
    if (nextPage === 1) {
      setDados(data);
    } else {
      setDados((old) => ({
        ...old,
        data: (old?.data || []).concat(data?.data || []),
      }));
    }
  };

  const toggleOffcanvas = (item) => {
    setOffcanvas(item);
  };

  async function handleSubmit() {
    const body = formFiltros();

    const filtros = Object.entries(body)
      .map(([key, value]) => (value ? `${key}=${value || ''}` : ''))
      .filter(Boolean);

    try {
      showLoader();
      const { data } = await api.get(
        `/ecommerce/loja/cupom?${filtros.join('&')}`
      );
      setDados({
        ...data,
        filtros,
      });
    } catch (err) {
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  async function handleEdit(e) {
    const errors = {};

    if (!e.codigo) {
      errors.codigo = 'Obrigatório';
    }

    if (!e.desconto || Number(e.desconto) < 1 || Number(e.desconto) > 99) {
      errors.desconto = 'Deve ser entre 1 e 99';
    }
    if (!e.quantidade || Number(e.quantidade) < 1) {
      errors.quantidade = 'Deve ser maior que 0';
    }

    offcanvasRef.current[0].setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho');
    }

    showLoader();
    try {
      if (offcanvas?.id) {
        await api.put(`/cupom/${offcanvas?.id}`, e);
        setDados({
          ...dados,
          data: dados?.data?.map((m) => {
            if (m.id !== offcanvas?.id) {
              return m;
            }

            return {
              ...m,
              ...e,
            };
          }),
        });
      } else {
        await api.post('/cupom', e);
        await loadMore(0);
      }

      toggleOffcanvas(false);
    } catch (err) {
      offcanvasRef.current[0].setErrors(err.errors);
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  async function hanleAlterarStatus(e) {
    showLoader();
    const status = e.status === 'Ativo' ? 'Inativo' : 'Ativo';
    try {
      await api.put(`/cupom/${e?.id}`, {
        status,
      });
      setDados({
        ...dados,
        data: dados?.data?.map((m) => {
          if (m.id !== e?.id) {
            return m;
          }

          return {
            ...m,
            status,
          };
        }),
      });
    } catch (err) {
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  useEffect(() => {
    const initialData = async () => {
      const { data } = await api.get('/ecommerce/loja/cupom').catch((err) => {
        toast.error(err.mensagem);
        return null;
      });
      setDados(data);
    };
    clearTimeout(debounceProduto.current);
    debounceProduto.current = setTimeout(() => {
      initialData();
    }, 100);
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Row>
              <Col lg={10}>
                <Row>
                  <Col lg={3} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Status</div>
                      </InputGroupText>
                      <select name="status">
                        <option>Ativo</option>
                        <option>Inativo</option>
                        <option value="">Todos</option>
                      </select>
                    </InputGroup>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Código</div>
                      </InputGroupText>
                      <input name="consulta_valor" />
                    </InputGroup>
                  </Col>
                  <Col lg={5} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Desconto</div>
                      </InputGroupText>
                      <input
                        placeholder="De"
                        name="desconto_min"
                        type="number"
                        min={1}
                        max={99}
                      />
                      <div className="overflow-visible">|</div>
                      <input
                        placeholder="Até"
                        name="desconto_max"
                        min={1}
                        max={99}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Tipo</div>
                      </InputGroupText>
                      <select name="tipo">
                        <option value="">Todos</option>
                        <option value="$">Valor</option>
                        <option value="%">Porcentagem</option>
                        <option value="F">Frete grátis</option>
                      </select>
                    </InputGroup>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Quantidade</div>
                      </InputGroupText>
                      <input placeholder="De" name="quantidade_min" />
                      <div className="overflow-visible">|</div>
                      <input placeholder="Até" name="quantidade_max" />
                    </InputGroup>
                  </Col>
                  <Col lg={5} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Validade</div>
                      </InputGroupText>
                      <Flatpickr
                        name="validade_inicio"
                        placeholder="Início"
                        className="border-none date-picker"
                        options={{
                          mode: 'single',
                          locale: Portuguese,
                          dateFormat: 'd/m/Y',
                        }}
                      />
                      <div className="overflow-visible">|</div>
                      <Flatpickr
                        name="validade_fim"
                        placeholder="Fim"
                        className="border-none date-picker"
                        options={{
                          mode: 'single',
                          locale: Portuguese,
                          dateFormat: 'd/m/Y',
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg={2}>
                <Row>
                  <Col xs={6} md={12} className="mb-3">
                    <Button
                      color="light-danger"
                      type="reset"
                      onClick={() => handleSubmit()}
                      block
                    >
                      Redefinir
                    </Button>
                  </Col>
                  <Col xs={6} md={12}>
                    <Button color="light-primary" type="submit" block>
                      Procurar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
          <Row>
            <Col
              md={8}
              xs={9}
              className="d-flex mb-3 align-items-center justify-content-sm-between justify-content-md-start"
            >
              <h5 className="mb-0">
                {dados?.total} Cupo{dados?.total > 1 ? 'ns' : 'm'}
              </h5>
            </Col>

            <Col md={4} xs={3} className="d-flex-wrap justify-content-end">
              <Button
                color="success"
                onClick={() => {
                  toggleOffcanvas(true);
                }}
                className="mb-3 ml-2 d-flex align-items-center"
              >
                Novo
              </Button>
            </Col>
          </Row>
          <InfiniteScroll
            className="row"
            dataLength={dados?.total || 0}
            hasMore={(dados.page || 1) < dados?.pages}
            next={() => {
              loadMore();
            }}
            loader={
              <div className="my-3">
                <BouncingDotsLoader />
              </div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Não há mais dados</b>
              </p>
            }
            scrollableTarget="main-content"
            refreshFunction={() => loadMore(0)}
            pullDownToRefresh={false}
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <h3 style={{ textAlign: 'center' }}>
            //     &#8595; Puxe para baixo para atualizar
            //   </h3>
            // }
            // releaseToRefreshContent={
            //   <h3 style={{ textAlign: 'center' }}>
            //     &#8593; Solte para atualizar
            //   </h3>
            // }
          >
            <Table className="rwd-table" responsive>
              <thead>
                <tr>
                  <th>Código</th>
                  <th className="w-10">Desconto</th>
                  <th className="w-15">Validade</th>
                  <th className="w-10">Quantidade</th>
                  <th className="w-10">Status</th>
                  <th className="text-end w-10">Opções</th>
                </tr>
              </thead>
              <tbody>
                {dados?.data?.map((m) => (
                  <tr key={m.id}>
                    <td data-label="Código" className="w-15">
                      {m.codigo}
                    </td>
                    <td data-label="Desconto" className="w-15">
                      {m.tipo === 'frete'
                        ? 'F'
                        : `${
                            m.tipo === '$'
                              ? Number(m.desconto).toLocaleString('pt-BR', {
                                  currency: 'BRL',
                                  style: 'currency',
                                })
                              : `${m.desconto}%`
                          }`}
                    </td>
                    <td data-label="Validade" className="w-10">
                      de{' '}
                      {m.validade_inicio
                        ? new Date(m.validade_inicio).toLocaleDateString()
                        : 'indefinido'}{' '}
                      até{' '}
                      {m.validade_fim
                        ? new Date(m.validade_fim).toLocaleDateString()
                        : 'indefinido'}
                    </td>
                    <td data-label="Quantidade" className="w-10">
                      {m.quantidade}
                    </td>
                    <td data-label="Status" className="w-10">
                      {m.status}
                    </td>
                    <td data-label="Opções" className="w-10">
                      <UncontrolledDropdown className="float-md-right">
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <HiDotsHorizontal />
                        </DropdownToggle>
                        <DropdownMenu direction="down">
                          <DropdownItem onClick={() => hanleAlterarStatus(m)}>
                            <HiOutlinePencil className="mr-1" />
                            {m.status === 'Ativo' ? 'Inativar' : 'Ativar'}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </CardBody>
      </Card>

      <Offcanvas
        isOpen={!!offcanvas}
        toggle={() => toggleOffcanvas(false)}
        direction="end"
        onClosed={() => setTipoDesconto('')}
      >
        <OffcanvasHeader toggle={() => toggleOffcanvas(false)}>
          Novo cupom
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            initialData={offcanvas}
            ref={(ref) => {
              offcanvasRef.current[0] = ref;
            }}
            onSubmit={(e) => handleEdit(e)}
          >
            <Row>
              <Col lg={12}>
                <Input
                  label="Código *"
                  name="codigo"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Select
                  label="Tipo desconto *"
                  name="tipo"
                  onChange={(e) => setTipoDesconto(e.target.value)}
                  className="form-control"
                  data={[
                    {
                      label: 'Valor fixo $',
                      value: '$',
                    },
                    {
                      label: 'Percentual %',
                      value: '%',
                    },
                    {
                      label: 'Frete grátis',
                      value: 'F',
                    },
                  ]}
                />
              </Col>
              {tipoDesconto !== 'frete' && (
                <Col lg={12}>
                  <Input
                    label="Desconto *"
                    name="desconto"
                    type="number"
                    className="form-control"
                  />
                </Col>
              )}
              <Col lg={12}>
                <Input
                  label="Quantidade *"
                  name="quantidade"
                  type="number"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Validade início"
                  name="validade_inicio"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Validade fim"
                  name="validade_fim"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Mínimo pedido"
                  name="minimo_pedido"
                  type="number"
                  className="form-control"
                />
              </Col>
              {tipoDesconto !== 'frete' && (
                <Col lg={12}>
                  <Input
                    label="Valor desconto máximo"
                    name="minimo_pedido"
                    type="number"
                    className="form-control"
                  />
                </Col>
              )}
              <Col lg={12}>
                <CheckBox
                  onChange={(e) => {
                    if (e.target.checked) {
                      offcanvasRef.current[0].setFieldValue('aleatorio', false);
                    }
                  }}
                  name="um_por_pessoa"
                  label="Um cupom por pessoa ?"
                />
              </Col>
              <Col lg={12}>
                <CheckBox
                  onChange={(e) => {
                    if (e.target.checked) {
                      offcanvasRef.current[0].setFieldValue(
                        'um_por_pessoa',
                        false
                      );
                    }
                  }}
                  name="aleatorio"
                  label="Gerar cupons com final diferente para a quantidade informada ?"
                />
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
        <Button
          color="primary"
          className="no-radius py-2"
          onClick={() => {
            offcanvasRef.current[0].submitForm();
          }}
        >
          Criar
        </Button>
      </Offcanvas>
    </>
  );
}

export default Cupoms;
