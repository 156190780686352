/* eslint-disable react/no-array-index-key */
import React, { useState, useRef } from 'react';
import { FaTooth, FaTrashAlt } from 'react-icons/fa';
import {
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Table,
} from 'reactstrap';
import boca from '../../../../../assets/images/boca.png';
import CampoTextoCurto from './CampoTextoCurto';

function makeid() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}
function Odontograma() {
  const obsRef = useRef();

  const dentesSuperiores = [
    18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
  ];
  const dentesInferiores = [
    48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
  ];

  const [itens, setItens] = useState([]);

  return (
    <div className="mb-3 component-render">
      <label>Odontograma</label>

      <Row className="justify-content-between">
        <Col className="justify-center">
          <div className="odontograma">
            <div className="dentadura-superior">
              {dentesSuperiores.map((numeroDente) => (
                <React.Fragment key={numeroDente}>
                  <Button
                    type="button"
                    className="dente"
                    id={`dente-${numeroDente}`}
                  >
                    <FaTooth size={18} color="#bdbdbd" />
                    <div className="numero-dente">{numeroDente}</div>
                  </Button>

                  <UncontrolledPopover
                    placement="left"
                    target={`dente-${numeroDente}`}
                    trigger="legacy"
                  >
                    <PopoverHeader>Dente {numeroDente}</PopoverHeader>
                    <PopoverBody>
                      <CampoTextoCurto
                        label="Observação"
                        className="form-control"
                        name={`odontograma_obs_dente_${numeroDente}`}
                        type="text"
                        onChange={(e) => {
                          obsRef.current = e?.target?.value;
                        }}
                      />
                      <div className="justify-end">
                        <Button
                          color="outline-primary"
                          type="button"
                          onClick={() => {
                            const obj = {
                              id: `obs_dente_${makeid()}`,
                              obsevacao: obsRef.current,
                              item: 'Dente',
                              numero: numeroDente,
                            };

                            setItens([...itens, obj]);
                            obsRef.current = '';
                          }}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </React.Fragment>
              ))}
            </div>
            <div className="dentadura-inferior">
              {dentesInferiores.map((numeroDente) => (
                <React.Fragment key={numeroDente}>
                  <Button
                    type="button"
                    className="dente"
                    id={`dente-${numeroDente}`}
                  >
                    <FaTooth size={18} color="#bdbdbd" />
                    <div className="numero-dente">{numeroDente}</div>
                  </Button>

                  <UncontrolledPopover
                    placement="left"
                    target={`dente-${numeroDente}`}
                    trigger="legacy"
                  >
                    <PopoverHeader>Dente {numeroDente}</PopoverHeader>
                    <PopoverBody>
                      <CampoTextoCurto
                        label="Observação"
                        className="form-control"
                        name={`odontograma_obs_dente_${numeroDente}`}
                        type="text"
                        onChange={(e) => {
                          obsRef.current = e?.target?.value;
                        }}
                      />
                      <div className="justify-end">
                        <Button
                          color="outline-primary"
                          type="button"
                          onClick={() => {
                            const obj = {
                              id: `obs_dente_${makeid()}`,
                              obsevacao: obsRef.current,
                              item: 'Dente',
                              numero: numeroDente,
                            };

                            setItens([...itens, obj]);
                            obsRef.current = '';
                          }}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Col>
        <Col lg={4} className="justify-center">
          <Button id="boca" color="link" type="button">
            <img src={boca} className="boca" alt="Boca" />
          </Button>

          <UncontrolledPopover placement="left" target="boca" trigger="legacy">
            <PopoverHeader>Boca</PopoverHeader>
            <PopoverBody>
              <CampoTextoCurto
                label="Observação"
                className="form-control"
                name="odontograma_obs_boca"
                type="text"
                onChange={(e) => {
                  obsRef.current = e?.target?.value;
                }}
              />
              <div className="justify-end">
                <Button
                  color="outline-primary"
                  type="button"
                  onClick={() => {
                    const obj = {
                      id: `boca_${makeid()}`,
                      obsevacao: obsRef.current,
                      item: 'Boca',
                      numero: '',
                    };

                    setItens([...itens, obj]);
                    obsRef.current = '';
                  }}
                >
                  Adicionar
                </Button>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      </Row>
      <Row hidden={!itens?.length}>
        <Col>
          <Table className="rwd-table" responsive>
            <thead>
              <tr>
                <th>Parte</th>
                <th>Observação</th>
                <th className="text-end">Ação</th>
              </tr>
            </thead>
            <tbody>
              {itens?.map((m, i) => (
                <tr key={i}>
                  <td data-label="Parte">
                    {m?.item} {m?.numero}
                  </td>
                  <td data-label="Observação">{m?.obsevacao}</td>
                  <td data-label="Ação" className="text-end">
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => {
                        setItens(itens?.filter((f) => f?.id !== m?.id));
                      }}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        {itens?.map((m, i) => (
          <div>
            <CampoTextoCurto
              className="form-control"
              type="text"
              name={`odontograma_parte_${i}`}
              value={`${m?.item} ${m?.numero}`}
              hidden
            />
            <CampoTextoCurto
              className="form-control"
              type="text"
              name={`odontograma_observacao_${i}`}
              value={m?.obsevacao}
              hidden
            />
          </div>
        ))}
      </Row>
    </div>
  );
}

export default Odontograma;
