import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import CampoTextoCurto from './CampoTextoCurto';

function CalculoDppIg() {
  const [dataUltrasom, setDataUltrasom] = useState('');
  const [semanasUltrasom, setSemanasUltrasom] = useState(0);
  const [diasUltrasom, setDiasUltrasom] = useState(0);
  const [dum, setDum] = useState('');
  const [igAtualUltrasom, setIgAtualUltrasom] = useState('- semanas, - dias');
  const [dppUltrasom, setDppUltrasom] = useState('__/__/____');
  const [igAtualDum, setIgAtualDum] = useState('- semanas, - dias');
  const [dppDum, setDppDum] = useState('__/__/____');

  useEffect(() => {
    if (dataUltrasom && semanasUltrasom >= 0 && diasUltrasom >= 0) {
      // Converte a data da ultrassonografia em um objeto Date
      const dataUltrasomObj = new Date(dataUltrasom);

      // Calcula a data atual em milissegundos
      const dataAtual = Date.now();

      // Calcula a diferença entre a data da ultrassonografia e a data atual em milissegundos
      const diferenca = dataAtual - dataUltrasomObj.getTime();

      // Calcula o número de semanas e dias com base na diferença em milissegundos
      const semanas = Math.floor(diferenca / (7 * 24 * 60 * 60 * 1000));
      const dias = Math.floor(
        (diferenca % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
      );

      let igSemanas = semanas + parseInt(semanasUltrasom, 10);
      let igDias = dias + parseInt(diasUltrasom, 10);

      // Converte os dias adicionais para semanas, se for maior ou igual a 7
      if (igDias >= 7) {
        const semanasExtras = Math.floor(igDias / 7);
        igSemanas += semanasExtras;
        igDias -= semanasExtras * 7;
      }

      // Define a IG atual com base nos cálculos da ultrassonografia e do ig_semanas e ig_dias
      setIgAtualUltrasom(`${igSemanas} semanas e ${igDias} dias`);

      // Converte as semanas e dias em dias totais
      const diasTotais = igSemanas * 7 + igDias;

      // Calcula a DPP com base na data de hoje
      const dataDppUltrasom = new Date();

      // Subtrai os dias totais para ajustar a DPP
      dataDppUltrasom.setDate(dataDppUltrasom.getDate() - diasTotais);

      // Adiciona 280 dias para estimar a DPP (40 semanas)
      dataDppUltrasom.setDate(dataDppUltrasom.getDate() + 280);

      // Formata a DPP como uma string no formato "dd/mm/yyyy" para a ultrassonografia
      const diaDppUltrasom =
        (dataDppUltrasom.getDate() < 10 ? '0' : '') + dataDppUltrasom.getDate();
      const mesDppUltrasom =
        (dataDppUltrasom.getMonth() + 1 < 10 ? '0' : '') +
        (dataDppUltrasom.getMonth() + 1);
      const anoDppUltrasom = dataDppUltrasom.getFullYear();
      const dppFormatadaUltrasom = `${diaDppUltrasom}/${mesDppUltrasom}/${anoDppUltrasom}`;

      // Define a DPP com base nos cálculos da ultrassonografia
      setDppUltrasom(dppFormatadaUltrasom);
    }
  }, [dataUltrasom, semanasUltrasom, diasUltrasom]);

  useEffect(() => {
    if (dum) {
      // Converte a data da última menstruação em um objeto Date
      const dataDumObj = new Date(dum);

      // Calcula a data atual em milissegundos
      const dataAtual = Date.now();

      // Calcula a diferença entre a data da última menstruação e a data atual em milissegundos
      const diferenca = dataAtual - dataDumObj.getTime();

      // Calcula o número de dias com base na diferença em milissegundos
      const dias = Math.floor(diferenca / (24 * 60 * 60 * 1000));

      // Calcula o número de semanas e dias de gestação com base na diferença em dias
      const semanas = Math.floor(dias / 7);
      const diasRestantes = dias % 7;

      // Define a IG atual com base nos cálculos da DUM
      setIgAtualDum(`${semanas} semanas e ${diasRestantes} dias`);

      // Calcula a DPP com base nos cálculos da DUM
      dataDumObj.setDate(dataDumObj.getDate() + 281);

      // Formata a DPP como uma string no formato "dd/mm/yyyy" para a DUM
      const diaDum =
        (dataDumObj.getDate() < 10 ? '0' : '') + dataDumObj.getDate();
      const mesDum =
        (dataDumObj.getMonth() + 1 < 10 ? '0' : '') +
        (dataDumObj.getMonth() + 1);
      const anoDum = dataDumObj.getFullYear();
      const dppFormatadaDum = `${diaDum}/${mesDum}/${anoDum}`;

      // Define a DPP com base nos cálculos da DUM
      setDppDum(dppFormatadaDum);
    }
  }, [dum]);

  return (
    <div className="mb-3 component-render">
      <label>IG e DPP</label>
      <Row>
        <Col>
          <h6>
            Critério para o cálculo: <strong>Data do exame anterior</strong>
          </h6>
          <hr />
          <div className="w-50">
            <CampoTextoCurto
              className="form-control"
              type="date"
              label="Data da ultrassonografia"
              name="calculodppig_data_ultrassonografia"
              value={dataUltrasom}
              onChange={(e) => setDataUltrasom(e.target.value)}
            />
          </div>
          <Row>
            <p>IG estimada na ultrassonografia:</p>
            <Col lg={4}>
              <CampoTextoCurto
                className="form-control"
                type="number"
                name="calculodppig_ig_semanas"
                label="Semanas"
                value={semanasUltrasom}
                onChange={(e) => setSemanasUltrasom(e.target.value)}
              />
            </Col>
            <Col lg={4}>
              <CampoTextoCurto
                className="form-control"
                type="number"
                name="calculodppig_ig_dias"
                label="Dias"
                value={diasUltrasom}
                onChange={(e) => setDiasUltrasom(e.target.value)}
              />
            </Col>
          </Row>
          <div>
            <p>
              <strong>IG atual (Ultrassonografia):</strong> {igAtualUltrasom}
            </p>
            <p>
              <strong>DPP (Ultrassonografia):</strong> {dppUltrasom}
            </p>
          </div>
        </Col>
        <Col>
          <h6>
            Critério para o cálculo:{' '}
            <strong>Data da última menstruação </strong>
          </h6>
          <hr />
          <div className="w-50">
            <CampoTextoCurto
              className="form-control"
              type="date"
              label="Data da última menstruação"
              name="calculodppig_data_ultima_menstruacao"
              value={dum}
              onChange={(e) => setDum(e.target.value)}
            />
          </div>
          <div>
            <p>
              <strong>IG atual (DUM):</strong> {igAtualDum}
            </p>
            <p>
              <strong>DPP (DUM):</strong> {dppDum}
            </p>
          </div>
        </Col>
        <Col>
          <CampoTextoCurto
            className="form-control"
            type="text"
            name="calculodppig_ig_atual_ultrassom"
            value={igAtualUltrasom}
            hidden
          />
          <CampoTextoCurto
            className="form-control"
            type="text"
            name="calculodppig_dpp_ultrassom"
            value={dppUltrasom}
            hidden
          />
          <CampoTextoCurto
            className="form-control"
            type="text"
            name="calculodppig_ig_atual_dum"
            value={igAtualDum}
            hidden
          />
          <CampoTextoCurto
            className="form-control"
            type="text"
            name="calculodppig_dpp_dum"
            value={dppDum}
            hidden
          />
        </Col>
      </Row>
    </div>
  );
}

export default CalculoDppIg;
