import React, { useEffect, useRef, useState } from 'react';
import { FiEdit, FiFilter, FiSettings, FiDisc, FiEye } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  ReactSelectAsync,
  NumberFormat,
} from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';
import PromoContainer from './PromoContainer';

function Promocoes() {
  const selectOptions = useRef();
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);
  const [tipo, setTipo] = useState('');
  const [variacoes, setVariacoes] = useState([]);
  const [variacoes2, setVariacoes2] = useState([]);

  const [promoData, setPromoData] = useState(null);

  const debounce = useRef();
  const filtrosRef = useRef();
  const formRef = useRef();

  const { showLoader, closeLoader, toast } = useValidator();
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);
  const toggleModal = () => setModal(!modal);

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(`/promocao?page=${p + 1}${filtros}`);
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  const handleSubmit = async (e) => {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f] || (f === 'filiais' && !e[f].length)) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        showLoader();
        await api.post(`/promocao-${tipo}`, e);
        formRef.current.reset();
        setTipo('');
        setVariacoes([]);
        setVariacoes2([]);
        toggleOffcanvas();
        await handleChangePage(0);
      } catch (err) {
        toast(err.mensagem);
        closeLoader();
      }
    }
  };

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  useEffect(() => {
    async function getData() {
      try {
        showLoader();
        const { data } = await api.get('/promocao?status=A');
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
    getData();
  }, []);

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/async/produto?desc=${inputValue}`);

    return data;
  }

  async function filtrarConsultaFiliais(inputValue) {
    const { data } = await api.get(
      `/options/filial?razao_social=${inputValue}`
    );

    return data;
  }

  const optionsConsulta = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  const optionsFiliais = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsultaFiliais(inputValue));
      }, 500);
    });

  async function handleAtivarInativar(id, tipoPromo) {
    try {
      showLoader();
      await api.put(`/promocao/ativar/${id}/${tipoPromo?.replace('/', '')}`);
      handleChangePage(0);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function verDados(id, tipoPromo) {
    try {
      showLoader();
      const { data } = await api.get(
        `/promocao/${id}/${tipoPromo?.replace('/', '')}`
      );
      toggleModal();
      setPromoData(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Promoções</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button onClick={toggleOffcanvas} color="primary">
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => handleChangePage(0)} ref={filtrosRef}>
              <Row>
                <Col lg={4}>
                  <Input
                    name="descricao"
                    label="Descrição"
                    onChange={filtrarPesquisa}
                    className="form-control"
                  />
                </Col>
                <Col lg={2}>
                  <Select
                    name="status"
                    label="Status"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      { value: 'A', label: 'Ativo' },
                      { value: 'I', label: 'Inativo' },
                      { value: '', label: 'Todos' },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Descrição PDV</th>
              <th>Tipo</th>
              <th>Filial</th>
              <th className="text-center">Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m?.id}>
                <td data-label="Descrição">{m?.descricao}</td>
                <td data-label="Descrição PDV">{m?.descricao_pdv}</td>
                <td data-label="Tipo">{m?.tipo}</td>
                <td data-label="Filial">{m?.Filial?.razao_social}</td>
                <td data-label="Status" className="text-center">
                  {m?.status}
                </td>
                <td data-label="Ação">
                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          onClick={() => {
                            verDados(m?.id, m?.tipo);
                          }}
                        >
                          <FiEye className="mr-1" />
                          Ver dados
                        </DropdownItem>
                        {/* <DropdownItem onClick={() => setOffcanvas(m)}>
                          <FiEdit className="mr-1" />
                          Editar
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => {
                            handleAtivarInativar(m?.id, m?.tipo);
                          }}
                        >
                          <FiDisc className="mr-1" />
                          Inativar / Ativar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Offcanvas
        direction="end"
        isOpen={!!offcanvas}
        toggle={() => {
          toggleOffcanvas();
          setTipo('');
          setVariacoes([]);
          setVariacoes2([]);
        }}
      >
        <OffcanvasHeader
          toggle={() => {
            toggleOffcanvas();
            setTipo('');
            setVariacoes([]);
            setVariacoes2([]);
          }}
        >
          {offcanvas?.id
            ? `Alterando "${offcanvas?.descricao}"`
            : 'Nova promoção'}
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={12}>
                <Select
                  name="tipo_promocao"
                  label="Tipo"
                  className="form-control"
                  data={[
                    { value: '', label: 'Selecione' },
                    { value: 'depor', label: 'DE/POR' },
                    { value: 'combo', label: 'COMBO' },
                    { value: 'kit', label: 'KIT' },
                    { value: 'prog', label: 'PROGRESSIVA' },
                  ]}
                  onChange={(e) => setTipo(e?.target?.value)}
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Descrição"
                  className="form-control"
                  placeholder="Digite a descrição"
                  name="descricao"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Descrição para o PDV"
                  className="form-control"
                  placeholder="Digite a descrição para o PDV"
                  name="descricao_pdv"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Data Início"
                  className="form-control"
                  name="data_inicio"
                  type="date"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Data Fim"
                  className="form-control"
                  name="data_encerramento"
                  type="date"
                />
              </Col>
              <Col lg={12}>
                <ReactSelectAsync
                  isMulti
                  label="Filiais"
                  name="filiais"
                  loadOptions={optionsFiliais}
                />
              </Col>
            </Row>
            <hr />
            {tipo !== '' && (
              <>
                {tipo === 'depor' || tipo === 'prog' || tipo === 'combo' ? (
                  <>
                    <Row>
                      <Col lg={12}>
                        <ReactSelectAsync
                          label="Produto"
                          name="produto"
                          loadOptions={optionsConsulta}
                          onChange={(e) => {
                            setVariacoes([]);
                            if (e) {
                              if (!e?.variacoes?.length) {
                                toast(
                                  'Produto sem variações cadastradas, cadastre as variações!'
                                );
                              } else {
                                setVariacoes(
                                  e?.variacoes?.map((v) => ({
                                    value: v?.id,
                                    label: `SKU: ${v?.sku || 's/n'} - Modelo: ${
                                      v?.modelo || 's/n'
                                    } - Cor: ${v?.cor || 's/n'}
                                    - Tamanho: ${v?.tamanho || 's/n'}
                                    - Preço: ${Number(
                                      v?.preco_venda
                                    ).toLocaleString('pt-BR', {
                                      currency: 'BRL',
                                      style: 'currency',
                                    })}`,
                                  }))
                                );
                              }
                            }
                          }}
                        />
                      </Col>

                      <Col lg={12} hidden={!variacoes?.length}>
                        <Select
                          name="id_produto_variacao"
                          label="Variação do produto"
                          className="form-control"
                          data={variacoes}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg={12}>
                        <ReactSelectAsync
                          label="Produto 1"
                          name="produto"
                          loadOptions={optionsConsulta}
                          onChange={(e) => {
                            setVariacoes([]);
                            if (e) {
                              if (!e?.variacoes?.length) {
                                toast(
                                  'Produto sem variações cadastradas, cadastre as variações!'
                                );
                              } else {
                                setVariacoes(
                                  e?.variacoes?.map((v) => ({
                                    value: v?.id,
                                    label: `${v?.modelo} - ${v?.cor} - ${Number(
                                      v?.preco_venda
                                    ).toLocaleString('pt-BR', {
                                      currency: 'BRL',
                                      style: 'currency',
                                    })}`,
                                  }))
                                );
                              }
                            }
                          }}
                        />
                      </Col>

                      <Col lg={12} hidden={!variacoes?.length}>
                        <Select
                          name="id_produto_variacao_1"
                          label="Variação do produto 1"
                          className="form-control"
                          data={variacoes}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <ReactSelectAsync
                          label="Produto 2"
                          name="produto"
                          loadOptions={optionsConsulta}
                          onChange={(e) => {
                            setVariacoes2([]);
                            if (e) {
                              if (!e?.variacoes?.length) {
                                toast(
                                  'Produto sem variações cadastradas, cadastre as variações!'
                                );
                              } else {
                                setVariacoes2(
                                  e?.variacoes?.map((v) => ({
                                    value: v?.id,
                                    label: `${v?.modelo} - ${v?.cor} - ${Number(
                                      v?.preco_venda
                                    ).toLocaleString('pt-BR', {
                                      currency: 'BRL',
                                      style: 'currency',
                                    })}`,
                                  }))
                                );
                              }
                            }
                          }}
                        />
                      </Col>

                      <Col lg={12} hidden={!variacoes2?.length}>
                        <Select
                          name="id_produto_variacao_2"
                          label="Variação do produto 2"
                          className="form-control"
                          data={variacoes2}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {tipo === 'depor' && (
                  <Row>
                    <Col lg={12}>
                      <NumberFormat
                        label="Valor promoção"
                        name="valor_promocao"
                        className="form-control"
                        prefix="R$"
                      />
                    </Col>
                  </Row>
                )}

                {tipo === 'combo' && (
                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Quantidade obrigatória"
                        className="form-control"
                        name="quantidade_obrigatoria"
                        type="number"
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Quantidade pagar"
                        className="form-control"
                        name="quantidade_pagar"
                        type="number"
                      />
                    </Col>
                  </Row>
                )}

                {tipo === 'prog' && (
                  <Row>
                    <Col lg={6}>
                      <Input
                        label="Quantidade mínima"
                        className="form-control"
                        name="quantidade_minima"
                        type="number"
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label="Percentual desconto (%)"
                        className="form-control"
                        name="percentual_desconto"
                        type="number"
                        step="0.01"
                        max="99.99"
                        min="0"
                      />
                    </Col>
                  </Row>
                )}

                {tipo === 'kit' && (
                  <>
                    <Row>
                      <Col lg={6}>
                        <Input
                          label="Quantidade produto 1 *"
                          className="form-control"
                          name="quantidade1"
                          type="number"
                        />
                      </Col>
                      <Col lg={6}>
                        <Input
                          label="Quantidade produto 2 *"
                          className="form-control"
                          name="quantidade2"
                          type="number"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <NumberFormat
                          label="Valor promoção"
                          name="valor_promocao"
                          className="form-control"
                          prefix="R$"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            <Button className="mb-0" block color="primary">
              Salvar
            </Button>
          </Form>
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Promoção</ModalHeader>
        <ModalBody>
          <PromoContainer promoData={promoData} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Promocoes;
