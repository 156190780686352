/* eslint-disable no-return-await */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  FaPlusCircle,
  FaTimes,
  FaTrash,
  FaCog,
  FaAngleDown,
} from 'react-icons/fa';
// import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import api from '../../services/apiBank';
import {
  NumberFormat,
  Select,
  Input,
  Radio,
} from '../../components/unform/index';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Transferencia() {
  const formRef = useRef();
  const formRefModal = useRef();
  const formRefModalFav = useRef();
  const [activeTab, setActiveTab] = useState('1');
  const [banks, setBanks] = useState([]);
  const [dadosTransferencia, setDadosTransferencia] = useState([]);
  const [favoritos, setFavoritos] = useState([]);

  const [selectedFav, setselectedFav] = useState([]);
  const [valueFromSelected, setValueFromSelected] = useState([]);

  const [favoritosTransfer, setFavoritosTransfer] = useState([]);

  const [showBtn, setShowBtn] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalFav, setModalFav] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  const toggleModalFav = () => setModalFav(!modalFav);

  async function getBanks() {
    try {
      const { data } = await axios.get('https://brasilapi.com.br/api/banks/v1');

      if (data) {
        const banksArray = [];

        for (let index = 0; index < data.length; index += 1) {
          const e = data[index];

          if (e?.code !== null) {
            banksArray.push({
              id: e?.code,
              value: e?.code,
              label: `${e?.code} - ${e?.fullName}`,
            });
          }
        }

        setBanks(banksArray);
      }
    } catch (err) {
      toast.error('Não foi possível listar os bancos!');
    }
  }

  async function getFavoritos() {
    try {
      const { data } = await api.get('/favoritos-pj/TRANSFERENCIA');
      setFavoritos(data);
    } catch (err) {
      toast.error('Erro ao buscar favoritos!');
    }
  }

  useEffect(() => {
    getBanks();
    getFavoritos();
  }, []);

  useEffect(() => {
    if (selectedFav.length) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [selectedFav]);

  async function handleTransferencia(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      setDadosTransferencia(data);
      toggleModal();
    }
  }

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefModal.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const body = dadosTransferencia;
      body.senha_conta = data.senha;
      body.codigo = data.codigo;
      try {
        await api.post('/transferencia-pj', body);
        toast.success('Transferência realizada com sucesso!');
        formRef.current.reset();
        toggleModal();
        Swal.close();
      } catch (err) {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
        toggleModal();
      } finally {
        Swal.close();
      }
    }
  }

  async function handleTransfer() {
    if (valueFromSelected.length) {
      const arrayToTransfer = [];
      const arrayFormat = valueFromSelected?.filter(
        (e) => e?.valor !== undefined
      );

      for (let i = 0; i < favoritos.length; i += 1) {
        const favorito = favoritos[i];
        for (let o = 0; o < arrayFormat.length; o += 1) {
          const selecionado = arrayFormat[o];

          if (favorito.id === selecionado.id) {
            arrayToTransfer.push({
              agencia_destinatario: favorito.agencia,
              compe_destinatario: favorito.codigo_banco,
              conta_destinatario: favorito.conta_banco,
              documento_destinatario: favorito.documento,
              nome_destinatario: favorito.nome,
              tipo_conta: favorito.tipo_conta,
              valor: Number(selecionado.valor),
              conta_digito: favorito?.digito_conta || null,
            });
          }
        }
      }

      setFavoritosTransfer(arrayToTransfer);
      toggleModalFav();
    }
  }

  async function handleSubmitMany(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefModalFav.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      // eslint-disable-next-line no-restricted-syntax
      for await (const [, favorito] of favoritosTransfer.entries()) {
        favorito.senha_conta = data.senha;
        favorito.codigo = data.codigo;
        try {
          await api.post('/transferencia-pj', favorito);
          toast.success(
            `Transferência para ${favorito?.nome_destinatario} realizada!`
          );
        } catch (err) {
          const error =
            err?.response?.data?.error || 'Ocorreu um erro tente novamente';
          toast.error(error);
        }
      }
      Swal.close();
      toggleModalFav();
    }
  }

  async function handleDeleteFavorito(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir esse favorito?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/deletar-favorito/${id}`)
            .then(() => {
              setFavoritos(favoritos?.filter((f) => f.id !== id));
              toast.success('Favorito excluido com sucesso!');
              Swal.close();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Transferência</h4>
        </Col>
      </Row>
      <Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Realizar transferência
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' && 'active'}
              onClick={() => {
                toggle('2');
              }}
            >
              Favoritos
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              ref={formRef}
              onSubmit={(e) => {
                handleTransferencia(e);
              }}
            >
              <CardBody>
                <Row>
                  <Col lg={2}>
                    <NumberFormat
                      name="valor"
                      label="Valor R$"
                      className="form-control"
                      thousandSeparator="."
                      prefix="R$"
                      decimalSeparator=","
                    />
                  </Col>
                  <Col lg={4}>
                    <Select
                      name="compe_destinatario"
                      label="Banco"
                      className="form-control"
                      data={banks}
                    />
                  </Col>
                  <Col lg={2}>
                    <Input
                      label="Agência"
                      className="form-control"
                      type="number"
                      name="agencia_destinatario"
                    />
                  </Col>
                  <Col lg={3}>
                    <Input
                      label="Conta"
                      className="form-control"
                      type="number"
                      name="conta_destinatario"
                    />
                  </Col>
                  <Col lg={1}>
                    <Input
                      label="Conta dígito"
                      className="form-control"
                      type="number"
                      name="conta_digito"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <Input
                      label="Nome"
                      className="form-control"
                      type="text"
                      name="nome_destinatario"
                    />
                  </Col>
                  <Col lg={4}>
                    <Input
                      label="Documento"
                      className="form-control"
                      type="number"
                      name="documento_destinatario"
                    />
                  </Col>
                  <Col lg={3}>
                    <Select
                      name="tipo_conta"
                      label="Tipo conta"
                      className="form-control"
                      data={[
                        { label: 'Conta Corrente', value: 1 },
                        { label: 'Conta Poupança', value: 2 },
                        { label: 'Conta Pagamento', value: 3 },
                        { label: 'Conta Salário', value: 4 },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <Radio
                      name="favorito"
                      label="Favoritar?"
                      className="radio"
                      defaultValue="S"
                      data={[
                        { id: 'S', label: 'Sim', value: true },
                        { id: 'N', label: 'Não', value: false },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Transferir
                </Button>
              </CardFooter>
            </Form>
          </TabPane>
          <TabPane tabId="2">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th style={{ textAlign: 'center' }}>Conta</th>
                    <th style={{ textAlign: 'center' }}>Agência</th>
                    <th style={{ textAlign: 'center' }}>Banco</th>
                    <th style={{ textAlign: 'center' }}>Transferência</th>
                    <th style={{ textAlign: 'center' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {favoritos?.length ? (
                    favoritos?.map((tar) => (
                      <tr key={tar?.id}>
                        <td>{tar?.nome}</td>
                        <td style={{ textAlign: 'center' }}>
                          {tar?.conta_banco}
                        </td>
                        <td style={{ textAlign: 'center' }}>{tar?.agencia}</td>
                        <td style={{ textAlign: 'center' }}>
                          {tar?.codigo_banco}
                        </td>
                        <td data-label="Ações" className="text-center w-10">
                          <Button
                            outline
                            color="success"
                            hidden={selectedFav?.includes(tar?.id)}
                            onClick={() => {
                              setselectedFav([...selectedFav, tar?.id]);
                            }}
                          >
                            <FaPlusCircle />
                          </Button>
                          {selectedFav?.includes(tar?.id) && (
                            <Row>
                              <Col>
                                <CurrencyInput
                                  className="form-control"
                                  onValueChange={(value) => {
                                    const newArray = valueFromSelected?.filter(
                                      (e) => e?.id !== tar?.id
                                    );
                                    const selected = {
                                      valor: value?.replace(',', '.'),
                                      id: tar?.id,
                                    };
                                    newArray.push(selected);
                                    setValueFromSelected(newArray);
                                  }}
                                  name="valor"
                                  intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                  }}
                                  placeholder="Valor R$"
                                  decimalsLimit={2}
                                  id={tar?.id}
                                />
                              </Col>
                              <Col lg={1} className="mr-3">
                                <Button
                                  outline
                                  className="btn-md"
                                  color="danger"
                                  onClick={() => {
                                    setValueFromSelected(
                                      valueFromSelected?.filter(
                                        (e) => e?.id !== tar?.id
                                      )
                                    );
                                    setselectedFav(
                                      selectedFav?.filter((e) => e !== tar?.id)
                                    );
                                  }}
                                >
                                  <FaTimes />
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </td>
                        <td data-label="Ações" className="text-center w-10">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color="link"
                              className="text-decoration-none padding-0-5"
                            >
                              <FaCog className="mr-2" /> <FaAngleDown />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() => {
                                  handleDeleteFavorito(tar?.id);
                                }}
                              >
                                <FaTrash className="mr-1" />
                                Excluir
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              {showBtn && (
                <Button color="primary" onClick={handleTransfer}>
                  Transferir
                </Button>
              )}
            </CardFooter>
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Validar transferência</ModalHeader>
        <Form
          ref={formRefModal}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col>
                <h6>Valor: {dadosTransferencia?.valor}</h6>
              </Col>
              <Col>
                <h6>Banco: {dadosTransferencia?.compe_destinatario}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Agência: {dadosTransferencia?.agencia_destinatario}</h6>
              </Col>
              <Col>
                <h6>Conta: {dadosTransferencia?.conta_destinatario}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Nome: {dadosTransferencia?.nome_destinatario}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Documento: {dadosTransferencia?.documento_destinatario}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>
                  Favorito:{' '}
                  {dadosTransferencia?.favorito === 'true' ? 'Sim' : 'Não'}
                </h6>
              </Col>
            </Row>
            <hr />
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modalFav} toggle={toggleModalFav}>
        <ModalHeader toggle={toggleModalFav}>Validar transferência</ModalHeader>
        <Form
          ref={formRefModalFav}
          onSubmit={(e) => {
            handleSubmitMany(e);
          }}
        >
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Conta</th>
                  <th>Agência</th>
                  <th>Banco</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {favoritosTransfer?.length ? (
                  favoritosTransfer?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>{tar?.nome_destinatario}</td>
                      <td>{tar?.conta_destinatario}</td>
                      <td>{tar?.agencia_destinatario}</td>
                      <td>{tar?.compe_destinatario}</td>
                      <td>
                        {Number(tar?.valor / 100)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <hr />
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Transferencia;
