import React, { useEffect, useRef, useState } from 'react';
import { FiEdit, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import axios from 'axios';
import { Form } from '@unform/web';
import { Input, MaskInput, Select } from '../../../components/unform';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';
import { maskDocumento } from '../../../utils';

function Filiais() {
  const formRef = useRef();
  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [page, setPage] = useState(1);
  const filtrosRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [idFilialEdit, setIdFilialedit] = useState(null);

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );
      const { data } = await api.get(`/filial?page=${p + 1}${filtros}`);
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  const getDados = async () => {
    try {
      showLoader();
      const { data } = await api.get('/filial');
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  useEffect(() => {
    getDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCep = async (cep) => {
    if (cep.length === 8) {
      showLoader();
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        formRef.current.setFieldValue('logradouro', resultCep?.street || '');
        formRef.current.setFieldValue('estado', resultCep?.state || '');
        formRef.current.setFieldValue('cidade', resultCep?.city || '');
        formRef.current.setFieldValue('bairro', resultCep?.neighborhood || '');
        closeLoader();
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');
        closeLoader();
        toast('CEP não encontrado', { type: 'warn' });
      }
    }
  };

  async function getDadosEditarFilial(id) {
    setIdFilialedit(id);
    showLoader();
    try {
      const { data } = await api.get(`/filial/dados/${id}`);

      Object.keys(data).forEach((e) => {
        formRef.current.setFieldValue(e, data[e]);
      });
    } catch (err) {
      toast('Erro ao buscar infos filial', { type: 'error' });
    } finally {
      closeLoader();
    }
  }

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = [
      'cnpj',
      'telefone',
      'telefone_ddd',
      'inscricao_estadual',
      'razao_social',
      'codigo',
      'nome',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'cidade',
      'estado',
    ];

    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      await api.put(`/filial/${idFilialEdit}`, e);

      getDados();
      toggleModal();
      closeLoader();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Filiais</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link
            className="btn btn-primary"
            to="/erp/filial/nova"
            color="primary"
          >
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Código"
                    name="codigo"
                    type="number"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome fantasia"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="CNPJ"
                    name="cnpj"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome fantasia</th>
              <th>CNPJ</th>
              <th className="text-center">Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id || m.codigo}>
                <td data-label="Código">{m.codigo}</td>
                <td data-label="Nome fantasia">{m.nome}</td>
                <td data-label="CNPJ">{maskDocumento(m.cnpj)}</td>
                <td className="text-center" data-label="Status">
                  {m.status}
                </td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        onClick={() => {
                          setIdFilialedit(null);
                          toggleModal();
                          getDadosEditarFilial(m?.id);
                        }}
                      >
                        <FiEdit className="mr-1" />
                        Editar
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Editar filial</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
            <Row>
              <p className="text-muted">Informações gerais</p>
              <Col lg={2}>
                <Input
                  name="codigo"
                  label="Código *"
                  type="number"
                  className="form-control"
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="cnpj"
                  label="CNPJ *"
                  getValueRegex={/[^A-Z0-9]/gi}
                  className="form-control"
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="razao_social"
                  label="Razão social *"
                  className="form-control"
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="nome"
                  label="Nome fantasia *"
                  className="form-control"
                />
              </Col>
              <Col lg={2}>
                <MaskInput
                  name="telefone_ddd"
                  label="DDD telefone *"
                  placeholder="(00)"
                  className="form-control"
                  mask={['(', /[0-9]/, /\d/, ')']}
                  removeEspecial
                />
              </Col>
              <Col lg={3}>
                <MaskInput
                  name="telefone"
                  label="Telefone *"
                  placeholder="00000-0000"
                  className="form-control"
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  removeEspecial
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="inscricao_estadual"
                  label="Inscrição estadual *"
                  className="form-control"
                />
              </Col>
              <Col lg={2}>
                <Select
                  name="status"
                  label="Status *"
                  className="form-control"
                  data={[
                    { value: 'A', label: 'Ativo' },
                    { value: 'I', label: 'Inativo' },
                  ]}
                />
              </Col>
              <p className="text-muted">Endereço</p>
              <Col lg={2}>
                <MaskInput
                  name="cep"
                  label="CEP *"
                  placeholder="00.000-000"
                  className="form-control"
                  removeEspecial
                  onChange={(e) =>
                    handleCep(String(e.target.value).replace(/\D/g, ''))
                  }
                  mask={[
                    /[0-9]/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="logradouro"
                  label="Logradouro *"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2}>
                <Input
                  name="numero"
                  label="Nº *"
                  className="form-control"
                  type="number"
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="complemento"
                  label="Complemento *"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="bairro"
                  label="Bairro *"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="estado"
                  label="Estado *"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="cidade"
                  label="Cidade *"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Filiais;
