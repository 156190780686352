import moment from 'moment';

export default function formatEvents(events) {
  const formattedEvents = [];

  events?.forEach((e) => {
    const check = moment(e?.data);

    const month = check.format('M');
    const day = check.format('D');
    const year = check.format('YYYY');

    const inicio = e?.hora_inicio;
    const fim = e?.hora_fim;

    const inicioSplit = inicio.split(':');
    const fimSplit = fim.split(':');

    formattedEvents.push({
      id: e?.id,
      allDay: false,
      startDate: new Date(year, month - 1, day, inicioSplit[0], inicioSplit[1]),
      endDate: new Date(year, month - 1, day, fimSplit[0], fimSplit[1]),
      title: e?.tipo === 1 ? e?.Cliente?.nome : 'Horário Bloqueado',
      atividade: e?.tipo === 1 ? 'atendimento' : 'bloqueio',
      blocked: false,
      textColor: '#f5f6f7',
      cursor: 'move',
    });
  });

  return formattedEvents;
}
