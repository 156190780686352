import React from 'react';
import { Col, Row } from 'reactstrap';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import axios from 'axios';
import Input from '../../../components/unform/input';
import { MaskInput } from '../../../components/unform';
import useValidator from '../../../hooks/useValidator';

// import { Container } from './styles';
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

function Boleto({ disabled = false, formRef }) {
  const { showLoader, closeLoader, toast } = useValidator();

  async function getCep(cepStr = '') {
    const cep = String(cepStr).replace(/[^A-Z0-9]/gi, '');
    if (cep.length !== 8) {
      return;
    }
    showLoader();
    try {
      const { data } = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      formRef.current.setFieldValue('cidade', data.city);
      formRef.current.setFieldValue('bairro', data.neighborhood);
      formRef.current.setFieldValue('estado', data.state);
      formRef.current.setFieldValue('rua', data.street);
      const numRef = formRef.current.getFieldRef('numero');
      closeLoader();
      if (numRef) {
        setTimeout(() => {
          numRef.focus();
        }, 300);
      }
    } catch (err) {
      formRef.current.setFieldValue('cidade', '');
      formRef.current.setFieldValue('bairro', '');
      formRef.current.setFieldValue('estado', '');
      formRef.current.setFieldValue('rua', '');
      toast('CEP não encontrado', { type: 'warning' });
      closeLoader();
    }
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <Input
            disabled={disabled}
            name="primeiro_nome"
            placeholder="Nome *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={6}>
          <Input
            disabled={disabled}
            name="ultimo_nome"
            placeholder="Sobrenome *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={6}>
          <MaskInput
            disabled={disabled}
            name="documento"
            placeholder="CPF *"
            labelClass="d-none mb-0"
            removeEspecial={false}
            className="form-control placeholder-dark"
            mask={[
              /[0-9]/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
          />
        </Col>
        <Col lg={6}>
          <MaskInput
            disabled={disabled}
            mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            name="data_nascimento"
            removeEspecial={false}
            placeholder="Data nascimento *"
            labelClass="d-none mb-0"
            pipe={autoCorrectedDatePipe}
            className="form-control placeholder-dark"
            placeholderChar="-"
          />
        </Col>
        <Col lg={6}>
          <MaskInput
            disabled={disabled}
            name="telefone"
            mask={[
              '(',
              /[0-9]/,
              /\d/,
              ')',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            removeEspecial={false}
            placeholder="Telefone celular *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={6}>
          <Input
            disabled={disabled}
            name="email"
            placeholder="Email *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
      </Row>
      <h6>Endereço</h6>
      <Row>
        <Col lg={4}>
          <MaskInput
            name="cep"
            onChange={(e) => getCep(e?.target?.value || '')}
            disabled={disabled}
            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholder="CEP *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={8}>
          <Input
            name="rua"
            disabled={disabled}
            placeholder="Logradouro *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={4}>
          <Input
            name="numero"
            disabled={disabled}
            placeholder="Número *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={8}>
          <Input
            name="complemento"
            disabled={disabled}
            placeholder="Complemento"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={5}>
          <Input
            name="bairro"
            disabled={disabled}
            placeholder="Bairro *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={5}>
          <Input
            name="cidade"
            disabled={disabled}
            placeholder="Cidade *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={2}>
          <Input
            name="estado"
            disabled={disabled}
            placeholder="UF *"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={2} className="d-none">
          <Input
            disabled
            readOnly
            name="pais"
            labelClass="d-none mb-0"
            value="BR"
            className="d-none disabled readonly"
          />
        </Col>
      </Row>
    </>
  );
}

export default Boleto;
