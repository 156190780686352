import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import CampoTextoCurto from './CampoTextoCurto';
import { Select } from '../../../../../components/unform';

function CalculoDppIg() {
  const [calculado, setCalculado] = useState(0);
  const [dadosCrescimento, setDadosCrescimento] = useState({
    estatura_mae: 0,
    estatura_pai: 0,
    sexo: '',
  });

  useEffect(() => {
    if (dadosCrescimento.sexo === 'Feminino') {
      setCalculado(
        (dadosCrescimento.estatura_pai - 13 + dadosCrescimento.estatura_mae) / 2
      );
    }
    if (dadosCrescimento.sexo === 'Masculino') {
      setCalculado(
        (dadosCrescimento.estatura_mae + 13 + dadosCrescimento.estatura_pai) / 2
      );
    }
  }, [dadosCrescimento]);

  return (
    <div className="mb-3 component-render">
      <label>Cálculo de crescimento</label>
      <Row>
        <Col lg={2}>
          <Select
            name="crescimento_sexo"
            label="Sexo"
            data={[
              {
                value: '',
                label: 'Selecione',
              },
              {
                value: 'Masculino',
                label: 'Masculino',
              },
              {
                value: 'Feminino',
                label: 'Feminino',
              },
            ]}
            className="form-control"
            onChange={(e) =>
              setDadosCrescimento({ ...dadosCrescimento, sexo: e.target.value })
            }
          />
        </Col>
        <Col lg={4}>
          <CampoTextoCurto
            className="form-control"
            type="number"
            name="crescimento_estatura_mae"
            label="Estatura do mãe"
            onChange={(e) =>
              setDadosCrescimento({
                ...dadosCrescimento,
                estatura_mae: Number(e?.target?.value),
              })
            }
          />
        </Col>
        <Col lg={4}>
          <CampoTextoCurto
            className="form-control"
            type="number"
            name="crescimento_estatura_pai"
            label="Estatura do pai"
            onChange={(e) =>
              setDadosCrescimento({
                ...dadosCrescimento,
                estatura_pai: Number(e?.target?.value),
              })
            }
          />
        </Col>
        <Col>
          <label>Estatura alvo</label>
          <h5 className="mt-2">~ {calculado > 0 ? calculado : 0} cm</h5>
        </Col>
      </Row>
      <CampoTextoCurto
        className="form-control"
        type="text"
        name="crescimento_resultado"
        value={calculado}
        hidden
      />
    </div>
  );
}

export default CalculoDppIg;
