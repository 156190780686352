import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {
  Input,
  InputGroup,
  ReactSelect,
  Select,
} from '../../../components/unform';
import { useAuth } from '../../../contexts/auth';
import useValidator from '../../../hooks/useValidator';
import api from '../../../services/api';

function Perfil() {
  const { isAdmin } = useAuth();
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const [dados, setDados] = useState({});
  const [bancos, setBancos] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalBanco, setModalBanco] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formRef = useRef();
  const formBancoRef = useRef();

  const toggleModal = () => setModal((old) => !old);
  const toggleModalBanco = () => setModalBanco((old) => !old);
  const toggleShowPassword = () => setShowPassword((old) => !old);

  async function handleSubmit(body) {
    const { errors, hasError } = validator(body);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }

    try {
      showLoader();
      await api.put('/usuario', body);
      setDados((old) => ({
        ...old,
        email: body.email,
      }));
      toggleModal();
      closeLoader();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  }

  async function handleVincular(body) {
    const { errors, hasError } = validator(body);
    formBancoRef.current.setErrors(errors);
    if (hasError) {
      toast();
      return;
    }
    showLoader();
    try {
      body.agencia = String(body.agencia).padStart(4, '0');
      const { data } = await api.post('/vincular-banco', body);
      setBancos(false);
      setDados((old) => ({
        ...old,
        vendedor: {
          ...old.vendedor,
          ...data,
        },
      }));
      setModalBanco(false);
      toast('Dados bancários cadastrado com sucesso', { type: 'success' });
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  useEffect(() => {
    const getData = async () => {
      try {
        showLoader();
        const { data } = await api.get('/me');
        setDados(data);
      } catch (error) {
        toast(error.mensagem);
      } finally {
        closeLoader();
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getBancos = async () => {
      try {
        const { data } = await api.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(
          data
            .filter((f) => Boolean(f.code))
            .map((m) => ({
              value: String(m.code).padStart(3, '0'),
              label: `${m.code} - ${m.name}`,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (modalBanco && !bancos.length) {
      getBancos();
    }
  }, [modalBanco, bancos]);

  return (
    <>
      <Row>
        {!isAdmin && (
          <Col lg={4}>
            <Card>
              <CardBody>
                <h4 className="text-center">Seus dados</h4>
                <hr />
                {dados?.vendedor?.codigo_banco && (
                  <div className="d-flex-wrap justify-content-between pt-3">
                    <h6>
                      Conta{' '}
                      {dados?.vendedor?.tipo_conta === 'Checking'
                        ? 'corrente'
                        : 'poupança'}
                    </h6>
                    <h6>Agência: {dados?.vendedor?.agencia}</h6>
                    <h6>Conta: {dados?.vendedor?.numero_conta}</h6>
                    <h6>Cód. banco: {dados?.vendedor?.codigo_banco}</h6>
                  </div>
                )}
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleModalBanco}
                >
                  {dados?.vendedor?.codigo_banco
                    ? 'Alterar banco'
                    : 'Cadastrar banco'}
                </button> */}
                <h6 className="mt-3">
                  Plano: {dados?.vendedor?.plano_id_zoop}
                </h6>
                <code>
                  * Para trocar de plano entre em contato com o suporte via Chat
                </code>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg={4}>
          <Card>
            <CardBody>
              <h4>Dados de acesso</h4>
              <hr />
              <h4>Email: {dados?.email}</h4>
              <div className="mt-3 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleModal}
                >
                  Alterar email e senha
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Alterar dados de acesso</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Input
                  name="email"
                  defaultValue={dados?.email}
                  label="email"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <InputGroup
                  name="senha_antiga"
                  label="Senha atual"
                  type={showPassword ? 'text' : 'password'}
                  className="form-control-group"
                  placeholder="* * * * *"
                  append={
                    <Button size="sm" color="link" onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FiEyeOff size={16} />
                      ) : (
                        <FiEye size={16} />
                      )}
                    </Button>
                  }
                />
              </Col>
              <Col lg={12}>
                <InputGroup
                  name="senha"
                  label="Nova senha"
                  type={showPassword ? 'text' : 'password'}
                  className="form-control-group"
                  placeholder="* * * * *"
                  append={
                    <Button size="sm" color="link" onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FiEyeOff size={16} />
                      ) : (
                        <FiEye size={16} />
                      )}
                    </Button>
                  }
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modalBanco} toggle={toggleModalBanco}>
        <ModalHeader toggle={toggleModalBanco}>Dados bancários</ModalHeader>
        <Form ref={formBancoRef} onSubmit={(e) => handleVincular(e)}>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                <code>
                  * A conta informada abaixo deve pertecencer ao CPF/CNPJ de sua
                  conta Uneo Pay
                </code>
              </Col>
              <Col lg={4}>
                <Input
                  name="agencia"
                  label="Agência"
                  className="form-control"
                />
              </Col>
              <Col lg={8}>
                <Input
                  name="conta_bancaria"
                  label="Número da conta"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Select
                  name="tipo"
                  label="Tipo da conta"
                  className="form-control"
                  data={[
                    {
                      value: '',
                      label: 'Selecione',
                    },
                    {
                      value: 'checking',
                      label: 'Corrente',
                    },
                    {
                      value: 'savings',
                      label: 'Poupança',
                    },
                  ]}
                />
              </Col>
              <Col lg={12}>
                <ReactSelect
                  name="codigo_banco"
                  label="Banco"
                  options={bancos}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Perfil;
