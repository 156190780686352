/* eslint-disable no-nested-ternary */
import axios from 'axios';

// export const baseURL = 'http://localhost:3333';
export const baseURL = 'https://api.uneopay.com.br';

const api = axios.create({
  baseURL,
});

export default api;
