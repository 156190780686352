import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import CurrencyInput from 'react-currency-input-field';

export default function NumberFormat({
  name,
  label,
  type,
  string = false,
  className = '',
  defaultValue,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue: dfValue,
    registerField,
    error,
  } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        const val = ref.current.value;

        return string
          ? val
          : Number(val.replace(/[^0-9,]/g, '').replace(',', '.'));
      },
      setValue: (ref, value) => {
        ref.current.value = Number(value).toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        });
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField, string]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>

      <CurrencyInput
        ref={inputRef}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        decimalsLimit={2}
        className={[className, error ? 'error' : ''].join(' ')}
        defaultValue={dfValue || defaultValue}
        id={fieldName}
        groupSeparator="."
        decimalSeparator=","
        prefix="R$"
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
