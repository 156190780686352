/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import CampoTextoCurto from './CampoTextoCurto';

function CalculoImc() {
  const [peso, setPeso] = useState('');
  const [altura, setAltura] = useState('');
  const [imc, setImc] = useState(0);
  const [imcCategory, setImcCategory] = useState('');

  const updateImcCategory = (imcValue) => {
    if (imcValue < 18.5) {
      setImcCategory('Abaixo do Peso');
    } else if (imcValue < 24.9) {
      setImcCategory('Peso Normal');
    } else if (imcValue < 29.9) {
      setImcCategory('Sobrepeso');
    } else if (imcValue < 34.9) {
      setImcCategory('Obesidade Classe 1');
    } else if (imcValue < 39.9) {
      setImcCategory('Obesidade Classe 2');
    } else {
      setImcCategory('Obesidade Classe 3');
    }
  };

  const calculateIMC = (pesoValue, alturaValue) => {
    if (
      !isNaN(pesoValue) &&
      !isNaN(alturaValue) &&
      alturaValue !== 0 &&
      pesoValue &&
      alturaValue
    ) {
      const imcValue = (pesoValue / (alturaValue * alturaValue)).toFixed(2);
      setImc(imcValue);
      updateImcCategory(imcValue);
    } else {
      setImc('0.00');
      setImcCategory('');
    }
  };
  function getImcColor() {
    if (imc < 18.5) {
      return 'blue';
    }
    if (imc < 24.9) {
      return 'text-success';
    }
    if (imc < 29.9) {
      return 'text-warning';
    }
    if (imc < 34.9) {
      return 'text-danger';
    }
    if (imc < 39.9) {
      return 'text-danger';
    }
    return 'text-danger';
  }

  const handlePesoChange = (e) => {
    const pesoValue = parseFloat(e.target.value);
    setPeso(pesoValue);
    calculateIMC(pesoValue, altura);
  };

  const handleAlturaChange = (e) => {
    const alturaValue = parseFloat(e.target.value) / 100;
    setAltura(alturaValue);
    calculateIMC(peso, alturaValue);
  };

  return (
    <div className="mb-3 component-render">
      <Row>
        <label>Cálculo IMC</label>
        <Col lg={2}>
          <div className="justify-between">
            <CampoTextoCurto
              label="Peso"
              className="form-control"
              name="imc_kg"
              type="number"
              value={peso}
              onChange={handlePesoChange}
            />
            <span className="ml-3 mt-5">kg</span>
          </div>
        </Col>
        <Col lg={2}>
          <div className="justify-between">
            <CampoTextoCurto
              label="Altura"
              className="form-control"
              name="imc_cm"
              type="number"
              value={altura * 100}
              onChange={handleAlturaChange}
            />
            <span className="ml-3 mt-5">cm</span>
          </div>
        </Col>
        <Col lg={3}>
          <label className="ml-3">IMC</label>
          <div>
            <h5 className={`ml-3 mt-3 imc-display ${getImcColor()}`}>
              {imc}
              {' - '}
              {imcCategory && (
                <span className={`mr-2 ${getImcColor()}`}>{imcCategory}</span>
              )}
            </h5>
          </div>
        </Col>
      </Row>
      <CampoTextoCurto
        className="form-control"
        type="text"
        name="imc_resultado"
        value={`${imc} - ${imcCategory}`}
        hidden
      />
    </div>
  );
}

export default CalculoImc;
