/* eslint-disable no-return-await */
import React, { useRef, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import axios from 'axios';
import { Button, Card, Col, Row, CardBody, CardFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import { Input } from '../../../components/unform/index';
import api from '../../../services/api';

// import cadastroParceiro from '../../validations/cadastroParceiro';

function NovoParceiro() {
  const formRef = useRef();
  const history = useHistory();

  const query = useMemo(
    () =>
      String(history?.location?.search || '')
        .replace('?', '')
        .split('&')
        .reduce((a, b) => {
          const [key, value] = b.split('=');
          a[key] = value;
          return a;
        }, {}),
    [history?.location?.search]
  );

  async function handleSubmit(data) {
    try {
      // await cadastroParceiro(data);

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (query?.id) {
        await api.put(`/parceiro/${query?.id}`, data);
      } else {
        await api.post('/parceiro', data);
      }
      toast.success('Dados salvos com sucesso!');
      Swal.close();
      history.push('/pay/parceiros');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
        Swal.close();
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  const consultarCep = async (data, tipo) => {
    if (data) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${data}`
        );
        if (resultCep && tipo === 'principal') {
          formRef.current.setFieldValue('logradouro', resultCep?.street || '');
          formRef.current.setFieldValue('estado', resultCep?.state || '');
          formRef.current.setFieldValue('cidade', resultCep?.city || '');
          formRef.current.setFieldValue(
            'bairro',
            resultCep?.neighborhood || ''
          );
        }
        if (resultCep && tipo === 'secundario') {
          formRef.current.setFieldValue(
            'socio_logradouro',
            resultCep?.street || ''
          );
          formRef.current.setFieldValue('socio_estado', resultCep?.state || '');
          formRef.current.setFieldValue('socio_cidade', resultCep?.city || '');
          // formRef.current.setFieldValue(
          //   'bairro',
          //   resultCep?.neighborhood || ''
          // );
        }
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');

        toast.warning('CEP não encontrado!');
      }
      Swal.close();
    }
  };

  useEffect(() => {
    const getEditData = async (id) => {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data } = await api.get(`/parceiro/${id}`);

        if (data) {
          Object.keys(data).forEach((f) => {
            if (f?.includes('data_')) {
              formRef.current.setFieldValue(f, data[f]?.split('T')[0]);
            } else {
              formRef.current.setFieldValue(f, data[f]);
            }
          });
        }
      } catch (err) {
        toast.error('Erro ao buscar dados!');
      }
      Swal.close();
    };

    if (query.id) {
      getEditData(query.id);
    }
  }, [query]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">{query.id ? 'Editar' : 'Criar'} parceiro</h4>
        </Col>
      </Row>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <CardBody>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Input
                  label="Razão social"
                  name="razao_social"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input
                  label="Nome fantasia"
                  name="nome_fantasia"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Input
                  label="CNPJ"
                  name="cnpj"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={4} sm={6}>
                <Input
                  label="Data de contratação"
                  name="data_contratacao"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  label="E-mail"
                  name="email"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={4} sm={6}>
                <Input
                  label="Telefone"
                  name="telefone"
                  className="form-control"
                  type="number"
                />
              </Col>
              <Col lg={6} md={4} sm={6}>
                <Input
                  label="Finalidade parceria"
                  name="finalidade_parceria"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={4} sm={6}>
                <Input
                  label="Observação"
                  name="observacao"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={3}>
                <Input
                  name="cep"
                  label="CEP"
                  className="form-control"
                  placeholder="Digite o CEP"
                  onBlur={(e) => {
                    consultarCep(e.target.value, 'principal');
                  }}
                />
              </Col>
              <Col md={7} sm={7}>
                <Input
                  name="logradouro"
                  label="Logradouro"
                  className="form-control"
                  placeholder="Digite o logradouro"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="numero"
                  label="Número"
                  className="form-control"
                  placeholder="Digite o nº"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  name="bairro"
                  label="Bairro"
                  className="form-control"
                  placeholder="Digite o bairro"
                />
              </Col>
              <Col md={4} sm={4}>
                <Input
                  name="cidade"
                  label="Cidade"
                  className="form-control"
                  placeholder="Digite a cidade"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="estado"
                  label="Estado"
                  className="form-control"
                  placeholder="Digite o estado"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <Input
                  name="complemento"
                  label="Complemento"
                  className="form-control"
                  placeholder="Digite o complemento"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Input
                  label="Sócio responsável"
                  name="socio_responsavel"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Input
                  label="CPF"
                  name="socio_cpf"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={4} sm={6}>
                <Input
                  label="Data de nascimento"
                  name="socio_data_nascimento"
                  className="form-control"
                  type="date"
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={3}>
                <Input
                  name="socio_cep"
                  label="CEP"
                  className="form-control"
                  placeholder="Digite o CEP"
                  onBlur={(e) => {
                    consultarCep(e.target.value, 'secundario');
                  }}
                />
              </Col>
              <Col md={7} sm={7}>
                <Input
                  name="socio_logradouro"
                  label="Logradouro"
                  className="form-control"
                  placeholder="Digite o logradouro"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="socio_numero"
                  label="Número"
                  className="form-control"
                  placeholder="Digite o nº"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={4}>
                <Input
                  name="socio_cidade"
                  label="Cidade"
                  className="form-control"
                  placeholder="Digite a cidade"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="socio_estado"
                  label="Estado"
                  className="form-control"
                  placeholder="Digite o estado"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <Input
                  name="complemento"
                  label="Complemento"
                  className="form-control"
                  placeholder="Digite o complemento"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default NovoParceiro;
