import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaListOl,
  FaWallet,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { useAuth } from 'contexts/auth';
import { CheckBox, Input, Select } from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';

function ListarUsuarios() {
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const { user, perfil } = useAuth();
  const filtrosRef = useRef();
  const debounce = useRef();
  const formRef = useRef();
  const { showLoader, closeLoader, toast, validator } = useValidator();

  const toggleModal = () => setModal((old) => !old);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/usuarios?page=${page || 1}&${query}`);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function getAcessos(usuario) {
    showLoader();
    try {
      const { data } = await api.get(`/usuarios/${usuario.id}/acessos`);
      setModal({
        ...usuario,
        acessos: data,
      });
      formRef.current.setData({
        ...usuario,
        acessos: data.reduce((a, b) => {
          a[b.tela] = true;
          return a;
        }, {}),
      });
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  async function handleSubmit({ acessos, ...body }) {
    const { errors, hasError } = validator(body);

    formRef.current.setErrors(errors);

    if (hasError) {
      return toast();
    }

    const arrAcessos = Object.entries(acessos || {})
      .map(([key, value]) => value && key)
      .filter(Boolean);

    const delAcessos =
      (modal?.acessos || [])?.filter(
        (f) => !arrAcessos.some((s) => s === f.tela)
      ) || [];
    const addAcessos =
      arrAcessos?.filter(
        (f) => !(modal?.acessos || [])?.some((s) => s.tela === f)
      ) || [];

    showLoader();

    try {
      if (modal?.id) {
        await api.put(`/usuarios/${modal?.id}`, {
          body,
          addAcessos,
          delAcessos,
        });
      } else {
        await api.post('/usuarios', {
          body,
          acessos: addAcessos,
        });
      }
      toggleModal();
      loadMore(1);
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/usuarios');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Novo
          </Button>
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    name="tipo_acesso"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Perfil',
                      },
                      {
                        value: 'Vendedor',
                        label: 'Vendedor',
                      },
                      {
                        value: 'Admin',
                        label: 'Admin',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Perfil</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="Perfil">{m.tipo_acesso}</td>
                <td data-label="Status">{m.status}</td>
                <td
                  data-label="Ações"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => getAcessos(m)}>
                        <FaEdit className="mr-1" />
                        Editar
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {modal?.nome || 'Novo usuário'}
        </ModalHeader>
        <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Input className="form-control" name="nome" label="Nome" />
              </Col>
              <Col lg={12}>
                <Input className="form-control" name="email" label="Email" />
              </Col>
              {modal?.status !== 'Pendente' && (
                <Col lg={12}>
                  <Select
                    name="status"
                    className="form-control"
                    label="Status"
                    data={[
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                    ]}
                  />
                </Col>
              )}
            </Row>
            {(modal?.tipo_acesso === 'Admin' ||
              (!modal?.tipo_acesso && user?.pay?.tipo_acesso === 'Admin')) && (
              <Scope path="acessos">
                <Row>
                  <Col lg={12}>
                    <label className="label-form">Acessos</label>
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/" label="Dashboard" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/usuarios" label="Usuários" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/vendedores" label="Vendedores" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/transacoes" label="Transações" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/transferencias" label="Transferências" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/link-pagamento" label="Link pagamento" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/pos" label="POS" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/chat" label="Chat" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/pre-cadastro" label="Pré cadastro" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/parceiros" label="Parceiros" />
                  </Col>
                  <Col lg={6} md={12}>
                    <CheckBox name="/funcionarios" label="Funcionários " />
                  </Col>
                </Row>
              </Scope>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default ListarUsuarios;
