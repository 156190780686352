import React, { useRef, useEffect } from 'react';
import Select from 'react-select/async-creatable';
import { useField } from '@unform/core';
import customStyles, { theme } from './stylesSelect';

export default function AsyncSelectCreatable({
  name,
  disabled = false,
  defaultValue,
  label,
  fullObj = false,
  className = '',
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (fullObj || !ref.select.state.value) {
            return ref.select.state.value || [];
          }

          if (ref.select.state.value) {
            return ref.select.state.value.map((option) => option.value);
          }
        }

        if (fullObj || !ref.select.state.value) {
          return ref.select.state.value;
        }

        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.state.value = value;
        ref.select.updater.enqueueForceUpdate(ref);
        // ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti, fullObj]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Select
        key={fieldName}
        defaultValue={defaultValue}
        styles={customStyles}
        ref={selectRef}
        classNamePrefix="react-select"
        isDisabled={disabled}
        className={error && 'error'}
        // defaultOptions
        placeholder="Digite para pesquisar"
        noOptionsMessage={() => 'Sem registros'}
        defaultOptions={false}
        theme={theme}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
