import React, { useMemo, useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipItem = (props) => {
  const { position = 'left', text, children } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const id = useMemo(
    () =>
      (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7),
    []
  );
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={`tooltip-${id}`}>{children}</span>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={`tooltip-${id}`}
        toggle={toggle}
      >
        {text}
      </Tooltip>
    </span>
  );
};
export default TooltipItem;
