/* eslint-disable no-return-await */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import api from '../../services/apiBank';
import { Input } from '../../components/unform/index';

function Transferencia() {
  const formRefModal = useRef();

  const [activeTab, setActiveTab] = useState('1');

  const [cupons, setCupons] = useState({
    items: [],
    page: 1,
    pages: 1,
    total: 1,
  });
  const [sorteios, setSorteios] = useState([]);
  const [dados, setDados] = useState({ valor: 0, premio: 0 });

  const [modal, setModal] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  async function getMeusCupons(page) {
    const { data: meusCupons } = await api.get(`/meus-cupons?page=${page}`);
    setCupons(meusCupons);
  }

  async function getUltimosSorteios() {
    const { data: ultimosSorteios } = await api.get('/vencedores-cupons');

    setSorteios(ultimosSorteios);
  }

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRefModal.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        await api.post('/comprar-cupom', data);
        toast.success('Cupom UneoCarbon adquirido com sucesso!');
        getMeusCupons(1);
        toggleModal();
        Swal.close();
      } catch (err) {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
        toggleModal();
      } finally {
        Swal.close();
      }
    }
  }

  async function getValores() {
    const { data: valorCupom } = await api.get('/valor-cupom');
    const { data: valorPremio } = await api.get('/valor-premio');

    setDados({
      valor: valorCupom?.valor,
      premio: valorPremio?.valor,
    });
  }
  useEffect(() => {
    getValores();
    getMeusCupons(1);
    getUltimosSorteios();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">UneoCarbon</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>
            Participe da campanha <strong>UneoCarbon</strong> e ajude a tornar o
            mundo mais verde!
          </h6>
          <h6>
            Adquira seus cupons de carbono por apenas{' '}
            <strong>
              {Number(dados?.valor || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </strong>{' '}
            e concorra no mês a 4 incríveis prêmios no valor de{' '}
            <strong>
              {Number(dados?.premio || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </strong>
            .
          </h6>
          <h6 className="mb-3">
            Juntos podemos fazer a diferença no combate às mudanças climáticas.
            Não perca tempo, cuntribua para um futuro mais sustentável agora
            mesmo.
          </h6>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Button color="primary" type="button" onClick={() => toggleModal()}>
            Ajudar meio ambiente
          </Button>
        </Col>
      </Row>
      <Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Meus cupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' && 'active'}
              onClick={() => {
                toggle('2');
              }}
            >
              Últimos cupons premiados
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th style={{ textAlign: 'center' }}>Mês</th>
                    <th style={{ textAlign: 'center' }}>Ano</th>
                  </tr>
                </thead>
                <tbody>
                  {cupons?.items?.length ? (
                    cupons?.items?.map((tar) => (
                      <tr key={tar?.id}>
                        <td>{tar?.codigo}</td>
                        <td style={{ textAlign: 'center' }}>{tar?.mes}</td>
                        <td style={{ textAlign: 'center' }}>{tar?.ano}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end">
                <Pagination
                  nextLabel={<FaChevronRight />}
                  previousLabel={<FaChevronLeft />}
                  pageCount={dados?.pages || 1}
                  onPageChange={({ selected }) => getMeusCupons(selected + 1)}
                  initialPage={0}
                  containerClassName="pagination justify-end mr-1"
                  activeClassName="active"
                />
              </div>
            </CardBody>
          </TabPane>
          <TabPane tabId="2">
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th style={{ textAlign: 'center' }}>Mês</th>
                    <th style={{ textAlign: 'center' }}>Ano</th>
                  </tr>
                </thead>
                <tbody>
                  {sorteios?.length ? (
                    sorteios?.map((tar) => (
                      <tr key={tar?.id}>
                        <td>{tar?.codigo}</td>
                        <td style={{ textAlign: 'center' }}>{tar?.mes}</td>
                        <td style={{ textAlign: 'center' }}>{tar?.ano}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Adquirir cupom</ModalHeader>
        <Form
          ref={formRefModal}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <h6>
                Adquirindo um <strong>cupom de carbono</strong> no valor de{' '}
                <strong>
                  {Number(dados?.valor || 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>{' '}
                , você concorrerá a 4 prêmios de{' '}
                <strong>
                  {Number(dados?.premio || 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>{' '}
                no mês {new Date().getMonth() + 1}
              </h6>
            </Row>
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha_conta"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Transferencia;
