import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FaChevronDown, FaPlus, FaTrash } from 'react-icons/fa';
import { FiEdit, FiSettings, FiDisc } from 'react-icons/fi';
import { Input, Select, ReactSelectAsync } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

import NovaVariacao from './novaVariacao';
import TributacoesUf from './modalTributacoesUf';
import DadosGeraisAdd from './modalDadosGerais';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function NovoProduto({ modal, toggleModal, refresh }) {
  const formRef = useRef();
  const tributacaoRef = useRef();
  const selectOptions = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [idProduto, setIdProduto] = useState(null);

  const [modalVariacao, setModalVariacao] = useState({ open: false, type: '' });
  const [dadosGerais, setDadosGerais] = useState({
    marcas: [],
    categorias: [],
    tipos: [],
    secoes: [],
    fabricantes: [],
    unidadesMedida: [],
  });
  const [subTipos, setSubTipos] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);

  const [produtoVariacoes, setProdutoVariacoes] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [cenarios, setCenarios] = useState([]);

  const [activeTab, setActiveTab] = useState('1');
  const [selectedUf, setSelectedUf] = useState(null);

  const [idProdutoTributacao, setIdProdutoTributacao] = useState(null);

  const [modalAdd, setModalAdd] = useState({ open: false, type: '' });

  const toggleModalAdd = (type, dados = [], idSub = null) =>
    setModalAdd({ open: !modalAdd?.open, type, dados, idSub });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModalVariacao = (type, objEdit = null) =>
    setModalVariacao({ open: !modalVariacao?.open, type, objEdit });

  const [modalTributacoesUf, setModalTributacoesUf] = useState({
    open: false,
    cenario: '',
    selectedUf: '',
  });
  const toggleModalTributacoes = (cenario) =>
    setModalTributacoesUf({
      open: !modalTributacoesUf?.open,
      cenario,
      selectedUf,
    });

  const handleSubmitDadosGerais = async (e) => {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        showLoader();
        if (modal?.type !== 'edit' && idProduto === null) {
          const { data: produtoSalvo } = await api.post('/produto/cadastro', e);
          setIdProduto(produtoSalvo?.id);
          toggle('2');
        } else {
          await api.put(`/produto/editar/${modal?.objEdit?.id}`, e);
        }
        refresh(0);
        toast('Dados salvos com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
  };

  async function getSub(id, tipo) {
    try {
      if (modal?.type !== 'edit') {
        showLoader();
      }
      const { data: subData } = id
        ? await api.get(`/dados/produto/sub/${id}/${tipo}`)
        : [];

      if (tipo === 'sub_categoria') {
        setSubCategorias(subData);
      } else if (tipo === 'sub_tipo') {
        setSubTipos(subData);
      }
    } catch (err) {
      toast(err.mensagem);
      if (tipo === 'sub_categoria') {
        setSubCategorias([]);
      } else if (tipo === 'sub_tipo') {
        setSubTipos([]);
      }
    } finally {
      closeLoader();
    }
  }

  async function getVariacoesProduto() {
    try {
      showLoader();
      const { data } = await api.get(`/produto/variacao/${idProduto}`);
      setProdutoVariacoes(data);
      refresh(0);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function getUfsFiliais() {
    try {
      showLoader();
      const { data } = await api.get('/ufs/filiais');
      setUfs(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function handleAtivarInativarVariacao(id) {
    try {
      showLoader();
      await api.put(`/produto/variacao/ativar/${id}/${idProduto}`);
      getVariacoesProduto();
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function filtrarConsultaNcm(inputValue) {
    const { data } = await api.get(`/ncm?desc=${inputValue}`);

    return data;
  }

  async function filtrarConsultaCest(inputValue) {
    const { data } = await api.get(`/cest?desc=${inputValue}`);

    return data;
  }

  async function filtrarConsultaCst(inputValue) {
    const { data } = await api.get(`/cst?desc=${inputValue}`);

    return data;
  }

  async function filtrarConsultaFornecedores(inputValue) {
    const { data } = await api.get(`/fornecedor/async?desc=${inputValue}`);

    return data;
  }

  const optionsConsultaNcm = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsultaNcm(inputValue));
      }, 500);
    });

  const optionsConsultaCest = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsultaCest(inputValue));
      }, 500);
    });

  const optionsConsultaCst = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsultaCst(inputValue));
      }, 500);
    });

  const optionsConsultaFornecedores = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.any = setTimeout(() => {
        resolve(filtrarConsultaFornecedores(inputValue));
      }, 500);
    });

  async function listaCenraiosUf(uf) {
    const { data: cenariosTri } = await api.get(
      `/cenarios/tributacao?produto=${idProduto}&uf=${uf}`
    );
    setCenarios(cenariosTri);
  }

  async function handleSubmitTributacao(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        if (f !== 'id_cest' && f !== 'id_uf') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    tributacaoRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        showLoader();
        e.id_produto = idProduto;
        const { data: tributacaoProduto } = await api.post(
          '/tributacao/produto',
          e
        );
        setIdProdutoTributacao(tributacaoProduto?.id);
        toast('Dados salvos com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
  }

  async function getDadosTributacaoProduto() {
    try {
      showLoader();
      const { data } = await api.get(`/produto/tributacao/${idProduto}`);
      if (data) {
        setIdProdutoTributacao(data?.id);
        Object.keys(data).forEach((e) => {
          tributacaoRef.current.setFieldValue(e, data[e]);
        });
      }
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function getDadosGerais() {
    const { data: dadosProd } = await api.get('/dados/produto');
    setDadosGerais(dadosProd);
  }

  useEffect(() => {
    (async () => {
      if (modal?.open) {
        try {
          showLoader();
          await getDadosGerais();

          if (modal?.type === 'edit') {
            if (modal?.objEdit) {
              const { objEdit, fornecedores } = modal;
              await getSub(objEdit?.id_categoria, 'sub_categoria');
              // await getSub(objEdit?.id_tipo, 'sub_tipo');
              setIdProduto(objEdit?.id);
              Object.keys(objEdit).forEach((e) => {
                formRef.current.setFieldValue(e, objEdit[e]);
              });
              if (fornecedores.length) {
                formRef.current.setFieldValue('fornecedores', fornecedores);
              }
            }
          }
        } catch (err) {
          toast(err.mensagem);
        } finally {
          closeLoader();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  async function handleDeleteTributacao(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir essa tributação?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          showLoader();
          api
            .delete(`/tributacao/produto/uf/deletar/${id}`)
            .then(() => {
              listaCenraiosUf(selectedUf);
              toast('Dados excluidos com sucesso!', { type: 'success' });
              closeLoader();
            })
            .catch((error) => {
              toast(error.mensagem);
              closeLoader();
            });
        }
      });
  }

  return (
    <>
      <Modal
        isOpen={modal?.open}
        toggle={() => {
          toggleModal();
          setSubCategorias([]);
          setSubTipos([]);
          setIdProduto(null);
          toggle('1');
          setIdProdutoTributacao(null);
          setIdProduto(null);
          setCenarios([]);
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
            setSubCategorias([]);
            setSubTipos([]);
            setIdProduto(null);
            toggle('1');
            setIdProdutoTributacao(null);
            setIdProduto(null);
            setCenarios([]);
          }}
        >
          Cadastro de produto
        </ModalHeader>
        <Nav className="p-3" tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : null}
              onClick={() => {
                toggle('1');
              }}
            >
              Dados Gerais
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : null}
              onClick={() => {
                if (idProduto) {
                  getVariacoesProduto();
                  toggle('2');
                } else {
                  toast('É necessário cadastrar os dados gerais antes!', {
                    type: 'error',
                  });
                }
              }}
            >
              Variações
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : null}
              onClick={() => {
                if (idProduto) {
                  setSelectedUf(null);
                  getUfsFiliais();
                  toggle('3');
                  getDadosTributacaoProduto();
                } else {
                  toast('É necessário cadastrar os dados gerais antes!', {
                    type: 'error',
                  });
                }
              }}
            >
              Tributação
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              onSubmit={(d) => {
                handleSubmitDadosGerais(d);
              }}
              ref={formRef}
            >
              <ModalBody>
                <Row>
                  <Col>
                    <Input
                      label="Código do produto"
                      className="form-control"
                      placeholder="Nome"
                      name="codigo"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      label="Descrição do produto"
                      className="form-control"
                      placeholder="descricao"
                      name="descricao"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <Select
                      name="id_fabricante"
                      label="Fabricante"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.fabricantes,
                      ]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Fabricante');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                  <Col lg={5}>
                    <Select
                      name="id_marca"
                      label="Marca"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.marcas,
                      ]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Marca');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <Select
                      name="id_categoria"
                      label="Categoria"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.categorias,
                      ]}
                      onChange={(e) => {
                        if (e?.target?.value) {
                          getSub(e?.target?.value, 'sub_categoria');
                        } else {
                          setSubCategorias([]);
                        }
                      }}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Categoria');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                  <Col lg={5}>
                    <Select
                      name="id_subcategoria"
                      label="Sub-categoria"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...subCategorias,
                      ]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd(
                          'Sub-categoria',
                          dadosGerais?.categorias,
                          formRef?.current?.getFieldValue('id_categoria')
                        );
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg={5}>
                    <Select
                      name="id_tipo"
                      label="Tipo"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.tipos,
                      ]}
                      onChange={(e) => {
                        if (e?.target?.value) {
                          getSub(e?.target?.value, 'sub_tipo');
                        } else {
                          setSubTipos([]);
                        }
                      }}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Tipo');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                  <Col lg={5}>
                    <Select
                      name="id_subtipo"
                      label="Sub-tipo"
                      className="form-control"
                      data={[{ value: '', label: 'Selecione' }, ...subTipos]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd(
                          'Sub-tipo',
                          dadosGerais?.tipos,
                          formRef?.current?.getFieldValue('id_tipo')
                        );
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                </Row> */}
                <Row>
                  <Col lg={5}>
                    <Select
                      name="id_secao"
                      label="Seção"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.secoes,
                      ]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Seção');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                  <Col lg={5}>
                    <Select
                      name="id_unidade_medida"
                      label="Unidade de medida"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.unidadesMedida,
                      ]}
                    />
                  </Col>
                  <Col lg={1} style={{ marginTop: '30px' }}>
                    <Button
                      onClick={() => {
                        toggleModalAdd('Unidade de medida');
                      }}
                      className="btn"
                      color="success"
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReactSelectAsync
                      isMulti
                      label="Fornecedores*"
                      name="fornecedores"
                      loadOptions={optionsConsultaFornecedores}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </ModalFooter>
            </Form>
          </TabPane>
          <TabPane tabId="2">
            <div className="p-3">
              <Button
                color="primary"
                className="btn btn-primary"
                onClick={() => toggleModalVariacao('add')}
                block
              >
                Adicionar
              </Button>
            </div>
            <hr />
            <Row>
              <Col>
                {produtoVariacoes.length ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th className="text-center">Status</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {produtoVariacoes?.map((v) => (
                        <tr key={v?.id}>
                          <td data-label="Descrição">
                            {v.cor} - {v.unidade_tamano}
                            {v.tamanho}
                          </td>
                          <td data-label="Valor">
                            {Number(v.preco_venda).toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                          </td>
                          <td data-label="Status" className="text-center">
                            {v.status}
                          </td>
                          <td data-label="Action">
                            <div className="d-flex justify-content-lg-end justify-content-sm-between">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  color="link"
                                  className="text-decoration-none padding-0-5"
                                >
                                  <FiSettings className="mr-2" />{' '}
                                  <FaChevronDown />
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <DropdownItem
                                    onClick={() =>
                                      toggleModalVariacao('edit', v)
                                    }
                                  >
                                    <FiEdit className="mr-1" />
                                    Editar
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      handleAtivarInativarVariacao(v?.id);
                                    }}
                                  >
                                    <FiDisc className="mr-1" />
                                    Inativar / Ativar
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Col>
                    <p className="p-3">
                      Sem registros encontrados! Adicione as variações do
                      produto.
                    </p>
                  </Col>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Form
              onSubmit={(d) => {
                handleSubmitTributacao(d);
              }}
              ref={tributacaoRef}
            >
              <ModalBody>
                <p>Tributação do produto</p>
                <hr />
                <Row>
                  <Col lg={12}>
                    <ReactSelectAsync
                      label="NCM*"
                      name="id_ncm"
                      loadOptions={optionsConsultaNcm}
                    />
                  </Col>
                  {/* <Col lg={2}>
                    <Select
                      name="excecao"
                      label="Exceção"
                      className="form-control"
                      data={[
                        { value: '', label: 'Selecione' },
                        ...dadosGerais?.fabricantes,
                      ]}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col lg={12}>
                    <ReactSelectAsync
                      label="CEST"
                      name="id_cest"
                      loadOptions={optionsConsultaCest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReactSelectAsync
                      label="CST (origem)*"
                      name="id_cst_origem"
                      loadOptions={optionsConsultaCst}
                    />
                  </Col>
                </Row>
                <Row hidden={!idProdutoTributacao}>
                  <p>Cenários por UF</p>
                  <hr />
                  <Row>
                    <Col lg={2}>
                      <Select
                        name="id_uf"
                        label="UF*"
                        className="form-control"
                        data={ufs}
                        onChange={(e) => {
                          listaCenraiosUf(e?.target?.value);
                          setSelectedUf(e?.target?.value);
                        }}
                      />
                    </Col>
                  </Row>
                  {selectedUf && (
                    <Col>
                      {cenarios.length ? (
                        <Table>
                          <thead>
                            <tr>
                              <th>Cenário</th>
                              <th>Tributação</th>
                              <th className="text-end">Ação</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cenarios?.map((v) => (
                              <tr key={v?.value}>
                                <td data-label="Descrição">{v.label}</td>
                                <td data-label="Tributação">
                                  {v?.produto_tributacao_uf?.TributacaoUf
                                    ?.descricao
                                    ? v?.produto_tributacao_uf?.TributacaoUf
                                        ?.descricao
                                    : 'Sem tributação vinculada, vincule nas ações'}
                                </td>
                                <td data-label="Ação">
                                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        color="link"
                                        className="text-decoration-none padding-0-5"
                                      >
                                        <FiSettings className="mr-2" />{' '}
                                        <FaChevronDown />
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <DropdownItem
                                          onClick={() =>
                                            toggleModalTributacoes(v?.value)
                                          }
                                        >
                                          <FiEdit className="mr-1" />
                                          Vincular tributação
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            handleDeleteTributacao(
                                              v?.produto_tributacao_uf?.id
                                            );
                                          }}
                                          hidden={!v?.produto_tributacao_uf}
                                        >
                                          <FaTrash className="mr-1" />
                                          Remover tributação
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <Col>
                          <p className="p-3">Sem registros encontrados!</p>
                        </Col>
                      )}
                    </Col>
                  )}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </ModalFooter>
            </Form>
          </TabPane>
        </TabContent>
      </Modal>

      <NovaVariacao
        modal={modalVariacao}
        toggleModal={toggleModalVariacao}
        idProduto={idProduto}
        refresh={getVariacoesProduto}
      />

      <TributacoesUf
        modal={modalTributacoesUf}
        toggleModal={toggleModalTributacoes}
        idProdutoTributacao={idProdutoTributacao}
        refresh={listaCenraiosUf}
        uf={selectedUf}
        idProduto={idProduto}
      />

      <DadosGeraisAdd
        modal={modalAdd}
        toggleModal={toggleModalAdd}
        refresh={getDadosGerais}
        getSub={getSub}
      />
    </>
  );
}

export default NovoProduto;
