/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Row, Table, Modal, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { AiOutlineShopping, AiOutlineDollar, AiFillTag } from 'react-icons/ai';
import {
  FaCashRegister,
  FaUserPlus,
  FaPercent,
  FaCheckCircle,
  FaListAlt,
  FaFileInvoiceDollar,
  FaSearchDollar,
  FaTrash,
  FaTag,
  FaHandHolding,
  FaUserTag,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import BarcodeReader from 'react-barcode-reader';
import { useAuth } from '../../../contexts/auth';

import DescontosModal from './DescontosModal';
import IdentificarClienteModal from './IdentificarClienteModal';
import ListarOrcamento from './ListarOrcamentos';
import ListarProdutos from './ListarProduto';
import CriarCliente from './CriarClienteModal';

import apiPdv from '../../../services/apiPDV';
import useValidator from '../../../hooks/useValidator';
import Tooltip from '../../../components/toolTip';
import DataHora from './dataHora';
import DropDownFooter from './dropDownFooter';

const defaultProduto = {
  nome: '',
  codigo: '',
  valorDe: 0,
  valorPor: 0,
  desconto: 0,
  quantidade: 0,
};

const defaultVenda = {
  itens: [],
  total: 0,
  subTotal: 0,
  quantidade: 0,
};

function Orcamento() {
  const { tipoPdv, idFilial } = useAuth();
  const { showLoader, showError, closeLoader, toast } = useValidator();
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const [produto, setProduto] = useState(defaultProduto);

  const [objCaixa, setObjCaixa] = useState({
    id_filial: idFilial,
  });

  const [objVenda, setObjVenda] = useState(defaultVenda);

  const toggleModal = () => setModal((old) => !old);

  const handleRemove = async (prod, index) => {
    try {
      showLoader();
      const { data } = await apiPdv.post('/grid', {
        id_filial: idFilial,
        itens: objVenda?.itens,
        codigo: prod.codigo,
        index,
      });
      const reduce = data.itens.reduce(
        (a, b) => ({
          quantidade: Number(a.quantidade) + (Number(b.quantidade) || 1),
          subTotal:
            a.subTotal +
            (Number(b?.valorDe) || 0) * (Number(b.quantidade) || 1),
        }),
        { quantidade: 0, subTotal: 0 }
      );

      setObjVenda({
        itens: data.itens,
        ...reduce,
      });
      setProduto(defaultProduto);
      if (!data.itens.length) {
        setObjCaixa((old) => ({
          ...old,
          desconto: null,
        }));
      }
      localStorage.setItem(
        'orcamento',
        JSON.stringify({
          itens: data.itens,
          ...reduce,
        })
      );
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    } finally {
      closeLoader();
    }
  };

  const handleScan = async (e, qtd) => {
    console.log('Scanner ', e);
    if (!e) {
      return;
    }
    try {
      showLoader();
      const { data } = await apiPdv.post(`/grid/${String(e).trim()}`, {
        id_filial: idFilial,
        itens: objVenda?.itens,
        qtd,
      });

      if (data?.msg) {
        return Swal.fire({
          title: data.msg,
          text: data.nomeProduto,
          input: 'number',
          inputValue: 1,
          inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Quantidade',
            min: 1,
            max: data.estoque,
          },
          confirmButtonText: 'Inserir',
          allowOutsideClick: true,
          backdrop: true,
          didOpen: () => {
            setTimeout(() => {
              document.querySelector('.swal2-input').focus();
            }, 300);
            setTimeout(() => {
              document.querySelector('.swal2-input').focus();
            }, 400);
          },
        }).then((res) => {
          if (res.isConfirmed && res.value && Number(res.value) > 0) {
            handleScan(e, res.value);
          }
        });
      }
      if (!data?.prod) {
        closeLoader();
        return toast('Produto não encontrado');
      }

      setProduto(data.prod);

      const reduce = data.itens.reduce(
        (a, b) => ({
          quantidade: Number(a.quantidade) + (Number(b.quantidade) || 1),
          subTotal:
            a.subTotal +
            (Number(b?.valorDe) || 0) * (Number(b.quantidade) || 1),
        }),
        { quantidade: 0, subTotal: 0 }
      );

      setObjVenda({
        itens: data.itens,
        ...reduce,
      });

      localStorage.setItem(
        'orcamento',
        JSON.stringify({
          itens: data.itens,
          ...reduce,
        })
      );
      closeLoader();
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
      closeLoader();
    }
  };

  const handleRequestNome = async () =>
    Swal.fire({
      title: 'Finalizar orçamento',
      html:
        `<input id="swal-input1" class="swal2-input placeholder-dark" autocomplete="off" autocorrect="off" spellcheck="false" value="${
          objCaixa?.nome || objCaixa?.cliente?.nome || ''
        }"  placeholder="Nome do cliente">` +
        '<input id="swal-input2" class="swal2-input placeholder-dark" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  placeholder="Cod. operador *" type="number">',
      confirmButtonText: 'Prosseguir',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      allowOutsideClick: true,
      preConfirm: () => {
        const operador = document.getElementById('swal-input2').value;
        if (!operador) {
          document.getElementById('swal-input2').focus();
          return Swal.showValidationMessage('Informe o operador');
        }
      },
    }).then((e) => {
      const nome = document.getElementById('swal-input1').value;
      const operador = document.getElementById('swal-input2').value;

      return {
        ...e,
        nome,
        operador,
      };
    });

  const limparOrcamento = () => {
    localStorage.removeItem('id_orcamento');
    localStorage.removeItem('orcamento');
    setProduto(defaultProduto);
    setObjVenda(defaultVenda);
    setObjCaixa({
      id_filial: objCaixa.id_filial,
    });
    document.querySelector('[name="codigo_barras"]').value = '';
  };

  const handleCancelarOrcamento = async () => {
    if (modal) {
      toggleModal();
      return;
    }
    try {
      const temOrcamento = JSON.parse(localStorage.orcamento || '{}');
      if (temOrcamento?.itens?.length) {
        Swal.fire({
          title: 'Cancelar orçamento ?',
          text: 'Cofirmar o cancelamento deste orçamento ?',
          showCancelButton: true,
          confirmButtonText: 'Confirmar cancelar',
          cancelButtonText: 'Não cancelar',
          allowOutsideClick: true,
        }).then((result) => {
          if (result.isConfirmed) {
            limparOrcamento();
          }
        });
      }
    } catch (error) {
      limparOrcamento();
    }
  };

  const handleFinalizar = async () => {
    if (!objVenda.itens.length) {
      return;
    }
    const result = await handleRequestNome();
    if (!result.isConfirmed) {
      return;
    }

    try {
      showLoader();
      const { data } = await apiPdv.post('/orcamento', {
        nome: String(result.nome || objCaixa?.cliente?.nome || '').trim(),
        operador: String(result.operador).trim(),
        id_orcamento: objCaixa.id_orcamento,
        produtos: objVenda.itens,
        id_filial: objCaixa.id_filial,
        id_cliente: objCaixa?.cliente?.id,
        id_condicao_pagamento: objCaixa?.desconto?.id_condicao_pagamento,
      });
      limparOrcamento();
      const text = data.nome || `Número ${data.numero}`;
      Swal.fire({
        title: 'Orçamento finalizado',
        html: `${text} <br /> Qtd. itens: ${
          data.qtde_itens
        } - Valor total: ${Number(data.valor_total).toLocaleString('pt-BR', {
          currency: 'BRL',
          type: 'currency',
        })}`,
        allowOutsideClick: true,
        showConfirmButton: true,
      });
    } catch (error) {
      showError({
        mensagem: error.mensagem,
        titulo: error.titulo,
      });
    }
  };

  const scrollToBottom = () => {
    const table = document.querySelector('.table-responsive');
    if (table) {
      table.scrollTo({
        top: document.querySelector('.rwd-table.table')?.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const renderModalComponent = useCallback(() => {
    async function reabrirOrcamento(idOrcamento) {
      try {
        showLoader();
        const { data } = await apiPdv.get(
          `/orcamento/${idOrcamento}?reabrir=${true}`
        );
        localStorage.setItem('id_orcamento', idOrcamento);
        localStorage.setItem('orcamento', JSON.stringify(data.grid));
        setObjCaixa(data.orcamento);
        setObjVenda(data.grid);
        closeLoader();
      } catch (err) {
        closeLoader();
        toast(err.mensagem);
      }
    }

    const identificarCliente = (e) => {
      setObjCaixa((old) => ({
        ...old,
        cliente: e,
      }));
    };

    const setDesconto = (e) => {
      setObjCaixa((old) => ({
        ...old,
        desconto: e,
      }));
    };

    switch (modalProps.type) {
      case 'DE':
        return (
          <DescontosModal
            modalProps={modalProps}
            toggleModal={toggleModal}
            open={setDesconto}
          />
        );
      case 'IC':
        return (
          <IdentificarClienteModal
            modalProps={modalProps}
            toggleModal={toggleModal}
            open={identificarCliente}
            criar={() => {
              setModalProps({
                title: 'Cadastro de cliente',
                type: 'CC',
              });
            }}
          />
        );
      case 'CC':
        return (
          <CriarCliente
            modalProps={modalProps}
            toggleModal={toggleModal}
            open={identificarCliente}
            voltar={() => {
              setModalProps({
                title: 'Identificar cliente',
                type: 'IC',
              });
            }}
          />
        );
      case 'RO':
        return (
          <ListarOrcamento
            modalProps={modalProps}
            toggleModal={toggleModal}
            open={reabrirOrcamento}
          />
        );
      case 'PP':
        return (
          <ListarProdutos
            modalProps={modalProps}
            toggleModal={toggleModal}
            open={handleScan}
          />
        );
      default:
        break;
    }
  }, [modalProps]);

  useEffect(() => {
    async function handleVerifyOrcamento() {
      const idOrcamento = localStorage.id_orcamento;

      if (!idOrcamento) {
        try {
          const temOrcamento = JSON.parse(localStorage.orcamento || '{}');
          if (temOrcamento?.itens?.length) {
            setObjVenda(temOrcamento);
          }
        } catch (err) {
          localStorage.removeItem('orcamento');
          console.log(err);
        }
        return;
      }
      try {
        showLoader();
        const { data } = await apiPdv.get(`/orcamento/${idOrcamento}`);
        setObjCaixa(data.orcamento);
        setObjVenda(data.grid);
        closeLoader();
      } catch (err) {
        localStorage.removeItem('id_orcamento');
        closeLoader();
      }
    }
    if (tipoPdv !== 'O') {
      history.push('/erp');
    } else {
      handleVerifyOrcamento();
    }
  }, [tipoPdv, history]);

  const handlePesquisar = () => {
    toggleModal();
    setModalProps({
      title: 'Pesquisar produto',
      type: 'PP',
    });
  };

  const handleAbrirDescontos = () => {
    toggleModal();
    setModalProps({
      title: 'Descontos',
      type: 'DE',
    });
  };
  const handleIdentificar = () => {
    toggleModal();
    setModalProps({
      title: 'Identificar cliente',
      type: 'IC',
    });
  };

  const handleAbrirOrcamento = () => {
    try {
      const temOrcamento = JSON.parse(localStorage.orcamento || '{}');
      if (temOrcamento?.itens?.length) {
        return;
      }
      toggleModal();
      setModalProps({
        title: 'Reabrir orçamento',
        type: 'RO',
      });
    } catch (err) {
      limparOrcamento();
      toggleModal();
      setModalProps({
        title: 'Reabrir orçamento',
        type: 'RO',
      });
    }
  };

  useEffect(() => {
    const keys = {
      F2: handleIdentificar,
      F3: handleAbrirDescontos,
      F8: handlePesquisar,
      F9: handleAbrirOrcamento,
      F10: handleFinalizar,
      Escape: handleCancelarOrcamento,
    };
    const handler = (event) => {
      if (!['F11', 'F12', 'F5'].includes(event.key)) {
        const func = keys[event.key];
        if (func) {
          event.preventDefault();
          func();
        }
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [modal, objCaixa, objVenda]);

  useEffect(scrollToBottom, [objVenda?.itens]);

  return (
    <>
      {/* Componente q faz o leitor de scanner iniciar e já sai lendo os codigos */}
      <BarcodeReader onScan={handleScan} />
      <Card className="px-4 pt-4 pdv-card">
        <Row>
          <Col lg={8} className="mb-2">
            <Card className="mb-0">
              <div className="p-3">
                <form
                  className="mt-1"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const myFormData = new FormData(e.target);
                    const formDataObj = Object.fromEntries(
                      myFormData.entries()
                    );
                    handleScan(
                      formDataObj.codigo_barras,
                      formDataObj.quantidade
                    );
                    e.currentTarget.reset();
                  }}
                >
                  <input
                    name="codigo_barras"
                    className="text-center h2 w-100"
                    style={{
                      background: 'transparent',
                      boxSshadow: 'none',
                      border: 'none',
                    }}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={
                      produto?.nome || 'Digite ou escaneie o produto'
                    }
                  />
                </form>
                <hr />

                <div className="d-flex-wrap justify-content-between pt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="inner-pdv-text mr-3">QTD.</h5>
                    <h3 className="inner-pdv-qtd mb-1">
                      {produto?.quantidade}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="inner-pdv-text mr-3">DESC.</h5>
                    <h3 className="inner-pdv-qtd mb-1">
                      {produto?.desconto?.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                      })}
                      %
                    </h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="inner-pdv-text mr-3">DE</h5>
                    <h3 className="inner-pdv-qtd mb-1">
                      {Number(produto?.valorDe)?.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                        minimumFractionDigits: 2,
                      })}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="inner-pdv-text mr-3">POR</h5>
                    <h3 className="inner-pdv-qtd mb-1">
                      {Number(produto?.valorPor)?.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                        minimumFractionDigits: 2,
                      })}
                    </h3>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col className="mb-2">
                <Card className="p-3 icon-pdv mb-0">
                  <h1 className="mt-2">
                    <AiOutlineShopping />
                  </h1>
                  <h6>ITENS</h6>
                  <h3 className="mb-2">{objVenda?.quantidade}</h3>
                </Card>
              </Col>
              <Col className="mb-2">
                <Card className="p-3 icon-pdv mb-0">
                  <h1 className="mt-2">
                    <AiOutlineDollar />
                  </h1>
                  <h6>SUB TOTAL</h6>
                  <h3>
                    {Number(objVenda?.subTotal).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </h3>
                </Card>
              </Col>
              <Col lg={5} className="mb-2">
                <Card className="p-3 icon-pdv total mb-0">
                  <h1 className="total-txt mt-1">
                    <AiFillTag />
                  </h1>

                  <h6 className="total-txt">TOTAL</h6>
                  <h2 className="total-txt">
                    {Number(
                      objVenda?.itens?.reduce((a, b) => {
                        if (b.id_promocao) {
                          return a + Number(b.valorPor) * b.quantidade;
                        }

                        if (
                          Number(
                            objCaixa?.desconto?.percentual_desconto_maximo
                          ) > 0
                        ) {
                          return (
                            a +
                            (1 -
                              Number(
                                objCaixa?.desconto?.percentual_desconto_maximo
                              ) /
                                100) *
                              Number(b.valorDe) *
                              b.quantidade
                          );
                        }

                        return a + b.valorDe * b.quantidade;
                      }, 0)
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </h2>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="pt-3">
          <Col>
            <Card className="p-3 vh-67 mb-3">
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">SEQ</th>
                    <th className="text-center">CÓDIGO</th>
                    <th>DESCRIÇÃO</th>
                    <th className="text-center">QUANTIDADE</th>
                    <th className="text-center">VALOR UNITÁRIO</th>
                    <th className="text-center">% DESCONTO</th>
                    <th className="text-center">VALOR TOTAL</th>
                    <th className="text-center">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {objVenda?.itens?.map((m, i) => {
                    const percDesc = Number(
                      objCaixa?.desconto?.percentual_desconto_maximo || 0
                    );

                    return (
                      <tr key={i}>
                        <td
                          data-label="SEQ"
                          className="text-center text-table-pdv "
                        >
                          {i + 1}
                        </td>
                        <td
                          data-label="CÓDIGO"
                          className="text-center text-table-pdv "
                        >
                          {m?.codigo}
                        </td>
                        <td data-label="DESCRIÇÃO" className="text-table-pdv">
                          {m?.nome}
                        </td>
                        <td
                          data-label="QUANTIDADE"
                          className="text-center text-table-pdv "
                        >
                          {m?.quantidade}
                        </td>
                        <td
                          data-label="VALOR UNITÁRIO"
                          className="text-center text-table-pdv "
                        >
                          {Number(m?.valorDe).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td
                          data-label="% DESCONTO"
                          className="text-center text-table-pdv "
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {!m.id_promocao && Number(percDesc) > 0 && (
                              <>
                                <Tooltip
                                  text={`Desconto ${objCaixa?.desconto?.CondicaoPagamento?.descricao}`}
                                >
                                  <FaHandHolding
                                    size={28}
                                    className="mr-2 mt--2 color-warning"
                                  />
                                </Tooltip>
                              </>
                            )}
                            {m.id_promocao && Number(m.desconto) > 0 && (
                              <>
                                <Tooltip text="Desconto kit">
                                  <FaTag
                                    size={20}
                                    className="mr-2 color-primary"
                                  />
                                </Tooltip>
                              </>
                            )}
                            {Number(m?.desconto || percDesc).toFixed(2)}%
                          </div>
                        </td>
                        <td
                          data-label="VALOR TOTAL"
                          className="text-center text-table-pdv "
                        >
                          {m.id_promocao
                            ? Number(
                                Number(m?.valorPor) * Number(m?.quantidade)
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })
                            : Number(
                                Number(m?.valorDe) *
                                  (percDesc ? 1 - percDesc / 100 : 1) *
                                  Number(m?.quantidade)
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                        </td>
                        <td
                          data-label="Remover"
                          className="text-center text-table-pdv "
                        >
                          <Button
                            color="light-danger"
                            onClick={() => handleRemove(m, i)}
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <button
              type="button"
              className="btn-pdv white"
              onClick={handleIdentificar}
            >
              <FaUserPlus size={20} />
              <h6 className="text-buttons">F2 - Identificar cliente</h6>
            </button>
          </Col>
          <Col className="mb-2">
            <button
              type="button"
              className="btn-pdv white"
              onClick={handleAbrirDescontos}
            >
              <FaPercent size={17} />
              <h6 className="text-buttons">F3 - Descontos</h6>
            </button>
          </Col>
          <Col className="mb-2">
            <button
              type="button"
              className="btn-pdv white"
              onClick={handlePesquisar}
            >
              <FaSearchDollar size={20} />
              <h6 className="text-buttons">F8 - Pesquisar produto</h6>
            </button>
          </Col>
          <Col className="mb-2">
            <button
              type="button"
              className="btn-pdv white"
              disabled={objVenda?.itens?.length}
              onClick={handleAbrirOrcamento}
            >
              <FaFileInvoiceDollar size={20} />
              <h6 className="text-buttons">F9 - Reabrir orçamento</h6>
            </button>
          </Col>
          <Col className="mb-2">
            <button
              type="button"
              className="btn-pdv success"
              onClick={handleFinalizar}
            >
              <FaCheckCircle size={20} />
              <h6 className="text-buttons">F10 - Finalizar orçamento</h6>
            </button>
          </Col>
        </Row>
      </Card>
      <div className="footer footer-pdv">
        <div className="d-flex justify-between">
          <div className="w-100 d-flex align-items-center">
            <div className="d-flex align-items-center mr-3">
              <FaCashRegister
                size={15}
                className="button-pdv mr-2 mobile-hidden"
              />
              <p className="text-left text-footer">Caixa orçamento</p>
            </div>

            <div className="d-flex align-items-center mr-3 ml-3">
              <FaListAlt size={18} className="button-pdv mr-2 mobile-hidden" />
              <p className="text-left text-footer">
                Orçamento{' '}
                {objCaixa?.numero_orcamento
                  ? `${objCaixa?.numero_orcamento} - ${objCaixa?.serie_orcamento}`
                  : '--'}
              </p>
            </div>

            <div className="d-flex align-items-center ml-3">
              <FaUserTag size={18} className="button-pdv mr-2 mobile-hidden" />
              <p className="text-left text-footer text-overflow-hidden">
                Cliente {objCaixa?.cliente?.nome || '--'}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <DataHora />
            <DropDownFooter />
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        {renderModalComponent()}
      </Modal>
    </>
  );
}

export default Orcamento;
