const toOptions = (obj) =>
  Object.entries(obj).map(([value, label]) => ({
    label,
    value,
  }));

const transacao = {
  succeeded: 'Pago',
  pending: 'Pendente',
  failed: 'Falhou',
  canceled: 'Cancelado',
};

const bandeiras = {
  MasterCard: 'maestro.svg',
  'American Express': 'american-express.svg',
  Aura: 'aura.svg',
  Cabal: 'cabal.svg',
  'Diners Club': 'diners-club.svg',
  Discover: 'discover.svg',
  Elo: 'elo.svg',
  Hipercard: 'hipercard.svg',
  Hiper: 'hiper.svg',
  JCB: 'jcb.svg',
  Maestro: 'mastercard.svg',
  pix: 'pix.png',
  boleto: 'boleto.svg',
  'Visa Electron': 'visa-electron.svg',
  Visa: 'visa.svg',
  Banescard: 'banescard.svg',
};

const statusHistorico = {
  created: 'Criado',
  authorization: 'Autorização',
  paid: 'Pago',
  proposta: 'Proposto',
  void: 'Atualização',
};

const transacaoColors = {
  succeeded: 'badge-success',
  pending: 'badge-warning',
  failed: 'badge bg-light-danger',
  canceled: 'badge-danger',
};

const tipoVenda = {
  credit: 'Crédito',
  debit: 'Débito',
  pix: 'Pix',
  boleto: 'Boleto',
};

const transferencia = {
  succeeded: 'Sucesso',
  failed: 'falhou',
};

const transacaoOptions = toOptions(transacao);
export {
  toOptions,
  transacao,
  transacaoOptions,
  transacaoColors,
  statusHistorico,
  tipoVenda,
  bandeiras,
  transferencia,
};
