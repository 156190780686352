import React, { useRef, useEffect } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';

export default function CampoMultiplo({ name, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        // ref.select.state.value = value;
        // ref.select.updater.enqueueForceUpdate(ref);
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Select
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        className={error && 'error'}
        defaultOptions
        placeholder="Digite para pesquisar"
        noOptionsMessage={() => 'Sem registros'}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
