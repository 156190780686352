/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useRef, useState } from 'react';
import { FiClipboard, FiFilter, FiLink, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  CheckBox,
  Input,
  NumberFormat,
  ReactSelectAsync,
  Select,
} from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';
import {
  tipoVenda,
  transacao,
  transacaoColors,
  bandeiras,
  transacaoOptions,
} from '../../../utils/status';
import { useAuth } from '../../../contexts/auth';

function ListarLinkPagamento() {
  const { isAdmin } = useAuth();
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const [modalTransacoes, setModalTransacoes] = useState(false);
  const [configCartao, setConfigCartao] = useState({
    show: true,
  });
  const filtrosRef = useRef();
  const formRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const toggleModal = () => {
    setModal((old) => !old);
  };

  const toggleModalTransacoes = () => {
    setModalTransacoes((old) => !old);
  };

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/options/vendedores?value=${inputValue}`);

    return data;
  }

  const getOptions = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(
        `/link-pagamento?page=${page || 1}&${query}`
      );
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  const handleSubmit = async (body) => {
    showLoader();
    try {
      const { data } = await api.post('/link-pagamento', body);
      toggleModal();
      closeLoader();
      loadMore(1);
      window.open(`${window.location.origin}/pagamento/${btoa(data.id)}`);
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
      formRef.current.setErrors(err?.response?.data?.errors || {});
    }
  };

  async function detalhesLink(tr) {
    showLoader();
    try {
      const { data } = await api.get(`/link-pagamento/${tr.id}/transacoes`);
      setModalTransacoes({
        ...tr,
        items: data.items,
      });
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/link-pagamento');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  useEffect(() => {
    if (modal) {
      setConfigCartao({ show: true });
    }
  }, [modal]);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          {!isAdmin ? (
            <Button color="primary" onClick={toggleModal}>
              Novo
            </Button>
          ) : (
            <div className="pl-3 pr-5" />
          )}
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                {isAdmin && (
                  <Col>
                    <ReactSelectAsync
                      placeholder="Vendedor"
                      name="id_vendedor_zoop"
                      defaultOptions
                      isClearable
                      loadOptions={getOptions}
                      onChange={filtrarPesquisa}
                    />
                  </Col>
                )}
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      { value: '', label: 'Status' },
                      { value: 'pending', label: 'Pendente' },
                      { value: 'succeeded', label: 'Pago' },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              {isAdmin && <th>Vendedor</th>}
              <th>Valor</th>
              <th>Descrição</th>
              <th>Criado em</th>
              <th>Expirado em</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                {isAdmin && (
                  <td data-label="Vendedor">{m?.VendedorZoop?.Pessoa?.nome}</td>
                )}
                <td data-label="Valor">
                  <div>
                    {Number(m.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: m.moeda || 'BRL',
                    })}
                  </div>
                </td>
                <td data-label="Descrição">{m?.descricao}</td>
                <td data-label="Criado em">
                  {new Date(m.created_at || m.createdAt)
                    .toLocaleString('pt-BR')
                    .substring(0, 17)
                    .replace(',', '')}
                </td>
                <td data-label="Expira em">
                  {m.data_expiracao &&
                    new Date(m.data_expiracao)
                      .toLocaleString('pt-BR')
                      .substring(0, 17)
                      .replace(',', '')}
                </td>
                <td data-label="Status">{transacao[m.status]}</td>
                <td data-label="Ações">
                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem onClick={() => detalhesLink(m)}>
                          <FiClipboard className="mr-1" />
                          Ver transações
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/pagamento/${btoa(
                                m.id
                              )}`
                            )
                          }
                        >
                          <FiLink className="mr-1" />
                          Link
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Gerar link de pagamento</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col xl={6} lg={6}>
                <NumberFormat
                  name="valor"
                  label="Valor *"
                  placeholder="R$ "
                  className="form-control"
                  prefix="$"
                />
              </Col>
              <Col xl={6} lg={6}>
                <Input
                  name="data_expiracao"
                  label="Expira em *"
                  type="datetime-local"
                  min={new Date().toISOString()}
                  className="form-control"
                />
              </Col>
              <Col lg={4}>
                <Select
                  name="moeda"
                  label="Moeda"
                  className="form-control"
                  data={[
                    {
                      value: 'BRL',
                      label: 'BRL',
                    },
                    {
                      value: 'USD',
                      label: 'USD',
                    },
                  ]}
                />
              </Col>
              <Col xl={8}>
                <Input
                  name="descricao"
                  label="Descrição"
                  className="form-control"
                />
              </Col>
              <Col lg={4}>
                <CheckBox name="aceita_pix" label="Aceita pix" defaultChecked />
              </Col>
              <Col lg={4}>
                <CheckBox
                  name="aceita_boleto"
                  label="Aceita boleto"
                  defaultChecked
                />
              </Col>
              <Col lg={4}>
                <CheckBox
                  name="aceita_cartao"
                  label="Aceita cartão"
                  defaultChecked
                  onChange={(e) =>
                    setConfigCartao({
                      show: e.target.checked,
                    })
                  }
                />
              </Col>
              {configCartao.show && (
                <>
                  <Col lg={4}>
                    <Select
                      name="parcelado_ate"
                      label="Parcelado até"
                      className="form-control"
                      data={Array.from(Array(12).keys()).map((m) => ({
                        value: m + 1,
                        label: `${m + 1}x`,
                      }))}
                    />
                  </Col>
                  <Col lg={4}>
                    <Select
                      name="juros_parcela_inicio"
                      label="Juros a partir da parcela"
                      className="form-control"
                      data={[{ value: '0', label: 'Selecione' }].concat(
                        Array.from(Array(11).keys()).map((m) => ({
                          value: m + 2,
                          label: `${m + 2}x`,
                        }))
                      )}
                      onChange={(e) =>
                        setConfigCartao((old) => ({
                          ...old,
                          juros_parcela_inicio: e.target.value,
                        }))
                      }
                    />
                  </Col>
                  {Number(configCartao?.juros_parcela_inicio || 0) > 0 && (
                    <Col lg={4}>
                      <Input
                        name="juros_por_parcela"
                        label="Juros por parcela"
                        placeholder="%"
                        className="form-control"
                        type="number"
                        step={0.01}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Gerar link
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        isOpen={!!modalTransacoes}
        toggle={toggleModalTransacoes}
        size="lg"
      >
        <ModalHeader toggle={toggleModalTransacoes}>Transações</ModalHeader>
        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Valor</th>
              <th>Data venda</th>
              <th>Data repasse</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {(modalTransacoes?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Tipo">
                  <div className="d-flex align-items-center">
                    {Object.keys(bandeiras).includes(
                      m?.TransacaoMetodoCartao?.metodo_pagamento_marca_cartao ||
                        m?.tipo_pagamento
                    ) && (
                      <img
                        width={30}
                        height={20}
                        alt={
                          m?.TransacaoMetodoCartao
                            ?.metodo_pagamento_marca_cartao || m?.tipo_pagamento
                        }
                        className="object-fit mr-1"
                        src={require(`../../../assets/svg/bandeiras/${
                          bandeiras[
                            m?.TransacaoMetodoCartao
                              ?.metodo_pagamento_marca_cartao ||
                              m?.tipo_pagamento
                          ]
                        }`)}
                      />
                    )}
                    <div>
                      {tipoVenda[m.tipo_pagamento] || m.tipo_pagamento}
                      {m.tipo_pagamento === 'credit' &&
                        (m.parcelas > 1 ? ` em ${m.parcelas}` : ' à vista')}
                    </div>
                  </div>
                </td>
                <td data-label="Valor">
                  <div>
                    {Number(m.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: m.moeda || 'BRL',
                    })}
                  </div>
                  <div className="small">
                    Taxas:{' '}
                    {Number(m.valor_total_taxas).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: m.moeda || 'BRL',
                    })}
                  </div>
                </td>
                <td data-label="Data venda">
                  {new Date(m.data_transacao)
                    .toLocaleString('pt-BR')
                    .substring(0, 17)
                    .replace(',', '')}
                </td>
                <td data-label="Data repasse">
                  {m?.RecebivelZoop?.pago_em &&
                    new Date(m?.RecebivelZoop?.pago_em).toLocaleDateString(
                      'pt-BR'
                    )}
                </td>
                <td data-label="Status">
                  <div className={`badge ${transacaoColors[m.status]}`}>
                    {transacao[m.status]}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>
    </>
  );
}

export default ListarLinkPagamento;
