import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { Input, NumberFormat } from 'components/unform';
import { useValidator } from 'hooks';
import api from 'services/apiPDV';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function FreteGratis() {
  const { showLoader, closeLoader, toast } = useValidator();

  const senhaRef = useRef();

  const [modal, setModal] = useState(false);
  const [frete, setFrete] = useState();

  const toggleModal = () => setModal(!modal);

  const getDados = async () => {
    showLoader();
    try {
      const { data } = await api.get('/frete-gratis');
      setFrete(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  async function handleSubmitFrete(e) {
    showLoader();
    const errors = {};
    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });
    senhaRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      try {
        const body = {
          valor_minimo: e?.valor_minimo,
          peso_maximo: e?.peso_maximo,
        };

        if (frete?.id) {
          await api.put('/frete-gratis', body);
        } else {
          await api.post('/frete-gratis', body);
        }
        getDados();
        toast('Frete grátis alterado!', { type: 'success' });
        toggleModal();
      } catch (err) {
        toast('Erro ao alterar frete grátis!', { type: 'error' });
      }
    }
    closeLoader();
  }

  async function handleDelete() {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir o frete grátis?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete('/frete-gratis')
            .then(() => {
              Swal.close();
              getDados();
              toast('Frete grátis excluido!', { type: 'success' });
            })
            .catch(() => {
              toast('Erro ao excluir frete grátis!', { type: 'error' });
            });
        }
      });
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Valor mínimo</th>
                    <th className="text-center">Peso máximo (em gramas)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td data-label="Valor mínimo" className="text-center">
                      {frete?.sem
                        ? frete?.valor_minimo
                        : Number(frete?.valor_minimo).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                    </td>
                    <td
                      data-label="Peso máximo (em gramas)"
                      className="text-center"
                    >
                      {frete?.peso_maximo}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          {frete?.id && (
            <Button color="danger" onClick={() => handleDelete()}>
              Deletar
            </Button>
          )}
          <Button color="primary" onClick={toggleModal}>
            Cadastrar
          </Button>
        </CardFooter>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="sm">
        <ModalHeader toggle={toggleModal}>
          Cadastrar parâmetros frete
        </ModalHeader>
        <Form onSubmit={(e) => handleSubmitFrete(e)} ref={senhaRef}>
          <ModalBody id="print">
            <Row>
              <Col>
                <NumberFormat
                  name="valor_minimo"
                  label="Valor mínimo"
                  className="form-control"
                  prefix="R$"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Peso máximo"
                  type="text"
                  name="peso_maximo"
                  className="form-control"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type="submit">
              Cadastrar / Alterar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default FreteGratis;
