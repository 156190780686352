/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import api from '../../../../services/apiPDV';
import { Input, CheckBox } from '../../../../components/unform';
import { useAuth } from '../../../../contexts/auth';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const itensRow = [
  { id: '0', label: 'Dom.', nome_dia: 'Domingo' },
  { id: '1', label: 'Seg.', nome_dia: 'Segunda-feira' },
  { id: '2', label: 'Ter.', nome_dia: 'Terça-feira' },
  { id: '3', label: 'Qua.', nome_dia: 'Quarta-feira' },
  { id: '4', label: 'Qui.', nome_dia: 'Quinta-feira' },
  { id: '5', label: 'Sex.', nome_dia: 'Sexta-feira' },
  { id: '6', label: 'Sáb.', nome_dia: 'Sábado' },
];

function ConfigsAgenda({ setModal, isOpen, idProfissional }) {
  const { selectedFilial } = useAuth();
  const formRef = useRef(null);

  const [disabled, setDisabled] = useState([
    { id: '0', disabled: false },
    { id: '1', disabled: false },
    { id: '2', disabled: false },
    { id: '3', disabled: false },
    { id: '4', disabled: false },
    { id: '5', disabled: false },
    { id: '6', disabled: false },
  ]);

  async function handleSubmit(data) {
    try {
      const obj = {
        horario_atendimento: [],
      };

      Object.keys(data).forEach((d) => {
        const i = itensRow.findIndex((e) => e.id === d);
        if (i >= 0) {
          obj.horario_atendimento.push({
            dia_semana: d,
            nome_dia: itensRow[i].nome_dia,
            horario_atendimento:
              data[d] === false
                ? false
                : {
                    inicio: data[`atendimento_inicio_${i}`],
                    fim: data[`atendimento_fim_${i}`],
                    almoco: {
                      inicio: data[`almoco_inicio_${i}`],
                      fim: data[`almoco_fim_${i}`],
                    },
                  },
          });
        }
      });

      const body = {
        id_profissional: idProfissional,
        id_filial: selectedFilial?.filial,
        dias_horas_atendimento: obj,
        fracao_minutos: 0,
      };

      await api.post('/config-profissional', body);

      toast.success('Configurações salvas com sucesso!');
      setModal(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          'Ocorreu um erro tente novamente mais tarde!'
      );
    }
  }

  async function getConfigsAgenda() {
    const { data: configs } = await api.get(
      `/agenda-config?id_filial=${selectedFilial?.filial}&id_profissional=${idProfissional}`
    );

    if (configs?.dias_horas_atendimento) {
      const horarios = configs?.dias_horas_atendimento?.horario_atendimento;

      horarios?.forEach((h) => {
        if (h?.horario_atendimento) {
          const atendimento = h?.horario_atendimento;
          formRef?.current?.setFieldValue(`${h?.dia_semana}`, true);
          formRef?.current?.setFieldValue(
            `atendimento_inicio_${h?.dia_semana}`,
            atendimento?.inicio
          );
          formRef?.current?.setFieldValue(
            `atendimento_fim_${h?.dia_semana}`,
            atendimento?.fim
          );
          formRef?.current?.setFieldValue(
            `almoco_inicio_${h?.dia_semana}`,
            atendimento?.almoco?.inicio
          );
          formRef?.current?.setFieldValue(
            `almoco_fim_${h?.dia_semana}`,
            atendimento?.almoco?.fim
          );
        } else {
          setDisabled((d) => [
            ...d?.filter((f) => f?.id !== `${h?.dia_semana}`),
            { id: `${h?.dia_semana}`, disabled: true },
          ]);
        }
      });
    }
  }

  useEffect(() => {
    getConfigsAgenda();
  }, [selectedFilial, idProfissional]);

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <ModalHeader
        toggle={() => {
          setModal(isOpen);
        }}
      >
        Configurações agenda
      </ModalHeader>
      <ModalBody>
        <Table className="rwd-table" responsive bordered>
          <thead>
            <tr>
              <th width="10%">Dias</th>
              <th width="40%">Horário de atendimento</th>
              <th width="40%">Horário de almoço</th>
            </tr>
          </thead>
          <tbody>
            {itensRow?.map((it) => (
              <tr key={it?.label}>
                <td data-label="Dias">
                  <CheckBox
                    value
                    name={it?.id}
                    label={it?.label}
                    onChange={(e) => {
                      setDisabled((d) => [
                        ...d?.filter((f) => f?.id !== it?.id),
                        { id: it?.id, disabled: !e?.target?.checked },
                      ]);
                      if (!e?.target?.checked) {
                        formRef?.current?.setFieldValue(
                          `atendimento_inicio_${it?.id}`,
                          ''
                        );
                        formRef?.current?.setFieldValue(
                          `atendimento_fim_${it?.id}`,
                          ''
                        );
                        formRef?.current?.setFieldValue(
                          `almoco_inicio_${it?.id}`,
                          ''
                        );
                        formRef?.current?.setFieldValue(
                          `almoco_fim_${it?.id}`,
                          ''
                        );
                      }
                    }}
                  />
                </td>
                <td data-label="Horário de atendimento">
                  <Row>
                    <Col lg={6}>
                      <Input
                        name={`atendimento_inicio_${it?.id}`}
                        label="Início"
                        type="time"
                        className="form-control"
                        disabled={
                          disabled[disabled?.findIndex((i) => i.id === it?.id)]
                            .disabled
                        }
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        name={`atendimento_fim_${it?.id}`}
                        label="Fim"
                        type="time"
                        className="form-control"
                        disabled={
                          disabled[disabled?.findIndex((i) => i.id === it?.id)]
                            .disabled
                        }
                      />
                    </Col>
                  </Row>
                </td>

                <td data-label="Horário de almoço">
                  <Row>
                    <Col lg={6}>
                      <Input
                        name={`almoco_inicio_${it?.id}`}
                        label="Início"
                        type="time"
                        className="form-control"
                        disabled={
                          disabled[disabled?.findIndex((i) => i.id === it?.id)]
                            .disabled
                        }
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        name={`almoco_fim_${it?.id}`}
                        label="Fim"
                        type="time"
                        className="form-control"
                        disabled={
                          disabled[disabled?.findIndex((i) => i.id === it?.id)]
                            .disabled
                        }
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </ModalFooter>
    </Form>
  );
}

export default ConfigsAgenda;
