import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row, FormGroup } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import {
  Input,
  MaskInput,
  ReactSelectAsync,
} from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';
import getOptions from '../../../../utils/getOptionsPDV';

function NovoListaEspera() {
  const { selectedFilial } = useAuth();
  const search = useSearch();
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = ['id_cliente'];

    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      if (search?.id) {
        await api.put(`/listaespera/${search?.id}`, e);
      } else {
        e.id_filial = selectedFilial?.filial;
        e.status = 'Ativo';
        await api.post('/cadlistaespera', e);
      }
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  useEffect(() => {
    async function getCliente() {
      try {
        const { data } = await api.get(
          `/listaespera/${search?.id}?id_filial=${selectedFilial?.filial}`
        );
        const { ...rest } = data;
        const body = {};

        Object.entries(rest).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key?.toLowerCase()}`] = {
              value: value?.value,
              label: value?.label,
            };
          } else {
            body[key] = value;
          }
        });

        formRef.current.setData(body);
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getCliente();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">
            {
              // eslint-disable-next-line no-nested-ternary
              search?.infos ? 'Informações' : search?.id ? 'Editar' : 'Nova'
            }{' '}
            lista de espera
          </h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col lg={4}>
              <FormGroup>
                <ReactSelectAsync
                  label="Paciente"
                  name="id_cliente"
                  isClearable
                  disabled={search?.infos}
                  loadOptions={async (value) => {
                    const dados = await getOptions({
                      value,
                      route: '/options-pacientes',
                      idFilial: selectedFilial?.filial,
                    });
                    return dados;
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <Input
                name="email"
                label="E-mail"
                className="form-control"
                type="text"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <MaskInput
                name="celular"
                label="Celular"
                placeholder="(00) 00000-0000"
                className="form-control"
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                removeEspecial
                disabled={search?.infos}
              />
            </Col>
            <Col lg={3}>
              <FormGroup>
                <ReactSelectAsync
                  label="Convênio"
                  name="id_convenio"
                  isClearable
                  disabled={search?.infos}
                  loadOptions={async (value) => {
                    const dados = await getOptions({
                      value,
                      route: '/convenios',
                      idFilial: selectedFilial?.filial,
                    });
                    return dados;
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={12}>
              <Input
                name="observacao"
                label="Observação"
                className="form-control"
                type="text"
                disabled={search?.infos}
              />
            </Col>

            {!search?.infos && (
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoListaEspera;
