import { Row, Col } from 'reactstrap';
import CampoTextoCurto from './CampoTextoCurto';
import CampoTextoLongo from './CampoTextoLongo';
import VerdadeiroFalso from './VerdadeiroFalso';
import CampoMultiplo from './CampoMultiplo';
import CampoNumerico from './CampoNumerico';
import PrescricaoOculos from './PrescricaoOculos';
import CalculoDppIg from './CalculoDppIg';
import Odontograma from './Odontograma';
import CalculoImc from './CalculoImc';
import Crescimento from './Crescimento';

function normalizeLabel(label) {
  return label
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\W+/g, '-')
    .toLowerCase();
}

export default function renderComponents(component, name, opcoes) {
  switch (component) {
    case 'CampoTextoCurto':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={12}>
              <CampoTextoCurto
                className="form-control"
                name={name}
                label={name}
                type="string"
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoTextoLongo':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={12}>
              <CampoTextoLongo
                className="form-control"
                name={name}
                label={name}
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoData':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={3}>
              <CampoTextoCurto
                className="form-control"
                name={name}
                label={name}
                type="date"
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoVerdadeiroFalso':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={4}>
              <VerdadeiroFalso
                name={name}
                label={name}
                className="radio"
                data={opcoes?.map((m) => ({
                  value: m?.value,
                  label: m?.label,
                }))}
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoMultiplo':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={6}>
              <CampoMultiplo
                name={name}
                label={name}
                options={opcoes}
                isMulti
                isClearable
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoOpcao':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={6}>
              <CampoMultiplo
                name={name}
                label={name}
                options={opcoes}
                isClearable
              />
            </Col>
          </Row>
        </div>
      );

    case 'CampoNumerico':
      return (
        <div className="mb-3 component-render">
          <Row>
            <Col lg={2}>
              <div className="justify-between">
                <CampoNumerico
                  name={name}
                  label={name}
                  className="form-control"
                  decimal={Number(opcoes?.casas_decimais)}
                />
                <span className="ml-3 mt-5">{opcoes?.unidade}</span>
              </div>
            </Col>
          </Row>
        </div>
      );

    case 'CalculoImc':
      return <CalculoImc />;

    case 'PrescricaoOculos':
      return <PrescricaoOculos />;

    case 'CalculoDppIg':
      return <CalculoDppIg />;

    case 'Odontograma':
      return <Odontograma />;

    case 'CurvaCrescimento':
      return <Crescimento />;

    default:
      return (
        <h6>
          {component} / {name}
        </h6>
      );
  }
}
