/* eslint-disable react/destructuring-assignment */
import { Form } from '@unform/web';
import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Select, Input } from '../../../../components/unform/index';

function TipoDespesaReceita({ tipo = true, ...props }) {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} lg>
      <Form onSubmit={(data) => props.onAdd(data)}>
        <ModalHeader toggle={props.toggle}>
          {props.title || 'Cadastro'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={8}>
              <Input
                label="Nome"
                className="form-control"
                type="text"
                name="nome"
                defaultValue={props.value?.valor || props.value}
              />
            </Col>
            {tipo && (
              <Col lg={4}>
                <Select
                  name="tipo"
                  label="Tipo"
                  className="form-control"
                  data={[
                    { label: 'Despesa', value: 'D' },
                    { label: 'Receita', value: 'R' },
                  ]}
                />
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggle}>
            Cancelar
          </Button>
          <Button color="success" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default TipoDespesaReceita;
