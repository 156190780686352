import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { Select } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

function ParametrizacaoDevolucao({ modal, toggleModal }) {
  const formRef = useRef();

  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { showLoader, closeLoader, toast } = useValidator();

  const [dataDevolucaoCliente, setDataDevolucaoCliente] = useState({
    cfopDevolucao: [],
    cfopDevolucaoST: [],
    cstPisVenda: [],
    cstPisDevolucao: [],
    cstCofinsVenda: [],
    cstCofinsDevolucao: [],
  });

  const [dataDevolucaoFornecedor, setDataDevolucaoFornecedor] = useState({
    cfopDevolucaoEstadual: [],
    cfopDevolucaoInterestadual: [],
    cfopDevolucaoSTEstadual: [],
    cfopDevolucaoSTInterestadual: [],
    cstPisDevolucao: [],
    cstCofinsDevolucao: [],
    cstIcmsCompra: [],
    cstIcmsDevolucao: [],
  });

  const [parametrizacaoCliente, setParametrizacaoCliente] = useState({
    cfop_devolucao: '',
    cfop_devolucao_st: '',
    cst_pis: [],
    cst_cofins: [],
  });

  const [parametrizacaoFornecedor, setParametrizacaoFornecedor] = useState({
    cfop_devolucao_estadual: '',
    cfop_devolucao_st_estadual: '',
    cfop_devolucao_interestadual: '',
    cfop_devolucao_st_interestadual: '',
    cst_pis_devolucao: '',
    cst_cofins_devolucao: '',
    cst_icms: [],
  });

  useEffect(() => {
    if (modal?.open) {
      (async () => {
        try {
          showLoader();
          // Cliente
          const { data: cfopClienteData } = await api.get(
            '/cfop/devolucao/cliente'
          );
          const { data: cfopPisClienteData } = await api.get(
            '/cst/pis/cliente'
          );
          const { data: cfopCofinsClienteData } = await api.get(
            '/cst/cofins/cliente'
          );

          // Fornecedor
          const { data: cfopFornecedorData } = await api.get(
            '/cfop/devolucao/fornecedor'
          );
          const { data: cfopPisFornecedorData } = await api.get(
            '/cst/pis/fornecedor'
          );
          const { data: cfopCofinsFornecedorData } = await api.get(
            '/cst/cofins/fornecedor'
          );
          const { data: cfopIcmsFornecedorData } = await api.get(
            '/cst/icms/fornecedor'
          );

          if (cfopClienteData && cfopPisClienteData && cfopCofinsClienteData) {
            console.log('cfopClienteData', cfopClienteData);
            console.log('cfopPisClienteData', cfopPisClienteData);
            console.log('cfopCofinsClienteData', cfopCofinsClienteData);

            setDataDevolucaoCliente({
              cfopDevolucao: cfopClienteData?.cfopDevolucao?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cfopDevolucaoST: cfopClienteData?.cfopDevolucaoST?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cstPisVenda: cfopPisClienteData?.pisVenda?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cstPisDevolucao: cfopPisClienteData?.pisDevolucao?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cstCofinsVenda: cfopCofinsClienteData?.cofinsVenda?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cstCofinsDevolucao: cfopCofinsClienteData?.cofinsDevolucao?.map(
                (c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })
              ),
            });

            // terminar aqui para jogar os valores nos campos
            setParametrizacaoCliente({
              cfop_devolucao:
                cfopClienteData?.parametrizacao?.id_cfop_devolucao,
              cfop_devolucao_st:
                cfopClienteData?.parametrizacao?.id_cfop_devolucao_st,
              cst_pis: [],
              cst_cofins: [],
            });
          }

          if (
            cfopFornecedorData &&
            cfopPisFornecedorData &&
            cfopCofinsFornecedorData &&
            cfopIcmsFornecedorData
          ) {
            // console.log('cfopFornecedorData', cfopFornecedorData);
            // console.log('cfopPisFornecedorData', cfopPisFornecedorData);
            // console.log('cfopCofinsFornecedorData', cfopCofinsFornecedorData);
            // console.log('cfopIcmsFornecedorData', cfopIcmsFornecedorData);
            setDataDevolucaoFornecedor({
              cfopDevolucaoEstadual:
                cfopFornecedorData?.cfopDevolucaoEstadual?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              cfopDevolucaoInterestadual:
                cfopFornecedorData?.cfopDevolucaoInterEstadual?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              cfopDevolucaoSTEstadual:
                cfopFornecedorData?.cfopDevolucaoSTEstadual?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              cfopDevolucaoSTInterestadual:
                cfopFornecedorData?.cfopDevolucaoSTInterestadual?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              cstPisDevolucao: [
                { value: '', label: 'Nenhum' },
                ...cfopPisFornecedorData?.pis?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              ],
              cstCofinsDevolucao: [
                { value: '', label: 'Nenhum' },
                ...cfopCofinsFornecedorData?.cofins?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              ],
              cstIcmsCompra: cfopIcmsFornecedorData?.icmsCompra?.map((c) => ({
                value: c?.id,
                label: `${c?.codigo} - ${c?.descricao}`,
              })),
              cstIcmsDevolucao: [
                { value: '', label: 'Nenhum' },
                ...cfopIcmsFornecedorData?.icmsDevolucao?.map((c) => ({
                  value: c?.id,
                  label: `${c?.codigo} - ${c?.descricao}`,
                })),
              ],
            });
          }
        } catch (err) {
          toast(err.mensagem);
        } finally {
          closeLoader();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const [rowsCstPisCliente, setRowsCstPisCliente] = useState([]);
  const handleAddRowCstPisCliente = () => {
    setRowsCstPisCliente([
      ...rowsCstPisCliente,
      { id: rowsCstPisCliente.length },
    ]);
  };
  const handleRemoveRowCstPisCliente = (index) => {
    setRowsCstPisCliente(rowsCstPisCliente.filter((row, i) => i !== index));
  };

  const [rowsCstCofinsCliente, setRowsCstCofinsCliente] = useState([]);
  const handleAddRowCstCofinsCliente = () => {
    setRowsCstCofinsCliente([
      ...rowsCstCofinsCliente,
      { id: rowsCstCofinsCliente.length },
    ]);
  };
  const handleRemoveRowCstCofinsCliente = (index) => {
    setRowsCstCofinsCliente(
      rowsCstCofinsCliente.filter((row, i) => i !== index)
    );
  };

  const [rowsCstIcmsFornecedor, setRowsCstIcmsFornecedor] = useState([]);
  const handleAddRowCstIcmsFornecedor = () => {
    setRowsCstIcmsFornecedor([
      ...rowsCstIcmsFornecedor,
      { id: rowsCstIcmsFornecedor.length },
    ]);
  };
  const handleRemoveRowCstIcmsFornecedor = (index) => {
    setRowsCstIcmsFornecedor(
      rowsCstIcmsFornecedor.filter((row, i) => i !== index)
    );
  };

  const groupBy = (xs, key) =>
    xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);

  async function salvaDevolucaoCliente(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      showLoader();
      try {
        const cstCPIS = [];
        const cstCofins = [];
        Object.keys(e).forEach((campo) => {
          if (campo.includes('cst_pis_')) {
            cstCPIS.push({
              id: e[campo],
              tipo: campo.replace('cst_pis_', '').split('_')[0],
              index: campo.replace('cst_pis_', '').split('_')[1],
            });
            delete e[campo];
          }
          if (campo.includes('cst_cofins_')) {
            cstCofins.push({
              id: e[campo],
              tipo: campo.replace('cst_cofins_', '').split('_')[0],
              index: campo.replace('cst_cofins_', '').split('_')[1],
            });
            delete e[campo];
          }
        });
        if (cstCPIS.length) {
          e.cstPIS = groupBy(cstCPIS, 'index');
        }
        if (cstCofins.length) {
          e.cstCofins = groupBy(cstCofins, 'index');
        }

        await api.post('/devolucao/cliente', e);
        toast('Dados salvos com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    }
  }

  async function salvaDevolucaoFornecedor(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (
        !e[f] &&
        f !== 'cst_pis_devolucao' &&
        f !== 'cst_cofins_devolucao' &&
        !f.includes('cst_icms_devolucao')
      ) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      showLoader();
      try {
        const cstICMS = [];
        Object.keys(e).forEach((campo) => {
          if (campo.includes('cst_icms_')) {
            cstICMS.push({
              id: e[campo],
              tipo: campo.replace('cst_icms_', '').split('_')[0],
              index: campo.replace('cst_icms_', '').split('_')[1],
            });
            delete e[campo];
          }
        });
        if (cstICMS.length) {
          e.cstICMS = groupBy(cstICMS, 'index');
        }
        await api.post('/devolucao/fornecedor', e);
        toast('Dados salvos com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    }
  }

  return (
    <Modal
      isOpen={modal?.open}
      toggle={() => {
        toggleModal();
        setDataDevolucaoCliente({
          cfopDevolucao: [],
          cfopDevolucaoST: [],
          cstPisVenda: [],
          cstPisDevolucao: [],
        });
        setDataDevolucaoFornecedor({
          cfopDevolucaoEstadual: [],
          cfopDevolucaoInterestadual: [],
          cfopDevolucaoSTEstadual: [],
          cfopDevolucaoSTInterestadual: [],
          cstPisDevolucao: [],
          cstCofinsDevolucao: [],
          cstIcmsCompra: [],
          cstIcmsDevolucao: [],
        });
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
          setDataDevolucaoCliente({
            cfopDevolucao: [],
            cfopDevolucaoST: [],
            cstPisVenda: [],
            cstPisDevolucao: [],
          });
          setDataDevolucaoFornecedor({
            cfopDevolucaoEstadual: [],
            cfopDevolucaoInterestadual: [],
            cfopDevolucaoSTEstadual: [],
            cfopDevolucaoSTInterestadual: [],
            cstPisDevolucao: [],
            cstCofinsDevolucao: [],
            cstIcmsCompra: [],
            cstIcmsDevolucao: [],
          });
        }}
      >
        Parametrização devolução
      </ModalHeader>

      <ModalBody>
        <Nav className="p-3" tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : null}
              onClick={() => {
                toggle('1');
              }}
            >
              Devolução Cliente
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : null}
              onClick={() => {
                toggle('2');
              }}
            >
              Devolução Fornecedor
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              onSubmit={(d) => {
                salvaDevolucaoCliente(d);
              }}
              ref={formRef}
            >
              <Row>
                <Col lg={2}>
                  <h6>CFOP</h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Select
                    name="cfop_devolucao"
                    label="CFOP devolução *"
                    className="form-control"
                    data={dataDevolucaoCliente?.cfopDevolucao}
                  />
                </Col>
                <Col lg={12}>
                  <Select
                    name="cfop_devolucao_st"
                    label="CFOP devolução ST"
                    className="form-control"
                    data={dataDevolucaoCliente?.cfopDevolucaoST}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={2}>
                  <h6>CST PIS</h6>
                </Col>
                <Col lg={10} className="d-flex justify-content-end">
                  <Button
                    color="success"
                    type="button"
                    onClick={handleAddRowCstPisCliente}
                  >
                    <FaPlusCircle />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <h6>Venda</h6>
                </Col>
                <Col lg={5}>
                  <h6>Devolução</h6>
                </Col>
              </Row>
              {rowsCstPisCliente.map((row, index) => (
                <Row key={row.id}>
                  <Col lg={5}>
                    <Select
                      name={`cst_pis_venda_${index}`}
                      className="form-control"
                      data={dataDevolucaoCliente?.cstPisVenda}
                    />
                  </Col>
                  <Col lg={5}>
                    <Select
                      name={`cst_pis_devolucao_${index}`}
                      className="form-control"
                      data={dataDevolucaoCliente?.cstPisDevolucao}
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      className="mt-4"
                      block
                      color="danger"
                      type="button"
                      onClick={() => handleRemoveRowCstPisCliente(index)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>
              ))}

              <hr />
              <Row>
                <Col lg={2}>
                  <h6>CST COFINS</h6>
                </Col>
                <Col lg={10} className="d-flex justify-content-end">
                  <Button
                    color="success"
                    type="button"
                    onClick={handleAddRowCstCofinsCliente}
                  >
                    <FaPlusCircle />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <h6>Venda</h6>
                </Col>
                <Col lg={5}>
                  <h6>Devolução</h6>
                </Col>
              </Row>
              {rowsCstCofinsCliente.map((row, index) => (
                <Row key={row.id}>
                  <Col lg={5}>
                    <Select
                      name={`cst_cofins_venda_${index}`}
                      className="form-control"
                      data={dataDevolucaoCliente?.cstCofinsVenda}
                    />
                  </Col>
                  <Col lg={5}>
                    <Select
                      name={`cst_cofins_devolucao_${index}`}
                      className="form-control"
                      data={dataDevolucaoCliente?.cstCofinsDevolucao}
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      className="mt-4"
                      block
                      color="danger"
                      type="button"
                      onClick={() => handleRemoveRowCstCofinsCliente(index)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>
              ))}

              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tabId="2">
            <Form
              onSubmit={(d) => {
                salvaDevolucaoFornecedor(d);
              }}
              ref={formRef}
            >
              <Row>
                <Col lg={2}>
                  <h6>CFOP</h6>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Select
                    name="cfop_devolucao_estadual"
                    label="CFOP devolução Estadual *"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cfopDevolucaoEstadual}
                  />
                </Col>
                <Col lg={6}>
                  <Select
                    name="cfop_devolucao_iterestadual"
                    label="CFOP devolução Interestadual *"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cfopDevolucaoInterestadual}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Select
                    name="cfop_devolucao_st_estadual"
                    label="CFOP devolução ST Estadual *"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cfopDevolucaoSTEstadual}
                  />
                </Col>
                <Col lg={6}>
                  <Select
                    name="cfop_devolucao_st_iterestadual"
                    label="CFOP devolução ST Interestadual *"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cfopDevolucaoSTInterestadual}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={6}>
                  <h6>PIS</h6>
                </Col>
                <Col lg={2}>
                  <h6>COFINS</h6>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Select
                    name="cst_pis_devolucao"
                    label="CST PIS devolução"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cstPisDevolucao}
                  />
                </Col>
                <Col lg={6}>
                  <Select
                    name="cst_cofins_devolucao"
                    label="CST COFINS devolução"
                    className="form-control"
                    data={dataDevolucaoFornecedor?.cstCofinsDevolucao}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={2}>
                  <h6>CST ICMS</h6>
                </Col>
                <Col lg={10} className="d-flex justify-content-end">
                  <Button
                    color="success"
                    type="button"
                    onClick={handleAddRowCstIcmsFornecedor}
                  >
                    <FaPlusCircle />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <h6>Compra</h6>
                </Col>
                <Col lg={5}>
                  <h6>Devolução</h6>
                </Col>
              </Row>
              {rowsCstIcmsFornecedor.map((row, index) => (
                <Row key={row.id}>
                  <Col lg={5}>
                    <Select
                      name={`cst_icms_compra_${index}`}
                      className="form-control"
                      data={dataDevolucaoFornecedor?.cstIcmsCompra}
                    />
                  </Col>
                  <Col lg={5}>
                    <Select
                      name={`cst_icms_devolucao_${index}`}
                      className="form-control"
                      data={dataDevolucaoFornecedor?.cstIcmsDevolucao}
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      className="mt-4"
                      block
                      color="danger"
                      type="button"
                      onClick={() => handleRemoveRowCstIcmsFornecedor(index)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>
              ))}

              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

export default ParametrizacaoDevolucao;
