import React from 'react';

const PromoComponent = ({ promoData }) => {
  const formatReal = (value) =>
    Number(value).toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
  };

  const renderDePor = () => (
    <h5>
      Compre o produto{' '}
      <strong>
        {promoData?.promo_tipo?.ProdutoVariacao?.Produto.descricao} -{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.tamanho}{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.cor}
      </strong>{' '}
      de <strong>{formatReal(promoData?.promo_tipo?.valor_normal)}</strong> por{' '}
      <strong>{formatReal(promoData?.promo_tipo?.valor_promocao)}</strong> cada
    </h5>
  );

  const renderCombo = () => (
    <h5>
      Leve <strong>{promoData?.promo_tipo?.quantidade_obrigatoria}</strong>{' '}
      produtos pagando somente{' '}
      <strong>{promoData?.promo_tipo?.quantidade_pagar}</strong>:{' '}
      <strong>
        {promoData?.promo_tipo?.ProdutoVariacao?.Produto.descricao} -{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.tamanho}{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.cor}
      </strong>
    </h5>
  );

  const renderProgressiva = () => (
    <h5>
      Desconto progressivo de{' '}
      <strong>{promoData?.promo_tipo?.percentual_desconto}%</strong> na compra
      de <strong>{promoData?.promo_tipo?.quantidade_minima}</strong> ou mais
      produtos:{' '}
      <strong>
        {promoData?.promo_tipo?.ProdutoVariacao?.Produto?.descricao} -{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.tamanho}{' '}
        {promoData?.promo_tipo?.ProdutoVariacao?.cor}
      </strong>
    </h5>
  );

  const renderKit = () => (
    <h5>
      Compre <strong>{promoData?.promo_tipo?.quantidade1}</strong> unidades de{' '}
      <strong>
        {promoData?.promo_tipo?.ProdutoVariacao1?.Produto?.descricao} -{' '}
        {promoData?.promo_tipo?.ProdutoVariacao1?.tamanho}{' '}
        {promoData?.promo_tipo?.ProdutoVariacao1?.cor}
      </strong>{' '}
      e <strong>{promoData?.promo_tipo.quantidade2}</strong> unidades de{' '}
      <strong>
        {promoData?.promo_tipo?.ProdutoVariacao2?.Produto?.descricao} -{' '}
        {promoData?.promo_tipo?.ProdutoVariacao2?.tamanho}{' '}
        {promoData?.promo_tipo?.ProdutoVariacao2?.cor}
      </strong>{' '}
      por apenas{' '}
      <strong>{formatReal(promoData?.promo_tipo?.valor_promocao)}</strong>
    </h5>
  );

  return (
    <div>
      <h5>{promoData?.promo.descricao}</h5>
      <br />
      <h6>
        Período:{' '}
        <strong>
          {formatDate(promoData?.promo.data_inicio)} -{' '}
          {formatDate(promoData?.promo.data_encerramento)}
        </strong>
      </h6>
      <br />
      {promoData?.promo.tipo === 'DE/POR' && renderDePor()}
      {promoData?.promo.tipo === 'COMBO' && renderCombo()}
      {promoData?.promo.tipo === 'PROGRESSIVA' && renderProgressiva()}
      {promoData?.promo.tipo === 'KIT' && renderKit()}
    </div>
  );
};

export default PromoComponent;
