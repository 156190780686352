// third-party
import { useEffect, useRef, useState } from 'react';
import {
  ChatList,
  MessageList,
  Navbar,
  Dropdown,
  Input,
  Button,
} from 'react-chat-elements';
import { Card, CardBody } from 'reactstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { useAuth } from '../../../contexts/auth';
import { useValidator } from '../../../hooks/index';

import 'react-chat-elements/dist/main.css';

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `há ${Math.floor(interval)} mes`;
    }
    return `há ${Math.floor(interval)} meses`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `há ${Math.floor(interval)} dia`;
    }
    return `há ${Math.floor(interval)} dias`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `há ${Math.floor(interval)} hora`;
    }
    return `há ${Math.floor(interval)} horas`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `há ${Math.floor(interval)} minuto`;
    }
    return `há ${Math.floor(interval)} minutos`;
  }
  if (interval === 0) {
    return 'agora mesmo';
  }
  return `há ${Math.floor(seconds)} segundos`;
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

export default function Chat() {
  const { toast, showLoader, closeLoader } = useValidator();
  const { socket, isAdmin } = useAuth();
  const location = useLocation();
  const [dados, setDados] = useState([]);
  const [cliente, setCliente] = useState('');
  const [mensagens, setMensagens] = useState([]);

  const messageListReferance = useRef();
  const inputReferance = useRef();
  const salaChatRef = useRef();

  function applyMessage(data) {
    if (data.id_sala_chat === salaChatRef.current) {
      socket.emit('visualizar', { sala: salaChatRef.current });
      setMensagens((oldArray) => [...oldArray, data]);
      return;
    }

    setDados((old) => {
      if (!old.length) {
        setMensagens([data]);
        salaChatRef.current = data.id_sala_chat;
      }
      if (old.some((s) => s.id === data.id_sala_chat)) {
        return old.map((m) =>
          m.id === data.id_sala_chat
            ? {
                ...m,
                id: data.id_sala_chat,
                nome_usuario: data.nome_usuario,
                unread: m.unread + 1,
                MensagemChats: [data],
              }
            : m
        );
      }
      return [
        {
          id: data.id_sala_chat,
          nome_usuario: data.nome_usuario,
          unread: old.length ? 1 : 0,
          MensagemChats: [data],
        },
      ].concat(old);
    });
  }

  function applyMessageVizualizado(data) {
    setMensagens((oldArray) =>
      oldArray.map((m) => {
        if (m.id_usuario === data.id_usuario) {
          return {
            ...m,
            visualizado: true,
          };
        }
        return m;
      })
    );
  }

  const toggleHidden = () => {
    if (!dados?.length) {
      return;
    }
    document
      .querySelector('.chat-container-list')
      ?.classList.toggle('mobile-hidden');
    document
      .querySelector('.chat-container')
      ?.classList.toggle('mobile-hidden');
  };

  const showMessages = async (id) => {
    try {
      const { data, headers } = await api.get(`/chat/${id}`);
      document
        .querySelector('.chat-container-list')
        ?.classList.add('mobile-hidden');
      document
        .querySelector('.chat-container')
        ?.classList.remove('mobile-hidden');
      setCliente(headers['x-user-name']);
      salaChatRef.current = id;
      setMensagens(data);

      setDados((old) =>
        old.map((m) => {
          if (m.id === id) {
            return {
              ...m,
              unread: 0,
            };
          }
          return m;
        })
      );

      socket.emit('visualizar', { sala: id });
      socket?.emit('pendentes');
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    }
  };

  async function handleFinalizar() {
    swalWithBootstrapButtons
      .fire({
        title: 'Finalizar atendimento',
        text: 'Ao finalizar o atendimento este chat será excluído',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          showLoader();
          socket.emit('finalizar', {
            sala: salaChatRef.current,
          });
        }
      });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('sala') !== undefined && params.get('sala') !== null) {
      showMessages(params.get('sala'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    socket?.on('message', (data) => {
      applyMessage(data);
    });
    socket?.on('visualizado', (data) => {
      applyMessageVizualizado(data);
    });
    socket?.on('closeLoader', (data) => {
      if (data?.sala) {
        setMensagens([]);
        setCliente('');
        salaChatRef.current = null;
        setDados((old) => old.filter((f) => f.id !== data.sala));
      }
      closeLoader();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    const getData = async () => {
      const { data } = await api.get('/chat');
      setDados(data);
      if (!dados?.length && !isAdmin) {
        setCliente('Suporte');
        document
          .querySelector('.chat-container-list')
          ?.classList.toggle('mobile-hidden');
        document
          .querySelector('.chat-container')
          ?.classList.toggle('mobile-hidden');
      }
    };
    getData();
  }, []);

  return (
    <>
      <Card>
        <CardBody className="card-chat">
          <div className="row g-0 h-100">
            <div className="col-lg-3 chat-container-list border-default">
              <ChatList
                className="chat-list"
                dataSource={dados?.map((m) => ({
                  id: m.id,
                  title: m?.nome_usuario || m?.usuario_1?.nome,
                  alt: m?.id_usuario_2,
                  subtitle: m?.MensagemChats?.[0]?.mensagem,
                  date: m?.MensagemChats?.[0]?.createdAt,
                  dateString: new Date(
                    m?.MensagemChats?.[0]?.createdAt || m.createdAt
                  )
                    .toLocaleString('pt-BR')
                    .substring(0, 17)
                    .replace(',', ''),
                  unread: m?.unread,
                }))}
                onClick={(e) => {
                  showMessages(e?.id);
                }}
              />
            </div>
            <div className="col-lg-9 chat-container mobile-hidden">
              {cliente ? (
                <>
                  <Navbar
                    left={
                      <button
                        type="button"
                        className="btn-none d-flex justify-content-center align-items-center color-white"
                        onClick={toggleHidden}
                      >
                        <FaChevronLeft className="d-lg-none mr-3" />
                        {cliente}
                      </button>
                    }
                    type="dark"
                    right={
                      isAdmin && (
                        <Dropdown
                          buttonProps={{
                            text: 'Opções',
                            color: '#fff',
                            // type: 'white',
                          }}
                          animationPosition="norteast"
                          onSelect={handleFinalizar}
                          items={[
                            {
                              text: 'Finalizar atendimento',
                            },
                          ]}
                        />
                      )
                    }
                  />

                  <MessageList
                    referance={messageListReferance}
                    className="message-list bg-gray"
                    lockable
                    toBottomHeight="100%"
                    dataSource={mensagens?.map((m) => ({
                      status: m?.visualizado ? 'read' : 'received',
                      position: m?.position,
                      title: m?.nome_usuario,
                      type: 'text',
                      text: m?.mensagem,
                      date: new Date(m.createdAt),
                      dateString:
                        new Date().toLocaleDateString() ===
                        new Date(m.createdAt).toLocaleDateString()
                          ? new Date(m.createdAt).toLocaleTimeString('pt-BR')
                          : new Date(m.createdAt)
                              .toLocaleString('pt-BR')
                              .substring(0, 17)
                              .replace(',', '.'),
                    }))}
                  />
                  <Input
                    referance={inputReferance}
                    placeholder="Digite aqui"
                    multiline
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.code === 'Enter' &&
                        !e.nativeEvent.shiftKey
                      ) {
                        socket.emit('message', {
                          sala: salaChatRef.current,
                          text: inputReferance.current.value,
                        });
                        inputReferance.current.value = '';
                      }
                    }}
                    rightButtons={
                      <Button
                        color="white"
                        backgroundColor="black"
                        text="Enviar"
                        onClick={() => {
                          socket.emit('message', {
                            sala: salaChatRef.current,
                            text: inputReferance.current.value,
                          });
                          inputReferance.current.value = '';
                        }}
                      />
                    }
                  />
                </>
              ) : (
                <div className="justify-center mt-5">
                  <h6>Selecione o chat ao lado para inciar a conversa.</h6>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
