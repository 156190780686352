/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import useValidator from '../../../hooks/useValidator';
import api from '../../../services/api';
import BarChart from '../../../components/charts/bar';
import LineChart from '../../../components/charts/line';
import PieChart from '../../../components/charts/pie';
import {
  bandeiras,
  tipoVenda,
  transacao,
  transacaoColors,
} from '../../../utils/status';

const nomeMes = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
};

function Dashboard() {
  const [dados, setDados] = useState();
  const [modal, setModal] = useState();
  const [filtros, setFiltros] = useState({
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  });
  const { showLoader, closeLoader, toast } = useValidator();

  const toggleModal = () => setModal(false);

  useEffect(() => {
    async function getDados() {
      showLoader();
      try {
        const { data } = await api.get(
          `/dashboard?ano=${filtros?.ano}&mes=${filtros?.mes}`
        );
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, [filtros]);

  return (
    <>
      <Row>
        <Col xxl={5} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Vendas por mês</h5>
              <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.ano}
                onChange={(e) =>
                  setFiltros((old) => ({
                    ...old,
                    ano: e.target.value,
                  }))
                }
              >
                {Array.from(Array(10).keys()).map((m) => {
                  const ano = new Date().getFullYear() - m;
                  return (
                    <option value={ano} key={ano}>
                      {ano}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="chart-card-body">
              <BarChart
                margin={{ top: 20, right: 20, bottom: 30, left: 20 }}
                data={dados?.vendaspormes || []}
                indexBy="mes"
                keys={['quantidade']}
                enableGridY={false}
                tooltipLabel={(e) => e.indexValue}
              />
            </div>
          </div>
        </Col>
        <Col xxl={7} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Vendas por período</h5>
              <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.mes}
                onChange={(e) =>
                  setFiltros((old) => ({
                    ...old,
                    mes: e.target.value,
                  }))
                }
              >
                {Array.from(Array(12).keys()).map((m) => (
                  <option value={m + 1} key={m}>
                    {nomeMes[m]}
                  </option>
                ))}
              </select>
            </div>
            <div className="chart-card-body">
              <LineChart
                margin={{ top: 25, right: 50, bottom: 35, left: 50 }}
                data={[
                  {
                    id: 'Vendas',
                    data: (dados?.evolucaohora || [])?.map((m) => ({
                      x: m.hora,
                      y: m.quantidade,
                    })),
                  },
                ]}
                legends={[]}
              />
            </div>
          </div>
        </Col>
        <Col xxl={3} xl={6} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Saldo</h5>
              <Button color="light-primary btn-sm opacity-0" disabled>
                &nbsp;
              </Button>
            </div>
            {dados?.taxas ? (
              <div className="chart-card-body d-flex align-items-center">
                <div className="w-100">
                  <h4 className="text-center txt-muted">
                    Projeção:{' '}
                    {Number(
                      dados?.taxas?.[0]?.total_taxa_uneo || 0
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h4>
                </div>
              </div>
            ) : (
              <div className="chart-card-body d-flex align-items-center">
                <div className="w-100">
                  <h4 className="text-center txt-muted">
                    Disponível:{' '}
                    {Number(
                      Number(dados?.saldouneopay?.items?.current_balance || 0) /
                        100
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h4>
                  <h5 className="text-center">
                    A liberar:{' '}
                    {Number(
                      Number(dados?.saldouneopay?.items?.account_balance || 0) /
                        100
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h5>
                  <h5 className="text-center text-danger">
                    Bloqueado:{' '}
                    {Number(
                      Number(
                        dados?.saldouneopay?.items?.current_blocked_balance || 0
                      ) / 100
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h5>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col xxl={3} xl={6} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Condição pagamento</h5>
              {/* <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.cond_pag}
              >
                <option value="">Cond. pgto</option>
                <option value="pix">Pix</option>
                <option value="credit">Crédito</option>
                <option value="debit">Débito</option>
                <option value="boleto">Boleto</option>
              </select> */}
              <Button
                color="light-primary btn-sm"
                onClick={() =>
                  setModal({
                    title: 'Condição pagamento',
                    items: dados?.condipag,
                    key: 'tipo',
                  })
                }
              >
                Ver em lista
              </Button>
            </div>
            <div className="chart-card-body">
              <PieChart
                data={(dados?.condipag || []).map((m) => ({
                  id: m.tipo,
                  label: m.tipo,
                  value: m.quantidade,
                }))}
                arcLabelsTextColor="black"
              />
            </div>
          </div>
        </Col>
        <Col xxl={3} xl={6} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Status venda</h5>
              {/* <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.autorizadora}
              >
                <option value="">Cond. pgto</option>
                <option value="pix">Pix</option>
                <option value="credit">Crédito</option>
                <option value="debit">Débito</option>
                <option value="boleto">Boleto</option>
              </select> */}
              <Button
                color="light-primary btn-sm"
                onClick={() =>
                  setModal({
                    title: 'Status venda',
                    items: dados?.vendastatus.map((m) => ({
                      ...m,
                      status: transacao[m.status] || m.status,
                    })),
                    key: 'status',
                  })
                }
              >
                Ver em lista
              </Button>
            </div>
            <div className="chart-card-body">
              <PieChart
                data={(dados?.vendastatus || []).map((m) => ({
                  id: transacao[m.status] || m.status,
                  label: transacao[m.status] || m.status,
                  value: m.quantidade,
                }))}
                arcLabelsTextColor="black"
              />
            </div>
          </div>
        </Col>
        <Col xxl={3} xl={6} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Bandeira</h5>
              {/* <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.autorizadora}
              >
                <option value="">Cond. pgto</option>
                <option value="pix">Pix</option>
                <option value="credit">Crédito</option>
                <option value="debit">Débito</option>
                <option value="boleto">Boleto</option>
              </select> */}
              <Button
                color="light-primary btn-sm"
                onClick={() =>
                  setModal({
                    title: 'Autorizadora',
                    items: dados?.vendagateway,
                    key: 'metodo_pagamento_marca_cartao',
                  })
                }
              >
                Ver em lista
              </Button>
            </div>
            <div className="chart-card-body">
              <PieChart
                data={(dados?.vendagateway || []).map((m) => ({
                  id: m.metodo_pagamento_marca_cartao,
                  label: m.metodo_pagamento_marca_cartao,
                  value: m.quantidade,
                }))}
                arcLabelsTextColor="black"
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        {(dados?.totais || []).map((m) => (
          <Col className="mb-3 mx-2 card">
            <div className="card-body">
              <h5 className="text-center">Total {transacao[m.status]}</h5>
              <h4 className="text-center mb-0">
                {Number(m.sum).toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                })}
              </h4>
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col xxl={dados?.totalvendedor ? 7 : 12} xl={12} className="mb-3">
          <div className="chart-card">
            <div className="chart-card-header">
              <h5 className="text-center mb-0">Últimas vendas</h5>
              {/* <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.autorizadora}
              >
                <option value="">Cond. pgto</option>
                <option value="pix">Pix</option>
                <option value="credit">Crédito</option>
                <option value="debit">Débito</option>
                <option value="boleto">Boleto</option>
              </select> */}
              <div />
            </div>
            <Table className="rwd-table" responsive>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Vendedor</th>
                  <th>Tipo</th>
                  <th>Valor</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(dados?.ultimasvendas || []).map((m) => (
                  <tr key={m.data_transacao}>
                    <td data-label="Data">
                      {new Date(m.data_transacao)
                        .toLocaleString('pt-BR')
                        .substring(0, 17)
                        .replace(',', '')}
                    </td>
                    <td data-label="Vendedor">{m.vendedor}</td>
                    <td data-label="Tipo">
                      <div className="d-flex align-items-center">
                        {Object.keys(bandeiras).includes(
                          m?.bandeira || m?.tipo_pagamento
                        ) && (
                          <img
                            width={30}
                            height={20}
                            alt={m?.bandeira}
                            src={require(`../../../assets/svg/bandeiras/${
                              bandeiras[m?.bandeira || m?.tipo_pagamento]
                            }`)}
                            className="mr-3 object-fit"
                          />
                        )}
                        <div>
                          {tipoVenda[m.tipo_pagamento] || m.tipo_pagamento}
                          {m.tipo_pagamento === 'credit' &&
                            (m.parcelas > 1 ? ` em ${m.parcelas}` : ' à vista')}
                        </div>
                      </div>
                    </td>
                    <td data-label="Valor">
                      {Number(m.valor).toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                      })}
                    </td>
                    <td data-label="Status">
                      <div className={`badge ${transacaoColors[m.status]}`}>
                        {transacao[m.status]}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
        {dados?.totalvendedor && (
          <Col xxl={5} xl={12} className="mb-3">
            <div className="chart-card">
              <div className="chart-card-header">
                <h5 className="text-center mb-0">Vendedores com mais vendas</h5>
                {/* <select
                className="btn btn-sm btn-light-primary"
                value={filtros?.autorizadora}
              >
                <option value="">Cond. pgto</option>
                <option value="pix">Pix</option>
                <option value="credit">Crédito</option>
                <option value="debit">Débito</option>
                <option value="boleto">Boleto</option>
              </select> */}
                <div />
              </div>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Vendedor</th>
                    <th>Qtd.</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {(dados?.totalvendedor || []).map((m) => (
                    <tr key={m.vendedor}>
                      <td data-label="Vendedor">{m.vendedor}</td>
                      <td data-label="Qtd.">{m.quantidade}</td>
                      <td data-label="Total">
                        {Number(m.total).toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        )}
      </Row>

      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modal?.title}</ModalHeader>
        <ModalBody>
          <Table className="rwd-table" responsive>
            <tbody>
              {(modal?.items || [])
                .concat([])
                .sort((a, b) => b.quantidade - a.quantidade)
                .map((m) => (
                  <tr key={m[modal?.key]}>
                    <td>
                      {m.quantidade} x {m[modal?.key]}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Dashboard;
