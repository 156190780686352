import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaListOl,
  FaWallet,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, ReactSelect, Select } from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';

function Vendedores() {
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const [modalPlano, setModalPlano] = useState(false);
  const [planos, setPlanos] = useState([]);
  const filtrosRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const toggleModal = () => setModal((old) => !old);
  const toggleModalPlano = () => setModalPlano((old) => !old);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/vendedores?page=${page || 1}&${query}`);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function getSaldo(vendedor) {
    showLoader();
    try {
      const { data } = await api.get(`/saldoconta?id_pessoa=${vendedor.id}`);
      setModal({
        id: vendedor.id,
        nome: vendedor.nome,
        saldo: data.items,
      });
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  async function changePlano(e) {
    if (e.plano_id_zoop === modalPlano?.plano) {
      toast('Escolha um plano diferente do atual');
      return;
    }
    if (!e.plano_id_zoop) {
      toast('Selecione um plano');
      return;
    }
    try {
      showLoader();
      await api.put('/plano-vendedor', {
        plano_id_zoop: e.plano_id_zoop,
        id_pessoa: modalPlano?.id,
      });
      toggleModalPlano();
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }
  async function getPlano(vendedor) {
    showLoader();
    try {
      const { data } = await api.get(`/vendedores/${vendedor.id}/plano`);
      if (data) {
        setModalPlano({
          id: vendedor.id,
          nome: vendedor.nome,
          plano: data.plano_id_zoop,
        });
      }
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/vendedores');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  const carregaPlanos = !!modalPlano && !planos.length;

  useEffect(() => {
    const getPlanos = async () => {
      try {
        const { data } = await api.get('/planos');
        setPlanos(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: m.id,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    if (carregaPlanos) {
      getPlanos();
    }
  }, [carregaPlanos]);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <UncontrolledDropdown>
            <DropdownToggle
              color="primary"
              className="text-decoration-none padding-0-5"
            >
              Novo <FaChevronDown />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem
                tag={Link}
                to="/pay/vendedores/novo-pj"
                color="primary"
              >
                Pessoa jurídica
              </DropdownItem>
              <DropdownItem
                tag={Link}
                to="/pay/vendedores/novo-pf"
                color="primary"
              >
                Pessoa física
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    name="tipo"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Tipo',
                      },
                      {
                        value: 'PF',
                        label: 'PF',
                      },
                      {
                        value: 'PJ',
                        label: 'PJ',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                      {
                        value: 'Pendente',
                        label: 'Pendente',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Responsável UneoPay</th>
              <th>Tipo</th>
              <th>MCC</th>
              <th>Plano</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="Responsável UneoPay">
                  {m?.VendedorZoop?.responsavel_conta || ''}
                </td>
                <td data-label="Tipo">{m.tipo}</td>
                <td data-label="MCC">{m.cnae_mcc}</td>
                <td data-label="Plano">{m?.VendedorZoop?.plano_id_zoop}</td>
                <td data-label="Status">{m.status}</td>
                <td
                  data-label="Ações"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => getSaldo(m)}>
                        <FaWallet className="mr-1" />
                        Saldo
                      </DropdownItem>
                      <DropdownItem onClick={() => getPlano(m)}>
                        <FaListOl className="mr-1" />
                        Alterar plano
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modal?.nome}</ModalHeader>
        <ModalBody>
          <p>
            Saldo atual:{' '}
            {Number(Number(modal?.saldo?.current_balance) / 100).toLocaleString(
              'pt-BR',
              {
                currency: 'BRL',
                style: 'currency',
              }
            )}
          </p>
          <p>
            Saldo a liberar:{' '}
            {Number(Number(modal?.saldo?.account_balance) / 100).toLocaleString(
              'pt-BR',
              {
                currency: 'BRL',
                style: 'currency',
              }
            )}
          </p>
          <p>
            Saldo bloqueado:{' '}
            {Number(
              Number(modal?.saldo?.current_blocked_balance) / 100
            ).toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            })}
          </p>
        </ModalBody>
      </Modal>
      <Modal isOpen={!!modalPlano} toggle={toggleModalPlano}>
        <ModalHeader toggle={toggleModalPlano}>
          Alterar plano: {modalPlano?.nome}
        </ModalHeader>
        <Form onSubmit={changePlano}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Input
                  name="plano_atual"
                  label="Plano atual"
                  className="form-control"
                  disabled
                  readOnly
                  value={modalPlano?.plano}
                />
              </Col>
              <Col lg={12}>
                <ReactSelect
                  name="plano_id_zoop"
                  label="Plano"
                  options={planos}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Alterar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Vendedores;
