import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { useValidator } from 'hooks';
import { Image, ImageDnD, Input, TextArea } from 'components/unform';
import api from 'services/apiPDV';
import ImgsUpload from './imgsUpload';

function Loja() {
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const formRef = useRef();

  const [url, setUrl] = useState(null);

  async function handleSubmit(e) {
    if (!e.url) {
      formRef.current.setErrors({
        url: 'Obrigatório',
      });
      toast();
      return;
    }

    formRef.current.setErrors({});

    const formData = new FormData();
    formData.append('descricao', e.descricao);
    formData.append('url', e.url);
    formData.append('exibir_endereco', e.exibir_endereco);
    formData.append('envio_em', e.envio_em);

    showLoader();
    const { error } = await api.post('/lojas', formData).catch((err) => {
      toast(err.mensagem);
      return { error: true };
    });
    closeLoader();
    if (!error) {
      toast('Loja alterada', { type: 'success' });
    }
  }

  async function handleChange(e) {
    if (!e.foto) {
      return;
    }

    const formData = new FormData();
    formData.append('file', e.foto);
    formData.append('field', e.field);

    showLoader();
    const { error } = await api.post('/lojas', formData).catch((err) => {
      toast(err.mensagem);
      return { error: true };
    });
    setUrl(e?.url || null);
    closeLoader();
    if (!error) {
      toast('Foto alterada', { type: 'success' });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const { data } = await api.get('/me/loja');
      formRef.current.setFieldValue('id_imagem_banner', data?.img_banner?.path);
      formRef.current.setFieldValue('id_imagem_perfil', data?.img_perfil?.path);
      formRef.current.setFieldValue('descricao', data?.descricao || '');
      formRef.current.setFieldValue('url', data?.url || '');
      formRef.current.setFieldValue('exibir_endereco', data?.exibir_endereco);
      formRef.current.setFieldValue('envio_em', Number(data?.envio_em) || 1);

      setUrl(data?.url || null);
    };
    getData();
  }, []);

  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <Card>
          <CardBody>
            <Row>
              <Col lg={3}>
                <Input
                  label="Nome"
                  name="nome"
                  labelClass="h5"
                  className="form-control"
                  placeholder="Nome da loja"
                />
              </Col>
              <Col lg={3}>
                <Input
                  label="URL"
                  name="url"
                  labelClass="h5"
                  className="form-control"
                  placeholder="URL"
                  onChange={(e) => setUrl(e.target.value)}
                />
              </Col>
              <Col lg={6} hidden={!url} style={{ marginTop: '25px' }}>
                A URL da sua loja ficará assim:
                <h6>https://sosebos.com.br/{url}</h6>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={3}>
                <Row>
                  <Col lg={12}>
                    <h5>
                      Logo
                      <small className="text-muted txt-muted">
                        {' '}
                        Tamanho 196x65
                      </small>
                    </h5>
                    <Image
                      name="id_imagem_perfil"
                      btnSelect="Selecionar"
                      objectFit="contain"
                      radius={false}
                      onChange={(e) => {
                        handleChange({
                          foto: e.file,
                          field: 'id_imagem_perfil',
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={9}>
                <Row>
                  <Col lg={12}>
                    <h5 className="mt-3 mb-0">
                      Breve descrição{' '}
                      <small className="text-muted txt-muted">
                        {' '}
                        Máx. 255 caracteres
                      </small>
                    </h5>
                    <TextArea
                      name="descricao"
                      className="form-control"
                      labelClass="d-none"
                      maxlength={255}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Cor primária"
                      name="cor_primaria"
                      labelClass="h5"
                      className="form-control"
                      type="color"
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Cor secundária"
                      name="cor_secundaria"
                      labelClass="h5"
                      className="form-control"
                      type="color"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <ImageDnD name="images" label="Banners" />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary">Salvar</Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
}

export default Loja;
