import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaUser,
} from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';

function ListarPreCadastro() {
  const [dados, setDados] = useState({ page: 1 });
  const [modal, setModal] = useState(false);
  const filtrosRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const toggleModal = () => setModal((old) => !old);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(
        `/pre-cadastro?page=${page || 1}&${query}`
      );
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function getDadosPre({ id }) {
    try {
      showLoader();
      const { data } = await api.get(`/pre-cadastro/${id}`);
      setModal(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function negarDados(id) {
    try {
      showLoader();
      await api.put(`/pre-cadastro/${id}`);
      setModal(false);
      setDados((old) => ({
        ...old,
        items: old.items.map((m) => {
          if (m.id === id) {
            return {
              ...m,
              status: 'Cancelado',
            };
          }

          return m;
        }),
      }));
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/pre-cadastro');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link className="btn btn-primary" to="/pay/pre-cadastro/novo">
            Novo
          </Link>
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="CPF/CNPJ"
                    name="documento"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    name="tipo"
                    onChange={filtrarPesquisa}
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Tipo',
                      },
                      {
                        value: 'PF',
                        label: 'PF',
                      },
                      {
                        value: 'PJ',
                        label: 'PJ',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Status',
                      },
                      {
                        value: 'Criado',
                        label: 'Criado',
                      },
                      {
                        value: 'Cancelado',
                        label: 'Cancelado',
                      },
                      {
                        value: 'Pendente',
                        label: 'Pendente',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF/CNPJ</th>
              <th>Responsável UneoPay</th>
              <th>Tipo</th>
              <th>Status</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="CPF/CNPJ">{m.documento}</td>
                <td data-label="Responsável UneoPay">{m?.responsavel_conta}</td>
                <td data-label="Tipo">{m.tipo}</td>
                <td data-label="Status">{m.status}</td>
                <td
                  data-label="Ações"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => getDadosPre(m)}>
                        <FaUser className="mr-1" />
                        Dados
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modal?.nome}</ModalHeader>
        <ModalBody>
          <Row>
            {Object.entries(modal?.dados || {}).map(([key, value]) => {
              if (
                [
                  'documento_frente',
                  'documento_verso',
                  'selfie',
                  'assinatura_cliente',
                  'assinatura_representante',
                ].includes(key)
              ) {
                return (
                  <Col lg={6} key={key} className="mb-3">
                    <p>{key}</p>
                    <img
                      src={value?.url || value}
                      alt={key}
                      className="w-100 object-fit"
                    />
                  </Col>
                );
              }

              return (
                <Col lg={6} key={key}>
                  <div>
                    <p className="mb-0 bold">{key}</p>
                    <p>{value}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="danger" onClick={() => negarDados(modal?.id)}>
            Negar dados
          </Button>
          <Button
            onClick={() => {
              history.push(
                `/pay/vendedores/novo-${String(modal?.tipo)?.toLowerCase()}`,
                modal?.dados
              );
            }}
            color="primary"
          >
            Abrir cadastro
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListarPreCadastro;
