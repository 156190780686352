import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser } from 'react-icons/fi';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardTitle,
  CardText,
  Card,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Placeholder,
} from 'reactstrap';

import {
  Input,
  Select,
  Radio,
  CheckBox,
  MaskInput,
  NumberFormat,
  ReactSelectCreatable,
  InputGroup,
  TextArea,
  Editor,
  DatePicker,
} from '../../../components/unform';

function Devolucao({ modalProps, toggleModal }) {
  return (
    <>
      <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
      <ModalBody>{modalProps?.type}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Cancelar
        </Button>
        <Button color="primary" onClick={toggleModal}>
          Aceitar
        </Button>
      </ModalFooter>
    </>
  );
}

export default Devolucao;
