import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import { Col, Row } from 'reactstrap';
import Input from '../../../components/unform/input';
import MaskInput from '../../../components/unform/maskInput';
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from '../../../utils/payments';
import { Select } from '../../../components/unform';

export default function VirtualCard() {
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocus] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      setNumber(target.value);
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      setExpiry(target.value);
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
      setCvc(target.value);
    } else {
      setName(target.value);
    }
  };

  return (
    <div id="PaymentForm">
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
        placeholders={{
          name: 'NOME TITULAR',
        }}
      />
      <Row className="mt-3">
        <Col lg={4}>
          <Select
            name="tipo"
            labelClass="d-none mb-0"
            className="form-control placeholder-dark"
            onFocus={handleInputFocus}
            data={[
              {
                value: 'credit',
                label: 'Crédito',
              },
              {
                value: 'debit',
                label: 'Débito',
              },
            ]}
          />
        </Col>
        <Col lg={8}>
          <Input
            type="tel"
            name="number"
            pattern="[\d| ]{16,22}"
            labelClass="d-none mb-0"
            placeholder="Número do cartão"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={12}>
          <Input
            name="name"
            placeholder="Nome do Titular do Cartão"
            labelClass="d-none mb-0"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={7}>
          <Input
            name="expiry"
            type="tel"
            pattern="\d\d/\d\d"
            labelClass="d-none mb-0"
            placeholder="Validade"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="form-control placeholder-dark"
          />
        </Col>
        <Col lg={5}>
          <Input
            name="cvc"
            type="tel"
            pattern="\d{3,4}"
            labelClass="d-none mb-0"
            placeholder="CVC"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="form-control placeholder-dark"
          />
        </Col>
      </Row>
    </div>
  );
}
