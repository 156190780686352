import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Col,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { Scope } from '@unform/core';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { NumberFormat, ReactSelect } from '../../../components/unform';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';

function FinalizarVenda({ modalProps, toggleModal }) {
  const [condicoes, setCondicoes] = useState([]);
  const [bandeiras, setBandeiras] = useState([]);
  const [qtds, setQtds] = useState(1);
  const { toast } = useValidator();

  useEffect(() => {
    async function getCondicaoPagamento() {
      try {
        const { data } = await api.get('/options/pagamento');
        setCondicoes(data.condicao);
        setBandeiras(data.bandeira);
      } catch (err) {
        toast(err.mensagem);
      }
    }
    getCondicaoPagamento();
  }, []);

  return (
    <>
      <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
      <ModalBody>
        <Form>
          {Array.from(Array(qtds).keys()).map((m) => (
            <Scope path={`condicoes[${m}]`}>
              <div className="d-flex">
                <Row className="w-100">
                  <Col lg={4}>
                    <ReactSelect
                      label="Condicao Pagamento *"
                      name="id_condicao_pagamento"
                      options={condicoes}
                    />
                  </Col>
                  <Col lg={4}>
                    <NumberFormat
                      name="valor"
                      label="Valor *"
                      className="form-control"
                      prefix="R$"
                    />
                  </Col>
                  <Col lg={4}>
                    <ReactSelect
                      label="Bandeira *"
                      name="id_bandeira_pagamento"
                      options={bandeiras}
                    />
                  </Col>
                </Row>
                <span
                  className="h-100"
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    paddingTop: 10,
                    marginLeft: 10,
                  }}
                >
                  {m !== 0 ? (
                    <Button
                      color="light-danger"
                      onClick={() => setQtds((old) => old - 1)}
                    >
                      <FaTrash />
                    </Button>
                  ) : (
                    <Button
                      color="light-success"
                      onClick={() => setQtds((old) => old + 1)}
                    >
                      <FaPlus />
                    </Button>
                  )}
                </span>
              </div>
            </Scope>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleModal}>
          Finalizar
        </Button>
      </ModalFooter>
    </>
  );
}

export default FinalizarVenda;
