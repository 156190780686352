/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  CardFooter,
  CardHeader,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { FaPlay, FaStop, FaUserAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';

import { useHistory } from 'react-router-dom';
import { Input, Editor } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';
import renderComponents from './Components/renderComponents';

function calcularDiferencaDeIdade(dataNascimento) {
  if (dataNascimento) {
    try {
      const hoje = new Date();
      const data = new Date(dataNascimento);

      if (isNaN(data.getTime())) {
        return 'Data de nascimento inválida';
      }

      const diffAnos = hoje.getFullYear() - data.getFullYear();
      const diffMeses = hoje.getMonth() - data.getMonth();
      const diffDias = hoje.getDate() - data.getDate();

      // Ajustar a diferença de meses e dias para que sejam positivos
      if (diffDias < 0) {
        const ultimoDiaDoMesAnterior = new Date(
          hoje.getFullYear(),
          hoje.getMonth(),
          0
        ).getDate();
        const diasAjustados =
          ultimoDiaDoMesAnterior - data.getDate() + hoje.getDate();
        return `${diffAnos} anos, ${diffMeses} meses, ${diasAjustados} dias`;
      }

      if (diffMeses < 0) {
        const mesesAjustados = 11 - data.getMonth() + hoje.getMonth();
        const ultimoDiaDoMesAnterior = new Date(
          hoje.getFullYear(),
          hoje.getMonth(),
          0
        ).getDate();
        const diasAjustados =
          ultimoDiaDoMesAnterior - data.getDate() + hoje.getDate();
        return `${diffAnos} anos, ${mesesAjustados} meses, ${diasAjustados} dias`;
      }

      return `${diffAnos} anos, ${diffMeses} meses, ${diffDias} dias`;
    } catch (err) {
      console.log('file: novoAtendimento.js:18 / err:', err);
    }
  }
}

function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function formatarSegundosParaHora(segundos) {
  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos % 3600) / 60);
  const segundosRestantes = segundos % 60;

  const formatoHora = `${String(horas).padStart(2, '0')}:${String(
    minutos
  ).padStart(2, '0')}:${String(segundosRestantes).padStart(2, '0')}`;

  return formatoHora;
}

function formatarObjeto(objeto) {
  const resultado = {
    oculos: {},
    odontograma: {},
    calculodppig: {},
    crescimento: {},
    imc: {},
    outros: {},
  };

  for (const chave in objeto) {
    const valor = objeto[chave];

    if (
      valor !== '' &&
      (Array.isArray(valor) ? valor.length > 0 : valor !== undefined)
    ) {
      if (chave.startsWith('oculos_')) {
        const campo = chave.replace('oculos_', '');
        resultado.oculos[campo] = valor;
      } else if (chave.startsWith('odontograma_parte_')) {
        const indice = chave.replace('odontograma_parte_', '');
        const observacaoChave = `odontograma_observacao_${indice}`;
        resultado.odontograma[indice] = {
          parte: valor,
          observacao: objeto[observacaoChave] || '',
        };
        delete objeto[observacaoChave];
      } else if (chave.startsWith('calculodppig_')) {
        const campo = chave.replace('calculodppig_', '');
        resultado.calculodppig[campo] = valor;
      } else if (chave.startsWith('crescimento_')) {
        const campo = chave.replace('crescimento_', '');
        resultado.crescimento[campo] = valor;
      } else if (chave.startsWith('imc_')) {
        const campo = chave.replace('imc_', '');
        resultado.imc[campo] = valor;
      } else {
        resultado.outros[chave] = valor;
      }
    }
  }

  return resultado;
}

function NovoPaciente() {
  const { selectedFilial } = useAuth();

  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const [cliente, setCliente] = useState(null);
  const [prontuario, setProntuario] = useState(null);
  const [activeTab, setActiveTab] = useState('Resumo');
  const [atendimento, setAtendimento] = useState(false);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const TimerInt =
      atendimento &&
      counter >= 0 &&
      setInterval(() => {
        setCounter((time) => time + 1);
      }, 1000);
    return () => {
      clearInterval(TimerInt);
    };
  }, [counter, atendimento]);

  const search = useSearch();
  const formRef = useRef();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleSubmit = async (e) => {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja finalizar o atendimento?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          setAtendimento(false);
          showLoader();
          const obj = {
            id_filial: selectedFilial?.filial,
            id_paciente: search?.pa,
            id_profissional: 1, // Ajustar para salvar o profissional
            data_atendimento: new Date(),
            atendimento: formatarObjeto(e),
          };

          api
            .post('/atendimento', obj)
            .then(() => {
              closeLoader();
              const isAgenda = search?.agenda === 'true';
              if (isAgenda) {
                history.push('/erp/agenda');
              } else {
                history.push('/erp/atendimento');
              }
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
        closeLoader();
      });
  };

  useEffect(() => {
    async function getClienteProcedimento() {
      try {
        const isAgenda = search?.agenda === 'true';

        if (isAgenda === false) {
          // Para atendimento não iniciado na agenda
          const { data: clienteDados } = await api.get(
            `/paciente/${search?.pa}`
          );

          const { data: prontuarioDados } = await api.get(
            `/prontuario/${search?.pr}?id_filial=${selectedFilial?.filial}`
          );

          const { data: convenioDados } = await api.get(
            `/convenios/${search?.co}`
          );

          setProntuario(prontuarioDados);
          setCliente({
            nome: clienteDados?.cli?.nome,
            idade: calcularDiferencaDeIdade(clienteDados?.cli?.data_nascimento),
            sexo: clienteDados?.cli?.sexo,
            convenio: convenioDados?.nome,
          });
        } else {
          console.log('Veio da agenda');
          // Para atendimento iniciado pela agenda
          //
          // get atendimento da agenda (fazer método)
        }
      } catch (err) {
        console.log('file: novoAtendimento.js:141 / err:', err);
        return err;
      }
    }

    getClienteProcedimento();
  }, [search?.id]);

  function selectModeloDocAtestado(tipo) {
    switch (tipo) {
      case 'atestado-padrao':
        formRef.current.setFieldValue(
          'documento_atestado',
          prontuario?.documentos_atestados?.atestado_padrao
        );
        break;
      case 'atestado-secundario':
        formRef.current.setFieldValue(
          'documento_atestado',
          prontuario?.documentos_atestados?.atestado_secundario
        );
        break;
      case 'documento-padrao':
        formRef.current.setFieldValue(
          'documento_atestado',
          prontuario?.documentos_atestados?.documento_padrao
        );
        break;
      case 'documento-secundario':
        formRef.current.setFieldValue(
          'documento_atestado',
          prontuario?.documentos_atestados?.documento_secundario
        );
        break;
      default:
        formRef.current.setFieldValue('documento_atestado', '');
        break;
    }
  }

  function renderSecao() {
    if (prontuario?.secao?.obj) {
      const camposSecao = [];
      const grouped = groupBy(prontuario?.secao?.obj, 'id');
      Object.keys(grouped).forEach((e) => {
        camposSecao.push({
          id: e,
          campos: grouped[e]?.map((m) => (
            <div>{renderComponents(m?.campo, m?.titulo, m?.opcoes)}</div>
          )),
        });
      });

      return camposSecao;
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Novo atendimento</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <CardHeader>
          <Row>
            <Col lg={1}>
              <FaUserAlt size={50} />
            </Col>
            <Col>
              <h6>
                <strong>Paciente:</strong> {cliente?.nome}
              </h6>
              <h6>
                <strong>Idade:</strong> {cliente?.idade}
              </h6>
            </Col>
            <Col lg={6}>
              <h6>
                <strong>Convênio:</strong> {cliente?.convenio}
              </h6>
              <h6>
                <strong>Sexo:</strong> {cliente?.sexo}
              </h6>
            </Col>
            <Col className="justify-end mt-2 mr-2">
              <h4>{formatarSegundosParaHora(counter)}</h4>
            </Col>
          </Row>
        </CardHeader>
        <hr />
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col lg={2}>
              <h4 className="text-center">Prontuário</h4>
              <hr />
              <div hidden={!atendimento}>
                <Button block color="outline-danger" type="submit">
                  <div>
                    <FaStop className="mr-2" size={12} />
                    <span>Finalizar atendimento</span>
                  </div>
                </Button>
              </div>

              <div hidden={atendimento}>
                <Button
                  block
                  color="outline-primary"
                  onClick={() => setAtendimento(true)}
                  type="button"
                >
                  <div>
                    <FaPlay className="mr-2" size={12} />
                    <span>Iniciar atendimento</span>
                  </div>
                </Button>
              </div>
              <hr />
              <Nav tabs vertical pills>
                <NavItem>
                  <NavLink
                    className={activeTab === 'Resumo' && 'active'}
                    onClick={() => {
                      toggle('Resumo');
                    }}
                  >
                    Resumo
                  </NavLink>
                </NavItem>

                {prontuario?.secao?.secoes?.map((m) => (
                  <NavItem>
                    <NavLink
                      className={activeTab === `${m?.id_secao}` && 'active'}
                      onClick={() => {
                        toggle(m?.id_secao);
                      }}
                    >
                      {m?.nome}
                    </NavLink>
                  </NavItem>
                ))}

                <NavItem>
                  <NavLink
                    className={activeTab === 'Docs' && 'active'}
                    onClick={() => {
                      toggle('Docs');
                    }}
                  >
                    Documentos e atestados
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col lg={10}>
              <Card className="mt-5">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="Resumo">
                    <CardBody>
                      <h6 className="text-center">
                        Paciente não possuí histórico de prontuários anteriores
                      </h6>
                    </CardBody>
                  </TabPane>

                  {renderSecao()?.map((item) => (
                    <TabPane tabId={item?.id}>
                      <CardBody>{item?.campos}</CardBody>
                    </TabPane>
                  ))}

                  <TabPane tabId="Docs">
                    <CardBody>
                      <Row className="justify-between">
                        <Col>
                          <h5>Documentos e atestados</h5>
                        </Col>
                        <Col lg={3}>
                          <InputGroup className="custom">
                            <InputGroupText>
                              <div>Modelo</div>
                            </InputGroupText>
                            <select
                              name="documento"
                              onChange={(e) =>
                                selectModeloDocAtestado(e?.target?.value)
                              }
                            >
                              <option value="">Nenhum</option>
                              <option value="atestado-padrao">
                                Atestado padrão
                              </option>
                              <option value="atestado-secundario">
                                Atestado secundário
                              </option>
                              <option value="documento-padrao">
                                Documento padrão
                              </option>
                              <option value="documento-secundario">
                                Documento secundário
                              </option>
                            </select>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Editor
                            key="documento_atestado"
                            name="documento_atestado"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoPaciente;
