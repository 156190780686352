/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import { Form } from '@unform/web';
import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaPlus, FaMinus } from 'react-icons/fa';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import api from '../../../../services/apiPDV';
import {
  Input,
  ReactSelectAsync,
  CheckBox,
  Radio,
  ReactSelectAsyncColor,
} from '../../../../components/unform';
import getOptions from '../../../../utils/getOptionsPDV';
import { useAuth } from '../../../../contexts/auth';

import 'moment/locale/pt-br';

moment.locale('pt-br');

function makeid() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}

function formatarData(data) {
  if (data) {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${ano}-${mes}-${dia}`;
  }
}

function formatHour(data) {
  if (data) {
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');

    const horaFormatada = `${horas}:${minutos}`;

    return horaFormatada;
  }
}

function extrairHoraMinuto(horaString) {
  if (horaString) {
    const partes = horaString.split(':');
    if (partes.length >= 2) {
      const hora = partes[0];
      const minuto = partes[1];
      return `${hora}:${minuto}`;
    }
    return horaString;
  }
}

function EditarEvento({
  setModal,
  isOpen,
  diaEvento,
  dadosEvento,
  setEvents,
  mainModal,
  idProfissional,
}) {
  const { selectedFilial } = useAuth();
  const formRef = useRef(null);
  const [disabledHOur, setDisabledHour] = useState(false);
  const [optionsMultiplos, setOptionsMultiplos] = useState([]);
  const [tipoEvento, setTipoEvento] = useState('1');
  const [procedimentos, setProcedimentos] = useState([]);
  const [minTotais, setMinutosTotais] = useState(1);

  const horarioData = {
    dia: formatarData(diaEvento?.start),
    horario_inicio: formatHour(diaEvento?.start),
    horario_fim: formatHour(diaEvento?.end),
  };

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f] && f !== 'observacao' && f !== 'allDay') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        const arrayProcedimento = [];
        procedimentos?.forEach((p) => {
          arrayProcedimento.push(p?.value);
        });
        data.id_profissional = idProfissional;
        data.procedimentos = arrayProcedimento;
        data.id_cliente = data?.paciente?.value;

        const { data: agenda } = await api.put(
          `/agenda/${dadosEvento?.agendamento?.id}`,
          data
        );
        const dadosRetorno = agenda;

        const check = moment(data?.data);

        const month = check.format('M');
        const day = check.format('D');
        const year = check.format('YYYY');

        const inicio = data?.hora_inicio;
        const fim = dadosRetorno || data?.hora_fim;

        const inicioSplit = inicio.split(':');
        const fimSplit = fim.split(':');

        setEvents((events) => [
          ...events?.filter((f) => f?.id !== dadosEvento?.agendamento?.id),
          {
            id: dadosEvento?.agendamento?.id,
            allDay: false,
            startDate: new Date(
              year,
              month - 1,
              day,
              inicioSplit[0],
              inicioSplit[1]
            ),
            endDate: new Date(year, month - 1, day, fimSplit[0], fimSplit[1]),
            title:
              tipoEvento === '1' ? data?.paciente?.label : 'Horário Bloqueado',
            atividade: tipoEvento === '1' ? 'atendimento' : 'bloqueio',
            blocked: false,
            textColor: '#f5f6f7',
            cursor: 'pointer',
          },
        ]);

        toast.success('Evento alterado com sucesso!');

        setModal(isOpen);
        mainModal(false);
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            'Ocorreu um erro tente novamente mais tarde!'
        );
      }
    }
  }

  function handleAllDay(e) {
    setDisabledHour(e.target.checked);
    if (e.target.checked) {
      formRef.current.setFieldValue('hora_fim', '');
      formRef.current.setFieldValue('hora_inicio', '');
    } else {
      formRef.current.setFieldValue('hora_fim', horarioData?.horario_fim);
      formRef.current.setFieldValue('hora_inicio', horarioData?.horario_inicio);
    }
  }

  const calcularMinutosTotais = () =>
    procedimentos.reduce(
      (totalMinutos, procedimento) =>
        totalMinutos + (procedimento.duracao_minutos || 0),
      0
    );

  useEffect(() => {
    if (procedimentos?.length) {
      const minutosTotais = calcularMinutosTotais();
      setMinutosTotais(minutosTotais);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedimentos]);

  useEffect(() => {
    setTipoEvento(String(dadosEvento?.agendamento?.tipo));

    setTimeout(() => {
      formRef?.current?.setFieldValue(
        'tipo',
        String(dadosEvento?.agendamento?.tipo)
      );

      formRef?.current?.setFieldValue('data', dadosEvento?.agendamento?.data);
      formRef?.current?.setFieldValue(
        'hora_inicio',
        extrairHoraMinuto(dadosEvento?.agendamento?.hora_inicio)
      );
      formRef?.current?.setFieldValue(
        'hora_fim',
        extrairHoraMinuto(dadosEvento?.agendamento?.hora_fim)
      );
      formRef?.current?.setFieldValue('paciente', {
        value: dadosEvento?.agendamento?.id_cliente,
        label: dadosEvento?.agendamento?.Cliente?.nome,
      });
      formRef?.current?.setFieldValue('id_convenio', {
        value: dadosEvento?.agendamento?.id_convenio,
        label: dadosEvento?.agendamento?.Convenio?.nome,
      });

      formRef?.current?.setFieldValue(
        'observacao',
        dadosEvento?.agendamento?.observacao
      );
      if (dadosEvento?.procedimentosAgenda?.length) {
        for (
          let index = 1;
          index < dadosEvento?.procedimentosAgenda?.length;
          index += 1
        ) {
          setOptionsMultiplos((optionsMultiplosOld) => [
            ...optionsMultiplosOld,
            { id: makeid() },
          ]);
        }

        setTimeout(() => {
          dadosEvento?.procedimentosAgenda?.forEach((f, i) => {
            formRef?.current?.setFieldValue(`procedimento_${i + 1}`, {
              index: i,
              value: f?.id_procedimento,
              label: f?.Procedimento?.nome,
              cor: f?.Procedimento?.cor,
              duracao_minutos: f?.Procedimento?.duracao_minutos,
            });
            setProcedimentos((procedimentosOld) => [
              ...procedimentosOld,
              {
                index: i,
                value: f?.id_procedimento,
                label: f?.Procedimento?.nome,
                cor: f?.Procedimento?.cor,
                duracao_minutos: f?.Procedimento?.duracao_minutos,
              },
            ]);
          });
        }, 200);
      }
    }, 200);
  }, []);

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <ModalHeader
        toggle={() => {
          setModal(false);
        }}
      >
        Editar evento
      </ModalHeader>
      <ModalBody>
        <Row hidden>
          <Radio
            name="tipo"
            className="radio"
            defaultValue={tipoEvento}
            data={[
              { id: '1', label: 'Agendar', value: '1' },
              { id: '2', label: 'Bloquear horário', value: '2' },
            ]}
            disabled
          />
        </Row>
        {tipoEvento === '1' && (
          <>
            <Row>
              <Col lg={10}>
                <ReactSelectAsyncColor
                  label="Procedimentos *"
                  name="procedimento_1"
                  onChange={(p) => {
                    if (p) {
                      const arrayFilter = procedimentos.filter(
                        (f) => f.index !== 0
                      );
                      setProcedimentos(arrayFilter);
                      setTimeout(() => {
                        p.index = 0;
                        setProcedimentos([...arrayFilter, p]);
                      }, 200);
                    } else {
                      const arrayFilter = procedimentos.filter(
                        (f) => f.index !== 0
                      );
                      setProcedimentos(arrayFilter);
                    }
                  }}
                  isClearable
                  loadOptions={async (value) => {
                    const dados = await getOptions({
                      value,
                      route: '/options-procedimento',
                      idFilial: selectedFilial?.filial,
                    });

                    return dados;
                  }}
                />
              </Col>
              <Col lg={2} style={{ marginTop: '30px' }}>
                <Button
                  block
                  color="success"
                  type="button"
                  onClick={() =>
                    setOptionsMultiplos([...optionsMultiplos, { id: makeid() }])
                  }
                >
                  <FaPlus />
                </Button>
              </Col>
            </Row>
            {optionsMultiplos?.map((e, index) => (
              <Row key={e?.id}>
                <Col lg={10}>
                  <ReactSelectAsyncColor
                    name={`procedimento_${index + 2}`}
                    onChange={(p) => {
                      if (p) {
                        const arrayFilter = procedimentos.filter(
                          (f) => f.index !== index + 2
                        );
                        setProcedimentos(arrayFilter);

                        setTimeout(() => {
                          p.index = index + 2;
                          setProcedimentos([...arrayFilter, p]);
                        }, 200);
                      } else {
                        const arrayFilter = procedimentos.filter(
                          (f) => f.index !== index + 2
                        );
                        setProcedimentos(arrayFilter);
                      }
                    }}
                    isClearable
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/options-procedimento',
                        idFilial: selectedFilial?.filial,
                      });
                      return dados;
                    }}
                  />
                </Col>
                <Col lg={2} style={{ marginTop: '22px' }}>
                  <Button
                    block
                    color="danger"
                    type="button"
                    onClick={() => {
                      setOptionsMultiplos(
                        optionsMultiplos.filter((o) => o.id !== e?.id)
                      );
                      const arrayFilter = procedimentos.filter(
                        (f) => f.index !== index + 2
                      );
                      setProcedimentos(arrayFilter);
                    }}
                  >
                    <FaMinus />
                  </Button>
                </Col>
              </Row>
            ))}
            <hr />
            <Row>
              <Col lg={6}>
                <Input
                  name="data"
                  label="Data *"
                  className="form-control"
                  type="date"
                  defaultValue={horarioData?.dia}
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="hora_inicio"
                  label="Hora início *"
                  className="form-control"
                  type="time"
                  defaultValue={horarioData?.horario_inicio}
                />
              </Col>
              <Col lg={3} style={{ marginTop: '35px' }}>
                {minTotais > 0 && <span>+ {minTotais} min</span>}
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactSelectAsync
                  label="Paciente *"
                  name="paciente"
                  fullObj
                  isClearable
                  loadOptions={async (value) => {
                    const data = await getOptions({
                      value,
                      route: '/options-pacientes',
                      idFilial: selectedFilial?.filial,
                    });
                    return data;
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactSelectAsync
                  label="Convênio *"
                  name="id_convenio"
                  isClearable
                  loadOptions={async (value) => {
                    const data = await getOptions({
                      value,
                      route: '/convenios',
                      idFilial: selectedFilial?.filial,
                    });
                    return data;
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="observacao"
                  label="Observação"
                  className="form-control"
                  type="text"
                  placeHolder="Obs"
                />
              </Col>
            </Row>
          </>
        )}
        {tipoEvento === '2' && (
          <>
            <Row>
              <Col lg={6}>
                <Input
                  name="data"
                  label="Data *"
                  className="form-control"
                  type="date"
                  defaultValue={horarioData?.dia}
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="hora_inicio"
                  label="Hora início *"
                  className="form-control"
                  type="time"
                  defaultValue={horarioData?.horario_inicio}
                  disabled={disabledHOur}
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="hora_fim"
                  label="Hora fim *"
                  className="form-control"
                  type="time"
                  defaultValue={horarioData?.horario_fim}
                  disabled={disabledHOur}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckBox
                  defaultChecked={dadosEvento?.allDay}
                  icons={false}
                  name="allDay"
                  label="Dia inteiro"
                  onClick={(e) => handleAllDay(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="observacao"
                  label="Observação"
                  className="form-control"
                  type="text"
                  placeHolder="Obs"
                />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </ModalFooter>
    </Form>
  );
}

export default EditarEvento;
