import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import {
  Input,
  NumberFormat,
  ReactSelectAsync,
} from '../../../components/unform';
import api from '../../../services/apiPDV';
import useValidator from '../../../hooks/useValidator';

function FecharCaixa({ modalProps, toggleModal, setObjCaixa }) {
  const formRef = useRef();

  const { showLoader, closeLoader, showError } = useValidator();

  async function handleSubmit(e) {
    showLoader();
    try {
      await api.post('/caixa/fechar', {
        ...e,
        ...modalProps,
      });
      setObjCaixa({
        bordero: '',
        operador: '',
        id_operaodr: '',
      });
      localStorage.removeItem('caixa');
      toggleModal();
      closeLoader();
    } catch (err) {
      showError(err.mensagem);
    }
  }

  return (
    <>
      <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <Input
                type="password"
                className="form-control"
                label="Senha operador"
                name="senha_operador"
              />
            </Col>
            <Col lg={6}>
              <NumberFormat
                className="form-control"
                prefix="R$"
                placeholder="R$ 0,00"
                label="Aporte final"
                name="aporte_final"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Fechar caixa
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}

export default FecharCaixa;
