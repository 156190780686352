import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';

function Skeleton() {
  return (
    <ContentLoader speed={1.5} width="100%" viewBox="0 0 250 72">
      <rect x="0" y="2" rx="3" ry="3" width="250" height="5" />
      <rect x="0" y="9" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="9" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="9" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="11" r="3" />
      <rect x="0" y="16" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="16" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="16" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="18" r="3" />
      <rect x="0" y="23" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="23" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="23" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="25" r="3" />
      <rect x="0" y="30" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="30" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="30" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="32" r="3" />
      <rect x="0" y="37" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="37" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="37" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="39" r="3" />
      <rect x="0" y="44" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="44" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="44" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="46" r="3" />
      <rect x="0" y="51" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="51" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="51" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="53" r="3" />
      <rect x="0" y="58" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="58" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="58" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="60" r="3" />
      <rect x="0" y="65" rx="3" ry="3" width="72" height="5" />
      <rect x="78" y="65" rx="3" ry="3" width="72" height="5" />
      <rect x="158" y="65" rx="3" ry="3" width="72" height="5" />
      <circle cx="240" cy="67" r="3" />
    </ContentLoader>
  );
}

export default memo(Skeleton);
