/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  FaTrashAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaInfoCircle,
  FaIdCard,
  FaCheck,
  FaHourglassHalf,
  FaTimes,
  FaUserCheck,
  FaRegCalendarAlt,
  FaEdit,
  FaDollarSign,
  FaPlay,
} from 'react-icons/fa';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Modal,
} from 'reactstrap';
import SelectIcon, { components } from 'react-select';
import api from '../../../../services/apiPDV';
import { useAuth } from '../../../../contexts/auth';
import { useValidator } from '../../../../hooks';
import EditarEvento from './editarEvento';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const options = [
  {
    value: 'Agendado',
    label: 'Agendado',
    icon: <FaRegCalendarAlt className="mr-3" />,
  },
  {
    value: 'Confirmado',
    label: 'Confirmado',
    icon: <FaCheck color="blue" className="mr-3" />,
  },
  {
    value: 'Aguardando',
    label: 'Aguardando',
    icon: <FaHourglassHalf color="yellow" className="mr-3" />,
  },
  {
    value: 'Não compareceu',
    label: 'Não compareceu',
    icon: <FaTimes color="red" className="mr-3" />,
  },
  {
    value: 'Atendido',
    label: 'Paciente atendido',
    icon: <FaUserCheck color="green" className="mr-3" />,
  },
];

const Option = (props) => (
  <components.Option {...props} className="country-option">
    {props?.data?.icon}
    {props?.data?.label}
  </components.Option>
);

function formatarDataComDiaDaSemana(dataString) {
  if (dataString) {
    const partes = dataString.split('-');
    const ano = parseInt(partes[0], 10);
    const mes = parseInt(partes[1], 10) - 1;
    const dia = parseInt(partes[2], 10);

    const diasDaSemana = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];

    const data = new Date(ano, mes, dia);
    const diaSemana = diasDaSemana[data.getDay()];

    const dataFormatada = `${String(dia).padStart(2, '0')}/${String(
      mes + 1
    ).padStart(2, '0')}/${ano}`;

    return `${dataFormatada} (${diaSemana})`;
  }
}

function extrairHoraMinuto(horaString) {
  if (horaString) {
    const partes = horaString.split(':');
    if (partes.length >= 2) {
      const hora = partes[0];
      const minuto = partes[1];
      return `${hora}:${minuto}`;
    }
    return horaString;
  }
}

function VizualizarEvento({
  setModal,
  edicao,
  setEvents,
  diaEvento,
  idProfissional,
}) {
  const { selectedFilial } = useAuth();
  const [dados, setDados] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(options[0]);
  const { showLoader, closeLoader } = useValidator();
  const [modalEdit, setModalEdit] = useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'ml-3 btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  const handleChangeStatus = (value) => {
    setSelectedStatus(value);
  };

  async function getDados(id) {
    const { data } = await api.get(
      `/agendamento/${id}?id_filial=${selectedFilial?.filial}&id_profissional=${idProfissional}`
    );

    setDados(data);

    let status = {};
    options.forEach((opt) => {
      if (opt?.value === data?.agendamento?.status) {
        status = opt;
      }
    });

    setSelectedStatus(status);
  }

  useEffect(() => {
    getDados(edicao?.id);
  }, []);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedStatus?.icon}
      {children}
    </components.SingleValue>
  );

  async function salvaStatus(e) {
    try {
      await api.put(`/agenda/${edicao?.id}`, {
        status: e?.value,
      });
      toast.success('Evento atualizado com sucesso!');
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          'Ocorreu um erro tente novamente mais tarde!'
      );
    }
  }

  async function handleDeletar() {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir esse agendamento?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          showLoader();
          api
            .delete(`/agendamento/${edicao?.id}`)
            .then(() => {
              toast('Agendamento excluido com sucesso!', { type: 'success' });
              setEvents((events) =>
                events?.filter((f) => f?.id !== edicao?.id)
              );
              setModal(false);
              closeLoader();
            })
            .catch((error) => {
              toast(error.mensagem);
              closeLoader();
            });
        }
      });
  }

  async function handleEditar() {
    setModalEdit(true);
  }

  async function handleLancar() {
    console.log('Lançar');
  }

  return (
    <>
      <ModalHeader
        toggle={() => {
          setModal(false);
        }}
      >
        Detalhes
      </ModalHeader>
      <ModalBody>
        {dados?.agendamento?.tipo === 1 && (
          <>
            <Row className="mt-1">
              <Col className="ml-1 mt-2">
                <h5 style={{ color: 'gray' }}>
                  {formatarDataComDiaDaSemana(dados?.agendamento?.data)} -{' '}
                  {extrairHoraMinuto(dados?.agendamento?.hora_inicio)} às{' '}
                  {extrairHoraMinuto(dados?.agendamento?.hora_fim)}
                </h5>
              </Col>
              <Col lg={4}>
                <SelectIcon
                  name="status"
                  value={selectedStatus}
                  options={options}
                  onChange={(e) => {
                    handleChangeStatus(e);
                    salvaStatus(e);
                  }}
                  defaultValue={dados?.agendamento?.status}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={1} className="justify-center mt-1">
                <FaUserAlt />
              </Col>
              <Col>
                <h5>{dados?.agendamento?.Cliente?.nome}</h5>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={1} className="justify-center mt-1">
                <FaPhoneAlt />
              </Col>
              <Col>
                <h5>
                  ({dados?.agendamento?.Cliente?.telefone_ddd}){' '}
                  {dados?.agendamento?.Cliente?.telefone}
                </h5>
              </Col>
              <Col lg={1} className="justify-center mt-1">
                <FaIdCard />
              </Col>
              <Col>
                <h5>
                  <strong>Convênio: </strong>
                  {dados?.agendamento?.Convenio?.nome}
                </h5>
              </Col>
            </Row>
            <hr className="mb-4" />
            <Row>
              <Col lg={1} className="justify-center mt-1">
                <FaInfoCircle />
              </Col>
              <Col>
                <h6>
                  <strong>Obs: </strong>
                  {dados?.agendamento?.observacao || 'N/A'}
                </h6>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Duração</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dados?.procedimentosAgenda?.length ? (
                      dados?.procedimentosAgenda?.map((c) => (
                        <tr key={c?.id}>
                          <td
                            style={{
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                width: '7px',
                                height: '100%',
                                backgroundColor: c?.Procedimento?.cor,
                                position: 'absolute',
                                left: '0',
                                top: '0',
                              }}
                            />
                          </td>
                          <td>{c?.Procedimento?.nome}</td>
                          <td>{c?.Procedimento?.tipo}</td>
                          <td>{c?.Procedimento?.duracao_minutos} min</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>Sem registros encontrados</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {dados?.agendamento?.tipo === 2 && (
          <>
            <Row className="justify-between">
              <Col className="ml-1 mt-2">
                <h5 style={{ color: 'gray' }}>
                  {formatarDataComDiaDaSemana(dados?.agendamento?.data)} -{' '}
                  {extrairHoraMinuto(dados?.agendamento?.hora_inicio)} às{' '}
                  {extrairHoraMinuto(dados?.agendamento?.hora_fim)}
                </h5>
              </Col>
              <Col className="ml-1 mt-2" lg={3}>
                <h5>Horário bloqueado</h5>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={1} className="justify-center mt-1">
                <FaInfoCircle />
              </Col>
              <Col>
                <h6>
                  <strong>Obs: </strong>
                  {dados?.agendamento?.observacao || 'N/A'}
                </h6>
              </Col>
            </Row>
            <hr />
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-between">
        <div>
          <Button color="outline-danger" type="button" onClick={handleDeletar}>
            <FaTrashAlt /> Excluir
          </Button>
          <Button
            className="ml-3"
            color="outline-primary"
            type="button"
            onClick={handleEditar}
          >
            <FaEdit /> Editar
          </Button>
          <Button
            className="ml-3"
            color="outline-success"
            type="button"
            onClick={handleLancar}
            hidden={dados?.agendamento?.tipo === 2}
          >
            <FaDollarSign /> Lançar
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            type="button"
            hidden={dados?.agendamento?.tipo === 2}
          >
            <FaPlay size={10} /> Iniciar atendimento
          </Button>
        </div>
      </ModalFooter>

      <Modal isOpen={modalEdit} size="md">
        <EditarEvento
          setEvents={setEvents}
          setModal={setModalEdit}
          isOpen={!modalEdit}
          diaEvento={diaEvento}
          dadosEvento={dados}
          mainModal={setModal}
          idProfissional={idProfissional}
        />
      </Modal>
    </>
  );
}

export default VizualizarEvento;
