import React, { useEffect, useRef, useState } from 'react';
import { FiDisc, FiEdit, FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaArrowDown,
  FaArrowUp,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';

import NovoProduto from './novoProduto';

function Produtos() {
  const filtrosRef = useRef();
  const ufRef = useRef();

  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });

  const [page, setPage] = useState(1);
  const { showLoader, closeLoader, toast } = useValidator();

  const [modal, setModal] = useState({ open: false, type: '' });

  const [showInnerTable, setShowInnerTable] = useState({});

  const handleRowClick = (index) => {
    setShowInnerTable((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleModal = (type, objEdit = null, fornecedores = []) =>
    setModal({ open: !modal?.open, type, objEdit, fornecedores });

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(`/estoque?page=${p + 1}${filtros}`);

      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  useEffect(() => {
    const getDados = async () => {
      try {
        showLoader();
        handleChangePage(0);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    };

    getDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filtrarPesquisa(tipo) {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      if (tipo === 'uf') {
        ufRef.current.submitForm();
      } else {
        filtrosRef.current.submitForm();
      }
    }, 500);
  }

  async function handleAtivarInativar(id) {
    try {
      showLoader();
      await api.put(`/produto/ativar/${id}`);
      handleChangePage(0);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Estoque</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>

          <Button
            color="primary"
            className="btn btn-primary"
            onClick={() => toggleModal('add')}
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Descrição"
                    name="descricao"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="EAN"
                    name="ean"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>
        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th className="text-center">Variações</th>
              <th>Código</th>
              <th>Descrição</th>
              <th>Marca</th>
              <th>Fabricante</th>
              <th>Tipo</th>
              <th>Seção</th>
              <th className="text-center">Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m, index) => (
              <>
                <tr key={m?.id}>
                  <td data-label="Variações" className="text-center">
                    <Button color="link" onClick={() => handleRowClick(index)}>
                      {showInnerTable[index] ? (
                        <FaArrowUp size={10} />
                      ) : (
                        <FaArrowDown size={10} />
                      )}
                    </Button>
                  </td>
                  <td data-label="Código">{m?.produto?.codigo}</td>
                  <td data-label="Descrição">{m?.produto?.descricao}</td>
                  <td data-label="Marca">{m?.produto?.Marca?.descricao}</td>
                  <td data-label="Fabricante">
                    {m?.produto?.Fabricante?.nome}
                  </td>
                  <td data-label="Tipo">{m?.produto?.Tipo?.descricao}</td>
                  <td data-label="Seção">{m?.produto?.Secao?.descricao}</td>
                  <td data-label="Status" className="text-center">
                    {m?.produto?.status}
                  </td>
                  <td data-label="Action">
                    <div className="d-flex justify-content-lg-end justify-content-sm-between">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiSettings className="mr-2" /> <FaChevronDown />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <DropdownItem
                            onClick={() =>
                              toggleModal('edit', m?.produto, m?.fornecedores)
                            }
                          >
                            <FiEdit className="mr-1" />
                            Editar
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              handleAtivarInativar(m?.produto?.id);
                            }}
                          >
                            <FiDisc className="mr-1" />
                            Inativar / Ativar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                {showInnerTable[index] && (
                  <>
                    {m?.variacoes?.length ? (
                      <tr>
                        <td colSpan="9" style={{ width: '100%' }}>
                          <Table>
                            <thead>
                              <tr>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th className="text-center">Estoque</th>
                              </tr>
                            </thead>
                            <tbody>
                              {m?.variacoes?.map((v) => (
                                <tr key={`Prod${m?.id}-Vari${v?.id}`}>
                                  <td className="w-50" data-label="Descrição">
                                    {v.cor} - {v.unidade_tamano}
                                    {v.tamanho}
                                  </td>
                                  <td data-label="Valor">
                                    {Number(v.preco_venda).toLocaleString(
                                      'pt-BR',
                                      {
                                        currency: 'BRL',
                                        style: 'currency',
                                      }
                                    )}
                                  </td>
                                  <td
                                    data-label="Estoque"
                                    className="text-center"
                                  >
                                    {v?.ProdutoVariacaoEstoques?.length ? (
                                      <>
                                        {v?.ProdutoVariacaoEstoques?.map(
                                          (estoque) => (
                                            <p>
                                              {estoque?.Filial?.nome} -{' '}
                                              {estoque?.estoque_disponivel} un
                                            </p>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <p> Sem estoque cadastrado</p>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="9" style={{ width: '100%' }}>
                          Sem variações cadastradas
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <NovoProduto
        toggleModal={toggleModal}
        modal={modal}
        refresh={handleChangePage}
      />
    </>
  );
}

export default Produtos;
