import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  CardFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import api from '../../services/apiBank';

import { NumberFormat, Input, Select } from '../../components/unform/index';

function Pagamento() {
  const formRef = useRef();
  const formRefPagar = useRef();

  const [filtro, setFiltro] = useState({});

  const [activeTab, setActiveTab] = useState('1');
  const [dadosPagamento, setDadosPagamento] = useState(null);
  const [modal, setModal] = useState(false);

  const [pagamentos, setPagamentos] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleModal = (e) => {
    setModal(!modal);
  };

  async function validaCodigo(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        if (f !== 'descricao') {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        let body = {};
        if (e?.tipo === 'linha_digitavel') {
          body = { linha_digitavel: e?.code };
        }
        if (e?.tipo === 'codigo_barras') {
          body = { codigo_barras: e?.code };
        }
        const { data } = await api.post('/boleto/validar', body);
        setDadosPagamento(data);
      } catch (err) {
        const error =
          err?.response?.data?.mensagem || 'Ocorreu um erro tente novamente';
        toast.error(error);
      }
      Swal.close();
    }
  }

  async function handleSubmit(e) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const body = {
        id: dadosPagamento?.id,
        valor: e?.valor,
        senha_conta: e?.senha,
        codigo: e?.codigo,
      };
      await api.post('/pagamentos/pagar-pj', body);
      setDadosPagamento(null);
      toggleModal();
      toast.success('Operação realizada com sucesso!');
    } catch (err) {
      const error =
        err?.response?.data?.error || 'Ocorreu um erro tente novamente';
      toast.error(error);
    }
    Swal.close();
  }

  function formatDate(date) {
    if (date) {
      const dataArray = date?.split('T')[0]?.split('-');
      return `${dataArray[2]}/${dataArray[1]}/${dataArray[0]}`;
    }
    return 'Sem data';
  }

  async function getPagamentos() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      if (params) {
        const { data, headers } = await api.get(`/pagamentos/listar?${params}`);
        const retorno = {
          pagamentos: data,
          total: headers['x-total-count'],
          pages: headers['x-total-pages'],
        };
        setPagamentos(retorno);
        window.scrollTo(0, 0);
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar agendamentos!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Pagamento</h4>
        </Col>
      </Row>
      <Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Realizar pagamento
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' && 'active'}
              onClick={() => {
                getPagamentos();
                toggle('3');
              }}
            >
              Pagamentos
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              ref={formRef}
              onSubmit={(e) => {
                validaCodigo(e);
              }}
            >
              <CardBody>
                <Row>
                  <Col lg={8}>
                    <Input
                      label="Código"
                      className="form-control"
                      type="text"
                      name="code"
                    />
                  </Col>
                  <Col>
                    <Select
                      name="tipo"
                      label="Tipo do código"
                      className="form-control"
                      data={[
                        { label: 'Linha digitável', value: 'linha_digitavel' },
                        { label: 'Código de barras', value: 'codigo_barras' },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Validar código
                </Button>
              </CardFooter>
            </Form>
            {dadosPagamento && (
              <>
                <hr />
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col>
                        <h6 className="mb-3">Tipo de código</h6>
                        <h5 className="mb-3">{dadosPagamento?.tipo_codigo}</h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Código</h6>
                        <h5 className="mb-3">
                          {dadosPagamento?.codigo_ou_digitavel}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <h6 className="mb-3">Cedente</h6>
                        <h5 className="mb-3">{dadosPagamento?.cedente}</h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Recebedor</h6>
                        <h5 className="mb-3">
                          {dadosPagamento?.nome_beneficiario}
                        </h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Pagador</h6>
                        <h5 className="mb-3">{dadosPagamento?.nome_pagador}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6 className="mb-3">Valor final</h6>
                        <h5 className="mb-3">
                          {Number(
                            dadosPagamento?.valor_final || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Valor original</h6>
                        <h5 className="mb-3">
                          {Number(
                            dadosPagamento?.valor_original || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Juros e multa</h6>
                        <h5 className="mb-3">
                          {Number(
                            dadosPagamento?.valor_multa_juros || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Desconto total</h6>
                        <h5 className="mb-3">
                          {Number(
                            dadosPagamento?.valor_desconto_total || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </h5>
                      </Col>
                      <Col>
                        <h6 className="mb-3">Vencimento</h6>
                        <h5 className="mb-3">
                          {dadosPagamento?.data_vencimento
                            ? formatDate(dadosPagamento?.data_vencimento)
                            : 'N/A'}
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => toggleModal()}
                    >
                      Pagar agora
                    </Button>
                  </CardFooter>
                </Card>
              </>
            )}
          </TabPane>
          <TabPane tabId="3">
            <div className="p-3 btn-toolbar">
              <Col lg={6}>
                <h6 className="txt-muted">
                  Total de registros: {pagamentos.total}
                </h6>
              </Col>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Tipo código</th>
                  <th>Código</th>
                  <th>Valor original</th>
                  <th>Valor final</th>
                  <th>Valor pago</th>
                  <th>Vencimento</th>
                  <th>Pagamento</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {pagamentos?.pagamentos?.length ? (
                  pagamentos?.pagamentos?.map((c) => (
                    <tr key={c?.id}>
                      <td>{c?.tipo_codigo}</td>
                      <td>{c?.codigo_ou_digitavel}</td>
                      <td>
                        {Number(c?.valor_original || 0).toLocaleString(
                          'pt-BR',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                      </td>
                      <td>
                        {Number(c?.valor_final || 0).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(c?.valor_pago || 0).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {c?.data_vencimento
                          ? formatDate(c?.data_vencimento)
                          : 'N/A'}
                      </td>
                      <td>
                        {c?.data_pagamento
                          ? formatDate(c?.data_pagamento)
                          : 'N/A'}
                      </td>
                      <td>{c?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={pagamentos.pages || 1}
              onPageChange={({ selected }) =>
                setFiltro({ ...filtro, page: selected + 1 })
              }
              initialPage={0}
              containerClassName="pagination justify-end mr-1"
              activeClassName="active"
            />
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Pagar agora</ModalHeader>
        <Form
          ref={formRefPagar}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Col>
              <NumberFormat
                name="valor"
                label="Valor"
                className="form-control"
                thousandSeparator="."
                prefix="R$"
                decimalSeparator=","
              />
            </Col>
            <Row>
              <Input
                label="Senha"
                className="form-control"
                type="password"
                name="senha"
              />
            </Row>
            <Row>
              <Input
                label="Código gerado pelo APP"
                className="form-control"
                type="text"
                name="codigo"
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Ok
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Pagamento;
