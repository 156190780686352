import React, { useEffect, useRef, useState } from 'react';
import { FiEdit, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, ReactSelectAsync } from '../../../components/unform/index';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';
import { useAuth } from '../../../contexts/auth';

function Dashboard() {
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const selectOptions = useRef();
  const [page, setPage] = useState(1);
  const filtrosRef = useRef();
  const formRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const { idFilial } = useAuth();

  const [offcanvas, setOffcanvas] = useState(false);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);
  const [defaultOptions, setDefaultOptions] = useState([]);
  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }
  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(
        `/filial/descontomanual?page=${p + 1}&id_filial=${idFilial}${filtros}`
      );
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(
      `/condicao-pagamento?options=true&descricao=${inputValue}`
    );

    return data;
  }

  const optionsConsulta = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.culturas = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  const handleSubmit = async (e) => {
    const errors = {};

    if (!e.id_condicao_pagamento) {
      errors.id_condicao_pagamento = 'Obrigatório';
    }
    if (!e.percentual_desconto_maximo) {
      errors.percentual_desconto_maximo = 'Obrigatório';
    } else if (
      Number.isNaN(Number(e.percentual_desconto_maximo)) ||
      Number(e.percentual_desconto_maximo) < 1 ||
      Number(e.percentual_desconto_maximo) > 99
    ) {
      errors.percentual_desconto_maximo = 'Valor inválido';
    }

    formRef.current.setErrors(errors);

    if (errors.id_condicao_pagamento || errors.percentual_desconto_maximo) {
      toast();
      return;
    }

    try {
      showLoader();
      if (offcanvas?.id) {
        await api.put(`/filial/descontomanual/${offcanvas?.id}`, {
          ...e,
          id_filial: idFilial,
        });
      } else {
        await api.post('/filial/descontomanual', {
          ...e,
          id_filial: idFilial,
        });
      }
      filtrosRef.current.reset();
      toggleOffcanvas();
      await handleChangePage(0);
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  useEffect(() => {
    async function getDefaultOptions() {
      const { data } = await api.get('/condicao-pagamento?options=true');
      setDefaultOptions(data);
    }
    async function getData() {
      try {
        showLoader();

        const { data } = await api.get(
          `/filial/descontomanual?id_filial=${idFilial}`
        );
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
    getData();
    getDefaultOptions();
  }, [idFilial]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Desconto manual</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button onClick={toggleOffcanvas} color="primary">
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => handleChangePage(0)} ref={filtrosRef}>
              <Row>
                <Col lg={4}>
                  <ReactSelectAsync
                    isClearable
                    label="Condicao pagamento"
                    name="id_condicao_pagamento"
                    loadOptions={optionsConsulta}
                    defaultOptions={defaultOptions}
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Condição pagamento</th>
              <th>Percentual max.</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id}>
                <td data-label="Condição pgto.">
                  {m?.CondicaoPagamento?.descricao}
                </td>
                <td data-label="Perc. max.">{m.percentual_desconto_maximo}%</td>
                <td
                  data-label="Ação"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        onClick={() =>
                          setOffcanvas({
                            ...m,
                            id_condicao_pagamento: {
                              label: m?.CondicaoPagamento?.descricao,
                              value: m?.CondicaoPagamento?.id,
                            },
                          })
                        }
                      >
                        <FiEdit className="mr-1" />
                        Editar
                      </DropdownItem>
                      {/* <DropdownItem>
                        <FiTrash className="mr-1" />
                        Excluir
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages || 1}
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={page - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Offcanvas direction="end" isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          {offcanvas?.id ? 'Alterar desconto manual' : 'Novo desconto manual'}
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={12}>
                <ReactSelectAsync
                  label="Condicao pagamento *"
                  name="id_condicao_pagamento"
                  loadOptions={optionsConsulta}
                  defaultValue={offcanvas?.id_condicao_pagamento || null}
                />
              </Col>
              <Col lg={12}>
                <Input
                  type="number"
                  min={1}
                  max={99}
                  placeholder="1-99"
                  className="form-control"
                  label="Percentual desconto máximo *"
                  name="percentual_desconto_maximo"
                  defaultValue={offcanvas?.percentual_desconto_maximo || null}
                />
              </Col>
            </Row>

            <Button className="mb-0" block color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default Dashboard;
