import React from 'react';

import uneohome from '../assets/images/uneohome.png';

function Saudacoes() {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <img
        className="object-fit w-60"
        src={uneohome}
        alt="unindo você ao que realmente importa"
      />
    </div>
  );
}

export default Saudacoes;
