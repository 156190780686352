import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function CampoTextoLongo({
  name,
  label,
  type,
  className = '',
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current?.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>

      <textarea
        ref={inputRef}
        defaultValue={defaultValue}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
