/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import {
  Input,
  ReactSelectAsync,
  Select,
} from '../../../components/unform/index';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import getQuery from '../../../utils/getQuery';
import { transferencia } from '../../../utils/status';
import { useAuth } from '../../../contexts/auth';

function ListarTransferencias() {
  const { isAdmin } = useAuth();
  const [dados, setDados] = useState({ page: 1 });
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/options/vendedores?value=${inputValue}`);

    return data;
  }

  const getOptions = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(
        `/transferencias?page=${page || 1}&${query}`
      );
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/transferencias');
        setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div className="pl-3 pr-5" />
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                {isAdmin && (
                  <Col>
                    <ReactSelectAsync
                      placeholder="Vendedor"
                      name="id_vendedor_zoop"
                      defaultOptions
                      isClearable
                      loadOptions={getOptions}
                      onChange={filtrarPesquisa}
                    />
                  </Col>
                )}
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Id zoop"
                    name="transferencia_id_zoop"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              {isAdmin && <th>Vendedor</th>}
              <th>Valor</th>
              <th>Data transferência</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                {isAdmin && (
                  <td data-label="Vendedor">{m?.VendedorZoop?.Pessoa?.nome}</td>
                )}
                <td data-label="Valor">
                  <div>
                    {Number(m.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                </td>
                <td data-label="Data">
                  {new Date(m.data_transferencia)
                    .toLocaleString('pt-BR')
                    .substring(0, 17)
                    .replace(',', '')}
                </td>
                <td data-label="Status">
                  {transferencia[m.status] || m.status}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ListarTransferencias;
