import React, { useEffect, useRef } from 'react';
import ReactInputMask from 'react-text-mask';
import { useField } from '@unform/core';

export default function MaskInput({
  name,
  label,
  removeEspecial = true,
  className = '',
  labelClass = '',
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        if (removeEspecial) {
          return ref.textMaskInputElement.state.previousConformedValue.replace(
            /[^A-Z0-9]/gi,
            ''
          );
        }
        return ref.textMaskInputElement.state.previousConformedValue;
      },
      setValue: (ref, value) => {
        ref.inputElement.value = value;
        ref.textMaskInputElement.update();
      },
    });
  }, [fieldName, registerField, removeEspecial]);
  return (
    <div className="form-group">
      <label
        htmlFor={fieldName}
        className={'form-label '.concat(labelClass).trim()}
      >
        {label}
      </label>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
