import React, { createContext, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import api, { baseURL } from '../services/api';
import apiPdv from '../services/apiPDV';
import apiBank from '../services/apiBank';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [perfil, setPerfil] = useState('');
  const [socket, setSocket] = useState(null);
  const [chatCount, setChatCount] = useState(0);
  const [idFilial, setIdFilial] = useState(1);
  const [filiais, setFiliais] = useState([]);
  const [tipoPdv, setTipoPdv] = useState(null);
  const [modulo, setModuloEscolhido] = useState(
    localStorage.getItem('moduloerp')
  );
  const [acessos, setAcessos] = useState({
    acessos: [],
    filiais: [],
    load: false,
  });
  const [selectedFilial, setSelectedFilial] = useState(null);

  async function signOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('modulo');
    localStorage.removeItem('moduloerp');
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = null;
    apiPdv.defaults.headers.Authorization = null;
    apiBank.defaults.headers.Authorization = null;
    setUser(null);
    setModuloEscolhido(null);
  }

  function setModulo(moduloEscolhido) {
    setModuloEscolhido(moduloEscolhido);
    if (moduloEscolhido) {
      localStorage.setItem('moduloerp', moduloEscolhido);
    } else {
      localStorage.removeItem('moduloerp');
    }
  }

  function signIn(response, tModulo) {
    const usuario = response.user || response.usuario;

    localStorage.setItem('modulo', tModulo);
    if (tModulo === 'erp') {
      apiPdv.defaults.headers.Authorization = `Bearer ${response.token}`;
    } else if (tModulo === 'pay') {
      api.defaults.headers.Authorization = `Bearer ${response.token}`;
      setSocket(
        io(baseURL, {
          extraHeaders: {
            Authorization: `Bearer ${response.token}`,
          },
        })
      );
    } else if (tModulo === 'bank') {
      apiBank.defaults.headers.Authorization = `Bearer ${response.token}`;
    }
    localStorage.setItem(`token-${tModulo}`, response.token);
    setUser((old = {}) => {
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...old,
          [tModulo]: usuario,
        })
      );
      return {
        ...old,
        [tModulo]: usuario,
      };
    });
  }

  useEffect(() => {
    async function loadStorageData() {
      apiPdv.interceptors.response.use(
        (res) => res,
        (error) => {
          const err = error?.response?.data?.error;
          const errMsg = error?.response?.data?.message;
          error.mensagem =
            (typeof err === 'string' && err) ||
            (typeof errMsg === 'string' && errMsg) ||
            'Ocorreu algum problema, tente novamente mais tarde!';
          error.alert = error?.response?.data?.alert || 'toast';
          error.titulo = error?.response?.data?.title || 'Ops...';
          error.errors = error?.response?.data?.errors || {};

          if (error?.response?.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        }
      );
      api.interceptors.response.use(
        (res) => res,
        (error) => {
          const err = error?.response?.data?.error;
          const errMsg = error?.response?.data?.message;
          error.mensagem =
            (typeof err === 'string' && err) ||
            (typeof errMsg === 'string' && errMsg) ||
            'Ocorreu algum problema, tente novamente mais tarde!';
          error.alert = error?.response?.data?.alert || 'toast';
          error.titulo = error?.response?.data?.title || 'Ops...';
          error.errors = error?.response?.data?.errors || {};

          if (error?.response?.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        }
      );
      apiBank.interceptors.response.use(
        (res) => res,
        (error) => {
          const err = error?.response?.data?.error;
          const errMsg = error?.response?.data?.message;
          error.mensagem =
            (typeof err === 'string' && err) ||
            (typeof errMsg === 'string' && errMsg) ||
            'Ocorreu algum problema, tente novamente mais tarde!';
          error.alert = error?.response?.data?.alert || 'toast';
          error.titulo = error?.response?.data?.title || 'Ops...';
          error.errors = error?.response?.data?.errors || {};

          if (error?.response?.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        }
      );

      const {
        user: storagedUser,
        'token-erp': storagedTokenErp,
        'token-pay': storagedTokenPay,
        'token-bank': storagedTokenBank,
      } = localStorage;

      if (storagedUser) {
        if (storagedTokenErp) {
          apiPdv.defaults.headers.Authorization = `Bearer ${storagedTokenErp}`;
        } else if (storagedTokenPay) {
          api.defaults.headers.Authorization = `Bearer ${storagedTokenPay}`;
          setSocket(
            io(baseURL, {
              extraHeaders: {
                Authorization: `Bearer ${storagedTokenPay}`,
              },
            })
          );
        } else if (storagedTokenBank) {
          apiBank.defaults.headers.Authorization = `Bearer ${storagedTokenErp}`;
        }

        setUser(JSON.parse(storagedUser));
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  useEffect(() => {
    async function getAcessos() {
      try {
        const { data } = await api.get('/acessos');
        setPerfil(data?.perfil);
        setAcessos({
          ...data,
          load: true,
        });
      } catch (err) {
        console.log(err?.response?.data);
      }
    }
    let socketIo = null;
    if (user?.pay) {
      getAcessos();
      const token = localStorage.getItem('token-pay');
      socketIo = io(baseURL, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      socketIo?.on('connect', () => {
        console.log('ws connected');
      });
      socketIo?.on('pendentes', (data) => {
        setChatCount(data?.count);
      });
      setSocket(socketIo);

      return () => socketIo?.close();
    }
    setPerfil('');
  }, [user]);

  useEffect(() => {
    const loadFiliais = async () => {
      try {
        const { data } = await apiPdv.get('/options/filial');
        setFiliais(data);

        if (!selectedFilial) {
          setSelectedFilial({ filial: data[1]?.value });
        }
      } catch (err) {
        setFiliais([]);
      }
    };
    if (user?.erp) {
      loadFiliais();
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signIn,
        signOut,
        socket,
        setSocket,
        chatCount,
        setChatCount,
        perfil,
        isAdmin: perfil === 'Admin',
        modulo,
        setModulo,
        setTipoPdv,
        tipoPdv,
        acessos,
        setAcessos,
        idFilial,
        setIdFilial,
        filiais,
        setFiliais,
        selectedFilial,
        setSelectedFilial,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
