import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCollapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  FaArrowDown,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaArrowLeft,
  FaMinus,
  FaTrashAlt,
} from 'react-icons/fa';

import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';

import { Input, Editor, ReactSelectAsync } from '../../../../components/unform';
import getOptions from '../../../../utils/getOptionsPDV';

import ImgCalculoDppIg from '../../../../assets/images/campos/calculo_dpp_ig.png';
import ImgCurvaCrescimento from '../../../../assets/images/campos/curva_crescimento.png';
import ImgOdontograma from '../../../../assets/images/campos/odontograma.png';
import ImgPrescricaoOculos from '../../../../assets/images/campos/prescricao_oculos.png';
import ImgCalculoImc from '../../../../assets/images/campos/calculo_imc.png';
import ImgCampoData from '../../../../assets/images/campos/data.png';
import ImgCampoMultiplo from '../../../../assets/images/campos/multipla_escolha.png';
import ImgCampoNumerico from '../../../../assets/images/campos/numerico.png';
import ImgCampoOpcao from '../../../../assets/images/campos/opcao.png';
import ImgCampoTextoCurto from '../../../../assets/images/campos/texto_curto.png';
import ImgCampoTextoLongo from '../../../../assets/images/campos/texto_longo.png';
import ImgCampoVerdadeiroFalso from '../../../../assets/images/campos/verdadeiro_falso.png';

function makeid() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function normalizeLabel(label) {
  return label
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\W+/g, '-')
    .toLowerCase();
}

function getDefaultValueLabel(campo) {
  if (campo === 'CalculoImc') {
    return 'Cálculo IMC';
  }
  if (campo === 'PrescricaoOculos') {
    return 'Prescrição de óculos';
  }
  if (campo === 'Odontograma') {
    return 'Odontograma';
  }
  if (campo === 'CalculoDppIg') {
    return 'IG e DPP';
  }
  if (campo === 'CurvaCrescimento') {
    return 'Curva de Crescimento';
  }
  return '';
}

function defaultValueAtestado(tipo) {
  if (tipo === 'atestado_padrao') {
    return `
    Atesto para os devidos fins a pedido do interessado que _______,
    portador do documento de identidade _______,
    foi submetido à consulta médica nesta data.
    <br/>
    <br/>
    Em decorrência, deverá permanecer afastado de suas atividades
    laborativas por um período de _______ dias a partir desta data.
    `;
  }
  if (tipo === 'atestado_secundario') {
    return `
    Atesto para os devidos fins a pedido do interessado que _______,
    portador do documento de identidade _______ foi
    submetido à consulta médica nesta data,
    sendo portador de afecção CID-10 ___________________.
    <br/>
    <br/>
    Em decorrência, deverá permanecer afastado de suas
     atividades laborativas por um período de _______ dias a partir desta data.
    `;
  }
}

function NovoPaciente() {
  const history = useHistory();
  const { selectedFilial } = useAuth();

  const search = useSearch();
  const formRef = useRef();
  const formRefAdicionarCampo = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [activeTab, setActiveTab] = useState('1');
  const [secoes, setSecoes] = useState([]);
  const [modal, setModal] = useState(false);

  const [campoAdicionar, setCampoAdicionar] = useState(null);
  const [optionsMultiplos, setOptionsMultiplos] = useState([]);
  const [objetoCampo, setObjetoCampos] = useState({
    secoes: [],
    documentos: {
      atestado_padrao: defaultValueAtestado('atestado_padrao'),
      atestado_secundario: defaultValueAtestado('atestado_secundario'),
      documento_padrao: null,
      documento_secundario: null,
    },
    // exames: {
    //   campos: [],
    // },
    // prescricoes: {
    //   campos: [],
    // },
  });

  function resetCampos() {
    setCampoAdicionar(null);
    setOptionsMultiplos([]);
  }

  const toggleModal = () => {
    setModal(!modal);
    resetCampos();
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleSubmit = async (e) => {
    const errors = {};

    const camposNaoObrigatorios = [
      'atestado_padrao',
      'atestado_secundario',
      'documento_padrao',
      'documento_secundario',
    ];

    Object.keys(e).forEach((f) => {
      if (!camposNaoObrigatorios.includes(f)) {
        if (!e[f]) {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      showLoader();
      try {
        const secoesArray = [];
        Object.keys(e).forEach((f) => {
          if (f.includes('nome_secao_')) {
            setSecoes(
              secoes?.forEach((m) => {
                if (m?.id_secao === f?.split('_')[2]) {
                  secoesArray.push({
                    id_secao: m?.id_secao,
                    nome: e[f],
                  });
                }
              })
            );
          }
        });

        const secoesObj = {
          secoes: secoesArray,
          obj: objetoCampo?.secoes,
        };
        const body = {
          nome: e?.nome,
          secao: secoesObj,
          documentos_atestados: objetoCampo?.documentos,
          id_filial: selectedFilial?.filial,
          id_profissional: e?.id_profissional,
        };
        if (search?.id) {
          await api.put(`/prontuario/${search?.id}`, body);
        } else {
          e.id_filial = selectedFilial?.filial;
          await api.post('/prontuario', body);
        }
        closeLoader();
        history.goBack();
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
  };

  function objetctReturn(rest) {
    const arraySecoes = [];
    try {
      Object.keys(rest).forEach((value) => {
        if (rest[value]) {
          if (typeof rest[value] === 'object') {
            Object.keys(rest[value]).forEach((f) => {
              if (f === 'secoes') {
                if (rest[value][f]) {
                  const obj = rest[value][f];
                  obj?.forEach((v) => {
                    arraySecoes.push({ id_secao: v?.id_secao, nome: v?.nome });
                  });
                  setSecoes(arraySecoes);
                }
              }
            });
          }
        }
      });
    } catch (erro) {
      console.log('erro', erro);
    }
  }

  useEffect(() => {
    async function getCliente() {
      try {
        const { data } = await api.get(
          `/prontuario/${search?.id}?id_filial=${selectedFilial?.filial}`
        );
        const { ...rest } = data;

        objetctReturn(rest);

        setObjetoCampos({
          secoes: rest?.secao?.obj,
        });

        formRef.current.setFieldValue('nome', rest?.nome);

        formRef.current.setFieldValue(
          'atestado_padrao',
          rest?.documentos_atestados?.atestado_padrao
        );
        formRef.current.setFieldValue(
          'atestado_secundario',
          rest?.documentos_atestados?.atestado_secundario
        );
        formRef.current.setFieldValue(
          'documento_padrao',
          rest?.documentos_atestados?.documento_padrao
        );
        formRef.current.setFieldValue(
          'documento_secundario',
          rest?.documentos_atestados?.documento_secundario
        );
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getCliente();
    }
  }, [search?.id]);

  function handleExcluiSecao(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja remover seção?',
        text: 'Esta operação não poderá ser revertida, os campos serão perdidos!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          setSecoes(secoes.filter((secao) => secao.id_secao !== id));
        }
      });
  }

  function adicionarCampoModal(id, tipo) {
    toggleModal();
    setTimeout(() => {
      formRefAdicionarCampo?.current?.setFieldValue('tipo', tipo);
      formRefAdicionarCampo?.current?.setFieldValue('id', id);
    }, 1000);
  }

  function adicionarCampo(e) {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
      if (f === 'casas_decimais') {
        if (Number(e[f]) > 10) {
          errors[f] = 'N° muito grande';
        }
      }
    });

    formRefAdicionarCampo.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      if (e?.tipo === 'secao') {
        const campos = objetoCampo?.secoes;
        if (
          campoAdicionar === 'CampoVerdadeiroFalso' ||
          campoAdicionar === 'CampoMultiplo' ||
          campoAdicionar === 'CampoOpcao'
        ) {
          const arrayOpcoes = [];
          Object.keys(e).forEach((c) => {
            if (e[c]) {
              if (c?.includes('opcao_')) {
                arrayOpcoes.push({
                  value: e[c],
                  label: e[c],
                });
              }
            }
          });

          campos.push({
            id: e?.id,
            titulo: e?.titulo,
            campo: campoAdicionar,
            id_campo: makeid(),
            opcoes: arrayOpcoes,
          });
        }

        if (
          campoAdicionar === 'CampoTextoCurto' ||
          campoAdicionar === 'CampoTextoLongo' ||
          campoAdicionar === 'CampoData' ||
          campoAdicionar === 'CalculoImc' ||
          campoAdicionar === 'PrescricaoOculos' ||
          campoAdicionar === 'Odontograma' ||
          campoAdicionar === 'CalculoDppIg' ||
          campoAdicionar === 'CurvaCrescimento'
        ) {
          if (
            campoAdicionar === 'Odontograma' ||
            campoAdicionar === 'CalculoDppIg' ||
            campoAdicionar === 'PrescricaoOculos' ||
            campoAdicionar === 'CurvaCrescimento' ||
            campoAdicionar === 'CalculoImc'
          ) {
            const camposAdd = objetoCampo?.secoes?.filter(
              (f) => f?.campo === campoAdicionar
            );

            if (camposAdd.length) {
              toast('Campo já adicionado desse tipo!');
              return;
            }
          }

          campos.push({
            id: e?.id,
            titulo: e?.titulo,
            campo: campoAdicionar,
            id_campo: makeid(),
          });
        }

        if (campoAdicionar === 'CampoNumerico') {
          campos.push({
            id: e?.id,
            titulo: e?.titulo,
            campo: campoAdicionar,
            id_campo: makeid(),
            opcoes: {
              casas_decimais: e?.casas_decimais,
              unidade: e?.unidade,
            },
          });
        }

        setObjetoCampos({ ...objetoCampo, secoes: campos });
      }

      resetCampos();
      toggleModal();
    }
  }

  function renderCampo(campo, label, options) {
    if (campo === 'CampoTextoCurto') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={6}>
              <label>{label}</label>
              <input
                className="form-control-dinamico"
                name={normalizeLabel(label)}
                type="text"
                disabled
              />
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CampoTextoLongo') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={12}>
              <label>{label}</label>
              <textarea
                className="form-control-dinamico"
                name={normalizeLabel(label)}
                type="text"
                disabled
              />
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CampoData') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={2}>
              <label>{label}</label>
              <input
                className="form-control-dinamico"
                name={normalizeLabel(label)}
                type="date"
                disabled
              />
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CampoVerdadeiroFalso') {
      return (
        <div className="mb-3">
          <label className="mb-2">{label}</label>
          <br />
          <input className="radio ml-2" type="radio" disabled />
          <label className="ml-2">{options[0].value}</label>
          <input className="radio ml-2" type="radio" disabled />
          <label className="ml-2">{options[1].value}</label>
        </div>
      );
    }
    if (campo === 'CampoMultiplo') {
      return (
        <div className="mb-3">
          <label className="mb-2">{label}</label>
          <br />
          {options.map((o) => (
            <>
              <input className="radio ml-2" type="checkbox" disabled />
              <label className="ml-2">{o?.value}</label>
            </>
          ))}
        </div>
      );
    }
    if (campo === 'CampoOpcao') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <label>{label}</label>
              <select className="form-control-dinamico" name="select" disabled>
                {options.map((o) => (
                  <option value={`${o?.value}`}>{o?.value}</option>
                ))}
              </select>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CampoNumerico') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <label>{label}</label>
              <div className="justify-between">
                <input
                  className="form-control-dinamico"
                  name={normalizeLabel(label)}
                  type="number"
                  disabled
                />
                <span className="ml-3 mt-2">{options?.unidade}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CalculoImc') {
      return (
        <div className="mb-3">
          <Row>
            <label>{label}</label>
            <Col lg={2}>
              <label>Peso</label>
              <div className="justify-between">
                <input
                  className="form-control-dinamico"
                  name={normalizeLabel(label)}
                  type="number"
                  disabled
                />
                <span className="ml-3 mt-2">kg</span>
              </div>
            </Col>
            <Col lg={2}>
              <label>Altura</label>
              <div className="justify-between">
                <input
                  className="form-control-dinamico"
                  name={normalizeLabel(label)}
                  type="number"
                  disabled
                />
                <span className="ml-3 mt-2">cm</span>
              </div>
            </Col>
            <Col lg={3}>
              <label className="ml-3">IMC</label>
              <div className="justify-between">
                <h5 className="ml-3 mt-2">0,0</h5>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'PrescricaoOculos') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <div className="button-adiciona-campo">
                <img width={100} src={ImgPrescricaoOculos} alt="Texto curto" />
                <span className="ml-3">{label}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'Odontograma') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <div className="button-adiciona-campo">
                <img width={100} src={ImgOdontograma} alt="Texto curto" />
                <span className="ml-3">{label}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CalculoDppIg') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <div className="button-adiciona-campo">
                <img width={100} src={ImgCalculoDppIg} alt="Texto curto" />
                <span className="ml-3">{label}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (campo === 'CurvaCrescimento') {
      return (
        <div className="mb-3">
          <Row>
            <Col lg={3}>
              <div className="button-adiciona-campo">
                <img width={100} src={ImgCurvaCrescimento} alt="Texto curto" />
                <span className="ml-3">{label}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  function removerCampoSecao(idCampo) {
    const campos = objetoCampo?.secoes?.filter((f) => f?.id_campo !== idCampo);
    setObjetoCampos({ ...objetoCampo, secoes: campos });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">
            {
              // eslint-disable-next-line no-nested-ternary
              search?.infos ? 'Informações' : search?.id ? 'Editar' : 'Novo'
            }{' '}
            prontuário
          </h4>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' && 'active'}
            onClick={() => {
              toggle('1');
            }}
          >
            Seções
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' && 'active'}
            onClick={() => {
              toggle('2');
            }}
          >
            Documentos e atestados
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={activeTab === '2' && 'active'}
            onClick={() => {
              toggle('2');
            }}
          >
            Exames e procedimentos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' && 'active'}
            onClick={() => {
              toggle('3');
            }}
          >
            Prescrições
          </NavLink>
        </NavItem> */}
      </Nav>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Card className="px-3 py-3">
            <Row>
              <Col lg={9}>
                <Input
                  label="Nome do prontuário"
                  className="form-control"
                  name="nome"
                  disabled={search?.infos}
                />
              </Col>
              <Col>
                <ReactSelectAsync
                  label="Profissional *"
                  name="id_profissional"
                  isClearable
                  loadOptions={async (value) => {
                    const data = await getOptions({
                      value,
                      route: '/options-profissionais',
                      idFilial: selectedFilial?.filial,
                    });
                    return data;
                  }}
                />
              </Col>
            </Row>
          </Card>
          <hr />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col lg={12} className="mt-2 mb-2">
                  <Button
                    type="button"
                    color="secondary"
                    disabled={search?.infos}
                    onClick={() => {
                      setSecoes([
                        ...secoes,
                        { id_secao: makeid(), nome: 'Nova seção' },
                      ]);
                    }}
                  >
                    Adicionar seção
                    <FaPlusCircle className="ml-2" />
                  </Button>
                </Col>
              </Row>
              <hr />
              {secoes?.map((e) => (
                <>
                  <Row key={e?.id_secao}>
                    <Col lg={10}>
                      <Input
                        label="Nome da seção"
                        className="form-control"
                        name={`nome_secao_${e?.id_secao}`}
                        defaultValue={e?.nome}
                      />
                    </Col>
                    <Col lg={1}>
                      <Button
                        color="outline-warning"
                        id={e?.id_secao}
                        style={{ marginTop: '30px' }}
                        type="button"
                        block
                      >
                        Campos <FaArrowDown className="ml-2" />
                      </Button>
                    </Col>
                    <Col lg={1}>
                      <Button
                        color="outline-danger"
                        onClick={() => handleExcluiSecao(e?.id_secao)}
                        style={{ marginTop: '30px' }}
                        type="button"
                        disabled={search?.infos}
                        block
                      >
                        Remover <FaMinusCircle className="ml-2" />
                      </Button>
                    </Col>

                    <UncontrolledCollapse toggler={`#${e?.id_secao}`}>
                      <hr />
                      <Row>
                        <Col className="justify-center">
                          <Button
                            color="outline-success"
                            type="button"
                            disabled={search?.infos}
                            onClick={() =>
                              adicionarCampoModal(e?.id_secao, 'secao')
                            }
                          >
                            Adicionar campo <FaPlus className="ml-2" />
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      {objetoCampo?.secoes
                        ?.filter((f) => f?.id === e?.id_secao)
                        .map((m) => (
                          <Row className="campos-adicionados">
                            <Form>
                              {renderCampo(m?.campo, m?.titulo, m?.opcoes)}
                            </Form>
                            <Col className="justify-end">
                              <Button
                                color="outline-danger"
                                type="button"
                                onClick={() => removerCampoSecao(m?.id_campo)}
                                disabled={search?.infos}
                              >
                                Excluir <FaTrashAlt className="ml-2" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                    </UncontrolledCollapse>
                  </Row>
                </>
              ))}
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <h5>Atestado padrão</h5>
                  <Editor
                    key="atestado_padrao"
                    name="atestado_padrao"
                    readOnly={!search?.infos}
                    defaultValue={objetoCampo?.documentos?.atestado_padrao}
                    onChange={(e) =>
                      setObjetoCampos({
                        ...objetoCampo,
                        documentos: {
                          ...objetoCampo?.documentos,
                          atestado_padrao: e,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Atestado secundário</h5>
                  <Editor
                    key="atestado_secundario"
                    name="atestado_secundario"
                    readOnly={!search?.infos}
                    defaultValue={objetoCampo?.documentos?.atestado_secundario}
                    onChange={(e) =>
                      setObjetoCampos({
                        ...objetoCampo,
                        documentos: {
                          ...objetoCampo?.documentos,
                          atestado_secundario: e,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Documento padrão</h5>
                  <Editor
                    key="documento_padrao"
                    name="documento_padrao"
                    readOnly={!search?.infos}
                    defaultValue={objetoCampo?.documentos?.documento_padrao}
                    onChange={(e) =>
                      setObjetoCampos({
                        ...objetoCampo,
                        documentos: {
                          ...objetoCampo?.documentos,
                          documento_padrao: e,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <h5>Documento secundário</h5>
                  <Editor
                    key="documento_secundario"
                    name="documento_secundario"
                    readOnly={!search?.infos}
                    defaultValue={objetoCampo?.documentos?.documento_secundario}
                    onChange={(e) =>
                      setObjetoCampos({
                        ...objetoCampo,
                        documentos: {
                          ...objetoCampo?.documentos,
                          documento_secundario: e,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <Row className="mt-5">
            {!search?.infos && (
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>

      <Modal
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
          }}
        >
          Adcionar campo
        </ModalHeader>
        <Form
          ref={formRefAdicionarCampo}
          onSubmit={(e) => {
            adicionarCampo(e);
          }}
        >
          <ModalBody className="mb-5">
            <Row>
              <Col>
                <Input type="text" name="tipo" hidden />
              </Col>
              <Col>
                <Input type="text" name="id" hidden />
              </Col>
            </Row>
            <div hidden={campoAdicionar !== null}>
              <Row>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoTextoCurto')}
                  >
                    <img src={ImgCampoTextoCurto} alt="Texto curto" />
                    <span>Texto curto</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoTextoLongo')}
                  >
                    <img src={ImgCampoTextoLongo} alt="Texto curto" />
                    <span>Texto longo</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoVerdadeiroFalso')}
                  >
                    <img src={ImgCampoVerdadeiroFalso} alt="Texto curto" />
                    <span>Verdadeiro ou falso</span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoNumerico')}
                  >
                    <img src={ImgCampoNumerico} alt="Texto curto" />
                    <span>Numérico</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoOpcao')}
                  >
                    <img src={ImgCampoOpcao} alt="Texto curto" />
                    <span>Lista escolha</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoMultiplo')}
                  >
                    <img src={ImgCampoMultiplo} alt="Texto curto" />
                    <span>Múltipla escolha</span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CampoData')}
                  >
                    <img src={ImgCampoData} alt="Texto curto" />
                    <span>Campo de data</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CalculoImc')}
                  >
                    <img src={ImgCalculoImc} alt="Texto curto" />
                    <span>Cálculo IMC</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('PrescricaoOculos')}
                  >
                    <img src={ImgPrescricaoOculos} alt="Texto curto" />
                    <span>Prescrição de óculos</span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('Odontograma')}
                  >
                    <img src={ImgOdontograma} alt="Texto curto" />
                    <span>Odontograma</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CurvaCrescimento')}
                  >
                    <img src={ImgCurvaCrescimento} alt="Texto curto" />
                    <span>Cálculo de crescimento</span>
                  </Button>
                </Col>
                <Col className="mt-3">
                  <Button
                    color="none"
                    type="button"
                    className="button-adiciona-campo"
                    onClick={() => setCampoAdicionar('CalculoDppIg')}
                  >
                    <img src={ImgCalculoDppIg} alt="Texto curto" />
                    <span>Cálculo DPP e IG</span>
                  </Button>
                </Col>
              </Row>
            </div>
            <div hidden={campoAdicionar === null}>
              <Row>
                <Col lg={12}>
                  <Input
                    className="form-control"
                    label="Título"
                    type="text"
                    name="titulo"
                    placeHolder="Digite aqui o título do campo"
                    defaultValue={getDefaultValueLabel(campoAdicionar)}
                  />
                </Col>
              </Row>
              {campoAdicionar === 'CampoVerdadeiroFalso' && (
                <>
                  <h6>Opções</h6>
                  <Row className="justify-center">
                    <Col lg={1} className="justify-center mt-4">
                      <input type="radio" disabled />
                    </Col>
                    <Col lg={6}>
                      <Input
                        className="form-control"
                        label="Opção 1"
                        type="text"
                        name="opcao_1"
                        placeHolder="Digite aqui a opção 1"
                      />
                    </Col>
                  </Row>
                  <Row className="justify-center">
                    <Col lg={1} className="justify-center mt-4">
                      <input type="radio" disabled />
                    </Col>
                    <Col lg={6}>
                      <Input
                        className="form-control"
                        label="Opção 2"
                        type="text"
                        name="opcao_2"
                        placeHolder="Digite aqui a opção 2"
                      />
                    </Col>
                  </Row>
                </>
              )}
              {campoAdicionar === 'CampoNumerico' && (
                <Row className="justify-center">
                  <Col lg={6}>
                    <Input
                      className="form-control"
                      label="Unidade"
                      name="unidade"
                      type="text"
                      placeHolder="Digite aqui a unidade ex: kg"
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      className="form-control"
                      label="Casas decimais"
                      type="number"
                      name="casas_decimais"
                      placeHolder="Quantidade de casas decimais"
                    />
                  </Col>
                </Row>
              )}
              {(campoAdicionar === 'CampoOpcao' ||
                campoAdicionar === 'CampoMultiplo') && (
                <>
                  <h6>Opções</h6>
                  <Row className="justify-center">
                    <Col lg={6}>
                      <Input
                        className="form-control"
                        label="Opção 1"
                        type="text"
                        name="opcao_1"
                        placeHolder="Digite aqui a opção 1"
                      />
                    </Col>
                    <Col lg={1} style={{ marginTop: '30px' }}>
                      <Button
                        color="success"
                        type="button"
                        onClick={() =>
                          setOptionsMultiplos([
                            ...optionsMultiplos,
                            { id: makeid() },
                          ])
                        }
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                  {optionsMultiplos?.map((e, index) => (
                    <Row className="justify-center" key={e?.id}>
                      <Col lg={6}>
                        <Input
                          className="form-control"
                          type="text"
                          label={`Opção ${index + 2}`}
                          name={`opcao_${index + 2}`}
                          placeHolder={`Digite aqui a opção ${index + 2}`}
                        />
                      </Col>
                      <Col lg={1} style={{ marginTop: '30px' }}>
                        <Button
                          color="danger"
                          type="button"
                          onClick={() =>
                            setOptionsMultiplos(
                              optionsMultiplos.filter((o) => o.id !== e?.id)
                            )
                          }
                        >
                          <FaMinus />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter
            hidden={campoAdicionar === null}
            className="justify-between"
          >
            <Button
              color="outline-secondary"
              type="button"
              onClick={() => resetCampos()}
            >
              <FaArrowLeft className="mr-2" />
              <span>Alterar campo</span>
            </Button>
            <Button color="primary" type="submit">
              Adicionar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default NovoPaciente;
