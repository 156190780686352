import React, { useEffect, useRef, useState } from 'react';
import { HiCreditCard, HiDeviceMobile, HiTruck, HiWifi } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form } from '@unform/web';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useAuth } from '../../contexts/auth';
import { setCorModulo } from '../../utils/global';
import { useValidator } from '../../hooks';
import { Spinner } from '../../components';
import { Input, InputGroup } from '../../components/unform';
import api from '../../services/api';
import apiPDV from '../../services/apiPDV';
import apiBank from '../../services/apiBank';

function Modulos() {
  const { signIn, user } = useAuth();
  const history = useHistory();
  const { validator, toast } = useValidator();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModalModulo] = useState('');

  const goTo = (e) => {
    history.push(e);
  };

  const handleSubmit = async (body) => {
    const { errors, hasError } = validator(body);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }
    setLoading(true);
    try {
      let res = null;
      if (modal === 'erp') {
        const { data } = await apiPDV.post('/sessions', body);
        res = data;
      } else if (modal === 'pay') {
        const { data } = await api.post('/sessions', body);
        res = data;
      } else if (modal === 'bank') {
        const { data } = await apiBank.post('/sessions/app', body);
        res = data;
      } else if (modal === 'telecom') {
        res = {
          user: {
            nome: 'Admin',
          },
          token: 'token',
        };
      }
      signIn(res, modal);
      setCorModulo(modal);
      goTo(`/${modal}/`);
      setLoading(false);
    } catch (err) {
      toast(err.mensagem);
      setLoading(false);
    }
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleModal = () => setModalModulo(!modal);
  const setModal = (mod) => {
    if ((user || {})?.[mod]) {
      setCorModulo(mod);
      goTo(`/${mod}/`);
    } else {
      setModalModulo(mod);
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          background: 'var(--body-bg)',
        }}
        className="align-items-lg-center align-items-end"
      >
        <div
          className="d-flex p-15px justify-content-between"
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
          }}
        >
          <h1 className="m-header w-100 text-center">
            <span className="color-theme">UneoSoluções. </span>
            <div className="m-sub-header">
              Conectando soluções inteligentes para o crescimento do seu negócio
            </div>
          </h1>
          {/* <span>
            <button
              className="btn btn-theme btn-sm py-2 px-2"
              type="button"
              onClick={signOut}
            >
              <FiPower size={18} />
            </button>
          </span> */}
        </div>
        <div className="d-flex horizontal-list justify-content-lg-center">
          <button
            className="list-item btn-none"
            type="button"
            onClick={() => {
              setModal('pay');
            }}
          >
            <div className="position-relative">
              <img
                src="https://images.unsplash.com/photo-1556742521-9713bf272865?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                alt="modulo 1"
                className="object-cover scale-hover"
              />

              <h3
                style={{
                  top: ' 0px',
                  color: '#fff',
                  padding: '20px',
                  fontWeight: 600,
                  position: 'absolute',
                  left: '0px',

                  transition: 'transform 250ms',
                }}
              >
                UneoPAY
              </h3>
              <HiCreditCard
                size={24}
                color="#FFF"
                style={{
                  bottom: '20px',
                  position: 'absolute',
                  right: '20px',
                  filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
                  zIndex: 2,
                }}
              />
            </div>
          </button>
          <button
            className="list-item btn-none"
            type="button"
            onClick={() => {
              setModal('bank');
            }}
          >
            <div className="position-relative">
              <img
                src="https://images.pexels.com/photos/8555246/pexels-photo-8555246.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="modulo 1"
                className="object-cover scale-hover"
              />

              <h3
                style={{
                  top: ' 0px',
                  color: '#fff',
                  padding: '20px',
                  fontWeight: 600,
                  position: 'absolute',
                  left: '0px',

                  transition: 'transform 250ms',
                }}
              >
                UneoPay Bank
              </h3>
              <HiDeviceMobile
                size={24}
                color="#FFF"
                style={{
                  bottom: '20px',
                  position: 'absolute',
                  right: '20px',
                  filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
                  zIndex: 2,
                }}
              />
            </div>
          </button>
          <button
            className="list-item btn-none"
            type="button"
            onClick={() => {
              setModal('erp');
            }}
          >
            <div className="position-relative">
              <img
                src="https://images.unsplash.com/photo-1556740767-414a9c4860c1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="modulo 1"
                className="object-cover scale-hover"
              />

              <h3
                style={{
                  top: ' 0px',
                  color: '#fff',
                  padding: '20px',
                  fontWeight: 600,
                  position: 'absolute',
                  left: '0px',

                  transition: 'transform 250ms',
                }}
              >
                UneoERP
              </h3>
              <HiTruck
                size={24}
                color="#FFF"
                style={{
                  bottom: '20px',
                  position: 'absolute',
                  right: '20px',
                  filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
                  zIndex: 2,
                }}
              />
            </div>
          </button>
          <button
            className="list-item btn-none"
            type="button"
            onClick={() => {
              setModal('telecom');
            }}
          >
            <div className="position-relative">
              <img
                src="https://images.pexels.com/photos/918283/pexels-photo-918283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="modulo 1"
                className="object-cover scale-hover"
              />

              <h3
                style={{
                  top: ' 0px',
                  color: '#fff',
                  padding: '20px',
                  fontWeight: 600,
                  position: 'absolute',
                  left: '0px',

                  transition: 'transform 250ms',
                }}
              >
                UneoTelecom
              </h3>
              <HiWifi
                size={24}
                color="#FFF"
                style={{
                  bottom: '20px',
                  position: 'absolute',
                  right: '20px',
                  filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
                  zIndex: 2,
                }}
              />
            </div>
          </button>
        </div>

        <div
          className="justify-center text-center"
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 10,
          }}
        >
          Made with &nbsp;
          <span
            style={{
              color: 'red',
            }}
          >
            ❤
          </span>
          &nbsp; by &nbsp;
          <a
            href="https://xcoding.com.br"
            rel="noreferrer"
            target="_blank"
            style={{
              color: 'rgb(var(--theme-color-rgb))',
              fontWeight: 600,
            }}
          >
            Xcoding
          </a>
        </div>
      </div>
      <Modal isOpen={!!modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Login {String(modal || '').toUpperCase()}
        </ModalHeader>
        <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
          <ModalBody>
            {modal !== 'bank' ? (
              <Input
                name="email"
                label="Email"
                className="form-control"
                placeholder="Informe seu e-mail"
                // defaultValue={process.env.REACT_APP_CPF}
              />
            ) : (
              <Input
                name="documento"
                label="Documento"
                className="form-control"
                placeholder="Informe seu documento"
                // defaultValue={process.env.REACT_APP_CPF}
              />
            )}
            <InputGroup
              name="senha"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              className="form-control-group"
              // defaultValue={process.env.REACT_APP_SENHA}
              placeholder="* * * * *"
              append={
                <Button size="sm" color="link" onClick={toggleShowPassword}>
                  {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
                </Button>
              }
            />
            <div className="d-flex justify-content-between">
              {/* <CheckBox name="persistir" label="Manter conectado ?" /> */}
              {/* <Link to="/esqueci-minha-senha" className="pt-1">
                Esqueceu a senha ?
              </Link> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Login'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Modulos;
