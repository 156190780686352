import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const types = {
  success: 'success',
  info: 'info',
  error: 'error',
  warning: 'warning',
  dark: 'dark',
  warn: 'warn',
};

function useValidator() {
  function validator(data, filter = []) {
    const errors = {};

    if (!data) {
      throw new Error('useValidator require data');
    }

    if (filter.length) {
      Object.entries(data).forEach(([key, value]) => {
        if (!filter.includes(key) && !value) {
          errors[key] = 'Obrigatório';
        }
      });
    } else {
      Object.entries(data).forEach(([key, value]) => {
        if (!value) {
          errors[key] = 'Obrigatório';
        }
      });
    }

    return { errors, hasError: Object.keys(errors).length > 0 };
  }

  function showError({ mensagem, titulo }) {
    Swal.fire({
      title: titulo,
      text: mensagem,
      icon: 'error',
      showCancelButton: false,
    });
  }

  function showLoader() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  function closeLoader() {
    Swal.close();
  }

  function showToast(
    msg = 'Verifique os campos em vermelho!',
    { type, ...options } = {}
  ) {
    const t = types[type] || types.error;

    toast[t](msg, options);
  }

  function closeModal(all) {
    const btnClose = document.querySelectorAll('.show .btn-close');
    if (all) {
      btnClose.map((m) => m.click());
    } else if (btnClose.length) {
      btnClose[btnClose.length - 1].click();
    }
  }

  function createQuery(filtros = {}) {
    return Object.keys(filtros)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtros[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtros[e]}`;
      })
      .join('&');
  }

  return {
    validator,
    closeModal,
    toast: showToast,
    closeLoader,
    showLoader,
    createQuery,
    showError,
  };
}

export default useValidator;
