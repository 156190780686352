import React, { useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaBoxOpen,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaShoppingBasket,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../../components/unform/index';
import api from '../../../services/apiPDV';
import { useValidator } from '../../../hooks';

function ListarProdutos({ modalProps, toggleModal, open }) {
  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });
  const [page, setPage] = useState(0);
  const filtrosRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );

      const { data } = await api.get(
        `/produto?page=${p + 1}${filtros}&status=A`
      );
      setPage(p + 1);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  return (
    <>
      <ModalHeader toggle={toggleModal}>{modalProps?.title}</ModalHeader>
      <ModalBody>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div />
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Descrição"
                    name="descricao"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="EAN"
                    name="ean"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Descrição</th>

              <th>Valor</th>
              <th>Estoque</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id}>
                <td data-label="Descrição">
                  {m.descricao}
                  <br />
                  {m.cor} - {m.unidade_tamano}
                  {m.tamanho}
                </td>
                <td data-label="Valor">
                  {Number(m.preco_venda).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
                <td data-label="Estoque" className="text-center">
                  {m?.ProdutoVariacaoEstoques?.[0]?.estoque_disponivel}
                </td>
                <td data-label="Action">
                  <div className="d-flex justify-content-lg-end justify-content-sm-between">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiSettings className="mr-2" /> <FaChevronDown />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          onClick={() => {
                            if (
                              m?.ProdutoVariacaoEstoques?.[0]
                                ?.estoque_disponivel > 0
                            ) {
                              toggleModal();
                              open(m.ProdutoVariacaoEans[0].ean);
                            }
                          }}
                        >
                          <FaShoppingBasket className="mr-1" />
                          Adicionar
                        </DropdownItem>
                        {/* <DropdownItem>
                        <FiTrash className="mr-1" />
                        Excluir
                      </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages}
          onPageChange={({ selected }) => handleChangePage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </ModalFooter>
    </>
  );
}

export default ListarProdutos;
