/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useReactToPrint } from 'react-to-print';
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Table,
  CardTitle,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import {
  HiDotsHorizontal,
  HiOutlineXCircle,
  HiOutlineTruck,
  HiOutlinePlusCircle,
  HiMinus,
  HiInformationCircle,
  HiCheckCircle,
  HiStar,
  HiChat,
  HiArchive,
  HiShoppingBag,
} from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
// import * as htmlToImage from 'html-to-image';
// import { jsPDF } from 'jspdf';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from 'services/apiPDV';

import logo from 'assets/svg/uneoerp.svg';
import BouncingDotsLoader from 'components/boucingDots';
import { useValidator } from 'hooks/index';
import formFiltros from 'utils/formFiltros';
import { Input, ReactSelect } from 'components/unform';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  reverseButtons: true,
  buttonsStyling: false,
});
function adicionaZero(numero) {
  if (numero <= 9) {
    return `0${numero}`;
  }
  return numero;
}

function formataData(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()} - ${adicionaZero(
    date.getHours()
  )}:${adicionaZero(date.getMinutes())}`;
}

function formataDataSemHora(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()}`;
}

const Grid = ({
  data = [],
  toggleModal,
  getDeclaracao,
  verInfosEnvio,
  infosGerais,
  cancelarPedido,
  handleConfirmarEntregaCombinada,
}) => (
  <>
    {data?.map((m) => (
      <Card key={m?.id}>
        <div className="bg-gray1">
          <CardHeader>
            <CardTitle>
              <Row>
                <Col lg={4}>
                  <strong>Cliente:&nbsp;&nbsp;</strong>
                  {m?.Cliente?.nome}
                </Col>
                <Col lg={4}>
                  <strong>Telefone:&nbsp;&nbsp;</strong>(
                  {m?.Cliente?.telefone_ddd}){m?.Cliente?.telefone}
                </Col>
                <Col lg={4}>
                  <strong>Pedido:&nbsp;&nbsp;</strong>#{m?.codigo}
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
        </div>
        <CardBody>
          <Row>
            <Col lg={4}>
              {m?.PedidoDetalhes?.map((pde) => (
                <Row key={pde?.id}>
                  <Col lg={12}>
                    <div className="d-flex align-items-center mb-2">
                      <img
                        width={60}
                        height={70}
                        className="object-fit"
                        src={
                          pde?.ProdutoVariacao?.ProdutoVariacaoImagems[0]
                            ?.Imagem?.path || '/default.jpg'
                        }
                        alt={pde?.nome_produto}
                      />
                      <div className="ml-3">
                        <p className="bold mb-0">{pde?.nome_produto}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Total com frete</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        {Number(m?.total_pedido).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Data pedido</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{formataData(m?.data_hora_pedido)}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Data entrega</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{m?.data_entrega && formataData(m?.data_entrega)}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Status pedido</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="no-wrap">{m?.status}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Status pgto.</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{m?.status_pagamento}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Opções</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UncontrolledDropdown className="float-md">
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <HiDotsHorizontal />
                        </DropdownToggle>
                        <DropdownMenu direction="down">
                          <DropdownItem
                            onClick={() => {
                              infosGerais(m);
                            }}
                          >
                            <HiInformationCircle className="mr-1" />
                            Infos do pedido
                          </DropdownItem>
                          {m?.status === 'Aguardando envio' && (
                            <>
                              <DropdownItem
                                onClick={() => {
                                  getDeclaracao(m);
                                }}
                              >
                                <HiArchive className="mr-1" />
                                Declaração de conteúdo
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  toggleModal();
                                  infosGerais(m, 'envio');
                                }}
                              >
                                <HiInformationCircle className="mr-1" />
                                Dados p/ envio
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  infosGerais(m, 'montar-envio');
                                }}
                                hidden={
                                  m?.tipo_frete === 'Combinar com o vendedor'
                                }
                              >
                                <HiOutlineTruck className="mr-1" />
                                Montar envio
                              </DropdownItem>
                              {m?.tipo_frete === 'Combinar com o vendedor' && (
                                <>
                                  <DropdownItem
                                    onClick={() => {
                                      handleConfirmarEntregaCombinada(m);
                                    }}
                                  >
                                    <HiShoppingBag className="mr-1" />
                                    Confirmar entrega combinada
                                  </DropdownItem>
                                </>
                              )}
                              <DropdownItem
                                onClick={() => cancelarPedido(m?.id)}
                              >
                                <HiOutlineXCircle className="mr-1" />
                                Cancelar
                              </DropdownItem>
                            </>
                          )}

                          {m?.status === 'Enviado' && (
                            <DropdownItem
                              onClick={() => {
                                verInfosEnvio(m?.id);
                              }}
                            >
                              <HiInformationCircle className="mr-1" />
                              Infos. envio
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {(m?.status === 'Cancelado' || m?.status === 'Devolvido') && (
                <>
                  <Row className="mt-4">
                    <Col>
                      <p>
                        <strong>Pedido cancelado pelo:</strong>
                      </p>
                      <p>
                        {m?.CancelamentoPedido?.cancelamento_parceiro
                          ? 'Parceiro'
                          : 'Cliente'}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <strong>Motivos cancelamento</strong>
                      </p>
                      <p>{m?.CancelamentoPedido?.cancelamento_motivo}</p>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    ))}
  </>
);

const status = [
  'Todos',
  'Aguardando envio',
  'Enviado',
  'Entregue',
  'Devolvido',
  'Cancelado',
  'Não pago',
  'Finalizado',
];

function Pedidos() {
  const history = useHistory();
  const { showLoader, closeLoader, toast } = useValidator();

  const envioRef = useRef();
  const cancelarRef = useRef();
  const debouncePedido = useRef();

  const [dados, setDados] = useState({});
  const [modal, setModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);
  const [modalEnvio, setModalEnvio] = useState(false);
  const [modalDeclaracao, setModalDeclaracao] = useState(null);
  const [infosEnvio, setInfosEnvio] = useState(null);
  const [activeTab, setActiveTab] = useState(status[0]);
  const [qtdEnvios, setQtdEnvios] = useState({ val: [] });
  const [idPedidoCancelar, setIdPedidoCancelar] = useState(null);
  const [infosGeraisDados, setInfosGeraisDados] = useState(null);
  const [modalInfosGerais, setModalInfosGerais] = useState(false);
  const [infosEnvioRastreio, setInfosEnvioRastreio] = useState([]);
  const [offcanvasCancelar, setOffcanvasCancelar] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);
  const toggleModalEnvio = () => setModalEnvio(!modalEnvio);
  const toggleModalInfos = () => setModalInfosGerais(!modalInfosGerais);
  const toggleOffcanvasCancelar = () =>
    setOffcanvasCancelar(!offcanvasCancelar);

  const loadMore = async (page) => {
    const nextPage = (page === 0 ? page : dados?.page || 1) + 1;

    const filtros = `page=${nextPage}&${dados?.filtros?.join('&') || ''}`;

    const { data } = await api
      .get(`/ecommerce/loja/pedidos?${filtros}`)
      .catch((err) => {
        toast(err.mensagem, { type: 'error' });
        return null;
      });

    if (nextPage === 1) {
      setDados(data);
    } else {
      setDados((old) => ({
        ...old,
        page: nextPage,
        data: (old?.data || []).concat(data?.data || []),
      }));
    }
  };

  async function handleSubmit(tab) {
    const body = formFiltros();

    const active = typeof tab === 'string' ? tab : activeTab;

    const filtros = Object.entries(body)
      .map(([key, value]) => (value ? `${key}=${value || ''}` : ''))
      .filter(Boolean);

    if (active !== 'Todos') {
      if (active === 'Esgotado') {
        filtros.push('estoque_disponivel=0');
      } else {
        filtros.push(`status=${active}`);
      }
    }

    try {
      showLoader();
      const { data } = await api.get(
        `/ecommerce/loja/pedidos?${filtros.join('&')}`
      );

      setDados({
        ...data,
        filtros,
      });
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    }
    closeLoader();
  }

  async function handleMontarEnvio(data) {
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      } else if (f.includes('itens_')) {
        if (!data[f].length) {
          errors[f] = 'Obrigatório';
        }
      }
    });
    envioRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      showLoader();
      try {
        const body = [];
        Object.keys(data).forEach((f) => {
          if (f.includes('rastreio_')) {
            const envioNumber = f.split('_')[1];
            Object.keys(data).forEach((e) => {
              if (e === `itens_${envioNumber}`) {
                body.push({
                  rastreamento: data[f],
                  pedido_detalhe: data[e],
                });
              }
            });
          }
        });
        await api.post(
          `/ecommerce/loja/pedidos/${infosGeraisDados?.id}/montar-envio`,
          body
        );
        toggleOffcanvas();
        loadMore(0);
        toast('Pedido atualizado com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err?.mensagem, { type: 'error' });
      }
      closeLoader();
    }
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      handleSubmit(tab);
    }
  };

  useEffect(() => {
    const initialData = async () => {
      showLoader();
      try {
        const { data } = await api
          .get(`/ecommerce/loja/pedidos?status=${status[0]}`)
          .catch((err) => {
            toast(err.mensagem, { type: 'error' });
            return {};
          });
        setDados(data);
      } catch (err) {
        toast(err.mensagem);
      } finally {
        closeLoader();
      }
    };
    clearTimeout(debouncePedido.current);
    debouncePedido.current = setTimeout(() => {
      initialData();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const vals = [...qtdEnvios.val];
    vals[this] = event.target.value;
    setQtdEnvios({ val: vals });
  }

  const addClick = () => {
    setQtdEnvios({ val: [...qtdEnvios.val, ''] });
  };

  const removeClick = (event) => {
    const vals = [...qtdEnvios.val];
    const index = Number(event.target.name);
    vals.splice(index, 1);
    setQtdEnvios({ val: vals });
  };

  function createInputs() {
    return qtdEnvios.val.map((el, i) => (
      <Card key={i}>
        <CardBody>
          <Row>
            <Col lg={12}>
              <ReactSelect
                label="Itens envio"
                name={`itens_${i}`}
                isMulti
                options={infosGeraisDados?.PedidoDetalhes?.map((m) => ({
                  label: m?.nome_produto,
                  value: m?.id,
                }))}
              />
            </Col>
            <Col lg={12}>
              <Input
                type="text"
                className="form-control"
                label="Código de rastreio"
                name={`rastreio_${i}`}
                value={el || ''}
                onChange={handleChange.bind(i)}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="danger"
            type="button"
            name={i}
            block
            className="btn-sm"
            onClick={removeClick.bind(i)}
          >
            Remover <HiMinus />
          </Button>
        </CardFooter>
      </Card>
    ));
  }

  const componentRef = useRef();
  const componentDeclaracaoRef = useRef();
  const printPdf = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: 'align-items-start view-print print-envio',
    removeAfterPrint: true,
  });
  const printPdfDeclaracao = useReactToPrint({
    content: () => componentDeclaracaoRef.current,
    bodyClass: 'align-items-start view-print',
    removeAfterPrint: true,
  });

  // function printPdf(pedido) {
  //   htmlToImage
  //     .toPng(document.getElementById('print'), { quality: 1 })
  //     .then((dataUrl) => {
  //       const link = document.createElement('a');
  //       link.download = 'pedido.jpeg';
  //       // eslint-disable-next-line new-cap
  //       const pdf = new jsPDF();
  //       pdf.addImage(dataUrl, 'PNG', 0, 0);
  //       pdf.save(`so_sebos_pedido_${pedido}.pdf`);
  //     });
  // }

  async function verInfosEnvio(idPedido) {
    showLoader();
    try {
      const { data } = await api.get(
        `/ecommerce/loja/pedidos/${idPedido}/dados-envio`
      );
      setInfosEnvioRastreio(data);
      toggleModalEnvio();
    } catch (err) {
      toast('Erro ao buscar dados do envio!', { type: 'error' });
    }
    closeLoader();
  }

  async function infosGerais(pedido, tipo) {
    showLoader();
    try {
      const { data } = await api.get(
        `/ecommerce/loja/pedidos/${pedido.id}/entrega`
      );

      setInfosGeraisDados({
        ...pedido,
        ...data,
      });
      if (tipo === 'montar-envio') {
        toggleOffcanvas();
      } else if (tipo !== 'envio') {
        toggleModalInfos();
      }
    } catch (err) {
      toast('Erro ao buscar dados do pedido!', { type: 'error' });
    }
    closeLoader();
  }

  async function getDeclaracao(pedido) {
    showLoader();
    try {
      const { data } = await api.get(
        `/ecommerce/loja/pedidos/${pedido.id}/entrega?loja=true`
      );
      let totalItens = 0;
      let pesoTotal = 0;
      const itens = Object.values(
        pedido.PedidoDetalhes?.reduce((a, b) => {
          if (!a[b.id]) {
            a[b.id] = {
              nome: b.nome_produto,
              valor: 0,
              quantidade: 0,
            };
          }

          a[b.id].valor += Number(b.valor_total);
          a[b.id].quantidade += Number(b.quantidade);
          totalItens += Number(b.quantidade);
          pesoTotal += Number(b.ProdutoVariacao.embalagem_peso);

          return a;
        }, {})
      );
      setInfosGeraisDados({
        ...pedido,
        ...data,
        PedidoDetalhes: null,
        itens,
        totalItens,
        pesoTotal,
      });
      setModalDeclaracao(true);
    } catch (err) {
      toast('Erro ao buscar dados do pedido!', { type: 'error' });
    }
    closeLoader();
  }

  function cancelarPedido(idPedido) {
    toggleOffcanvasCancelar();
    setIdPedidoCancelar(idPedido);
  }

  async function handleConfirmarEntregaCombinada(pedido) {
    swalWithBootstrapButtons
      .fire({
        title: 'Digite o código fornecido pelo cliente',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.post(
              `/ecommerce/loja/pedidos/${pedido.id}/confirmar-entrega-combinada`,
              {
                codigo: result.value,
              }
            );
            Swal.close();
            loadMore(0);
            toast('Entrega combinada confirmada', { type: 'success' });
          } catch (err) {
            Swal.fire({
              title: 'Erro',
              html: err.mensagem,
              icon: 'error',
            });
          }
        }
      });
  }

  async function handleCancelarPedido(data) {
    showLoader();
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });
    cancelarRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      try {
        await api.delete(`/ecommerce/pedidos/${idPedidoCancelar}`, data);
        loadMore(0);
        toggleOffcanvasCancelar();
        toast('Pedido cancelado, o estorno foi realizado para o cliente!', {
          type: 'success',
        });
      } catch (err) {
        toast('Erro ao cancelar pedido!', { type: 'error' });
      }
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Nav tabs>
            {status?.map((m) => (
              <NavItem>
                <NavLink
                  active={activeTab === m}
                  onClick={() => {
                    toggle(m);
                  }}
                >
                  {m}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = formFiltros();
              handleSubmit(data);
            }}
          >
            <Row>
              <Col lg={9}>
                <Row>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <select name="consulta">
                          <option value="id">ID Pedido</option>
                          <option value="cliente">Nome cliente</option>
                        </select>
                      </InputGroupText>
                      <input placeholder="Digite o valor" name="valor" />
                    </InputGroup>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Data de criação do pedido</div>
                      </InputGroupText>

                      <Flatpickr
                        label="date ranger"
                        name="data_criacao"
                        className="border-none date-picker"
                        options={{
                          mode: 'range',
                          locale: Portuguese,
                          dateFormat: 'd/m/Y',
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg={3}>
                <Row>
                  <Col lg={6} sm={6} className="mb-1">
                    <Button color="light-danger" type="reset" block>
                      Redefinir
                    </Button>
                  </Col>
                  <Col lg={6} sm={6} className="mb-3">
                    <Button color="light-primary" type="submit" block>
                      Procurar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>

          <h5>
            {dados?.count} Pedido{dados?.count > 1 ? 's' : ''}
          </h5>

          <TabContent activeTab={activeTab}>
            <InfiniteScroll
              className="stop"
              style={{ minHeight: 350 }}
              dataLength={dados?.total || 0}
              hasMore={(dados?.page || 1) < dados.pages}
              next={() => {
                loadMore();
              }}
              loader={
                <div className="my-3">
                  <BouncingDotsLoader />
                </div>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Não há mais dados</b>
                </p>
              }
              scrollableTarget="main-content"
              refreshFunction={() => loadMore(0)}
              pullDownToRefresh
            >
              <Grid
                data={dados?.data}
                status={activeTab}
                toggle={(id) => toggleOffcanvas(id)}
                toggleModal={toggleModal}
                setInfosEnvio={setInfosEnvio}
                verInfosEnvio={verInfosEnvio}
                infosGerais={infosGerais}
                cancelarPedido={cancelarPedido}
                getDeclaracao={getDeclaracao}
                setInfosGeraisDados={setInfosGeraisDados}
                handleConfirmarEntregaCombinada={
                  handleConfirmarEntregaCombinada
                }
              />
            </InfiniteScroll>
          </TabContent>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Dados para envio</ModalHeader>
        <div ref={componentRef}>
          <ModalBody id="print">
            <Row className="infos-envio mb-3">
              <Row className="justify-end align-items-center">
                <Col>
                  <h6 className="mb-0">
                    Pedido <strong>#{infosGeraisDados?.codigo}</strong>
                  </h6>
                </Col>
                <div
                  style={{
                    width: 150,
                  }}
                >
                  <img
                    width={150}
                    src={logo}
                    className="object-fit img-envio"
                    alt="logo simbolo"
                  />
                </div>
              </Row>
              <Col lg={12} className="mb-1 mt-3">
                <div className="hr" />
              </Col>
              <Col lg={12}>
                <h6 className="mt-2">
                  Destinatário:{' '}
                  <strong>{infosGeraisDados?.Cliente?.nome}</strong>
                </h6>
              </Col>
              <Col lg={12}>
                <h6>
                  Endereço:{' '}
                  <strong>
                    {infosGeraisDados?.endereco?.logradouro}
                    {', '}
                    {infosGeraisDados?.endereco?.numero}
                    {infosEnvio?.endereco?.complemento
                      ? ` - ${infosEnvio?.endereco?.complemento}`
                      : ''}
                    , {infosGeraisDados?.endereco?.bairro}
                    {'- '}
                    {infosGeraisDados?.endereco?.cidade}
                    {', '}
                    {infosGeraisDados?.endereco?.estado} -{' '}
                    {infosGeraisDados?.endereco?.cep}
                  </strong>
                </h6>
                <h6>
                  Tipo de entrega:{' '}
                  <strong>{infosGeraisDados?.endereco?.tipo}</strong>
                </h6>
              </Col>
            </Row>
            <Row>
              <Table className="rwd-table no-min" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Valor produtos</th>
                    <th className="text-center">Valor frete</th>
                    <th className="text-center">Desconto total</th>
                    <th className="text-center">Valor total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Valor produtos" className="text-center">
                      {Number(infosGeraisDados?.valor_produto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor frete" className="text-center">
                      {Number(infosGeraisDados?.taxa_frete).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Desconto total" className="text-center">
                      {Number(infosGeraisDados?.valor_desconto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor total" className="text-center">
                      {Number(infosGeraisDados?.total_pedido).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col className="justify-center mt-3">
                <h5>Itens</h5>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <Table className="rwd-table no-min" responsive>
                  <tbody>
                    {infosGeraisDados?.PedidoDetalhes?.map((i) => (
                      <>
                        <tr key={i?.id}>
                          <td data-label="Nome">1 x {i?.nome_produto}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
        </div>
        <ModalFooter>
          <Button color="primary" onClick={() => printPdf()}>
            Imprimir
          </Button>
          <Button
            color="primary"
            onClick={() => {
              getDeclaracao(infosGeraisDados);
              toggleModal();
            }}
          >
            Declaração de conteúdo
          </Button>
          <Button
            hidden={infosEnvio?.tipo_frete === 'Combinar com o vendedor'}
            color="success"
            onClick={toggleOffcanvas}
          >
            Montar envio
          </Button>
          <Button color="info" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDeclaracao}
        toggle={() => setModalDeclaracao(false)}
        size="xl"
      >
        <ModalHeader toggle={() => setModalDeclaracao(false)}>
          Declaração de conteúdo correios
        </ModalHeader>
        <ModalBody>
          <div id="printDeclaracao" ref={componentDeclaracaoRef}>
            <Row>
              <Col>
                <Row className="justify-end">
                  <Col>
                    <h5 className="text-center upper bold">
                      Declaração de conteúdo
                    </h5>
                  </Col>
                </Row>
                <div className="hr" />
                <Row>
                  <Col
                    xs={6}
                    className="br-2"
                    style={{
                      marginRight: -2,
                    }}
                  >
                    <p className="upper">Unidade de postagem</p>
                    <br />
                  </Col>
                  <Col xs={6}>
                    <p className="upper">Número de registro</p>
                    <br />
                  </Col>
                </Row>
                <div className="hr" />
                <Row className="upper">
                  <Col
                    xs={6}
                    className="br-2"
                    style={{
                      marginRight: -2,
                    }}
                  >
                    <h6 className="mb-3 bold text-center">remetente</h6>
                    <h6 className="bb-2 pb-2">
                      <b>NOME: </b>
                      {infosGeraisDados?.loja?.Filial?.razao_social}
                    </h6>
                    <h6 className="bb-2 pb-2">
                      <b>ENDEREÇO: </b>
                      {
                        infosGeraisDados?.loja?.Filial?.FilialEndereco
                          ?.logradouro
                      }
                      , {infosGeraisDados?.loja?.Filial?.FilialEndereco?.numero}
                      {infosGeraisDados?.loja?.Filial?.FilialEndereco
                        ?.complemento
                        ? ` ${infosGeraisDados?.loja?.Filial?.FilialEndereco?.complemento}`
                        : ''}
                      , {infosGeraisDados?.loja?.Filial?.FilialEndereco?.bairro}
                    </h6>
                    <div className="d-flex bb-2">
                      <h6 className="w-60 br-2 mr-2">
                        <b>CIDADE: </b>
                        {
                          infosGeraisDados?.loja?.Filial?.FilialEndereco?.Cidade
                            ?.nome
                        }
                      </h6>
                      <h6 className="w-40">
                        <b>UF: </b>
                        {
                          infosGeraisDados?.loja?.Filial?.FilialEndereco?.Uf
                            ?.nome
                        }
                      </h6>
                    </div>
                    <div className="d-flex mt-2">
                      <h6 className="w-40 br-2 mr-2">
                        <b>CEP: </b>
                        {infosGeraisDados?.loja?.Filial?.FilialEndereco?.cep}
                      </h6>
                      <h6 className="w-60">
                        <b>CPF:/CNPJ: </b>
                        {infosGeraisDados?.loja?.Filial?.cnpj}
                      </h6>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <h6 className="mb-3 bold text-center">destinatário</h6>
                    <h6 className="bb-2 pb-2">
                      <b>NOME: </b>
                      {infosGeraisDados?.Cliente?.nome}
                    </h6>
                    <h6 className="bb-2 pb-2">
                      <b>ENDEREÇO: </b>
                      {infosGeraisDados?.endereco?.logradouro},{' '}
                      {infosGeraisDados?.endereco?.numero}
                      {infosGeraisDados?.endereco?.complemento
                        ? ` ${infosGeraisDados?.endereco?.complemento}`
                        : ''}
                      , {infosGeraisDados?.endereco?.bairro}
                    </h6>
                    <div className="d-flex bb-2">
                      <h6 className="w-60 br-2 mr-2">
                        <b>CIDADE: </b>
                        {infosGeraisDados?.endereco?.cidade}
                      </h6>
                      <h6 className="w-40">
                        <b>UF: </b>
                        {infosGeraisDados?.endereco?.estado}
                      </h6>
                    </div>
                    <div className="d-flex mt-2">
                      <h6 className="w-40 mr-2 br-2">
                        <b>CEP: </b>
                        {infosGeraisDados?.endereco?.cep}
                      </h6>
                      {/* <h6 className="w-60">
                        <b>CPF:/CNPJ: </b>
                        {infosGeraisDados?.Cliente?.cpf}
                      </h6> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <h6 className="text-center mt-2 upper bold">
                  Identificação de bens
                </h6>
              </Col>
            </Row>
            <Row className="upper">
              <Col>
                <Table className="rwd-table" responsive>
                  <thead>
                    <tr>
                      <th className="text-center">item</th>
                      <th className="text-center">conteúdo</th>
                      <th className="text-center">QUANT.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {infosGeraisDados?.itens?.map((i, index) => (
                      <tr key={i?.id}>
                        <td className="text-center" data-label="item">
                          {index + 1}
                        </td>
                        <td data-label="conteúdo">{i?.nome}</td>
                        <td className="text-center" data-label="QUANT.">
                          {i?.quantidade}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        className="text-end"
                        style={{
                          background: 'rgba(var(--theme-color-rgb), 0.12)',
                        }}
                        colSpan={2}
                      >
                        TOTAIS
                      </th>
                      <td className="text-center">
                        {infosGeraisDados?.totalItens}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="text-end"
                        colSpan={2}
                        style={{
                          background: 'rgba(var(--theme-color-rgb), 0.12)',
                        }}
                      >
                        PESO TOTAL(KG)
                      </th>
                      <td className="text-center">
                        {Number(
                          infosGeraisDados?.pesoTotal / 1000
                        ).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <h6 className="text-center mt-2 upper bold">Declaração</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p-small">
                  Declaro que não me enquadro no conceito de contribuinte
                  previsto no art. 4º da Lei Complementar nº 87/1996, uma vez
                  que não realizo, com habitualidade ou em volume que
                  caracterize intuito comercial, operações de circulação de
                  mercadoria, ainda que se iniciem no exterior, ou estou
                  dispensado da emissão da nota fiscal por força da legislação
                  tributária vigente, responsabilizando-me, nos termos da lei e
                  a quem de direito, por informações inverídicas. Declaro ainda
                  que não estou postando conteúdo inflamável, explosivo,
                  causador de combustão espontânea, tóxico, corrosivo, gás ou
                  qualquer outro conteúdo que constitua perigo, conforme o art.
                  13 da Lei Postal nº 6.538/78.
                </p>
                <p className="p-small">
                  _________________________ , _______ de __________________
                  de________________________________
                </p>
                <p className="text-center w-100 mb-1">
                  Assinatura do Declarante/Remetente
                </p>
                <p className="text-center w-100 mb-0">
                  ____________________________________________________________________________
                </p>
              </Col>
            </Row>
            <div className="hr" />
            <h6 className="mt-2 bold">OBSERVAÇÃO:</h6>
            <p className="p-small">
              Constitui crime contra a ordem tributária suprimir ou reduzir
              tributo, ou contribuição social e qualquer acessório (Lei 8.137/90
              Art. 1º, V).
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              printPdfDeclaracao();
            }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEnvio} toggle={toggleModalEnvio} size="xl">
        <ModalHeader toggle={toggleModalEnvio}>Dados do envio</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Código</th>
                    <th>Status entrega</th>
                  </tr>
                </thead>
                <tbody>
                  {infosEnvioRastreio?.map((i) => (
                    <tr key={i?.id}>
                      <td data-label="Item">{i?.nome_produto}</td>
                      <td data-label="Código">
                        {i?.PedidoEntrega?.codigo_rastreio}
                      </td>
                      <td data-label="Status entrega">
                        {i?.PedidoEntrega?.status_entrega}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={toggleModalEnvio}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalInfosGerais} toggle={toggleModalInfos} size="lg">
        <ModalHeader toggle={toggleModalInfos}>
          Pedido #{infosGeraisDados?.codigo}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table className="rwd-table no-min" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Status</th>
                    <th className="text-center">Tipo de envio</th>
                    <th className="text-center">Avaliação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.status}
                    </td>
                    <td data-label="Tipo de envio" className="text-center">
                      {infosGeraisDados?.endereco?.tipo}
                    </td>
                    <td data-label="Avaliação" className="text-center">
                      {infosGeraisDados?.avaliacao === 'N' ? (
                        'N/ avaliado'
                      ) : (
                        <div>
                          {infosGeraisDados?.avaliacao}{' '}
                          <HiStar color="orange" />
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table no-min" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Método de pagamento</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Data pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      data-label="Método de pagamento"
                      className="text-center"
                    >
                      {infosGeraisDados?.condicao_pagamento || 'Sem pagamento'}
                    </td>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.status_pagamento}
                    </td>
                    <td data-label="Data pagamento" className="text-center">
                      {infosGeraisDados?.data_hora_pagamento
                        ? formataData(infosGeraisDados?.data_hora_pagamento)
                        : 'Sem data de pagamento'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table no-min" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Cupom</th>
                    <th className="text-center">Desconto cupom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.codigo_cupom || 'Sem cupom'}
                    </td>
                    <td data-label="Status Pagamento" className="text-center">
                      {infosGeraisDados?.valor_cupom || 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table no-min" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Valor produtos</th>
                    <th className="text-center">Valor frete</th>
                    <th className="text-center">Desconto</th>
                    <th className="text-center">Valor total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Valor produtos" className="text-center">
                      {Number(infosGeraisDados?.valor_produto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor frete" className="text-center">
                      {Number(infosGeraisDados?.taxa_frete).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Desconto" className="text-center">
                      {Number(infosGeraisDados?.valor_desconto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor total" className="text-center">
                      {Number(infosGeraisDados?.total_pedido).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {infosGeraisDados?.PedidoDetalhes?.map((pdt) => {
            const entrega = infosGeraisDados?.entregas?.find(
              (f) => f.id === pdt.id_pedido_entrega
            );

            return (
              <Row key={pdt?.id}>
                <Col>
                  <Card>
                    <CardHeader>
                      <strong>{pdt?.nome_produto}</strong>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg={1}>
                          <div className="d-flex align-items-center mb-2">
                            <img
                              width={60}
                              height={70}
                              className="object-fit"
                              src={
                                pdt?.ProdutoVariacao
                                  ?.ProdutoVariacaoImagems?.[0]?.Imagem?.path ||
                                '/default.jpg'
                              }
                              alt={pdt?.Produto?.titulo}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Table className="rwd-table no-min" responsive>
                            <thead>
                              <tr>
                                <th className="text-center">Valor normal</th>
                                <th className="text-center">Valor desconto</th>
                                <th className="text-center">Valor total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  data-label="Valor total"
                                  className="text-center"
                                >
                                  {Number(pdt?.valor_normal).toLocaleString(
                                    'pt-BR',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                                <td
                                  data-label="Valor sem frete"
                                  className="text-center"
                                >
                                  {Number(pdt?.valor_desconto).toLocaleString(
                                    'pt-BR',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                                <td
                                  data-label="Valor frete"
                                  className="text-center"
                                >
                                  {Number(pdt?.valor_total).toLocaleString(
                                    'pt-BR',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {entrega && (
                        <Row>
                          <Col lg={6}>
                            <h6>
                              Status entrega: &nbsp;
                              <br />
                              <strong>{entrega?.status_entrega}</strong>
                            </h6>
                          </Col>
                          <Col>
                            <h6>
                              Código rastreio: &nbsp;
                              <br />
                              <strong>{entrega?.codigo_rastreio}</strong>
                            </h6>
                          </Col>
                          <Col>
                            <h6>
                              Data entrega: &nbsp;
                              <br />
                              <strong>
                                {entrega?.data_entrega
                                  ? formataDataSemHora(entrega?.data_entrega)
                                  : 'Sem data de entrega'}
                              </strong>
                            </h6>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={toggleModalInfos}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Offcanvas direction="end" isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Montar envio</OffcanvasHeader>
        <OffcanvasHeader>
          <Row>
            <Col>
              <Button
                color="outline-primary"
                className="btn-block"
                onClick={toggleModal}
              >
                Caso ainda não tenha enviado o pedido clique aqui para ver as
                infomações de envio <HiInformationCircle />
              </Button>
            </Col>
          </Row>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row className="mb-3">
            <Col>
              <strong>Adicione quantidade de envios para esse pedido</strong>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              O cliente escolheu o método de envio:&nbsp;&nbsp;
              <strong>{infosGeraisDados?.endereco?.tipo}</strong>
            </Col>
          </Row>
          <Form ref={envioRef} onSubmit={(e) => handleMontarEnvio(e)}>
            {infosGeraisDados?.endereco?.tipo !== 'Combinar com o vendedor' &&
              createInputs()}
            <>
              <div className="hr" />
              <Row>
                <Col>
                  <Button
                    color="outline-success"
                    className="btn-block"
                    onClick={addClick}
                  >
                    Adicionar <HiOutlinePlusCircle />
                  </Button>
                </Col>
              </Row>
              <div className="hr" />
              {qtdEnvios?.val?.length ? (
                <Row>
                  <Col>
                    <Button color="primary" className="btn-block" type="submit">
                      Salvar <HiCheckCircle />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <div />
              )}
            </>
          </Form>
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas
        direction="end"
        isOpen={offcanvasCancelar}
        toggle={toggleOffcanvasCancelar}
      >
        <OffcanvasHeader toggle={toggleOffcanvasCancelar}>
          Cancelar pedido
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form ref={cancelarRef} onSubmit={(e) => handleCancelarPedido(e)}>
            <Row>
              <Col>
                <Input
                  type="text"
                  className="form-control"
                  label="Motivo do cancelamento"
                  name="cancelamento_motivo"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button color="light-warning" type="submit" block>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default Pedidos;
