import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import { Input, Select } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';

function NovoPaciente() {
  const { selectedFilial } = useAuth();
  const search = useSearch();
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();

  const handleSubmit = async (e) => {
    const errors = {};

    e.principal = true;

    const campos = ['nome', 'duracao_minutos', 'tipo', 'cor'];

    campos.forEach((f) => {
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast();
      return;
    }

    try {
      showLoader();
      if (search?.id) {
        await api.put(`/procedimento/${search?.id}`, e);
      } else {
        e.id_filial = selectedFilial?.filial;
        e.status = 'Ativo';
        await api.post('/cadprocedimento', e);
      }
      closeLoader();
      history.goBack();
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  useEffect(() => {
    async function getCliente() {
      try {
        const { data } = await api.get(
          `/procedimento/${search?.id}?id_filial=${selectedFilial?.filial}`
        );
        const { ...rest } = data;
        const body = {};

        Object.entries(rest).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key}`] = value;
          } else {
            body[key] = value;
          }
        });

        formRef.current.setData(body);
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getCliente();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">
            {
              // eslint-disable-next-line no-nested-ternary
              search?.infos ? 'Informações' : search?.id ? 'Editar' : 'Novo'
            }{' '}
            procedimento
          </h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col lg={6}>
              <Input
                name="nome"
                label="Nome"
                className="form-control"
                type="text"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={2}>
              <Input
                name="duracao_minutos"
                label="Duração em minutos *"
                className="form-control"
                type="number"
                disabled={search?.infos}
              />
            </Col>
            <Col lg={3}>
              <Select
                name="tipo"
                label="Tipo *"
                placeholder="Tipo empresa"
                className="form-control"
                disabled={search?.infos}
                data={[
                  {
                    value: '',
                    label: 'Selecione',
                  },
                  {
                    value: 'Consulta',
                    label: 'Consulta',
                  },
                  {
                    value: 'Exame',
                    label: 'Exame',
                  },
                  {
                    value: 'Sessão',
                    label: 'Sessão',
                  },
                  {
                    value: 'Retorno',
                    label: 'Retorno',
                  },
                ]}
              />
            </Col>
            <Col lg={1}>
              <Input
                name="cor"
                label="Cor *"
                className="form-control"
                type="color"
                disabled={search?.infos}
              />
            </Col>
            {!search?.infos && (
              <Col lg={12} className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default NovoPaciente;
