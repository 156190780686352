/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'reactstrap';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import { bandeiras } from '../../../utils/status';

function ListarTaxas() {
  const [dados, setDados] = useState();

  const { showLoader, closeLoader, toast } = useValidator();

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        const { data } = await api.get('/plano-vendedor');
        const tempData = data.reduce((a, b) => {
          if (!a[`${b.metodo}-${b.tipo}`]) {
            a[`${b.metodo}-${b.tipo}`] = {
              recebimento: b.recebimento,
              taxas: [],
            };
          }

          a[`${b.metodo}-${b.tipo}`].taxas.push(b.taxas);

          return a;
        }, {});

        setDados([
          {
            key: `Pix | Recebimento em ${tempData['pix-barcode'].recebimento} dia(s)`,
            items: tempData['pix-barcode'].taxas,
          },
          {
            key: `Boleto | Recebimento em ${tempData['boleto-chip'].recebimento} dia(s)`,
            items: tempData['boleto-chip'].taxas,
          },
          {
            key: `Crédito venda física | Recebimento em ${tempData['credit-chip'].recebimento} dia(s)`,
            items: tempData['credit-chip'].taxas,
          },
          {
            key: `Crédito venda online | Recebimento em ${tempData['credit-manually_keyed'].recebimento} dia(s)`,
            items: tempData['credit-manually_keyed'].taxas,
          },
          {
            key: `Débito venda física | Recebimento em ${tempData['debit-chip'].recebimento} dia(s)`,
            items: tempData['debit-chip'].taxas,
          },
          {
            key: `Débito venda online | Recebimento em ${tempData['debit-manually_keyed'].recebimento} dia(s)`,
            items: tempData['debit-manually_keyed'].taxas,
          },
        ]);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      {dados?.map((tipos) => (
        <Card>
          <h5 className="w-100 text-center mt-2 mb-2">{tipos.key}</h5>
          <Table className="rwd-table" responsive>
            <thead>
              <tr>
                {!(
                  tipos.key.includes('Pix') || tipos.key.includes('Boleto')
                ) && <th className="width-40px">&nbsp;</th>}
                {Array.from(
                  Array(Object.keys(tipos?.items?.[0]).length - 1).keys()
                ).map((i) => (
                  <th className="text-start">{i + 1}x</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(tipos?.items || []).map((bandeira) => (
                <tr key={bandeira.card_brand}>
                  {!(
                    tipos.key.includes('Pix') || tipos.key.includes('Boleto')
                  ) && (
                    <td data-label="Bandeira" className="width-40px">
                      {bandeiras[bandeira.card_brand] && (
                        <img
                          width={35}
                          height={35}
                          alt={bandeira.card_brand}
                          className="object-fit mr-1"
                          src={require(`../../../assets/svg/bandeiras/${
                            bandeiras[bandeira.card_brand]
                          }`)}
                        />
                      )}
                    </td>
                  )}
                  {Object.keys(bandeira)
                    .filter((f) => f !== 'card_brand')
                    .map((key) => (
                      <td
                        className="text-start font-weight-600"
                        data-label={key}
                      >
                        {bandeira[key].percent_amount
                          ? `${bandeira[key].percent_amount / 100}%`
                          : Number(
                              bandeira[key].dollar_amount / 100
                            ).toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      ))}
    </>
  );
}

export default ListarTaxas;
