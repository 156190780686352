import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { Form } from '@unform/web';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

import { Input, Select } from '../../../../components/unform/index';

function AdicionarDado({ modal, toggleModal, refresh, getSub }) {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const handleSubmit = async (e) => {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        showLoader();
        if (modal?.type === 'Marca') {
          await api.post('/empresa/marca', e);
        }
        if (modal?.type === 'Fabricante') {
          await api.post('/empresa/fabricante', e);
        }
        if (modal?.type === 'Categoria') {
          await api.post('/empresa/categoria', e);
        }
        if (modal?.type === 'Sub-categoria') {
          await api.post('/empresa/subcategoria', e);
          getSub(modal?.idSub, 'sub_categoria');
        }
        // if (modal?.type === 'Tipo') {
        //   await api.post('/empresa/tipo', e);
        // }
        // if (modal?.type === 'Sub-tipo') {
        //   await api.post('/empresa/subtipo', e);
        //   getSub(modal?.idSub, 'sub_tipo');
        // }
        if (modal?.type === 'Seção') {
          await api.post('/empresa/secao', e);
        }
        if (modal?.type === 'Unidade de medida') {
          await api.post('/empresa/unidade-medida', e);
        }
        closeLoader();
        toggleModal();
        refresh();
      } catch (err) {
        toast(err.mensagem);
        closeLoader();
      }
    }
  };

  return (
    <Modal
      isOpen={modal?.open}
      toggle={() => {
        toggleModal();
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
        }}
      >
        Adicionar {modal?.type}
      </ModalHeader>
      <ModalBody>
        {modal?.type === 'Marca' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={12}>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
        {modal?.type === 'Fabricante' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={6}>
                <Input
                  placeholder="CNPJ"
                  className="form-control"
                  label="CNPJ *"
                  name="cnpj"
                />
              </Col>
              <Col lg={6}>
                <Input
                  placeholder="Nome"
                  className="form-control"
                  label="Nome *"
                  name="nome"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
        {modal?.type === 'Categoria' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
        {modal?.type === 'Sub-categoria' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={6}>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
              <Col lg={6}>
                {modal?.idSub ? (
                  <Input
                    placeholder="Categoria"
                    className="form-control"
                    // label="Categoria *"
                    name="id_categoria"
                    value={modal?.idSub}
                    hidden
                  />
                ) : (
                  <Select
                    name="id_categoria"
                    label="Categoria"
                    className="form-control"
                    data={[{ value: '', label: 'Selecione' }, ...modal?.dados]}
                  />
                )}
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
        {/* {modal?.type === 'Tipo' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )} */}
        {/* {modal?.type === 'Sub-tipo' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={6}>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
              <Col lg={6}>
                {modal?.idSub ? (
                  <Input
                    placeholder="Categoria"
                    className="form-control"
                    name="id_tipo"
                    value={modal?.idSub}
                    hidden
                  />
                ) : (
                  <Select
                    name="id_tipo"
                    label="Tipo"
                    className="form-control"
                    data={[{ value: '', label: 'Selecione' }, ...modal?.dados]}
                  />
                )}
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )} */}
        {modal?.type === 'Seção' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
        {modal?.type === 'Unidade de medida' && (
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={6}>
                <Input
                  placeholder="Sigla"
                  className="form-control"
                  label="Sigla *"
                  name="sigla"
                />
              </Col>
              <Col lg={6}>
                <Input
                  placeholder="Descrição"
                  className="form-control"
                  label="Descrição *"
                  name="descricao"
                />
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
}

export default AdicionarDado;
