/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Col,
  Row,
} from 'reactstrap';
import { useAuth } from '../../../contexts/auth';
// import logo from '../../../assets/svg/logo-text.svg';

import Banking from '../../../assets/svg/modules/Banking.svg';
import Cadastro from '../../../assets/svg/modules/Cadastro.svg';
import Configuracao from '../../../assets/svg/modules/Configuracao.svg';
import Ecommerce from '../../../assets/svg/modules/Ecommerce.svg';
import Estoque from '../../../assets/svg/modules/Estoque.svg';
import Financeiro from '../../../assets/svg/modules/Financeiro.svg';
import PDV from '../../../assets/svg/modules/PDV.svg';
import Saude from '../../../assets/svg/modules/Saude.svg';

import anuncio1 from '../../../assets/images/anuncios/banner_anuncio1.jpg';
import anuncio2 from '../../../assets/images/anuncios/banner_anuncio2.jpg';
import anuncio3 from '../../../assets/images/anuncios/banner_anuncio3.jpg';

import especialista from '../../../assets/images/especialista.jpg';

const modulos = [
  {
    label: 'PDV',
    img: PDV,
    value: 'Pdv',
  },
  {
    label: 'Estoque',
    img: Estoque,
    value: 'Estoque',
  },
  {
    label: 'Ecommerce',
    img: Ecommerce,
    value: 'Ecommerce',
  },
  {
    label: 'Financeiro',
    img: Financeiro,
    value: 'Financeiro',
  },
  {
    label: 'Cadastros de pessoas',
    img: Cadastro,
    value: 'Cadastros',
  },
  {
    label: 'Saúde',
    img: Saude,
    value: 'Saude',
  },
  {
    label: 'Configurações',
    img: Configuracao,
    value: 'Configuracoes',
  },
];

const items = [
  {
    src: anuncio1,
    altText: 'Slide 1',
    key: 1,
  },
  {
    src: anuncio2,
    altText: 'Slide 2',
    key: 2,
  },
  {
    src: anuncio3,
    altText: 'Slide 3',
    key: 3,
  },
];

function Modulo() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { setModulo } = useAuth();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.src}
    >
      <img src={item.src} className="img-carousel-item" alt={item.altText} />
      {/* <CarouselCaption
        captionText={item.caption}
        captionHeader={item.caption}
      /> */}
    </CarouselItem>
  ));

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
      }}
    >
      <div
        className="bg-gray0"
        style={{
          webkitBoxFlex: 1,
          flexGrow: 1,
          minWidth: 0,
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        <main
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {/* <div>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </div> */}
          <div className="container">
            <Row className="mt-5 mb-5">
              <Col lg={8}>
                <h1
                  className="text-start ml-3"
                  style={{
                    color: '#000',
                    fontWeight: 600,
                  }}
                >
                  UneoERP
                  <span
                    className="small font-600"
                    style={{
                      color: 'rgb(110, 110, 115)',
                    }}
                  >
                    {' '}
                    unindo você <br />
                    ao que realmente importa.
                  </span>
                </h1>
              </Col>
              <Col lg={4}>
                <div className="d-flex justify-content-end">
                  <img
                    src={especialista}
                    className="especialista"
                    alt="Nosso especialista"
                  />
                  <div className="mx-3 text-start mt-2">
                    <p className="mb-0 font-600 font-black">
                      Precisa de ajuda?
                    </p>
                    <a className="decoration-none" href="#">
                      Fale com um especialista
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {modulos.map((m) => (
                <Col lg={3} md={4} sm={6}>
                  <button
                    onClick={() => setModulo(m.value)}
                    type="button"
                    className="mb-1 btn-modulo"
                  >
                    <div>
                      <img src={m.img} alt={m.label} width={200} />
                    </div>
                    <div className="font-600">{m.label}</div>
                    <br />
                  </button>
                </Col>
              ))}
            </Row>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Modulo;
