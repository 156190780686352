import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaListOl,
  FaWallet,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { IoSearch } from 'react-icons/io5';
import {
  Input,
  ReactSelect,
  InputGroup,
  Select,
} from 'components/unform/index';
import api from 'services/api';
import useValidator from 'hooks/useValidator';
import getQuery from 'utils/getQuery';

function AgentesTelecom() {
  const [dados, setDados] = useState({ page: 1 });
  const filtrosRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/vendedores?page=${page || 1}&${query}`);
      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  function noRefCheck() {}

  useEffect(() => {
    async function getDados() {
      try {
        showLoader();
        // const { data } = await api.get('/vendedores');
        // setDados(data);
      } finally {
        closeLoader();
      }
    }
    getDados();
  }, []);

  return (
    <>
      <div className="page-header">
        <Row>
          <Col lg={9} xs={5}>
            <h4>Agentes</h4>
          </Col>
          <Col lg={3} xs={7}>
            <Button block color="primary">
              Usuários
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">OCIOSO</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(186, 190, 195)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">FALANDO</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(92, 184, 92)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">TPA</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(44, 123, 229)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">MTPA</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(81, 45, 168)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">MANUAL</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(255, 145, 0)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6 col-lg-6 col-md-6 col-xl">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="text-muted text-status-sm">INTERVALO</div>
                  <span className="font-500 h4">0</span>
                </Col>
                <Col className="col-auto">
                  <div
                    className="dot-status"
                    style={{
                      background: 'rgb(255, 208, 55)',
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card>
        <div className="px-3">
          <Form onSubmit={() => loadMore()} ref={filtrosRef}>
            <Row>
              <Col lg={6}>
                <InputGroup
                  name="nome"
                  className="form-control-group no-border mb-0"
                  placeholder="Nome"
                  formGroupClass="mb-3"
                  prepend={<IoSearch />}
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="tipo"
                  formGroupClass="mb-3"
                  onChange={filtrarPesquisa}
                  className="form-control no-border mb-0"
                  data={[
                    {
                      value: '',
                      label: 'Equipe',
                    },
                  ]}
                />
              </Col>
              <Nav className="nav-filter" tabs>
                <NavItem>
                  <button
                    className="btn-nav active small"
                    type="button"
                    onClick={noRefCheck}
                  >
                    <div className="text-center">
                      ONLINE
                      <br /> 0
                    </div>
                  </button>
                </NavItem>
                <NavItem>
                  <button
                    className="btn-nav small"
                    type="button"
                    onClick={noRefCheck}
                  >
                    <div className="text-center">
                      OFFLINE
                      <br /> 0
                    </div>
                  </button>
                </NavItem>
              </Nav>
            </Row>
          </Form>
        </div>

        <Table className="rwd-table min-height" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Campanha</th>
              <th>Status</th>
              <th>Ligações hoje</th>
              <th>Tempo total</th>
            </tr>
          </thead>
          <tbody>
            {(dados?.items || []).map((m) => (
              <tr key={m.id}>
                <td data-label="Nome">{m.nome}</td>
                <td data-label="Campanha">{m.campanha}</td>
                <td data-label="Status">{m.status}</td>
                <td data-label="Ligações hoje">{m.ligacoes}</td>
                <td data-label="Tempo total">{m.tempo_total}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default AgentesTelecom;
