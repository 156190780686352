import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/auth';
import { ThemeProvider } from './contexts/theme';
import Routes from './routes';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
