import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { Input, NumberFormat, Select } from '../../../../components/unform';
import { useValidator } from '../../../../hooks';
import api from '../../../../services/apiPDV';

import { useAuth } from '../../../../contexts/auth';

function NovaVariacao({ modal, toggleModal, refresh, idProduto }) {
  const { filiais } = useAuth();
  const formRef = useRef();

  const { showLoader, closeLoader, toast } = useValidator();

  const [rowEan, setRowEan] = useState([]);
  const handleAddRowEan = () => {
    setRowEan([...rowEan, { id: rowEan.length }]);
  };
  const handleRemoveRowEan = (index) => {
    setRowEan(rowEan.filter((row, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    const errors = {};

    const campos = [
      'id_filial',
      'preco_custo',
      'preco_venda',
      'markup',
      'estoque_minimo',
      'estoque_disponivel',
      'quantidade_unidade',
    ];

    Object.keys(e).forEach((campo) => {
      if (campo.includes('ean_')) {
        campos.push(campo);
      }
    });

    campos.forEach((f) => {
      if (f === 'preco_custo' || f === 'preco_venda') {
        if (e[f] !== undefined && e[f] !== null && e[f] === 0) {
          errors[f] = 'Obrigatório';
        }
      }
      if (typeof e[f] === 'undefined' || e[f] === null || e[f] === '') {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      const eanArray = [];
      Object.keys(e).forEach((campo) => {
        if (campo.includes('ean_')) {
          eanArray.push({ ean: e[campo], principal: !eanArray.length });
          delete e[campo];
        }
      });
      if (eanArray.length) {
        e.eans = eanArray;
      }
      try {
        showLoader();
        if (modal?.type !== 'edit') {
          await api.post(`/produto/${idProduto}/variacao`, e);
        } else {
          await api.put(
            `/produto/variacao/${modal?.objEdit?.id}/${idProduto}`,
            e
          );
        }
        refresh();
        toast('Dados salvos com sucesso!', { type: 'success' });
        setRowEan([]);
        toggleModal();
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    }
  };

  useEffect(() => {
    async function getDataEdit() {
      if (modal?.objEdit) {
        const { objEdit } = modal;
        setTimeout(() => {
          Object.keys(objEdit).forEach((e) => {
            formRef.current.setFieldValue(e, objEdit[e]);
          });
        }, 200);

        if (objEdit?.ProdutoVariacaoEans) {
          objEdit.ProdutoVariacaoEans.sort((a, b) => b.principal - a.principal);

          const updatedRows = objEdit.ProdutoVariacaoEans.map((ean, i) => ({
            ...rowEan[i],
            ean: ean.ean,
          }));
          setRowEan(updatedRows);

          setTimeout(() => {
            for (let i = 0; i < objEdit?.ProdutoVariacaoEans?.length; i += 1) {
              formRef?.current?.setFieldValue(
                `ean_${i}`,
                objEdit?.ProdutoVariacaoEans[i].ean
              );
            }
          }, 200);
        }
      }
    }
    getDataEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return (
    <Modal
      isOpen={modal?.open}
      toggle={() => {
        toggleModal();
        setRowEan([]);
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggleModal();
          setRowEan([]);
        }}
      >
        Cadastrar variação
      </ModalHeader>

      <Form
        onSubmit={(d) => {
          handleSubmit(d);
        }}
        ref={formRef}
      >
        <ModalBody>
          <Row>
            <Col>
              <Input
                label="Códgio SKU"
                className="form-control"
                placeholder="Digite o SKU"
                name="sku"
              />
            </Col>
            <Col lg={3} style={{ marginTop: '30px' }}>
              <Button
                block
                color="success"
                type="button"
                onClick={handleAddRowEan}
              >
                Adicionar EAN <FaPlusCircle />
              </Button>
            </Col>
            {rowEan.map((row, index) => (
              <Row key={row.id}>
                <Col lg={11}>
                  <Input
                    name={`ean_${index}`}
                    label={`EAN ${index + 1 === 1 ? 'Principal' : index + 1}`}
                    className="form-control"
                  />
                </Col>
                <Col lg={1} style={{ marginTop: '30px' }}>
                  <Button
                    block
                    color="danger"
                    type="button"
                    onClick={() => handleRemoveRowEan(index)}
                  >
                    <FaTrashAlt />
                  </Button>
                </Col>
              </Row>
            ))}
          </Row>
          <hr />
          <Row>
            <Col>
              <NumberFormat
                name="preco_custo"
                label="Preço custo *"
                className="form-control"
                prefix="R$"
                defaultValue={modal?.objEdit?.preco_custo}
              />
            </Col>
            <Col>
              <NumberFormat
                name="preco_venda"
                label="Preço venda *"
                className="form-control"
                prefix="R$"
                defaultValue={modal?.objEdit?.preco_venda}
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Markup *"
                className="form-control"
                placeholder="Digite o Markup"
                name="markup"
                step="0.01"
                min="0"
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Input
                label="Cor"
                className="form-control"
                placeholder="Digite a cor"
                name="cor"
              />
            </Col>
            <Col>
              <Input
                label="Tamanho"
                className="form-control"
                placeholder="Digite o tamnho (P, M, G, etc..)"
                name="tamanho"
              />
            </Col>
            <Col>
              <Input
                label="Unidade tamanho"
                className="form-control"
                placeholder="Digite a unidade"
                name="unidade_tamanho"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                type="number"
                label="Quantidade unidade"
                className="form-control"
                placeholder="Digite a quantidade da unidade"
                name="quantidade_unidade"
              />
            </Col>
            <Col>
              <Select
                name="permite_informar_quantidade"
                label="Permite informar qtd."
                className="form-control"
                data={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Peso do produto"
                className="form-control"
                placeholder="Digite o peso do produto"
                name="peso"
                step="0.01"
                min="0"
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Peso da embalagem"
                className="form-control"
                placeholder="Digite o peso da embalagem"
                name="embalagem_peso"
                step="0.01"
                min="0"
              />
            </Col>
          </Row>
          <Row />
          <Row>
            <Col>
              <Input
                type="number"
                label="Altura da embalagem"
                className="form-control"
                placeholder="Digite a altura da embalagem"
                name="embalagem_altura"
                step="0.01"
                min="0"
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Largura da embalagem"
                className="form-control"
                placeholder="Digite a largura da embalagem"
                name="embalagem_largura"
                step="0.01"
                min="0"
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Comprimento da embalagem"
                className="form-control"
                placeholder="Digite o comprimento da embalagem"
                name="embalagem_comprimento"
                step="0.01"
                min="0"
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Select
                name="id_filial"
                label="Filial"
                className="form-control"
                data={[{ value: '', label: 'Selecione' }, ...filiais]}
                onChange={(e) => {
                  if (e?.target?.value) {
                    if (
                      modal?.objEdit &&
                      modal?.objEdit?.ProdutoVariacaoEstoques
                    ) {
                      const estoqueFilial =
                        modal?.objEdit?.ProdutoVariacaoEstoques?.filter(
                          (pe) => pe.id_filial === Number(e?.target?.value)
                        )[0];

                      if (estoqueFilial) {
                        formRef?.current?.setFieldValue(
                          'estoque_minimo',
                          estoqueFilial?.estoque_minimo
                        );
                        formRef?.current?.setFieldValue(
                          'estoque_disponivel',
                          estoqueFilial?.estoque_disponivel
                        );
                      } else {
                        formRef?.current?.setFieldValue('estoque_minimo', '');
                        formRef?.current?.setFieldValue(
                          'estoque_disponivel',
                          ''
                        );
                      }
                    }
                  } else {
                    formRef?.current?.setFieldValue('estoque_minimo', '');
                    formRef?.current?.setFieldValue('estoque_disponivel', '');
                  }
                }}
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Estoque mínimo *"
                className="form-control"
                placeholder="Digite o estoque mínimo"
                name="estoque_minimo"
              />
            </Col>
            <Col>
              <Input
                type="number"
                label="Estoque disponível *"
                className="form-control"
                placeholder="Digite o estoque disponível"
                name="estoque_disponivel"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default NovaVariacao;
