import React, { memo, useEffect, useState } from 'react';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';

// import { Container } from './styles';

function DataHora() {
  const [data, setData] = useState('');
  const [hora, setHora] = useState('');

  useEffect(() => {
    const timeout =
      1000 * 60 -
      (1000 * new Date().getSeconds() + new Date().getMilliseconds());

    const agora = new Date();
    const horaFormatada1 = agora.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const dataFormatada1 = agora.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
    });

    setData(dataFormatada1);
    setHora(horaFormatada1);

    let interval;
    setTimeout(() => {
      interval = setInterval(() => {
        const dataAtual = new Date();
        const horaFormatada = dataAtual.toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit',
        });
        const dataFormatada = dataAtual.toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
        });

        setData(dataFormatada);
        setHora(horaFormatada);
      }, 1000 * 60);
    }, timeout);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="display-flex align-items-center mobile-hidden">
        <FaCalendarAlt size={18} className="button-pdv mr-2" />
        <p className="text-left text-footer">{data}</p>
      </div>

      <div className="display-flex align-items-center mx-3 mobile-hidden">
        <FaClock size={18} className="button-pdv mr-2" />
        <p className="text-left text-footer">{hora}</p>
      </div>
    </>
  );
}

export default memo(DataHora, () => true);
