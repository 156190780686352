/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-return-await */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
// /* eslint-disable no-return-await */
// /* eslint-disable no-underscore-dangle */
import { Form } from '@unform/web';
import React, { useRef, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  NumberFormat,
  Input,
  ReactSelectAsyncCreatable,
  ReactSelectAsync,
  ReactSelect,
} from '../../../../components/unform/index';

import api from '../../../../services/apiPDV';
import getOptions from '../../../../utils/getOptionsPDV';
import { colors } from '../../../../utils/global';
import ModalGenerecia from '../TipoDespesaReceita/Cadastro';
import useSearch from '../../../../utils/searchParams';
import { useAuth } from '../../../../contexts/auth';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

const CadastroLancamento = () => {
  const { selectedFilial } = useAuth();
  const formRef = useRef(null);
  const formModalRef = useRef(null);
  const [novoRegistro, setNovoRegistro] = useState('');
  const [modal, setModal] = useState();
  const [bancos, setBancos] = useState([]);
  const [parcelas, setParcelas] = useState();
  const [valorTotal, setValorTotal] = useState();
  const history = useHistory();
  const search = useSearch();

  function handleOpenModal({ tipo, valor }) {
    setModal(tipo);
    setNovoRegistro(valor);
  }

  const closeModal = () => setModal(null);

  async function handleAddItem(valor, obj = novoRegistro) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    valor.id_filial = selectedFilial?.filial;
    try {
      const { data } = await api.post(obj?.rota || modal, {
        descricao: valor.nome,
        ...valor,
      });
      const val = {
        value: data?.id,
        label: data?.nome || data?.descricao,
      };
      if (modal === '/tipo-lancamento') {
        val.cor = data.tipo === 'D' ? colors.errorDarken() : colors.success();
        val.tipo = data.tipo;
      }
      formRef.current.setFieldValue(obj?.field, val);
      Swal.close();
      if (modal) {
        closeModal();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possível salvar');
      Swal.close();
    }
  }

  async function handleSubmit(data) {
    const erro = {};

    const notRequired = [
      'id_plano_contas',
      'id_forma_pagamento',
      'banco',
      'id_centro_custo',
      'cliente_fornecedor',
    ];

    Object.keys(data).forEach((f) => {
      if (!notRequired.includes(f)) {
        if (!data[f]) {
          erro[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(erro);

    if (!Object.keys(erro).length) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        data.id_filial = selectedFilial?.filial;
        if (data?.cliente_fornecedor) {
          if (data?.cliente_fornecedor?.tipo === 'F') {
            data.id_fornecedor = data?.cliente_fornecedor?.value;
            data.id_cliente = null;
          }
          if (data?.cliente_fornecedor?.tipo === 'C') {
            data.id_cliente = data?.cliente_fornecedor?.value;
            data.id_fornecedor = null;
          }

          delete data?.cliente_fornecedor;
        }
        const body = {
          ...data,
          tipo: data?.id_tipo_lancamento?.tipo,
          id_tipo_lancamento: data?.id_tipo_lancamento?.value,
          valor_total:
            typeof data.valor_total === 'number'
              ? data.valor_total
              : Number(
                  data.valor_total.replace(/[^0-9,]/g, '').replace(',', '.')
                ),
        };
        if (search?.id) {
          await api.put(`/despesa-receita/${search?.id}`, body);
          toast.success('Despesa/Receita alterada!');
        } else {
          await api.post('/despesa-receita', body);
          toast.success('Despesa/Receita lançada!');
        }
        Swal.close();
        history.push('/erp/lancamento');
      } catch (err) {
        const errors = {};
        if (err?.response?.data?.messages?.length) {
          err.response.data.messages.forEach((f) => {
            errors[f?.path] = f?.message;
          });
        }
        formRef.current.setErrors(errors);
        Swal.close();
        toast.error(err?.response?.data?.error || 'Não foi possível salvar');
      }
    }
  }

  async function handleEditParcela(data) {
    const erro = {};

    const notRequired = ['data_pagamento', 'valor_pago'];

    Object.keys(data).forEach((f) => {
      if (!notRequired.includes(f)) {
        if (!data[f]) {
          erro[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(erro);

    if (!Object.keys(erro).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        if (data?.data_pagamento === '') {
          delete data.data_pagamento;
        }
        if (data?.valor_pago === 0) {
          delete data?.valor_pago;
        }
        await api.put(`/despesa-receita/${novoRegistro?.id}/parcela`, data);
        setParcelas(
          parcelas.map((m) => {
            if (m.id === novoRegistro?.id) {
              return {
                ...data,
                id: novoRegistro?.id,
                status: data.data_pagamento ? 'Fechado' : 'Aberto',
                data_vencimento: data?.data_vencimento,
                data_pagamento: data?.data_pagamento,
              };
            }
            return m;
          })
        );
        closeModal();
        Swal.close();
      } catch (err) {
        toast.error(
          err?.response?.data?.error || 'Não foi possível alterar a parcela'
        );
        Swal.close();
      }
    }
  }

  function handeDeleteLancamento(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja cancelar esta parcela?',
        text: 'A parcela será cancelada e desconsiderada das projeções',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/despesa-receita/${id}/parcela`)
            .then((r) => {
              swalWithBootstrapButtons.fire(
                'Lançamento cancelado!',
                '',
                'success'
              );
              setParcelas(
                parcelas.map((m) => {
                  if (m.id === id) {
                    return {
                      ...m,
                      status: 'Cancelado',
                      valor_pago: '0',
                      data_pagamento: new Date().toISOString(),
                    };
                  }

                  return m;
                })
              );
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Ocorreu um erro, tente novamente mais tarde!',
                '',
                'error'
              );
            });
        }
      });
  }

  useEffect(() => {
    async function getBancos() {
      try {
        const { data } = await api.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(
          data.map((m) => ({
            value: m.name,
            label: `${m.code || ''}${m.code ? ' - ' : ' '}${m.fullName}`,
          }))
        );
      } catch (err) {
        return err;
      }
    }
    getBancos();
  }, []);

  useEffect(() => {
    async function getLancamento() {
      try {
        const { data } = await api.get(`/despesa-receita/${search?.id}`);
        const {
          despesa_receita_cf,
          despesa_receita_parcela,
          data_lancamento,
          data_base_vencimento,
          ...rest
        } = data;

        const body = {};

        Object.entries(rest).forEach(([key, value]) => {
          if (typeof value === 'object') {
            body[`id_${key}`] = value;
          } else {
            body[key] = value;
          }
        });

        body.data_lancamento = new Date(data_lancamento)
          ?.toLocaleDateString()
          ?.split('/')
          ?.reverse()
          ?.join('-');
        body.data_base_vencimento = new Date(data_base_vencimento)
          ?.toLocaleDateString()
          ?.split('/')
          ?.reverse()
          ?.join('-');
        body.id_cliente_fornecedor = despesa_receita_cf?.cliente_fornecedor;
        body.id_tipo_lancamento.cor =
          body.id_tipo_lancamento.tipo === 'D'
            ? colors.error()
            : colors.success();

        formRef.current.setData(body);
        formRef.current.setFieldValue('cliente_fornecedor', {
          value:
            data?.despesa_receita_cf?.fornecedor?.id ||
            data?.despesa_receita_cf?.cliente?.id ||
            '',
          label:
            data?.despesa_receita_cf?.fornecedor?.nome ||
            data?.despesa_receita_cf?.cliente?.nome ||
            '',
          // eslint-disable-next-line no-nested-ternary
          tipo: data?.despesa_receita_cf?.fornecedor
            ? 'F'
            : data?.despesa_receita_cf?.cliente
            ? 'C'
            : '',
        });

        formRef.current.setFieldValue('banco', {
          value: data?.banco,
          label: data?.banco,
        });
        setValorTotal(body.valor_total);
        setParcelas(despesa_receita_parcela);
      } catch (err) {
        return err;
      }
    }
    if (search?.id) {
      getLancamento();
    }
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Novo Lançamento</h4>
        </Col>
      </Row>
      <Card className="mb-3">
        <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
          <CardBody>
            <Row>
              <Col lg={3} md={6}>
                <FormGroup>
                  <Input
                    label="Número documento *"
                    type="text"
                    name="documento"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={6}>
                <FormGroup>
                  <ReactSelectAsync
                    label="Tipo de lançamento *"
                    name="id_tipo_lancamento"
                    fullObj
                    isClearable
                    isDisabled={!!valorTotal}
                    onCreateOption={(valor) =>
                      handleOpenModal({
                        tipo: '/tipo-lancamento',
                        valor: {
                          field: 'id_tipo_lancamento',
                          title: 'Novo tipo lançamento',
                          valor,
                        },
                      })
                    }
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/tipo-lancamento',
                        idFilial: selectedFilial?.filial,
                      });

                      const error = colors.errorDarken();
                      const success = colors.successDarken();
                      return dados.map((m) => ({
                        ...m,
                        cor: m.tipo === 'D' ? error : success,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={5} md={12}>
                <FormGroup>
                  <Input
                    label="Descrição *"
                    type="text"
                    name="descricao"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={6}>
                <FormGroup>
                  <ReactSelectAsyncCreatable
                    label="Plano de contas"
                    name="id_plano_contas"
                    onCreateOption={(valor) => {
                      handleAddItem(
                        {
                          nome: valor,
                        },
                        {
                          rota: '/plano-contas',
                          field: 'id_plano_contas',
                        }
                      );
                    }}
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/plano-contas',
                        idFilial: selectedFilial?.filial,
                      });
                      return dados;
                    }}
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={6}>
                <FormGroup>
                  <ReactSelectAsyncCreatable
                    label="Forma pagamento"
                    name="id_forma_pagamento"
                    onCreateOption={(valor) => {
                      handleAddItem(
                        {
                          nome: valor,
                        },
                        {
                          rota: '/forma-pagamento',
                          field: 'id_forma_pagamento',
                        }
                      );
                    }}
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/forma-pagamento',
                        idFilial: selectedFilial?.filial,
                      });
                      return dados;
                    }}
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={6}>
                <FormGroup>
                  <ReactSelect
                    label="Banco"
                    name="banco"
                    options={bancos}
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={6}>
                <FormGroup>
                  <ReactSelectAsyncCreatable
                    label="Centro custo"
                    name="id_centro_custo"
                    onCreateOption={(valor) => {
                      handleAddItem(
                        {
                          nome: valor,
                        },
                        {
                          rota: '/centro-custos',
                          field: 'id_centro_custo',
                        }
                      );
                    }}
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/centro-custos',
                        idFilial: selectedFilial?.filial,
                      });
                      return dados;
                    }}
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col lg={5}>
                <FormGroup>
                  <ReactSelectAsync
                    label="Cliente / Fornecedor"
                    name="cliente_fornecedor"
                    disabled={!!valorTotal}
                    isClearable
                    fullObj
                    loadOptions={async (value) => {
                      const dados = await getOptions({
                        value,
                        route: '/cliente-fornecedor',
                        idFilial: selectedFilial?.filial,
                      });
                      return dados;
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={3} md={6}>
                <FormGroup>
                  {valorTotal ? (
                    <Input
                      label="Valor total *"
                      name="valor_total"
                      value={Number(valorTotal).toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                      })}
                      disabled
                      className="form-control"
                    />
                  ) : (
                    <NumberFormat
                      label="Valor total *"
                      name="valor_total"
                      placeholder="R$"
                      number
                      className="form-control"
                    />
                  )}
                </FormGroup>
              </Col>
              <Col lg={2} md={6}>
                <FormGroup>
                  <Input
                    label="Qtd. parcelas *"
                    type="number"
                    min="1"
                    disabled={!!valorTotal}
                    className="form-control"
                    name="quantidade_parcelas"
                  />
                </FormGroup>
              </Col>
              <Col lg={3} md={6}>
                <FormGroup>
                  <Input
                    label="Data lançamento *"
                    type="date"
                    name="data_lancamento"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col lg={3} md={6}>
                <FormGroup>
                  <Input
                    label="Data base vencimento *"
                    disabled={!!valorTotal}
                    type="date"
                    name="data_base_vencimento"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>
            {parcelas && (
              <>
                <Row>
                  <Col lg={12}>
                    <Label for="dataVencimento">Saldo devedor</Label>
                  </Col>
                  <Col lg={4} sm={12} className="mb-3">
                    <Card>
                      <CardBody>
                        <Label
                          for="dataVencimento"
                          className="bold text-center w-100"
                        >
                          Não vencidas
                        </Label>
                        <h5 className="text-center">
                          {parcelas
                            ?.filter(
                              (f) =>
                                f.status === 'Aberto' &&
                                new Date(
                                  new Date().setDate(new Date().getDate() - 1)
                                ) < new Date(f.data_vencimento).getTime()
                            )
                            ?.reduce(
                              (total, item) => Number(item.valor) + total,
                              0
                            )
                            ?.toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4} sm={12} className="mb-3">
                    <Card>
                      <CardBody>
                        <Label
                          for="dataVencimento"
                          className="bold text-center w-100"
                        >
                          Vencidas
                        </Label>
                        <h5 className="text-center">
                          {parcelas
                            ?.filter(
                              (f) =>
                                f.status === 'Aberto' &&
                                new Date(
                                  new Date().setDate(new Date().getDate() - 1)
                                ).getTime() >
                                  new Date(f.data_vencimento).getTime()
                            )
                            ?.reduce(
                              (total, item) => Number(item.valor) + total,
                              0
                            )
                            ?.toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4} sm={12} className="mb-3">
                    <Card>
                      <CardBody>
                        <Label
                          for="dataVencimento"
                          className="bold text-center w-100"
                        >
                          Total
                        </Label>
                        <h5 className="text-center">
                          {parcelas
                            ?.filter((f) => !f.valor_pago)
                            ?.reduce(
                              (total, item) => Number(item.valor) + total,
                              0
                            )
                            ?.toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Table className="mb-0 rwd-table">
                  <thead>
                    <tr>
                      <th scope="col" className="bt-0 t-center">
                        Parcela
                      </th>
                      <th scope="col" className="bt-0 t-center">
                        Data Vencimento
                      </th>
                      <th scope="col" className="bt-0 t-center">
                        Valor Parcela
                      </th>
                      <th scope="col" className="bt-0 t-center">
                        Data Pagamento
                      </th>
                      <th scope="col" className="bt-0 t-center">
                        Valor Pagamento
                      </th>
                      <th scope="col" className="bt-0 t-center">
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {parcelas.map((parcela) => (
                      <tr key={parcela.id}>
                        <td className="t-center" data-label="parcela">
                          <p>
                            {parcela.parcela}/{parcelas.length}
                          </p>
                        </td>
                        <td
                          className="t-center"
                          data-label="Data vencimento parcela"
                        >
                          {new Date(
                            parcela?.data_vencimento
                          ).toLocaleDateString('pt-BR')}
                        </td>
                        <td className="t-center" data-label="Valor parcela">
                          <p>
                            {Number(parcela.valor).toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                          </p>
                        </td>
                        <td
                          className="t-center"
                          data-label="dataPagamentoParcela"
                        >
                          <p>
                            {parcela.data_pagamento &&
                              new Date(
                                parcela.data_pagamento
                              ).toLocaleDateString('pt-BR')}
                          </p>
                        </td>
                        <td
                          className="t-center"
                          data-label="valorPagamentoParcela"
                        >
                          <p>
                            {parcela.valor_pago &&
                              Number(parcela.valor_pago).toLocaleString(
                                'pt-BR',
                                {
                                  currency: 'BRL',
                                  style: 'currency',
                                }
                              )}
                          </p>
                        </td>
                        <td className="t-center" data-label="ações">
                          {!parcela.data_pagamento && (
                            <>
                              <Button
                                color="info"
                                className="btn-sm color-white"
                                type="button"
                                onClick={() =>
                                  handleOpenModal({
                                    tipo: 'editar-parcela',
                                    valor: parcela,
                                  })
                                }
                              >
                                <FaPencilAlt />
                              </Button>
                              <Button
                                color="danger"
                                className="btn-sm ml-1"
                                type="button"
                                onClick={() =>
                                  handeDeleteLancamento(parcela?.id)
                                }
                              >
                                <FaTrashAlt />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
            <div className="justify-between d-flex mt-2">
              <div />
              <Button color="success" type="submit">
                Salvar
              </Button>
            </div>
          </CardBody>
        </Form>
      </Card>
      <Modal isOpen={modal === 'editar-parcela'} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Parcela</ModalHeader>
        <Form
          onSubmit={(data) => handleEditParcela(data)}
          ref={formModalRef}
          initialData={{
            valor: novoRegistro?.valor,
            data_vencimento: new Date(novoRegistro?.data_vencimento)
              ?.toLocaleDateString()
              ?.split('/')
              ?.reverse()
              ?.join('-'),
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Input
                    label="Data vencimento *"
                    type="date"
                    className="form-control"
                    name="data_vencimento"
                  />
                  <NumberFormat
                    label="Valor parcela *"
                    name="valor"
                    placeholder="R$"
                    number
                    className="form-control"
                  />
                  <NumberFormat
                    label="Valor pago"
                    name="valor_pago"
                    placeholder="R$"
                    number
                    className="form-control"
                  />
                  <Input
                    label="Data pagamento"
                    type="date"
                    number
                    className="form-control"
                    name="data_pagamento"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" type="button" onClick={closeModal}>
              Cancelar
            </Button>
            <Button color="success">Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
      <ModalGenerecia
        toggle={closeModal}
        isOpen={
          modal && !['editar-parcela', 'cliente_fornecedor'].includes(modal)
        }
        value={novoRegistro}
        onAdd={handleAddItem}
        tipo={modal === '/tipo-lancamento'}
        title={novoRegistro?.title}
      />
    </>
  );
};

export default CadastroLancamento;
