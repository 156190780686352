import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';
import {
  Input,
  MaskInput,
  Select,
  ReactSelect,
  Image,
  Assinatura,
  Radio,
  NumberFormat,
} from '../../../components/unform';
import api from '../../../services/api';
import useValidator from '../../../hooks/useValidator';
import ufs from './ufs.json';

function PreCadastro() {
  const formRef = useRef();
  const [planos, setPlanos] = useState([]);
  const [mccs, setMccs] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [informarBanco, setInformarBanco] = useState(false);
  const [tipoPessoa, setTipoPessoa] = useState('PF');
  const { showLoader, closeLoader, toast, validator } = useValidator();

  async function handleSubmit(dados) {
    const { errors, hasError } = validator(dados, [
      'complemento',
      'representante_complemento',
    ]);

    formRef.current.setErrors(errors);

    if (hasError) {
      toast();
      return;
    }

    if (tipoPessoa === 'PJ') {
      dados.representante_codigo_pais = 'BR';
    }

    const formData = new FormData();

    Object.entries(dados).forEach(([key, value]) => {
      if (['documento_frente', 'documento_verso', 'selfie'].includes(key)) {
        formData.append('file', value, `${key}@${value.name}`);
      } else {
        formData.append(key, value);
      }
    });

    showLoader();
    try {
      await api.post('/pre-cadastro', formData);
      closeLoader();
      toast('Dados do pré cadastro salvo', { type: 'success' });
      formRef.current.reset();
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  }

  async function getCep(cepStr = '', rep) {
    const cep = String(cepStr).replace(/[^A-Z0-9]/gi, '');
    const str = rep ? 'representante_' : '';
    if (cep.length !== 8) {
      return;
    }
    showLoader();
    try {
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      formRef.current.setFieldValue(`${str}cidade`, data.city);
      formRef.current.setFieldValue(`${str}bairro`, data.neighborhood);
      formRef.current.setFieldValue(`${str}estado`, data.state);
      formRef.current.setFieldValue(`${str}logradouro`, data.street);
    } catch (err) {
      formRef.current.setFieldValue(`${str}cidade`, '');
      formRef.current.setFieldValue(`${str}bairro`, '');
      formRef.current.setFieldValue(`${str}estado`, '');
      formRef.current.setFieldValue(`${str}logradouro`, '');
      toast('CEP não encontrado', { type: 'warning' });
    }
    closeLoader();
  }

  useEffect(() => {
    const getPlanos = async () => {
      try {
        const { data } = await api.get('/planos');
        setPlanos(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: m.id,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    const getMccs = async () => {
      try {
        const { data } = await api.get('/mcc');
        setMccs(
          (data?.items || [])?.map((m) => ({
            ...m,
            value: m.id,
            label: `${m.code} - ${m.category} : ${m.description}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };

    getPlanos();
    getMccs();
  }, []);

  useEffect(() => {
    const getBancos = async () => {
      try {
        const { data } = await api.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(
          data
            .filter((f) => Boolean(f.code))
            .map((m) => ({
              value: String(m.code).padStart(3, '0'),
              label: `${m.code} - ${m.name}`,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (informarBanco && !bancos.length) {
      getBancos();
    }
  }, [informarBanco, bancos]);

  return (
    <>
      <Card className="px-3 py-3">
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Radio
            name="tipo_pessoa"
            label="Tipo do cadastro"
            onChange={(e) => setTipoPessoa(e.target.value)}
            defaultValue="PF"
            data={[
              {
                value: 'PF',
                id: 'PF',
                label: 'Pessoa física',
              },
              {
                id: 'PJ',
                value: 'PJ',
                label: 'Pessoa jurídica',
              },
            ]}
          />
          {tipoPessoa === 'PJ' ? (
            <>
              <div className="separator">Dados da empresa</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cnpj"
                    label="CNPJ"
                    placeholder="CNPJ"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="razao_social"
                    label="Razão social"
                    placeholder="Razão social"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="nome_fantasia"
                    label="Nome fantasia"
                    placeholder="Nome fantasia"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={3}>
                  <Input
                    name="data_abertura_empresa"
                    label="Data de abertura"
                    placeholder="Data de abertura"
                    className="form-control"
                    type="date"
                  />
                </Col>
                <Col lg={3} sm={3}>
                  <NumberFormat
                    name="faturamento"
                    label="Faturamento"
                    className="form-control"
                    thousandSeparator="."
                    prefix="R$"
                    decimalSeparator=","
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    className="form-control"
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Select
                    name="tipo_empresa"
                    label="Tipo empresa"
                    placeholder="Tipo empresa"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'MEI',
                        label: 'MEI',
                      },
                      {
                        value: 'EI',
                        label: 'EI',
                      },
                      {
                        value: 'EIREILI',
                        label: 'EIREILI',
                      },
                      {
                        value: 'LTDA',
                        label: 'LTDA',
                      },
                      {
                        value: 'ME',
                        label: 'ME',
                      },
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect
                    name="plano_id_zoop"
                    label="Plano"
                    options={planos}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect name="cnae_mcc" label="MCC" options={mccs} />
                </Col>
              </Row>
              <div className="separator">Endereço</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cep"
                    label="CEP"
                    placeholder="00.000-000"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => getCep(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6}>
                  <Input
                    name="logradouro"
                    label="Logrdouro"
                    placeholder="Logradouro"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="numero"
                    label="Número"
                    placeholder="Número"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="estado"
                    label="Estado"
                    placeholder="Estado"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="separator">
                Dados do representante legal empresa
              </div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_legal_cpf"
                    label="CPF"
                    placeholder="CPF"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>

                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_nome"
                    label="Nome"
                    placeholder="Nome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_sobrenome"
                    label="Sobrenome"
                    placeholder="Sobrenome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_data_nascimento"
                    label="Data nascimento"
                    type="date"
                    placeholder="Data nascimento"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_legal_email"
                    label="E-mail"
                    placeholder="E-mail"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_legal_telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    className="form-control"
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="rg_responsavel"
                    label="RG"
                    placeholder="RG"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="representante_nome_mae"
                    label="Nome mãe"
                    placeholder="Nome mãe"
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="separator">Documento</div>
              <Row>
                <Col lg={3}>
                  <Select
                    name="representante_legal_tipo_documento"
                    label="Tipo do documento"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'RG',
                        label: 'RG',
                      },
                      {
                        value: 'CNH',
                        label: 'CNH',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={6}>
                  <Image
                    name="representante_legal_documento_frente"
                    label="Documento frente"
                    objectFit="fit"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    objectFit="fit"
                    name="representante_legal_documento_verso"
                    label="Documento verso"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    objectFit="fit"
                    name="selfie"
                    label="Selfie"
                    btnSelect="Selecionar"
                  />
                </Col>
              </Row>
              <div className="separator">Endereço</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="representante_cep"
                    label="CEP"
                    placeholder="00.000-000"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => getCep(e.target.value, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_logradouro"
                    label="Logrdouro"
                    placeholder="Logradouro"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="representante_numero"
                    label="Número"
                    placeholder="Número"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="representante_cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="representante_estado"
                    label="Estado"
                    placeholder="Estado"
                    className="form-control"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="separator">Dados da pessoa física</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cpf"
                    label="CPF"
                    placeholder="CPF"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="primeiro_nome"
                    label="Nome"
                    placeholder="Nome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="ultimo_nome"
                    label="Sobrenome"
                    placeholder="Sobrenome"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Select
                    name="sexo"
                    label="Sexo"
                    className="form-control"
                    data={[
                      { label: 'Selecione', value: '' },
                      {
                        label: 'Masculino',
                        value: 'M',
                      },
                      {
                        label: 'Feminino',
                        value: 'F',
                      },
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="rg"
                    label="RG"
                    placeholder="RG"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="rgorgao"
                    label="RG orgão emissor"
                    placeholder="RG orgão emissor"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Select
                    name="rguf"
                    label="RG UF"
                    className="form-control"
                    data={ufs}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="rgdata"
                    label="Data de expedição RG"
                    className="form-control"
                    type="date"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="nome_exibicao"
                    label="Nome de exibição"
                    placeholder="Nome de exibição"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    className="form-control"
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="data_nascimento"
                    label="Data nascimento"
                    type="date"
                    placeholder="Data nascimento"
                    className="form-control"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="nomeMae"
                    label="Nome da mãe"
                    className="form-control"
                    placeholder="Digite o nome completo da mãe"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Input
                    name="nomePai"
                    label="Nome do pai"
                    className="form-control"
                    placeholder="Digite o nome completo do pai"
                  />
                </Col>
                <Col lg={3} sm={3}>
                  <NumberFormat
                    name="faturamento"
                    label="Faturamento"
                    className="form-control"
                    thousandSeparator="."
                    prefix="R$"
                    decimalSeparator=","
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect
                    name="plano_id_zoop"
                    label="Plano"
                    options={planos}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <ReactSelect name="cnae_mcc" label="MCC" options={mccs} />
                </Col>
              </Row>
              <div className="separator">Documento</div>
              <Row>
                <Col lg={3}>
                  <Select
                    name="tipo_documento"
                    label="Tipo do documento"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'RG',
                        label: 'RG',
                      },
                      {
                        value: 'CNH',
                        label: 'CNH',
                      },
                      // {
                      //   value: 'RNE',
                      //   label: 'RNE',
                      // },
                      // {
                      //   value: 'CRNM',
                      //   label: 'CRNM',
                      // },
                      // {
                      //   value: 'DNI',
                      //   label: 'DNI',
                      // },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={6}>
                  <Image
                    name="documento_frente"
                    label="Documento frente"
                    objectFit="fit"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    objectFit="fit"
                    name="documento_verso"
                    label="Documento verso"
                    btnSelect="Selecionar"
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <Image
                    objectFit="fit"
                    name="selfie"
                    label="Selfie"
                    btnSelect="Selecionar"
                  />
                </Col>
              </Row>
              <div className="separator">Endereço</div>
              <Row>
                <Col lg={3} sm={6}>
                  <MaskInput
                    name="cep"
                    label="CEP"
                    placeholder="00.000-000"
                    className="form-control"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={(e) => getCep(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6}>
                  <Input
                    name="logradouro"
                    label="Logrdouro"
                    placeholder="Logradouro"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="numero"
                    label="Número"
                    placeholder="Número"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    className="form-control"
                  />
                </Col>
                <Col lg={5} sm={6}>
                  <Input
                    name="cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    className="form-control"
                  />
                </Col>
                <Col lg={2} sm={6}>
                  <Input
                    name="estado"
                    label="Estado"
                    placeholder="Estado"
                    className="form-control"
                  />
                </Col>
              </Row>
            </>
          )}

          <div className="separator">Conta bancaria</div>
          <Row>
            <Col lg={12} className="mb-3">
              <Radio
                name="abrir_conta"
                label="Abrir conta no UneoPay Bank"
                onChange={(e) => setInformarBanco(e.target.value === 'nao')}
                defaultValue="sim"
                data={[
                  {
                    id: 'sim',
                    value: 'sim',
                    label: 'Sim',
                  },
                  {
                    value: 'nao',
                    id: 'nao',
                    label: 'Não',
                  },
                ]}
              />
            </Col>
            {informarBanco && (
              <>
                <Col lg={12} className="mb-3">
                  <code>
                    * A conta de recebimento deve pertecencer ao CPF/CNPJ do
                    vendedor
                  </code>
                </Col>
                <Col lg={3}>
                  <Input
                    name="agencia"
                    label="Agência"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    name="conta_bancaria"
                    label="Número da conta"
                    className="form-control"
                  />
                </Col>
                <Col lg={3}>
                  <Select
                    name="tipo_conta"
                    label="Tipo da conta"
                    className="form-control"
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'checking',
                        label: 'Corrente',
                      },
                      {
                        value: 'savings',
                        label: 'Poupança',
                      },
                    ]}
                  />
                </Col>
                <Col lg={3}>
                  <ReactSelect
                    name="codigo_banco"
                    label="Banco"
                    options={bancos}
                  />
                </Col>
              </>
            )}
          </Row>

          <div className="separator">Confirmação</div>
          <Row>
            <Col lg={12}>
              <h5>DECLARAÇÕES- CLIENTE</h5>
              <h6 className="texto-b">
                Declaro que são verdadeiras as informações preenchidas neste
                cadastro e estou ciente do valor de R$ 49,99 cobrado mensalmente
                sobre a taxa de aluguel da maquininha UneoPay, me comprometo a
                informar quaisquer alterações que vieram a ocorrer nos meus
                dados cadastrais, responsabilizando-me pela exatidão das
                informações prestadas, a vista dos originais de documentos de
                identificação CPF, comprovante de endereço e outros documentos
                comprobatórios dos demais elementos de informação apresentados.
              </h6>
            </Col>
            <Col lg={12}>
              <Assinatura
                name="assinatura_cliente"
                label="Assinatura cliente"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h5>REPRESENTANTE UNEOPAY</h5>
              <h6 className="texto-b">
                Declaro que atendi o cliente acima e que me certifiquei da
                veracidade e autencidade das informações acima contidas e
                respondo cívil e criminalmente pelas mesmas. Declaro ainda que
                sou responsável pela forma de pagamento realizado a favor da
                UneoPay. Respeitando a lei 9613/98 e circular 3461/09.
              </h6>
            </Col>
            <Col lg={4} md={6} sm={12} className="mt-3">
              <Input
                name="responsavel_conta"
                label="Representante UneoPay"
                className="form-control"
                placeholder="Representante UneoPay"
              />
            </Col>
            <Col lg={12}>
              <Assinatura
                name="assinatura_representante"
                label="Assinatura Representante"
              />
            </Col>
          </Row>
          <></>
          <Row className="justify-content-end">
            <Col lg={3} sm={12}>
              <Button color="primary" type="submit" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default PreCadastro;
