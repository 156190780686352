import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Row, Col } from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { FaCopy, FaLink } from 'react-icons/fa';
import { Form } from '@unform/web';
import useValidator from '../../../hooks/useValidator';
import api from '../../../services/api';
import logo from '../../../assets/images/logo-uneopay.png';
import logoWhite from '../../../assets/images/logo-uneopay-white.png';
import VirtualCard from './virtualCard';
import { verifyCard } from '../../../utils/payments';
import Boleto from './boleto';
import { useTheme } from '../../../contexts/theme';

// import { Container } from './styles';

function Pagamento() {
  const params = useParams();
  const [dados, setDados] = useState({});
  const { isDarkMode } = useTheme();
  const [isPago, setIsPago] = useState(false);
  const [retorno, setRetorno] = useState({});
  const [confiTela, setConfigTela] = useState({ parcelas: 1 });
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const formRef = useRef();
  const debounce = useRef();

  const temRetorno = Object.keys(retorno).length > 0;

  const handleSubmit = async (body) => {
    if (!confiTela?.tipo) {
      return;
    }

    let valorComJuros = Number(dados.valor);

    if (
      confiTela.tipo === 'cartao' &&
      (Number(dados?.parcelado_ate) || 1) !== 1 &&
      confiTela.parcelas >= Number(dados?.juros_parcela_inicio)
    ) {
      valorComJuros = Array.from(
        Array(
          confiTela.parcelas - (Number(dados?.juros_parcela_inicio) - 1)
        ).keys()
      ).reduce(
        (a) => a * (1 + Number(dados.juros_por_parcela) / 100),
        Number(dados?.valor)
      );
    }

    body.vendedor = dados.VendedorZoop.seller_id_zoop;
    body.id_link_pagamento = dados.id;
    body.valor = valorComJuros * 100;
    body.valor_centavos = Number(dados.valor) * 100;
    body.parcelas = confiTela.parcelas || 1;
    body.data_validade = dados.data_expiracao;
    body.moeda = dados.moeda;

    showLoader();
    try {
      const { data } = await api.post(
        `/criar-transacao/${confiTela.tipo}`,
        body
      );
      setRetorno(data);
      if (confiTela.tipo === 'cartao') {
        Swal.fire({
          html: 'Pagamento aprovado com sucesso',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.close();
          }
        });
      } else {
        setDados((old) => ({
          ...old,
          [data?.payment_type]: data,
        }));
        closeLoader();
      }
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  function copyText(text) {
    navigator.clipboard.writeText(text);
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      toast('Texto copiado', { type: 'success' });
    }, 300);
  }

  useEffect(() => {
    const getDadosPagamento = async () => {
      showLoader();
      try {
        const { data } = await api.get(`/link-pagamento/${atob(params?.id)}`);
        const pgto = [
          { value: '', label: 'Selecione' },
          data.data.aceita_cartao && {
            value: 'cartao',
            label: 'Cartão',
          },
          data.data.aceita_pix && {
            value: 'pix',
            label: 'Pix',
          },
          data.data.aceita_boleto && {
            value: 'boleto',
            label: 'Boleto',
          },
        ].filter(Boolean);
        setDados({
          ...data.data,
          pix: data.pix,
          boleto: data.boleto,
          pgto,
        });
        if (pgto.length === 2) {
          setConfigTela((old) => ({
            ...old,
            tipo: pgto[1].value,
          }));
          if (pgto[1].value === 'pix' && data.pix) {
            setRetorno(data.pix);
            return;
          }
          if (pgto[1].value === 'boleto' && data.boleto) {
            setRetorno(data.boleto);
            return;
          }
        }
        closeLoader();
      } catch (err) {
        Swal.fire({
          html: err.mensagem,
          icon:
            err.mensagem === 'Este link de pagamento já foi pago'
              ? 'success'
              : 'error',
          showConfirmButton: true,
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.close();
          }
        });
      }
    };
    getDadosPagamento();
  }, []);

  useEffect(() => {
    if (isPago) {
      Swal.fire({
        html: 'Pagamento concluído com sucesso',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.close();
        }
      });
      return;
    }
    async function getPosts() {
      try {
        const { data } = await api.get(
          `/link-pagamento/${atob(params?.id)}/status`
        );

        if (data.pago) {
          setIsPago(data.pago);
        }
      } catch (err) {
        console.log(err);
      }
    }
    const interval = setInterval(() => {
      getPosts();
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [isPago, params?.id]);

  return (
    <div
      className="d-flex align-items-center justify-content-center bg-gray1"
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
      }}
    >
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <img
                src={isDarkMode ? logoWhite : logo}
                alt="logo uneopay"
                className="object-fit"
                width={200}
                height={50}
              />
            </div>
            <Row className="mt-3">
              <Col lg={6} className="br-md-1 mb-3">
                <div>
                  <div
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.7)',
                    }}
                  >
                    Valor total
                  </div>
                  <div
                    className="h4"
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.7)',
                    }}
                  >
                    {Number(dados?.valor || 0).toLocaleString('pt-BR', {
                      currency: dados.moeda || 'BRL',
                      style: 'currency',
                    })}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.5)',
                    }}
                  >
                    Vendedor
                  </div>
                  <div
                    className="h5"
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.7)',
                    }}
                  >
                    {dados?.VendedorZoop?.Pessoa?.nome}&nbsp;
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.5)',
                    }}
                  >
                    Descrição
                  </div>
                  <div
                    className="h5"
                    style={{
                      fontWeight: 500,
                      color: dados?.descricao
                        ? 'rgba(var(--theme-color-rgb), 0.7)'
                        : '#rgba(var(--theme-color-rgb), 0.5)',
                    }}
                  >
                    {dados?.descricao || 'Não informado'}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.5)',
                    }}
                  >
                    Forma de pagamento
                  </div>
                  <select
                    className="h5"
                    onChange={(e) => {
                      setConfigTela((old) => ({
                        ...old,
                        tipo: e.target.value,
                      }));
                      if (e.target.value === 'pix' && dados.pix) {
                        setRetorno(dados.pix);
                        return;
                      }
                      if (e.target.value === 'boleto' && dados.boleto) {
                        setRetorno(dados.boleto);
                        return;
                      }
                      setRetorno({});
                    }}
                    value={confiTela.tipo}
                    style={{
                      fontWeight: 500,
                      color: 'rgba(var(--theme-color-rgb), 0.7)',
                      border: '1px solid rgba(var(--theme-color-rgb), 0.15)',
                      borderRadius: 6,
                      padding: 5,
                      backgroundColor: 'transparent',
                      maxWidth: 'calc(100vw - 50px)',
                    }}
                  >
                    {(dados?.pgto || [{ label: 'Selecione', value: '' }])?.map(
                      (m) => (
                        <option
                          value={m.value}
                          key={m.label}
                          style={{
                            color: '#000',
                          }}
                        >
                          {m.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                {confiTela?.tipo === 'cartao' &&
                  (Number(dados?.parcelado_ate) || 1) !== 1 && (
                    <div>
                      <div
                        style={{
                          fontWeight: 500,
                          color: 'rgba(var(--theme-color-rgb), 0.5)',
                        }}
                      >
                        Parcelado em:
                      </div>
                      <select
                        className="h5"
                        style={{
                          fontWeight: 500,
                          color: 'rgba(var(--theme-color-rgb), 0.7)',
                          border:
                            '1px solid rgba(var(--theme-color-rgb), 0.15)',
                          borderRadius: 6,
                          padding: 5,
                          backgroundColor: 'transparent',
                          maxWidth: 'calc(100vw - 50px)',
                        }}
                        value={confiTela?.parcelas}
                        onChange={(e) =>
                          setConfigTela((old) => ({
                            ...old,
                            parcelas: e.target.value,
                          }))
                        }
                      >
                        {Array.from(
                          Array(dados?.parcelado_ate || 1).keys()
                        )?.map((m) => {
                          let juros = 0;

                          if (Number(dados?.juros_parcela_inicio) - 1 < m + 1) {
                            juros =
                              Array.from(
                                Array(
                                  m +
                                    1 -
                                    (Number(dados?.juros_parcela_inicio) - 1)
                                ).keys()
                              ).reduce(
                                (a) =>
                                  a *
                                  (1 + Number(dados.juros_por_parcela) / 100),
                                Number(dados?.valor)
                              ) - Number(dados?.valor);
                          }

                          return (
                            <option
                              value={m + 1}
                              key={m}
                              style={{
                                color: 'black',
                              }}
                            >
                              {(Number(dados.valor) + juros).toLocaleString(
                                'pt-BR',
                                {
                                  currency: dados.moeda || 'BRL',
                                  style: 'currency',
                                }
                              )}
                              {' em '}
                              {m + 1}x de{' '}
                              {(
                                (Number(dados.valor) + juros) /
                                (m + 1)
                              ).toLocaleString('pt-BR', {
                                currency: dados.moeda || 'BRL',
                                style: 'currency',
                              })}
                              {` ${juros > 0 ? 'com juros' : 'sem juros'}`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
              </Col>
              {!temRetorno && confiTela?.tipo === 'cartao' && (
                <Col lg={6} className="mb-3">
                  <Form
                    ref={formRef}
                    onSubmit={(e) => {
                      const errors = verifyCard(e);
                      formRef.current.setErrors(errors);
                      if (Object.keys(errors).length > 0) {
                        toast();
                      }
                      const body = {
                        numero_cartao: e.number,
                        nome_no_cartao: e.name,
                        mes_expiracao: e.expiry.split('/')[0],
                        ano_expiracao: e.expiry.split('/')[1],
                        codigo_seguranca: e.cvc,
                        tipo: e.tipo,
                      };
                      handleSubmit(body);
                    }}
                  >
                    <VirtualCard disabled={temRetorno} />
                  </Form>
                </Col>
              )}
              {!temRetorno && confiTela?.tipo === 'boleto' && (
                <Col lg={6} className="mb-3">
                  <Form
                    ref={formRef}
                    onSubmit={(e) => {
                      const { errors, hasError } = validator(e, [
                        'complemento',
                      ]);
                      formRef.current.setErrors(errors);
                      if (hasError) {
                        toast();
                        return;
                      }
                      e.data_nascimento = e.data_nascimento
                        .split('/')
                        .reverse()
                        .join('-');
                      handleSubmit(e);
                    }}
                  >
                    <h5>Dados de cobrança do boleto</h5>
                    <Boleto disabled={temRetorno} formRef={formRef} />
                  </Form>
                </Col>
              )}
              {!temRetorno && confiTela?.tipo === 'pix' && (
                <Col lg={6} className="mb-3">
                  <Form
                    ref={formRef}
                    onSubmit={() => {
                      handleSubmit({});
                    }}
                  >
                    <h5 className="text-center w-100">Aprovação na hora</h5>
                  </Form>
                </Col>
              )}
              {temRetorno && (
                <Col lg={6}>
                  <Row className="h-100 align-items-center">
                    {retorno?.metodo_pagamento_pix_qrcode_objeto && (
                      <Col lg={12} className="mb-3">
                        <>
                          <div className="d-flex justify-content-center">
                            <span
                              style={{
                                backgroundColor: '#fff',
                                padding: 10,
                                paddingBottom: 4,
                              }}
                            >
                              <QRCodeCanvas
                                size={200}
                                value={
                                  retorno?.metodo_pagamento_pix_qrcode_objeto
                                }
                              />
                            </span>
                          </div>
                          <div className="mt-3">
                            <b>Copia e cola: </b>
                            {retorno?.metodo_pagamento_pix_qrcode_objeto}
                            <button
                              type="button"
                              className="btn btn-light-primary btn-sm ml-2"
                              onClick={() => {
                                copyText(
                                  retorno?.metodo_pagamento_pix_qrcode_objeto
                                );
                              }}
                            >
                              Copiar <FaCopy color="currentColor" />
                            </button>
                          </div>
                          <div className="mt-2">
                            <h5>Como pagar com pix</h5>
                            1 - Acesse o app ou site do seu banco
                            <br />
                            2 - Busque a opção de pagar com Pix
                            <br />
                            3 - Leia o QR code ou use o Copia e cola
                            <br />4 - Pronto! Você verá a confirmação do
                            pagamento
                          </div>
                        </>
                      </Col>
                    )}
                    {retorno?.url_boleto && (
                      <Col lg={12}>
                        <h5>
                          Lembre-se de pagar o seu boleto até o dia{' '}
                          {retorno?.data_expiracao
                            ?.split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                          , caso contrário ele será cancelado.
                        </h5>
                        <div className="mt-2 mb-2">
                          <a
                            href={retorno?.url_boleto}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaLink className="mr-2" /> VER BOLETO
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          Código de barras: {retorno?.codigo_barras}
                          <button
                            type="button"
                            className="btn btn-light-primary btn-sm ml-2"
                            onClick={() => {
                              copyText(retorno?.codigo_barras);
                            }}
                          >
                            Copiar <FaCopy color="currentColor" />
                          </button>
                        </div>
                      </Col>
                    )}
                    {['credit', 'debit'].includes(retorno?.payment_type) && (
                      <Col lg={12}>
                        <h4 className="w-100 text-center">
                          Pagamento aprovado
                        </h4>
                        <h5 className="w-100 text-center">
                          Cartão {retorno?.payment_method?.card_brand} final{' '}
                          {retorno?.payment_method?.last4_digits}
                        </h5>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </div>
          <Row className="justify-content-center mb-3">
            <Col lg={6}>
              {temRetorno && (
                <h6 className="text-center">
                  * É possível alterar o método de pagamento caso deseje
                </h6>
              )}
              <button
                className="btn btn-block btn-uneopay"
                type="button"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submitForm();
                  }
                }}
                disabled={!confiTela?.tipo || temRetorno}
              >
                Pagar
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Pagamento;
