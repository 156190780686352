import React, { useRef, useState, useEffect } from 'react';
import { FiEdit, FiEye, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';
import { useAuth } from '../../../../contexts/auth';

function Prontuarios() {
  const { selectedFilial } = useAuth();
  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });

  const [profissional, setProfissional] = useState(null);
  const [profissionais, setProfissionais] = useState([]);

  const filtrosRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );
      if (profissional) {
        const { data } = await api.get(
          `/prontuarios?page=${p + 1}${filtros}&filial=${
            selectedFilial?.filial
          }&id_profissional=${profissional}`
        );
        setDados(data);
      }
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  async function getProfissionais() {
    try {
      if (selectedFilial?.filial) {
        const { data } = await api.get(
          `/profissionais?filial=${selectedFilial?.filial}&options=true`
        );
        setProfissionais(data?.data);
        setProfissional(data?.data[0]?.value);
      }
    } catch (err) {
      toast.error('Erro ao buscar profissionais!');
    }
  }

  useEffect(() => {
    getProfissionais();
  }, [selectedFilial]);

  useEffect(() => {
    handleChangePage(0);
  }, [selectedFilial, profissional]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Prontuários</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link
            className="btn btn-primary"
            to="/erp/prontuario/novo"
            color="primary"
          >
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
          <Row>
            <Col lg={2}>
              <label>Profissional</label>
              <select
                className="form-control"
                name="select"
                defaultValue={6}
                onChange={(e) => setProfissional(e.target.value)}
              >
                {profissionais?.map((p) => (
                  <option value={p?.value}>{p?.label}</option>
                ))}
              </select>
            </Col>
          </Row>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id || m.cpf_cnpj}>
                <td data-label="Nome">{m?.nome}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <Link to={`/erp/prontuario/novo?id=${m?.id}`}>
                        <DropdownItem>
                          <FiEdit className="mr-1" />
                          Editar
                        </DropdownItem>
                      </Link>
                      <Link to={`/erp/prontuario/novo?id=${m?.id}&infos=true`}>
                        <DropdownItem>
                          <FiEye className="mr-1" />
                          Ver informações
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages}
          onPageChange={({ selected }) => handleChangePage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Prontuarios;
