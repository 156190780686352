import React, { useRef, useState, useEffect } from 'react';
import { FiEdit, FiEye, FiFilter, FiSettings, FiTrash2 } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaRegCalendarCheck,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';
import { useAuth } from '../../../../contexts/auth';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function ListaEspera({
  vizualizacaoAgenda,
  setAgendar,
  setModal,
  setModalListaEspera,
}) {
  const { selectedFilial } = useAuth();
  let debounce = null;
  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });

  const filtrosRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      const filtros = Object.entries(filtrosRef.current.getData()).reduce(
        (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
        '&'
      );
      const { data } = await api.get(
        `/listaespera?page=${p + 1}${filtros}&id_filial=${
          selectedFilial?.filial
        }`
      );

      setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  const handleDelete = async (id) => {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir esse item?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          showLoader();
          api
            .delete(`/excluirlistaespera/${id}`)
            .then(() => {
              toast('Excluido com sucesso!', { type: 'success' });
              handleChangePage(0);
              closeLoader();
            })
            .catch((error) => {
              toast(error.mensagem);
              closeLoader();
            });
        }
      });
  };

  useEffect(() => {
    handleChangePage(0);
  }, [selectedFilial]);

  return (
    <>
      <Row hidden={vizualizacaoAgenda}>
        <Col lg={12}>
          <h4 className="mb-3">Lista de espera</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link
            className="btn btn-primary"
            to="/erp/listaespera/novo"
            color="primary"
          >
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Celular</th>
              <th>Observação</th>
              <th>Convênio</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id || m.cpf_cnpj}>
                <td data-label="Nome">{m?.Cliente?.nome}</td>
                <td data-label="E-mail">{m?.email}</td>
                <td data-label="Celular">{m?.celular}</td>
                <td data-label="Observação">{m?.observacao}</td>
                <td data-label="Convênio">{m?.Convenio?.nome}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu
                      end
                      container={vizualizacaoAgenda ? '.modal' : 'body'}
                    >
                      {vizualizacaoAgenda ? (
                        <>
                          <DropdownItem
                            onClick={() => {
                              setAgendar(m);

                              setModal(true);
                              setModalListaEspera(false);
                            }}
                          >
                            <FaRegCalendarCheck className="mr-1" />
                            Agendar
                          </DropdownItem>
                        </>
                      ) : (
                        <>
                          <Link to={`/erp/listaespera/novo?id=${m?.id}`}>
                            <DropdownItem>
                              <FiEdit className="mr-1" />
                              Editar
                            </DropdownItem>
                          </Link>
                          <Link
                            to={`/erp/listaespera/novo?id=${m?.id}&infos=true`}
                          >
                            <DropdownItem>
                              <FiEye className="mr-1" />
                              Ver informações
                            </DropdownItem>
                          </Link>
                        </>
                      )}
                      <DropdownItem onClick={() => handleDelete(m?.id)}>
                        <FiTrash2 className="mr-1" />
                        Excluir
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages}
          onPageChange={({ selected }) => handleChangePage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ListaEspera;
