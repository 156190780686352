import React, { useRef, useState, useEffect } from 'react';
import { FiEdit, FiEye, FiFilter, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaPlay,
} from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, ReactSelectAsync } from '../../../../components/unform/index';
import api from '../../../../services/apiPDV';
import { useValidator } from '../../../../hooks';
import { useAuth } from '../../../../contexts/auth';
import getOptions from '../../../../utils/getOptionsPDV';

function Profissionais() {
  const { selectedFilial } = useAuth();
  let debounce = null;

  const [modal, setModal] = useState(false);

  const [dados, setDados] = useState({
    data: [],
    total: 0,
    pages: 1,
  });

  const history = useHistory();

  const filtrosRef = useRef();
  const formRefAtendimento = useRef();

  const { showLoader, closeLoader, toast } = useValidator();

  const toggleModal = () => {
    setModal(!modal);
  };

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const handleChangePage = async (p) => {
    try {
      showLoader();

      // const filtros = Object.entries(filtrosRef.current.getData()).reduce(
      //   (a, [key, value]) => (value ? `${a}${key}=${value}&` : a),
      //   '&'
      // );
      // const { data } = await api.get(
      //   `/atendimentos?page=${p + 1}${filtros}&id_filial=${
      //     selectedFilial?.filial
      //   }`
      // );
      // setDados(data);
    } catch (err) {
      toast(err.mensagem);
    }
    closeLoader();
  };

  useEffect(() => {
    handleChangePage(0);
  }, [selectedFilial]);

  function inciarAtendimento(e) {
    history.push(
      `/erp/atendimento/novo?pa=${e?.id_paciente}&pr=${e?.id_prontuario}&co=${e?.id_convenio}&agenda=false`
    );
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Atendimentos</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="btn"
            color="outline-success"
            block
            type="button"
            onClick={() => {
              // toggleModal();
              history.push('/erp/atendimento/novo?pa=1&pr=3&co=5&agenda=false'); // Mudar aqui depois
            }}
          >
            Iniciar atendimento
            <FaPlay size={10} className="ml-3" />
          </Button>
        </Col>
      </Row>
      <hr />
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          {/* <Link
            className="btn btn-primary"
            to="/erp/prontuario/novo"
            color="primary"
          >
            Adicionar
          </Link> */}
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                handleChangePage(0);
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m.id || m.cpf_cnpj}>
                <td data-label="Nome">{m?.nome}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <Link to={`/erp/prontuario/novo?id=${m?.id}`}>
                        <DropdownItem>
                          <FiEdit className="mr-1" />
                          Editar
                        </DropdownItem>
                      </Link>
                      <Link to={`/erp/prontuario/novo?id=${m?.id}&infos=true`}>
                        <DropdownItem>
                          <FiEye className="mr-1" />
                          Ver informações
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados.pages}
          onPageChange={({ selected }) => handleChangePage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Iniciar atendimento</ModalHeader>
        <Form
          ref={formRefAtendimento}
          onSubmit={(e) => {
            inciarAtendimento(e);
          }}
        >
          <ModalBody>
            <FormGroup>
              <ReactSelectAsync
                label="Paciente"
                name="id_paciente"
                isClearable
                loadOptions={async (value) => {
                  const data = await getOptions({
                    value,
                    route: '/options-pacientes',
                    idFilial: selectedFilial?.filial,
                  });
                  return data;
                }}
              />
            </FormGroup>
            <FormGroup>
              <ReactSelectAsync
                label="Prontuário"
                name="id_prontuario"
                isClearable
                loadOptions={async (value) => {
                  const data = await getOptions({
                    value,
                    route: '/options-prontuarios',
                    idFilial: selectedFilial?.filial,
                  });
                  return data;
                }}
              />
            </FormGroup>
            <FormGroup>
              <ReactSelectAsync
                label="Convênio"
                name="id_convenio"
                isClearable
                loadOptions={async (value) => {
                  const data = await getOptions({
                    value,
                    route: '/convenios',
                    idFilial: selectedFilial?.filial,
                  });
                  return data;
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Iniciar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Profissionais;
