/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Row,
  Input,
  CardTitle,
  CardDeck,
} from 'reactstrap';
import api from '../../../../services/apiPDV';
import { useAuth } from '../../../../contexts/auth';

const Dashboard = () => {
  const { selectedFilial } = useAuth();
  const [mes, setMes] = React.useState(moment().month() + 1);
  const [dados, setDados] = React.useState(null);

  function calculaPercentual(val1, val2) {
    if (Number(val1 || 0) === 0 || Number(val2 || 0) === 0) {
      return 0;
    }

    return (Number(val2) / Number(val1)) * 100;
  }

  useEffect(() => {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    async function initData() {
      try {
        const ano = new Date().getFullYear();
        const { data } = await api.get(
          `/dashboard/?ano=${ano}&mes=${mes}&filial=${selectedFilial?.filial}`
        );
        setDados(data);
      } catch (e) {
        toast.error(e.message || 'Erro ao carregar dados!');
      } finally {
        Swal.close();
      }
    }
    initData();
  }, [mes, selectedFilial]);

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Card
      className="mb-2"
      style={{ backgroundColor: 'transparent', padding: 0 }}
    >
      <Row>
        <Col lg={12}>
          <div className="hr-text hr-text-center mt-2 mb-0">
            <span className="theme-active">Movimentação Financeira</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <div className="d-flex mt-0 mb-2">
            <Input
              type="select"
              onChange={async (e) => {
                setMes(e.target.value);
              }}
              defaultValue={mes}
              style={{ marginRight: 5 }}
            >
              <option value="1">Janeiro</option>
              <option value="2">Fevereiro</option>
              <option value="3">Março</option>
              <option value="4">Abril</option>
              <option value="5">Maio</option>
              <option value="6">Junho</option>
              <option value="7">Julho</option>
              <option value="8">Agosto</option>
              <option value="9">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </Input>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h6" className="mb-1">
                Receitas
              </CardTitle>
              <div>
                <div className="mb-3">
                  <h2>
                    {Number(
                      dados?.receitasRealizadas[0]?.valor || 0
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h2>
                </div>
                <div>
                  Realizado/Meta
                  {calculaPercentual(
                    dados?.receitaProjetada[0]?.valor,
                    dados?.receitasRealizadas[0]?.valor
                  ) >= 0 ? (
                    <FaCaretUp className="text-success" />
                  ) : (
                    <FaCaretDown className="text-danger" />
                  )}
                  {calculaPercentual(
                    dados?.receitaProjetada[0]?.valor,
                    dados?.receitasRealizadas[0]?.valor
                  ).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  %
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h6" className="mb-1">
                Despesas
              </CardTitle>
              <div>
                <div className="mb-3">
                  <h2>
                    {Number(
                      dados?.despesasRealizadas[0]?.valor || 0
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </h2>
                </div>
                <div>
                  Realizado/Meta{' '}
                  {calculaPercentual(
                    dados?.despesaProjetada[0]?.valor,
                    dados?.despesasRealizadas[0]?.valor
                  ) >= 0 ? (
                    <FaCaretUp className="text-danger" />
                  ) : (
                    <FaCaretDown className="text-success" />
                  )}
                  {calculaPercentual(
                    dados?.despesaProjetada[0]?.valor,
                    dados?.despesasRealizadas[0]?.valor
                  ).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  %
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h6" className="mb-1 text-center">
                Resultado
              </CardTitle>
              <div>
                <div className="mb-3">
                  <h1 className="mt-3 text-center">
                    R${' '}
                    {Number(dados?.resultadoRealizado).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h1>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12}>
          <CardDeck>
            <Card className="mb-1">
              <CardBody>
                <CardTitle className="mb-1 d-flex">
                  <h6 className="text-center w-100 bold">Fluxo de caixa</h6>
                </CardTitle>
                <Row>
                  <Col lg={6} md={12} className="mb-5 px-0">
                    <CardTitle className="mb-1 d-flex">
                      <h5 className="text-center w-100 text-green">Receitas</h5>
                    </CardTitle>
                    <ResponsiveContainer
                      width="100%"
                      aspect={window.screen.width > 768 ? 3 : 1}
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={[
                          {
                            name: 'Receitas',
                            Realizado: Number(
                              dados?.receitasRealizadas[0]?.valor || 0
                            ),
                            Projetado: Number(
                              dados?.receitaProjetada[0]?.valor || 0
                            ),
                          },
                        ]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                          width={100}
                          tickFormatter={(value) =>
                            currencyFormatter.format(value)
                          }
                        />
                        <Tooltip
                          labelFormatter={() => ''}
                          formatter={(value) => currencyFormatter.format(value)}
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="bottom"
                          align="center"
                          wrapperStyle={{
                            position: 'relative',
                          }}
                        />
                        <Bar dataKey="Realizado" fill="#33cc33" />
                        <Bar dataKey="Projetado" fill="var(--color-primary" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col lg={6} md={12} className="px-0">
                    <CardTitle className="mb-1 d-flex">
                      <h5 className="text-center w-100 text-red">Despesas</h5>
                    </CardTitle>
                    <ResponsiveContainer
                      width="100%"
                      aspect={window.screen.width > 768 ? 3 : 1}
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={[
                          {
                            name: 'Despesas',
                            Realizado: Number(
                              dados?.despesasRealizadas[0]?.valor || 0
                            ),
                            Projetado: Number(
                              dados?.despesaProjetada[0]?.valor || 0
                            ),
                          },
                        ]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                          width={100}
                          tickFormatter={(value) =>
                            currencyFormatter.format(value)
                          }
                        />
                        <Tooltip
                          labelFormatter={() => ''}
                          formatter={(value) => currencyFormatter.format(value)}
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="bottom"
                          align="center"
                          wrapperStyle={{
                            position: 'relative',
                          }}
                        />
                        <Bar dataKey="Realizado" fill="#ff3300" />
                        <Bar dataKey="Projetado" fill="var(--color-primary)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardDeck>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="hr-text hr-text-center mt-1 mb-2">
            <span className="theme-active">Contratos</span>
          </div>
        </Col>
        <Col lg={3}>
          <Link to="/contrato?status=">
            <Card className="mb-3">
              <CardBody>
                <CardTitle tag="h6" className="mb-0 text-center">
                  Total
                </CardTitle>
                <div>
                  <div className="mb-0">
                    <h3 className="text-center">
                      {dados?.contratosVigente[0]?.count}
                    </h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/contrato">
            <Card className="mb-3">
              <CardBody>
                <CardTitle tag="h6" className="mb-0 text-center text-success">
                  Novos
                </CardTitle>
                <div>
                  <div className="mb-0">
                    <h3 className="text-center">
                      {dados?.contratosNovos[0]?.count}
                    </h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/contrato">
            <Card className="mb-3">
              <CardBody>
                <CardTitle tag="h6" className="mb-0 text-center text-warning">
                  Vencendo
                </CardTitle>
                <div>
                  <div className="mb-0">
                    <h3 className="text-center">
                      {dados?.contratosVencendo[0]?.count}
                    </h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/contrato?status=Vencido">
            <Card className="mb-3">
              <CardBody>
                <CardTitle tag="h6" className="mb-0 text-center text-danger">
                  Vencidos
                </CardTitle>
                <div>
                  <div className="mb-0">
                    <h3 className="text-center">
                      {dados?.contratosVencido[0]?.count}
                    </h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </Card>
  );
};

export default Dashboard;
