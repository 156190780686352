import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import CampoTextoCurto from './CampoTextoCurto';

export default function PrescricaoOculos() {
  return (
    <div className="mb-3 component-render">
      <label>Prescrição de óculos</label>
      <Table responsive>
        <thead>
          <tr>
            <th className="text-center">Longe</th>
            <th className="text-center">Esférico</th>
            <th className="text-center">Cilíndrico</th>
            <th className="text-center">Eixo</th>
            <th className="text-center">DNP</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              <h6 className="mt-4">O.D.</h6>
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_longe_direito_esferico"
                type="number"
              />
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_longe_direito_cilindrico"
                type="number"
              />
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_longe_direito_eixo"
                  type="number"
                />
                <span className="help-text">°</span>
              </div>
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_longe_direito_dnp"
                  type="number"
                />
                <span className="help-text">mm</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <h6 className="mt-4">O.E.</h6>
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_longe_esquerdo_esferico"
                type="number"
              />
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_longe_esquerdo_cilindrico"
                type="number"
              />
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_longe_esquerdo_eixo"
                  type="number"
                />
                <span className="help-text">°</span>
              </div>
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_longe_esquerdo_dnp"
                  type="number"
                />
                <span className="help-text">mm</span>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <Table responsive>
        <thead>
          <tr>
            <th className="text-center">Perto</th>
            <th className="text-center">Esférico</th>
            <th className="text-center">Cilíndrico</th>
            <th className="text-center">Eixo</th>
            <th className="text-center">DNP</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              <h6 className="mt-4">O.D.</h6>
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_perto_direito_esferico"
                type="number"
              />
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_perto_direito_cilindrico"
                type="number"
              />
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_perto_direito_eixo"
                  type="number"
                />

                <span className="text-center help-text">°</span>
              </div>
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_perto_direito_dnp"
                  type="number"
                />
                <span className="text-center help-text">mm</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <h6 className="mt-4">O.E.</h6>
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_perto_esquerdo_esferico"
                type="number"
              />
            </td>
            <td>
              <CampoTextoCurto
                className="form-control"
                name="oculos_perto_esquerdo_cilindrico"
                type="number"
              />
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_perto_esquerdo_eixo"
                  type="number"
                />
                <span className="help-text">°</span>
              </div>
            </td>
            <td>
              <div className="justify-between">
                <CampoTextoCurto
                  className="form-control"
                  name="oculos_perto_esquerdo_dnp"
                  type="number"
                />
                <span className="help-text">mm</span>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
