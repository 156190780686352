import { useLocation } from 'react-router-dom';

const useSearch = () => {
  const location = useLocation();
  const body = {};
  const search = location.search.replace('?', '').split('&');
  search.forEach((f) => {
    const [prop, val] = f.split('=');

    body[prop] = val;
  });

  return body;
};
export default useSearch;
